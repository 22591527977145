/*
 * INTEL CONFIDENTIAL
 * Copyright 2022 Intel Corporation.
 * This software and the related documents are Intel copyrighted materials, and
 * your use of them is governed by the express license under which they were
 * provided to you (License). Unless the License provides otherwise, you may not
 * use, modify, copy, publish, distribute, disclose or transmit this software or
 * the related documents without Intel's prior written permission. This software
 * and the related documents are provided as is, with no express or implied
 * warranties, other than those that are expressly stated in the License.
 */
import React, { Component } from 'react'
import { connect } from 'react-redux'
import {Container, Row, Col} from 'react-bootstrap'
import { Formik, Form } from 'formik';

import * as Yup from 'yup';
import { registerUser, register, resetErrorMessages, clearResendState } from '../../actions/user'
import { codeRegEx, passwordRegex, testPasswordStrength} from '@pcwallet/common/validationEngine';
import { analyticsPageView } from '../../utils'
import { Link } from 'react-router-dom'
import Button from '../inputs/Button';
import CheckBox from '../inputs/CheckBox'
import ErrorMessage from '../shared/ErrorMessage';
import Notification from '../shared/Notification';
import TextInput from '../inputs/TextInput';

export class RegisterUser extends Component {

  constructor(props) {
    super(props)
    this.state = {
        showToast:false,
        passwordHide: true,
        confirmPasswordHide: true,
        strengthResult:0,
      }
      this.showToast=this.showToast.bind(this);
      this.togglePasswordHide = this.togglePasswordHide.bind(this);
      this.toggleConfirmPasswordHide = this.toggleConfirmPasswordHide.bind(this);
      this.handleStrengthTest = this.handleStrengthTest.bind(this);
  }

  showToast(){
    this.setState({
        showToast:true,
      })
  }

  togglePasswordHide() {
    this.setState({ passwordHide: !this.state.passwordHide });
  }

  toggleConfirmPasswordHide() {
    this.setState({ confirmPasswordHide: !this.state.confirmPasswordHide });
  }

  handleStrengthTest(event,handleChange){
    if(event.target.value != null){
        let testResult = testPasswordStrength(event.target.value)
        this.setState(({strengthResult: testResult}))
    }
    handleChange(event)
  }

  componentDidMount(){
    //initializing analytics
    analyticsPageView('/registerUser');
  }

  componentWillUnmount(){
    this.props.resetErrorMessages()
  }

  render() {
    const { history } = this.props;
    const registerUserSchema = Yup.object().shape({
            otp : Yup.string()
              .label('Registration Code')
              .matches(codeRegEx,'code format is invalid')
              .required('Required'),
              password: Yup.string()
                .label('Password')
                .min(8, 'Minimum password length is 8 characters')
                .max(64, 'Maxmimum password length is 64 characters')
                .matches(passwordRegex, 'Password contains not allowed special character')
                .required('Required'),
              confirmPassword: Yup.string()
                .label('Confirm password')
                .oneOf([Yup.ref('password'), null], 'Confirm Password does not match')
                .required('Required'),
              acceptedTerms: Yup.bool().oneOf([true], 'Required to accept terms and conditions to continue'), 
            }
    )
    return (
    <div className="wrapper">
      <div className="content">
          {
            this.props.user.resendCode?
            <Notification 
              show={this.state.showToast} 
              hide={()=>{
                this.setState({showToast:false})
                this.props.clearResendState()
              }} 
              msg="Code succesfully resent to provided email"> 
            </Notification>
            :
            <></>
          }
          {
            this.props.user.error && this.props.user.errorMessage?
            <ErrorMessage
              show = {this.props.user.error}
              msg = {this.props.user.errorMessage}
              onClose = {()=>{
                  this.props.resetErrorMessages()
              }}
            />
            :<></>
          }
      <div className="main-wrapper">
      <h1 className="px-5">Create Password</h1>
      <p className="divider my-4"/>
        <div className="px-5 pb-5"> 
          <p className="pb-1 text-left">
          We sent a code to the email address you provided. Please enter the code below and set up a password for your account.
          </p>
          <p className="pb-3">Didn’t receive a code? Check your spam folder or <span className='link' onClick={()=>{
            this.props.register({
              resendCode:true,
              username:this.props.user.email.trim(),
              history,
              callback:this.showToast
            })}}> resend the code</span>.</p>
          
          <Formik
            initialValues={{
              otp:'',
              password: '',
              confirmPassword: '',
              acceptedTerms: false, 
            }}
            validationSchema={registerUserSchema}
            onSubmit={(values, {setSubmitting}) => {
                this.props.registerUser({
                  username: this.props.user.email.trim(),
                  password: values.password.trim(),
                  otp: values.otp.trim(),
                  devicePort: this.props.devicePortDiscovery.devicePort,
                  history
                })
                setTimeout(()=>{
                  setSubmitting(false) 
              },1000)
            }}
          >
            {({ isValid, dirty, isSubmitting, handleChange }) => (
              <Form>
                <Container fluid className="p-1 pt-0">
                  <label className='form-required-instruction-label'>All Fields Required</label>
                  <div>
                    <TextInput
                        label="Registration Code"
                        name="otp"
                        type="text"
                      />
                    <TextInput
                      label="Password"
                      name="password"
                      type={this.state.passwordHide ? 'password' : 'text'}
                      toggleshow={this.togglePasswordHide}
                      hidestate={this.state.passwordHide.toString()}
                      onChange={event=>{
                        this.handleStrengthTest(event,handleChange)
                      }}
                      result={this.state.strengthResult}
                      fieldname="password"
                    />
                    <TextInput
                      label="Confirm Password"
                      name="confirmPassword"
                      type={this.state.confirmPasswordHide ? 'password' : 'text'}
                      toggleshow={this.toggleConfirmPasswordHide}
                      hidestate={this.state.confirmPasswordHide.toString()}
                    />
                  </div>
                  <Row>
                      <Col>
                      </Col>
                      <Col sm={9} className="ml-5 pl-4">
                        <ul className="px-3 mb-1">
                          <li>Minimum of 8 characters.</li>
                          <li>Maximum of 64 characters.</li>
                          <li>Any combination of letters, numerics and allowed special characters <br/> (! @ # $ % ^ &amp; * ! () - _ + ).</li>
                        </ul>
                      </Col>
                  </Row>
                  <Row>
                    <Col></Col>
                    <Col sm={9} className="ml-5 pl-4">
                    <br></br>
                    <div className ="d-flex align-items-center">
                      <CheckBox 
                      name="acceptedTerms"
                      type="checkbox"
                      onChange={handleChange}>
                        <label className ="pl-2 mb-0"> I am 18 years or older and agree to Intel Pay <span className="link" onClick={()=>{window.open("https://www.intel.com/content/www/us/en/legal/terms-of-use.html")}}> Terms of Use</span>,
                        <span className="link" onClick={()=>{window.open("https://www.intel.com/content/www/us/en/privacy/intel-cookie-notice.html")}}> Cookie</span> &amp;<span className="link" onClick={()=>{window.open("https://www.intel.com/content/www/us/en/privacy/intel-privacy-notice.html")}}> Privacy Policy</span>
                      </label>
                      </CheckBox>
                  </div>                 
                  </Col>
                  </Row>
                <div>
                <div className= "d-flex justify-content-between mt-3">
                  <div className="mt-3">
                    <p>Already Registered? <Link to="/login" className='link'>Sign In</Link></p>
                    </div>  
                    <div>
                      <Button
                        type="submit"
                        disabled={  isSubmitting || !dirty || !isValid || this.props.user.isLoading}
                      >
                        {this.props.user.isLoading && <span className="spinner-border spinner-border-sm mr-1" role="status" aria-hidden="true" />}
                        Next
                      </Button>
                      </div>
                </div>
                </div>
                </Container>
              </Form>
            )
            }
          </Formik>
        </div>
    </div>
    </div>
    </div>
    )
  }
}

const mapStateToProps = state => ({
  user: state.user,
  devicePortDiscovery: state.devicePortDiscovery
})

const mapDispatchToProps = dispatch => ({
  registerUser:(data)=>dispatch(registerUser(data)),
  register: (data) => dispatch(register(data)),
  resetErrorMessages: () => dispatch(resetErrorMessages()),
  clearResendState: () => dispatch(clearResendState()),
})

export default connect(mapStateToProps,mapDispatchToProps)(RegisterUser)

