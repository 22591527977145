/*
 * INTEL CONFIDENTIAL
 * Copyright 2022 Intel Corporation.
 * This software and the related documents are Intel copyrighted materials, and
 * your use of them is governed by the express license under which they were
 * provided to you (License). Unless the License provides otherwise, you may not
 * use, modify, copy, publish, distribute, disclose or transmit this software or
 * the related documents without Intel's prior written permission. This software
 * and the related documents are provided as is, with no express or implied
 * warranties, other than those that are expressly stated in the License.
 */
import React, { Component } from 'react'
import { Container, Row, Col} from 'react-bootstrap'
import { connect } from 'react-redux'
import { Link } from 'react-router-dom'
import { Formik, Form } from 'formik';
import * as Yup from 'yup';

import { resetPassword, resetPasswordCanceled, forgotPassword, resetErrorMessages} from '../actions/user'
import {codeRegEx, passwordRegex, emailRegEx, emailLengthValidator, testPasswordStrength }  from '@pcwallet/common/validationEngine'
import { analyticsPageView } from '../utils'

import Button from './inputs/Button'
import ErrorMessage from './shared/ErrorMessage';
import Notification from './shared/Notification';
import TextInput from './inputs/TextInput';

export class ResetPasswordContainer extends Component {

  constructor(props) {
    super(props)
    this.state = {
        passwordHide: true,
        confirmPasswordHide: true,
        showToast:false
      }
      this.togglePasswordHide = this.togglePasswordHide.bind(this);
      this.toggleConfirmPasswordHide = this.toggleConfirmPasswordHide.bind(this);
      this.handleStrengthTest = this.handleStrengthTest.bind(this);
      this.showNotification = this.showNotification.bind(this);
      this.hideNotification = this.hideNotification.bind(this);
      this.resetCanceled = this.resetCanceled.bind(this);
  }

  togglePasswordHide() {
    this.setState({ passwordHide: !this.state.passwordHide });
  }

  toggleConfirmPasswordHide() {
    this.setState({ confirmPasswordHide: !this.state.confirmPasswordHide });
  }
  resetCanceled(){
    this.props.resetPasswordCanceled();
    this.props.history.push('/forgot-password');
  }

  handleStrengthTest(event,handleChange){
    if(event.target.value != null){
        let testResult = testPasswordStrength(event.target.value)
        this.setState(({strengthResult: testResult}))
    }
    handleChange(event)
  }

  showNotification(){
    this.setState({showToast:true})
  }

  hideNotification () {
    this.setState({showToast:false})  
  }

  componentDidMount(){
    //initializing analytics
      analyticsPageView('/reset-password');
  }

  componentWillUnmount(){
    this.props.resetErrorMessages()
  }

  render() {
    const { history } = this.props;
    return (
      <div className="wrapper">
          <div className="content">
          {
              this.props.resetPasswordError && this.props.resetPasswordErrorMessage?
              <ErrorMessage
                show = {this.props.resetPasswordError}
                msg = {this.props.resetPasswordErrorMessage}
                onClose = {()=>{
                    this.props.resetErrorMessages()
                }}
              />
              :<></>
            }
          <div className="main-wrapper">
            <h1 className="px-5" >Reset Password</h1>
            <p className="divider my-4"/>
            {
              this.props.user.resendCode?
              <Notification 
              show={this.state.showToast}
              hide={this.hideNotification}
              msg={"Code succesfully resent to provided email"}/>
              :
              <></>
            }
            <div className="px-5 pb-5">
              <p className="pb-1 text-left">
                We sent a code to the email address you provided. Please enter the code below to create a new password.
              </p>
              <Formik
              initialValues={{
                  username:this.props.user.username?this.props.user.username:'',
                  resetCode:'',
                  password:'',
                  confirmPassword:''
              }}
              validationSchema={Yup.object({
                username: Yup.string()
                .email('invalid email address')
                .test('valid email length','maximum character length exceeded',function(value){
                    return emailLengthValidator(value)
                  })
                .matches(emailRegEx,'email contains invalid character')
                .required('Required'),
                resetCode: Yup.string()
                .matches(codeRegEx,'code format is invalid')
                .required('Required'),
                password: Yup.string()
                .label('Password')
                .min(8, 'Minimum password length is 8 characters')
                .max(64, 'Maxmimum password length is 64 characters')
                .matches(passwordRegex, 'You need at least one uppercase letter, one lowercase letter, one number and one special character')
                .required('Required'),
                confirmPassword: Yup.string()
                .label('Confirm password')
                .test('passwords-match', 'Passwords must match.', function(value) {
                  return this.parent.password === value;
                })
                .required('Required')
              })}
              onSubmit={(values,{resetForm}) => {
                  this.props.resetPassword({
                    username:values.username.trim().toLowerCase(),
                    password:values.password.trim(),
                    otp:values.resetCode.trim(),
                    history
                  })
                  setTimeout(()=>{
                    resetForm()
                  },1000)       
              }}
              >
                {({values, dirty, isValid, isSubmitting, handleChange, resetForm}) => ( 
                  <Form>
                    <p className="pb-5">Didn’t receive a code? Check your spam folder or &nbsp;
                      { 
                      this.props.user.username?
                        <span
                            className='link' 
                            onClick={() => {this.props.forgotPassword({
                                username:this.props.user.username,
                                resendCode:true,
                                callback:this.showNotification
                              })
                              resetForm();
                            }}>
                            Resend Code
                        </span>
                        :
                        <Link to ="/forgot-password" className='link'>Resend Code</Link>
                      }
                    </p>
                    <Container fluid className="p-1 pt-0">
                      <label className='form-required-instruction-label'>All Fields Required</label>
                        <TextInput
                          label="Email Address"
                          name="username"
                          type="email"
                          placeholder="email@website.com"
                          disabled={this.props.user.username?true:false}
                        />
                        <TextInput
                          label="Verification Code"
                          name="resetCode"
                          placeholder="xxxxxx"
                        />
                        <TextInput
                          label="New Password"
                          name="password"
                          type={this.state.passwordHide ? 'password' : 'text'}
                          toggleshow={this.togglePasswordHide}
                          hidestate={this.state.passwordHide.toString()}
                          placeholder="Password"
                          onChange={event=>{
                            this.handleStrengthTest(event,handleChange)
                          }}
                          result={this.state.strengthResult}
                          fieldname="password"
                        />
                        <TextInput
                          label="Confirm New Password"
                          name="confirmPassword"
                          type={this.state.confirmPasswordHide ? 'password' : 'text'}
                          toggleshow={this.toggleConfirmPasswordHide}
                          hidestate={this.state.confirmPasswordHide.toString()}
                          placeholder="Confirm Password"
                        />
                    <Row>
                      <Col>
                      </Col>
                      <Col sm={9} className="ml-5 pl-4">
                        <ul className="px-3 mb-1">
                          <li>Minimum of 8 characters.</li>
                          <li>Maximum of 64 characters.</li>
                          <li>Any combination of letters, numerics and allowed special characters <br/> (! @ # $ % ^ &amp; * ! () - _ + ).</li>
                        </ul>
                      </Col>
                    </Row>
                    <div className="button-wrapper button-wrapper d-flex justify-content-between">
                        <button
                          onClick={this.resetCanceled}
                          className="btn btn-outline-primary btn-lg button-outline"
                          type="button"
                        >
                          Cancel
                        </button>                
                      <Button
                          disabled={  isSubmitting || !dirty || !isValid || this.props.isLoading}
                          type="submit"
                      >
                        {this.props.isLoading && <span className="spinner-border spinner-border-sm mr-1" role="status" aria-hidden="true" />}
                        Reset Password
                      </Button>
                    </div>
                      </Container>
                  </Form>
                )}
              </Formik>
            </div>
          </div>
        </div>
    </div>
    ); 
  }
}

const mapStateToProps = state => ({
  user:state.user,
  resetPasswordErrorMessage: state.user.errorMessage,
  resetPasswordError: state.user.error,
  isLoading: state.user.isResetPwdLoading
})

const mapDispatchToProps = (dispatch) => ({
  resetPassword: (data) => dispatch(resetPassword(data)),
  resetPasswordCanceled: () => dispatch(resetPasswordCanceled),
  forgotPassword : (data) => dispatch(forgotPassword(data)),
  resetErrorMessages: () => dispatch(resetErrorMessages()),
})

export default connect(mapStateToProps, mapDispatchToProps )(ResetPasswordContainer)
