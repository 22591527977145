
// From iframe to portal
export const GET_INFO = "GET_INFO";         // {cmd:GET_INFO}
export const SEND_DECISION = "SEND_DECISION" // {cmd:SEND_DECISION, tokenId: tokenId, decision: APPROVED | REQUIRE_ADDITIONAL_AUTHENTICATION, verificationMethods: []}
export const SEND_BACK = "SEND_BACK" // {cmd:SEND_BACK}
export const SHOW_NOTIFICATION = "SHOW_NOTIFICATION" // {cmd:SHOW_NOTIFICATION}
export const HIDE_NOTIFICATION = "HIDE_NOTIFICATION" // {cmd:HIDE_NOTIFICATION}
export const SEND_SKIP = "SEND_SKIP" // {cmd:SEND_SKIP}


// from portal to iframe
export const SEND_INFO = "SEND_INFO";         // {cmd:SEND_INFO, deviceId: number, publicId: string, token: string, deviceToken: string, setup: boolean}