/*
 * INTEL CONFIDENTIAL
 * Copyright 2022 Intel Corporation.
 * This software and the related documents are Intel copyrighted materials, and
 * your use of them is governed by the express license under which they were
 * provided to you (License). Unless the License provides otherwise, you may not
 * use, modify, copy, publish, distribute, disclose or transmit this software or
 * the related documents without Intel's prior written permission. This software
 * and the related documents are provided as is, with no express or implied
 * warranties, other than those that are expressly stated in the License.
 */
import React, { Component } from 'react'
import { connect } from 'react-redux'
import * as constants from '@pcwallet/common/constants'
import { Redirect } from 'react-router';
import { Link } from 'react-router-dom';

import { clearCardState, getCardListFromServer, resetErrorMessages, startAddCard, verifyCard} from '../../actions/cards';
import { queryStatusFromDevice, reportWalletSetupProgress } from '../../actions/devices'
import { BiErrorCircle } from 'react-icons/bi'
import * as messages from '@pcwallet/common/endUserMessages'
import { getNextComponent, getPreviousComponent, isStepCompleted } from './WalletSetupProgressUtil';import SetupProgressIndicator from '../shared/SetupProgressIndicator';
import { analyticsPageView } from '../../utils';

import Button from '../inputs/Button';
import CardEnrollmentIFrame from './CardEnrollmentIframe';
import Loading from '../shared/Loading';
import ErrorMessage from '../shared/ErrorMessage';
import VerifyCard from '../enrollment/VerifyCard';

export class AddCardContainer extends Component {

    constructor(props) {
        super(props)
        this.state = {
            isLoading:true,
            disableButton:false,
            proceedTo:null,
            backTo:null,
            cardActivated:false,
            setup:false
        }
        this.handleNavigation = this.handleNavigation.bind(this)

    }


    componentDidMount(){
        //initializing analytics
        if(this.props.cards.cardState === constants.CARD_STATE_ADD){
            analyticsPageView('/add-card')
        }
        if(this.props.devices.connected && this.props.devices.connected.walletSetupProgress){
            if(this.props.devices.connected.walletSetupProgress !== constants.DONE) {
                this.setState({ setup : true })
                    this.handleNavigation(constants.CARD_DIGITIZE)
            }
            else{
                this.setState({isLoading:false}) 

            } 
                        
        }       
    }

    handleNavigation(data) {
        this.props.reportWalletSetupProgress({
            deviceId:this.props.devices.connected.deviceId,
            walletSetupProgress:data,
            callback:()=>{
                let walletSetupProgress = this.props.devices.connected.walletSetupProgress;
                let proceedTo = getNextComponent(this.props.devices.connected.walletSetupStatus, walletSetupProgress);
                this.setState({proceedTo : proceedTo})
                let backTo = getPreviousComponent(this.props.devices.connected.walletSetupStatus, walletSetupProgress)
                this.setState({backTo : backTo})
                this.setState({isLoading:false}) 
            }
        })
    }

    componentDidUpdate(prevProps) {
        if(this.props.cards.error){
            setTimeout(()=>{
                this.setState({disableButton:false})
            },1000)  
        }
    }
    
    render() {
        const { history } = this.props;
        return (
            <div>
                {
                 this.props.devices.isLoading || this.props.cards.isLoading || this.state.isLoading ?
                Loading()
                :
                <div className="wrapper">
                <div className="content">
                {
                    this.state.setup && this.props.devices.connected && this.props.devices.connected.walletSetupProgress === constants.CARD_DIGITIZE?
                    <SetupProgressIndicator 
                        devices={this.props.devices}
                        isStepCompleted={isStepCompleted}
                        cardState={this.props.cards.cardState}
                        />
                    :
                    <></>
                }
                {
                        (this.props.cards.error && this.props.cards.errorMessage) || (this.props.verifyState.error && this.props.verifyState.errorMessage)?
                        <ErrorMessage
                        error={true}
                        show={this.props.cards.error || this.props.verifyState.error}
                        autohide={false}
                        msg={this.props.cards.errorMessage || this.props.verifyState.errorMessage}                        onClose = {()=>{
                            this.props.resetErrorMessages()
                        }}
                        />
                        :<></>
                    }
                <div className="main-wrapper">
                    {
                        this.props.cards.cardState === constants.CARD_STATE_ADDED_SUCCESSFULLY?
                        <div>
                            {
                                this.props.cards.success && this.state.setup?
                                <Redirect to={this.state.proceedTo.componentUrl}/>
                                :
                                <Redirect to='/add-billing'/>
                            }
                        </div>     
                        :
                        (this.props.cards.cardState === constants.CARD_STATE_VERIFY)?
                        <div>                    
                            {
                            <div>
                                <div className='d-flex align-items-center justify-content-between mt-4 pt-2 mx-5'>
                                <h1 className="m-0">Verify your Card</h1>
                                {
                                    this.state.setup?
                                    <div
                                    className="button-tertiary-outline px-3 py-1"
                                    type="button"
                                    onClick = {()=>{
                                        console.log(this.state.proceedTo)
                                        this.props.reportWalletSetupProgress({
                                            deviceId: this.props.devices.connected.deviceId,
                                            walletSetupProgress:constants.BILLING_ADDRESS_ENROLL,
                                            callback:()=>{
                                                history.push(this.state.proceedTo.componentUrl)  
                                            }
                                        })                                      
                                    }}
                                    ><b>Verify Later</b>
                                    </div>
                                    :<></>
                                }
                                </div>
                                <p className="divider my-4"/>
                                <div className="px-5 pb-5">
                                    <VerifyCard
                                        verifyMethods={(this.props.cards.activeCard && this.props.cards.activeCard.verificationMethods?this.props.cards.activeCard.verificationMethods:this.props.idvMethodList)}
                                        history={history}
                                        setup={this.state.setup}
                                        proceedTo={this.state.proceedTo}
                                        backTo={this.state.backTo}
                                    />
                            </div>
                            </div> 
                            }
                        </div>
                        :
                        this.props.cards.errorMessage && this.props.cards.cardState === constants.PROVISION_CARD_TIMED_OUT?
                        <div>
                            <h1 className="mt-4 pt-1 px-5">
                                    <BiErrorCircle size="1.25em"/>
                                    &nbsp;Failed to Add Card</h1>
                            <p className="divider my-4"/>
                            <div  className="px-5 pb-5">
                                 <br/>
                                <p><b style={{"color":"#F44343"}}>{this.props.cards.errorMessage}</b></p>
                                <br/> 
                                <p><b style={{"color":"#F44343"}}>{messages.ADD_CARD_TIMEOUT_MSG2}</b></p>
                                <br/>
                                <div className="button-wrapper d-flex justify-content-end">
                                    <Link to='/cards'>
                                        <Button onClick={()=>{this.props.clearCardState()}}>Return to Dashboard</Button>
                                    </Link>
                                </div>
                            </div>
                        </div>
                        :
                        <div>
                            <CardEnrollmentIFrame 
                                setup={this.state.setup} 
                                history={history}  
                                proceedTo={this.state.proceedTo}
                                backTo={this.state.backTo} />
                        </div>                                                          
                        }
                </div>
                </div>
                </div>
                }
            </div>


        )}
}

const mapStateToProps = state => ({
    devices: state.devices,
    cards: state.cards,
    userIdleFlag: state.user.userIdle,
    terms:state.cards.resources,
    idvMethodList:state.cards.idvMethodList,
    devicePortDiscovery: state.devicePortDiscovery,
    verifyState: state.verify
})

const mapDispatchToProps = (dispatch) => ({
    queryStatus:(data)=>dispatch(queryStatusFromDevice(data)),
    resetErrorMessages: () => dispatch(resetErrorMessages()),
    startAddCard: (data) => dispatch(startAddCard(data)),
    clearCardState: () => dispatch(clearCardState()),
    verifyCard: (data) => dispatch(verifyCard(data)),
    getCardListFromServer: (data) => dispatch(getCardListFromServer(data)),
    reportWalletSetupProgress: (data) => dispatch(reportWalletSetupProgress(data)),

})

export default connect(mapStateToProps, mapDispatchToProps)(AddCardContainer)

