export const SESSION_TIMEOUT_IN_MINUTES = 5
export const INUS_ADDRESS = window.REACT_APP_INUS_ADDRESS
export const MTO_ADDRESS_HOSTNAME = window.REACT_APP_MTO_ADDRESS_HOSTNAME
export const TSD_ADDRESS = window.REACT_APP_TSD_ADDRESS
export const INPS_ADDRESS = window.REACT_APP_INPS_ADDRESS
export const ENROLLMENT_IFRAME_ADDRESS = window.REACT_APP_ENROLLMENT_IFRAME_ADDRESS
export const PRE_JWT = "pre_jwt"
export const TOKEN = 'i2token'
export const PROVISION_CARD_TIMED_OUT_SECONDS = 45000


export const DEVICE_STATE_UPDATE  = 'DEVICE_STATE_UPDATE'
export const DEVICE_STATE_REGISTER = 'DEVICE_STATE_REGISTER'
export const DEVICE_STATE_DELETE = 'DEVICE_STATE_DELETE'
export const DEVICE_STATE_REPORT = 'DEVICE_STATE_REPORT'
export const DEVICE_STATE_CLEAR = 'DEVICE_STATE_CLEAR'
export const DEVICE_STATE_LOCK = 'DEVICE_STATE_LOCK'
export const DEVICE_STATE_LOCK_PENDING = 'DEVICE_STATE_LOCK_PENDING'
export const DEVICE_STATE_UNLOCK = 'DEVICE_STATE_UNLOCK'

export const CARD_STATE_ADD = 'CARD_STATE_ADD' 
export const CARD_STATE_DELETE = 'CARD_STATE_DELETE'
export const CARD_STATE_SET_DEFAULT_CARD = 'CARD_STATE_SET_DEFAULT_CARD'
export const CARD_STATE_SCAN = 'CARD_STATE_SCAN' 
export const CARD_STATE_ADDED_SUCCESSFULLY = 'CARD_STATE_ADDED_SUCCESSFULLY' 
export const CARD_STATE_CLEAR = 'CARD_STATE_CLEAR'
export const CARD_STATE_VERIFY = 'CARD_STATE_VERIFY'
export const CARD_STATE_VERIFY_LATER = 'CARD_STATE_VERIFY_LATER'
export const CARD_NICKNAME_UPDATE = 'CARD_NICKNAME_UPDATE'
export const CARD_NICKNAME_UPDATE_FAILED = 'CARD_NICKNAME_UPDATE_FAILED'
export const CARD_NICKNAME_UPDATE_SUCCESS = 'CARD_NICKNAME_UPDATE_SUCCESS'
export const CARD_STATE_DIGITIZE_PENDING = 'CARD_STATE_DIGITIZE_PENDING'

export const CLEAR_SESSION_LOGOUT ='CLEAR_SESSION_LOGOUT'
export const CLEAR_SESSION_DELETE_DEVICE = 'CLEAR_SESSION_DELETE_DEVICE'
 
export const CONTACT_STATE_CLEAR = 'CONTACT_STATE_CLEAR'

export const SECURITY_STATE_CLEAR = 'SECURITY_STATE_CLEAR' 
export const SECURITY_AUTH_STATE_CLEAR = 'SECURITY_AUTH_STATE_CLEAR' 

export const FP_STATE_ADD = 'FP_STATE_ADD' 
export const FP_STATE_UPDATE = 'FP_STATE_UPDATE'

export const PIN_STATE_ADD = 'PIN_STATE_ADD' 
export const PIN_STATE_CHANGE = 'PIN_STATE_CHANGE'
export const PIN_STATE_RESET = 'PIN_STATE_RESET'

// redux types 
export const START_DEVICE_DELETE = 'START_DEVICE_DELETE'
export const START_DEVICE_UPDATE = 'START_DEVICE_UPDATE'
export const START_DEVICE_REGISTER = 'START_DEVICE_REGISTER'
export const START_DEVICE_REPORT = 'START_DEVICE_REPORT'

export const START_CARD_ADD = 'START_CARD_ADD'
export const START_CARD_DELETE = 'START_CARD_DELETE'

export const START_FA_ADD = 'START_FA_ADD'
export const START_FA_UPDATE = 'START_FA_UPDATE'
export const START_FP_ADD = 'START_FP_ADD'
export const START_FP_UPDATE = 'START_FP_UPDATE'

export const USER_LOGIN = 'USER_LOGIN'
export const USER_LOGIN_SUCCESS = 'USER_LOGIN_SUCCESS'
export const USER_LOGIN_FAILED = 'USER_LOGIN_FAILED'
export const USER_LOGIN_WITH_FIDO = 'USER_LOGIN_WITH_FIDO'
export const USER_LOGIN_WITH_PASSWORD = 'USER_LOGIN_WITH_PASSWORD'
export const USER_DETAILS = 'USER_DETAILS'
export const USER_DETAILS_SUCCESS = 'USER_DETAILS_SUCCESS'
export const USER_DETAILS_FAILED = 'USER_DETAILS_FAILED'
export const USER_LOGOUT = 'USER_LOGOUT'
export const USER_LOGOUT_SUCCESS = 'USER_LOGOUT_SUCCESS'
export const USER_IDLE = 'USER_IDLE'
export const USER_TIMED_OUT = 'USER_TIMED_OUT'
export const USER_EMAIL_REGISTER = 'USER_EMAIL_REGISTER'
export const USER_EMAIL_REGISTER_SUCCESS = 'USER_EMAIL_REGISTER_SUCCESS'
export const USER_EMAIL_REGISTER_FAILED = 'USER_EMAIL_REGISTER_FAILED'
export const USER_REGISTER = 'USER_REGISTER'
export const USER_REGISTER_CODE_SAVE = 'USER_REGISTER_CODE_SAVE'
export const USER_CODE_RESEND_STATE_CLEAR = 'USER_CODE_RESEND_STATE_CLEAR'
export const USER_REGISTER_SUCCESS = 'USER_REGISTER_SUCCESS'
export const USER_REGISTER_FAILED = 'USER_REGISTER_FAILED'
export const USER_RESET_PASSWORD_RESEND_CODE = 'USER_RESET_PASSWORD_RESEND_CODE'
export const USER_FORGOT_PASSWORD ='USER_FORGOT_PASSWORD'
export const USER_FORGOT_PASSWORD_SUCCESS ='USER_FORGOT_PASSWORD_SUCCESS'
export const USER_FORGOT_PASSWORD_FAILED ='USER_FORGOT_PASSWORD_FAILED'
export const USER_RESET_PASSWORD_CANCEL = 'USER_RESET_PASSWORD_CANCEL'
export const USER_RESET_PASSWORD ='USER_RESET_PASSWORD'
export const USER_RESET_PASSWORD_SUCCESS ='USER_RESET_PASSWORD_SUCCESS'
export const USER_RESET_PASSWORD_FAILED ='USER_RESET_PASSWORD_FAILED'
export const USER_CHANGE_PASSWORD ='USER_CHANGE_PASSWORD'
export const USER_CHANGE_PASSWORD_SUCCESS ='USER_CHANGE_PASSWORD_SUCCESS'
export const USER_CHANGE_PASSWORD_FAILED ='USER_CHANGE_PASSWORD_FAILED'
export const UPDATE_ACCOUNT = 'UPDATE_ACCOUNT'
export const UPDATE_ACCOUNT_SUCCESS = 'UPDATE_ACCOUNT_SUCCESS'
export const UPDATE_ACCOUNT_FAILED = 'UPDATE_ACCOUNT_FAILED'
export const VERIFY_REQUEST_CODE = 'VERIFY_REQUEST_CODE'
export const VERIFY_REQUEST_CODE_SUCCESS = 'VERIFY_REQUEST_CODE_SUCCESS'
export const VERIFY_REQUEST_CODE_FAILED = 'VERIFY_REQUEST_CODE_FAILED'
export const VERIFY_SUBMIT_CODE = 'VERIFY_SUBMIT_CODE'
export const VERIFY_SUBMIT_CODE_SUCCESS = 'VERIFY_SUBMIT_CODE_SUCCESS'
export const VERIFY_SUBMIT_CODE_FAILED = 'VERIFY_SUBMIT_CODE_FAILED'
export const VERIFY_RESET = 'VERIFY_RESET'

export const GET_DEVICES = 'GET_DEVICES'
export const GET_DEVICES_SUCCESS = 'GET_DEVICES_SUCCESS'
export const GET_DEVICES_FAILED = 'GET_DEVICES_FAILED'
export const DETECT_DEVICE = 'DETECT_DEVICE'
export const DETECT_DEVICE_SUCCESS = 'DETECT_DEVICE_SUCCESS'
export const DETECT_DEVICE_FAILED = 'DETECT_DEVICE_FAILED'
export const DETECT_DEVICE_COMPLETE = 'DETECT_DEVICE_COMPLETE'
export const PREPARE_REGISTRATION = 'PREPARE_REGISTRATION'
export const PREPARE_REGISTRATION_SUCCESS = 'PREPARE_REGISTRATION_SUCCESS'
export const PREPARE_REGISTRATION_FAILED = 'PREPARE_REGISTRATION_FAILED'
export const ADD_DEVICE = 'ADD_DEVICE'
export const ADD_DEVICE_SUCCESS = 'ADD_DEVICE_SUCCESS'
export const ADD_DEVICE_FAILED = 'ADD_DEVICE_FAILED'
export const DELETE_DEVICE = 'DELETE_DEVICE'
export const DELETE_DEVICE_PENDING = 'DELETE_DEVICE_PENDING'
export const DELETE_DEVICE_SUCCESS = 'DELETE_DEVICE_SUCCESS'
export const DELETE_DEVICE_FAILED = 'DELETE_DEVICE_FAILED'
export const REPORT_DEVICE = 'REPORT_DEVICE'
export const REPORT_DEVICE_SUCCESS = 'REPORT_DEVICE_SUCCESS'
export const REPORT_DEVICE_FAILED = 'REPORT_DEVICE_FAILED'
export const UPDATE_DEVICE = 'UPDATE_DEVICE'
export const UPDATE_DEVICE_SUCCESS = 'UPDATE_DEVICE_SUCCESS'
export const UPDATE_DEVICE_FAILED = 'UPDATE_DEVICE_FAILED'
export const LOCK_WALLET_START = 'LOCK_WALLET_START'
export const LOCK_WALLET_LOADING = 'LOCK_WALLET_LOADING'
export const LOCK_WALLET_CANCEL = 'LOCK_WALLET_CANCEL'
export const LOCK_WALLET_PENDING = 'LOCK_WALLET_PENDING'
export const LOCK_WALLET_SUCCESS = 'LOCK_WALLET_SUCCESS'
export const LOCK_WALLET_FAILED = 'LOCK_WALLET_FAILED'
export const UNLOCK_WALLET_START = 'UNLOCK_WALLET_START'
export const UNLOCK_WALLET_LOADING = 'UNLOCK_WALLET_LOADING'
export const UNLOCK_WALLET_CANCEL = 'UNLOCK_WALLET_CANCEL'
export const UNLOCK_WALLET_SUCCESS = 'UNLOCK_WALLET_SUCCESS'
export const UNLOCK_WALLET_FAILED = 'UNLOCK_WALLET_FAILED'

export const SETUP_WALLET_MSG = 'SETUP_WALLET_MSG'

export const GET_PAYMENT_CARDS = 'GET_PAYMENT_CARDS'
export const GET_PAYMENT_CARDS_SUCCESS = 'GET_PAYMENT_CARDS_SUCCESS'
export const GET_PAYMENT_CARDS_SUCCESS_LIST_EMPTY = 'GET_PAYMENT_CARDS_SUCCESS_LIST_EMPTY'
export const GET_PAYMENT_CARDS_FAILED = 'GET_PAYMENT_CARDS_FAILED'
export const ADD_CARD_SUCCESS = 'ADD_CARD_SUCCESS'
export const DELETE_CARD = 'DELETE_CARD'
export const DELETE_CARD_SUCCESS = 'DELETE_CARD_SUCCESS'
export const DELETE_CARD_FAILED = 'DELETE_CARD_FAILED'
export const START_SET_DEFAULT_CARD = 'START_SET_DEFAULT_CARD'
export const SET_DEFAULT_CARD_SUCCESS = 'SET_DEFAULT_CARD_SUCCESS'
export const SET_DEFAULT_CARD_FAILED = 'SET_DEFAULT_CARD_FAILED'
export const SHOW_CARD_DETAILS = 'SHOW_CARD_DETAILS'
export const SHOW_TRANSACTIONS= 'SHOW_TRANSACTIONS'
export const TRANSACTIONS_LOADED = 'TRANSACTIONS_LOADED'

export const ADD_FA = 'ADD_FA'
export const ADD_FA_SUCCESS = 'ADD_FA_SUCCESS'
export const ADD_FA_FAILED = 'ADD_FA_FAILED'
export const UPDATE_FA = 'UPDATE_FA'
export const UPDATE_FA_SUCCESS = 'UPDATE_FA_SUCCESS'
export const UPDATE_FA_FAILED = 'UPDATE_FA_FAILED'


export const CONTACTS_GET_START = 'CONTACTS_GET_START'
export const CONTACTS_GET_SUCCESS = 'CONTACTS_GET_SUCCESS'
export const CONTACTS_GET_FAILED = 'CONTACTS_GET_FAILED'
export const CONTACT_ADD_START = 'CONTACT_ADD_START'
export const CONTACT_ADD_STARTED = 'CONTACT_ADD_STARTED'
export const CONTACT_ADD_CANCEL = 'CONTACT_ADD_CANCEL'
export const CONTACT_ADD_SUCCESS = 'CONTACT_ADD_SUCCESS'
export const CONTACT_ADD_FAILED = 'CONTACT_ADD_FAILED'
export const CONTACT_ADD_BILLING_ADDRESS = 'CONTACT_ADD_BILLING_ADDRESS'
export const CONTACT_ADD_SHIPPING_ADDRESS = 'CONTACT_ADD_SHIPPING_ADDRESS'
export const CONTACT_DELETE_DENIED = 'CONTACT_DELETE_DENIED'
export const CONTACT_DELETE_START = 'CONTACT_DELETE_START'
export const CONTACT_DELETE_LOADING = 'CONTACT_DELETE_LOADING'
export const CONTACT_DELETE_SUCCESS = 'CONTACT_DELETE_SUCCESS'
export const CONTACT_DELETE_FAILED = 'CONTACT_DELETE_FAILED'
export const CONTACT_UPDATE_START = 'CONTACT_UPDATE_START'
export const CONTACT_UPDATE_LOADING = 'CONTACT_UPDATE_LOADING'
export const CONTACT_UPDATE_CANCEL = 'CONTACT_UPDATE_CANCEL'
export const CONTACT_UPDATE_SUCCESS = 'CONTACT_UPDATE_SUCCESS'
export const CONTACT_UPDATE_FAILED = 'CONTACT_UPDATE_FAILED'
export const CONTACT_SHOW_DETAILS = 'CONTACT_SHOW_DETAILS'

export const ACCESS_TOKEN_INVALID = 'ACCESS_TOKEN_INVALID'
export const REFRESH_TOKEN_GET = 'REFRESH_TOKEN_GET'
export const REFRESH_TOKEN_GET_SUCCESS = 'REFRESH_TOKEN_GET_SUCCESS'
export const REFRESH_TOKEN_GET_FAILED = 'REFRESH_TOKEN_GET_FAILED'
export const REFRESH_TOKEN_EXPIRED = 'REFRESH_TOKEN_EXPIRED'

export const USER_ACTION_FORBIDDEN = 'USER_ACTION_FORBIDDEN'
export const USER_ACTION_FORBIDDEN_MSG = 'Forbidden : Action is Forbidden'


//read-write channel
export const QUERY_STATUS = 'QUERY_STATUS'

export const CREATE_CONTACT = 'CREATE_CONTACT'
export const UPDATE_CONTACT = 'UPDATE_CONTACT'
export const READ_CONTACTS = 'READ_CONTACTS'
export const DELETE_CONTACT = 'DELETE_CONTACT'
export const SET_DEFAULT_CONTACT ='SET_DEFAULT_CONTACT'
export const ENROLL_FINGERPRINT = 'ENROLL_FINGERPRINT'
export const DELETE_FINGERPRINT  = 'DELETE_FINGERPRINT'

export const ENROLL_CARD = 'ENROLL_CARD'
export const SET_DEFAULT_CARD = 'SET_DEFAULT_CARD'

export const REGISTER_DEVICE = 'REGISTER_DEVICE'
export const READ_FINGERPRINTS = 'READ_FINGERPRINTS'

export const PROVISION_CARD_PENDING = 'PROVISION_CARD_PENDING'
export const PROVISION_CARD_VERIFY = 'PROVISION_CARD_VERIFY'
export const PROVISION_CARD_VERIFY_LATER = 'PROVISION_CARD_VERIFY_LATER'
export const PROVISION_CARD_FAILED = 'PROVISION_CARD_FAILED'
export const PROVISION_CARD_TIMED_OUT = 'PROVISION_CARD_TIMED_OUT'

export const ACTIVATE_CARD = 'ACTIVATE_CARD'
export const ACTIVATE_CARD_SUCCESS = 'ACTIVATE_CARD_SUCCESS'
export const ACTIVATE_CARD_FAILED = 'ACTIVATE_CARD_FAILED'

export const GET_NEXT_SCRIPT = 'GET_NEXT_SCRIPT'
export const GET_NEXT_SCRIPT_SUCCESS = 'GET_NEXT_SCRIPT_SUCCESS'
export const GET_NEXT_SCRIPT_FAILED = 'GET_NEXT_SCRIPT_FAILED'
export const GET_NEXT_JOB = 'GET_NEXT_JOB'
export const GET_NEXT_JOB_SUCCESS = 'GET_NEXT_JOB_SUCCESS'
export const GET_NEXT_JOB_FAILED = 'GET_NEXT_JOB_FAILED'

export const GET_SCRIPT_SUCCESS= 'GET_SCRIPT_SUCCESS'
export const GET_SCRIPT_FAILED = 'GET_SCRIPT_FAILED'

//Fingerprint Enrollment
export const FP_ENROLLMENT_START = 'FP_ENROLLMENT_START'
export const FP_ENROLLMENT_START_SUCCESS = 'FP_ENROLLMENT_START_SUCCESS'
export const FP_ENROLLMENT_START_FAILED = 'FP_ENROLLMENT_START_FAILED'
export const FP_ENROLLMENT_CALLBACK = 'FP_ENROLLMENT_CALLBACK'
export const FP_ENROLLMENT_CALLBACK_SUCCESS = 'FP_ENROLLMENT_CALLBACK_SUCCESS'
export const FP_ENROLLMENT_CALLBACK_FAILED = 'FP_ENROLLMENT_CALLBACK_FAILED'
export const FP_ENROLLMENT_COMPLETE = 'FP_ENROLLMENT_COMPLETE'
export const FP_ENROLLMENT_COMPLETE_FAILED = 'FP_ENROLLMENT_COMPLETE_FAILED'
export const FP_ENROLLMENT_CANCEL_START = 'FP_ENROLLMENT_CANCEL_START'
export const FP_ENROLLMENT_CANCELED = 'FP_ENROLLMENT_CANCELED'
export const FP_ENROLLMENT_CANCEL_FAILED ='FP_ENROLLMENT_CANCEL_FAILED'
export const ENROLL_FP_RESULT = 'ENROLL_FP_RESULT'

export const FINGERPRINT_DELETE_START= 'FINGERPRINT_DELETE_START'
export const FINGERPRINT_DELETE= 'FINGERPRINT_DELETE'
export const FINGERPRINT_DELETE_SUCCESS = 'FINGERPRINT_DELETE_SUCCESS'
export const FINGERPRINT_DELETE_FAILED = 'FINGERPRINT_DELETE_FAILED'

export const NAME_FINGERPRINT = 'NAME_FINGERPRINT'
export const FP_ENROLLMENT_FINGERPRINT_NAME_START = 'FP_ENROLLMENT_FINGERPRINT_NAME_START'
export const FINGERPRINT_RENAME_START ='FINGERPRINT_RENAME_START'
export const FINGERPRINT_NAME_START = 'FINGERPRINT_NAME_START'
export const NAME_FINGERPRINT_LOADING = 'NAME_FINGERPRINT_LOADING'
export const NAME_FINGERPRINT_SUCCESS = 'NAME_FINGERPRINT_SUCCESS'
export const NAME_FINGERPRINT_FAILED = 'NAME_FINGERPRINT_FAILED'
export const FINGERPRINT_LIST_GET_START = 'FINGERPRINT_LIST_GET_START'
export const FINGERPRINTS_GET_SUCCESS = 'FINGERPRINTS_GET_SUCCESS'
export const FINGERPRINTS_GET_FAILED = 'FINGERPRINTS_GET_FAILED'

export const FINGER_ACTION_FINGERPRINT_LIST_SHOW = 'FINGER_ACTION_FINGERPRINT_LIST_SHOW'
export const FINGER_ACTION_FINGERPRINT_RENAME = 'FINGER_ACTION_FINGERPRINT_RENAME'
export const FINGER_ACTION_FINGERPRINT_DELETE = 'FINGER_ACTION_FINGERPRINT_DELETE'

export const VERIFY_DEVICE_OWNER = 'VERIFY_DEVICE_OWNER'
export const VERIFY_DEVICE_OWNER_START = 'VERIFY_DEVICE_OWNER_START'
export const VERIFY_DEVICE_OWNER_SUCCESS = 'VERIFY_DEVICE_OWNER_SUCCESS'
export const VERIFY_DEVICE_OWNER_FAILED = 'VERIFY_DEVICE_OWNER_FAILED'
//PIN Setup
export const SAVE_PIN = 'SAVE_PIN';
export const PIN_SETUP_START = 'PIN_SETUP_START'
export const PIN_SETUP_LOADING = 'PIN_SETUP_LOADING'
export const PIN_SETUP_SUCCESS = 'PIN_SETUP_SUCCESS'
export const PIN_SETUP_FAILED = 'PIN_SETUP_FAILED'
export const PIN_SETUP_CANCELLED = 'PIN_SETUP_CANCELLED'
export const PIN_STATE_CHANGE_START = 'PIN_STATE_CHANGE_START'
export const PIN_STATE_RESET_START = 'PIN_STATE_RESET_START'

//security states
export const SHOW_FINGERPRINT_SETTINGS = 'SHOW_FINGERPRINT_SETTINGS'
export const SHOW_PIN_SETTINGS = 'SHOW_PIN_SETTINGS'
export const SHOW_WALLET_SETTINGS = 'SHOW_WALLET_SETTINGS'
export const DELETE_DEVICE_CANCEL ='DELETE_DEVICE_CANCEL'

//account setting states
export const USER_ACCOUNT_SHOW = 'USER_ACCOUNT_SHOW'
export const USER_ACCOUNT_RESET_SHOW_SUCCESS = 'USER_ACCOUNT_RESET_SHOW_SUCCESS'

//contact states
export const SET_DEFAULT_CONTACT_START = 'SET_DEFAULT_CONTACT_START'
export const SET_DEFAULT_CONTACT_SUCCESS = 'SET_DEFAULT_CONTACT_SUCCESS'

//linking billing to card
export const LINK_BILLING_TO_CARD = 'LINK_BILLING_TO_CARD'
export const LINK_BILLING_TO_CARD_SUCCESS = 'LINK_BILLING_TO_CARD_SUCCESS'
export const LINK_BILLING_TO_CARD_FAILED = 'LINK_BILLING_TO_CARD_FAILED'


//Wallet Set up
export const WALLET_SETUP_COMPLETE = 'WALLET_SETUP_COMPLETE'
export const SET_ENROLLMENT_STATUS_SUCCESS = 'SET_ENROLLMENT_STATUS_SUCCESS'
export const SET_ENROLLMENT_STATUS_FAILED = 'SET_ENROLLMENT_STATUS_FAILED'
export const RESET_ERROR_STATES = 'RESET_ERROR_STATES'

//Device Port Discovery
export const PORT_DISCOVERY = 'PORT_DISCOVERY'
export const DEVICE_PORT_DISCOVERY_ERROR = 'DEVICE_PORT_DISCOVERY_ERROR'
export const DEVICE_PORT_DISCOVERY_FAILED = 'DEVICE_PORT_DISCOVERY_FAILED' 
export const DEVICE_PORT_VERIFICATION_SUCCESS = 'DEVICE_PORT_VERIFICATION_SUCCESS'
export const DEVICE_PORT_VERIFICATION_FAILED = 'DEVICE_PORT_VERIFICATION_FAILED'
export const DEVICE_PORT_RANGE_START = 20600
export const DEVICE_PORT_RANGE_END = 20610

//OTP Purpose
export const MFA = 'MFA'
export const RESET_PIN = 'RESET_PIN'

export const SHOW_ERROR_MESSAGES ='SHOW_ERROR_MESSAGES'


//wallet setup progress
export const WALLET_REGISTRATION = 'WALLET_REGISTRATION'
export const PIN_ENROLL = 'PIN_ENROLL'
export const FINGERPRINT_ENROLL = 'FINGERPRINT_ENROLL'
export const CARD_DIGITIZE = 'CARD_DIGITIZE'
export const BILLING_ADDRESS_ENROLL = 'BILLING_ADDRESS_ENROLL'
export const SHIPPING_ADDRESS_ENROLL = 'SHIPPING_ADDRESS_ENROLL'
export const DONE ='DONE'
export const WALLET_SETUP_PROGRESS_SUCCESS ='WALLET_SETUP_PROGRESS_SUCCESS'
export const WALLET_SETUP_PROGRESS_FAILED ='WALLET_SETUP_PROGRESS_FAILED'
export const WALLET_SETUP_CANCELLED ='WALLET_SETUP_CANCELLED'
