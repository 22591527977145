/*
 * INTEL CONFIDENTIAL
 * Copyright 2023 Intel Corporation.
 * This software and the related documents are Intel copyrighted materials, and
 * your use of them is governed by the express license under which they were
 * provided to you (License). Unless the License provides otherwise, you may not
 * use, modify, copy, publish, distribute, disclose or transmit this software or
 * the related documents without Intel's prior written permission. This software
 * and the related documents are provided as is, with no express or implied
 * warranties, other than those that are expressly stated in the License.
 */
import React, { Component } from 'react'

import { Alert } from 'react-bootstrap'
import { Bs1Circle, Bs2Circle, Bs3Circle, Bs4Circle, BsFill1CircleFill, BsFill2CircleFill, BsFill3CircleFill, BsFill4CircleFill , BsFillCheckCircleFill, BsCheckCircle} from 'react-icons/bs'

import * as constants from '@pcwallet/common/constants' 
import { isStepCompleted } from '../enrollment/WalletSetupProgressUtil'

const SetupProgressIndicator = (props) => {
    return (
        <div className='progress-indicator'>
            <div className='progress-indicator-step'>
                <div className='d-flex w-100 align-items-center justify-content-center'> 
                    <div style={{width:"100%"}}/>
                    {
                        props.devices.connected && ((!props.devices.connected.enrolledFlag && !props.devices.connected.owner) || (props.devices.connected.walletSetupProgress === constants.WALLET_REGISTRATION)) ?
                        <BsFill1CircleFill color="#1B5AD7" size="5rem"/>
                        :props.isStepCompleted(constants.WALLET_REGISTRATION, props.devices.connected.walletSetupStatus)?
                        <BsCheckCircle color="#3C3C3C" size="5rem"/>
                        :
                        <Bs1Circle color="#3C3C3C" size="5rem"/>
                    }
                    <hr width="100%" color="#3C3C3C" style={{height:"2px", marginLeft:"-1px"}}/> 
                </div>
            <div className={
                props.devices.connected && ((!props.devices.connected.enrolledFlag && !props.devices.connected.owner) || (props.devices.connected.walletSetupProgress === constants.WALLET_REGISTRATION))?
                'progress-indicator-step-label progress-indicator-selected-color'
                :'progress-indicator-step-label'
            }>Wallet Setup</div>
            </div>
            <hr/>
            <div className='progress-indicator-step'>
                <div className='d-flex w-100 align-items-center justify-content-center'>
                    <hr width="100%" color="#3C3C3C" style={{height:"2px", marginLeft:"-1px"}}/>
                    {
                    props.devices.connected && props.devices.connected.walletSetupProgress === constants.FINGERPRINT_ENROLL ?
                    <BsFill2CircleFill color="#1B5AD7" size="5rem"/>
                    :props.isStepCompleted(constants.FINGERPRINT_ENROLL, props.devices.connected.walletSetupStatus)?
                    <BsCheckCircle color="#3C3C3C" size="5rem"/>

                    :
                    <Bs2Circle color="#3C3C3C" size="5rem"/>
                    
                    }
                    <hr width="100%" color="#3C3C3C" style={{height:"2px", marginLeft:"-1px"}}/>
                </div> 
           
            <div className={
                  props.devices.connected && props.devices.connected.walletSetupProgress === constants.FINGERPRINT_ENROLL?
                  'progress-indicator-step-label progress-indicator-selected-color'
                  :'progress-indicator-step-label'
            }>Add Fingerprint</div>
            </div>            
            <div className='progress-indicator-step'>
                <div className='d-flex w-100 align-items-center justify-content-center'>
                    <hr width="100%" color="#3C3C3C" style={{height:"2px", marginLeft:"-1px"}}/>
                    {
                        props.devices.connected && (props.devices.connected.walletSetupProgress === constants.CARD_DIGITIZE || (props.cardState && props.cardState === constants.CARD_STATE_VERIFY) ||props.devices.connected.walletSetupProgress === constants.BILLING_ADDRESS_ENROLL) ?
                        <BsFill3CircleFill color="#1B5AD7" size="5rem"/>
                        :props.isStepCompleted(constants.CARD_DIGITIZE, props.devices.connected.walletSetupStatus) && props.isStepCompleted(constants.BILLING_ADDRESS_ENROLL, props.devices.connected.walletSetupStatus)?
                        <BsCheckCircle color="#3C3C3C" size="5rem"/>
                        :
                        <Bs3Circle color="#3C3C3C" size="5rem"/>
                    }
                    <hr width="100%" color="#3C3C3C" style={{height:"2px", marginLeft:"-1px"}}/>
                </div>
            <div className={
                props.devices.connected && (props.devices.connected.walletSetupProgress === constants.CARD_DIGITIZE || (props.cardState && props.cardState === constants.CARD_STATE_VERIFY) ||props.devices.connected.walletSetupProgress === constants.BILLING_ADDRESS_ENROLL) ?
                'progress-indicator-step-label progress-indicator-selected-color'
                :'progress-indicator-step-label'}>Add Card</div>
            </div>
            <hr/>
            <div className='progress-indicator-step'>
                <div className='d-flex w-100 align-items-center justify-content-flex-start'> 
                <hr width="100%" color="#3C3C3C" style={{height:"2px", marginLeft:"-1px"}}/>
                    {
                        props.devices.connected && props.devices.connected.walletSetupProgress === constants.SHIPPING_ADDRESS_ENROLL ?
                        <BsFill4CircleFill color="#1B5AD7" size="5rem"/>
                        :props.isStepCompleted(constants.SHIPPING_ADDRESS_ENROLL, props.devices.connected.walletSetupStatus)?
                        <BsCheckCircle color="#3C3C3C" size="5rem"/>
                        :
                        <Bs4Circle color="#3C3C3C" size="5rem"/>
                        
                    }
                    <div style={{width:"100%"}}/>
                </div>
            <div className={
                (props.devices.connected && props.devices.connected.walletSetupProgress === constants.SHIPPING_ADDRESS_ENROLL) ?
                'progress-indicator-step-label progress-indicator-selected-color'
                :'progress-indicator-step-label'
            }>Add Address</div>
            </div>
        </div>
    )
    
}

export default SetupProgressIndicator