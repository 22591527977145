/*
 * INTEL CONFIDENTIAL
 * Copyright 2022 Intel Corporation.
 * This software and the related documents are Intel copyrighted materials, and
 * your use of them is governed by the express license under which they were
 * provided to you (License). Unless the License provides otherwise, you may not
 * use, modify, copy, publish, distribute, disclose or transmit this software or
 * the related documents without Intel's prior written permission. This software
 * and the related documents are provided as is, with no express or implied
 * warranties, other than those that are expressly stated in the License.
 */
import React, { Component } from 'react'
import { connect } from 'react-redux'
import { processDecision, resetErrorMessages, showErrorMessages } from '../../actions/cards';

import * as constants from '@pcwallet/common/constants';
import { GET_INFO, SEND_BACK, SEND_DECISION, SEND_INFO, SHOW_NOTIFICATION, HIDE_NOTIFICATION, SEND_SKIP } from "@pcwallet/common/iFrameCmds";
import { stringify, parse } from 'lossless-json'
import { customNumberParser, getDeviceTokenFromCookie } from '@pcwallet/common/utils';
import { startAddContact } from '../../actions/contacts';

export class CardEnrollmentIframe extends Component {
    constructor(props) {
        super(props);
        this.iframeRef = React.createRef();
    }

    handler = event => {
        if(event.origin !== (new URL(constants.ENROLLMENT_IFRAME_ADDRESS)).origin) {
            return;
        }
        
        const msg = parse(event.data, null,customNumberParser);
        const connected = this.props.devices.connected;
        const deviceId = connected? this.props.devices.connected.deviceId: '';
        const publicId = connected? this.props.devices.connected.publicId: '';
        const devicePort = this.props.devicePortDiscovery.devicePort;

        switch(msg.cmd) {
            case GET_INFO:
                const deviceToken = getDeviceTokenFromCookie();
                const jwtCmd = {
                    cmd : SEND_INFO,
                    deviceId : deviceId,
                    publicId : publicId,
                    deviceToken : deviceToken,
                    devicePort: devicePort,
                    setup:this.props.setup,
                    proceedTo:this.props.proceedTo,
                    backTo:this.props.backTo
                }
                this.iframeRef.current.contentWindow.postMessage(stringify(jwtCmd), "*");
            break;
            case SEND_DECISION:
                this.props.processDecision({
                    setup: this.props.setup,
                    deviceId: deviceId,
                    tokenId: msg.tokenId,
                    decision: msg.decision,
                    history: this.props.history,
                    verificationMethods: msg.verificationMethods,
                    devicePort: devicePort,
                    contactId:this.props.contacts.defaultShippingContactId?this.props.contacts.defaultShippingContactId:(this.props.contacts.contacts && this.props.contacts.contacts.length>=1)?this.props.contacts.contacts[0].id:null
                });
            break;
            case SEND_BACK:
                if(this.props.setup && this.props.backTo){
                    this.props.history.push(this.props.backTo.componentUrl)
                }
                else{
                    this.props.history.push('/cards')
                }
            break;
            case SHOW_NOTIFICATION:
                this.props.showErrorMessages(msg.errorMessage)
            break;
            case HIDE_NOTIFICATION:
                this.props.resetErrorMessages()
            break;
            case SEND_SKIP:
                const {history} = this.props
                if(this.props.devices.connected && this.props.devices.connected.walletSetupProgress === constants.CARD_DIGITIZE){
                    // show Shipping address UI
                    this.props.startAddContact({
                        setup:true,
                        contact:this.props.contacts.contacts[0],
                        addContact: true,
                        callback:()=>{
                            history.push('/add-shipping')
                        }
                    }) 
                }
                else{
                    history.push('/dashboard')
                }
            break;
        }
    }

    componentDidMount() {
        window.addEventListener("message", this.handler);
    }

    componentWillUnmount() {
        window.removeEventListener("message", this.handler);
    }


    render() {
        return (
            <>
                <iframe 
                    ref={this.iframeRef}
                    className="border-0 p-1" 
                    height={650} 
                    width="100%" 
                    scrolling="no" 
                    src={constants.ENROLLMENT_IFRAME_ADDRESS}
                >

                </iframe>
            </>
        )
    }
}

const mapStateToProps = state => ({
    devices: state.devices,
    cards: state.cards,
    userIdleFlag: state.user.userIdle,
    terms:state.cards.resources,
    idvMethodList:state.cards.idvMethodList, 
    devicePortDiscovery: state.devicePortDiscovery,
    contacts: state.contacts
})

const mapDispatchToProps = (dispatch) => ({
    processDecision: (data, callback) => dispatch(processDecision(data, callback)),
    showErrorMessages: (data) => dispatch(showErrorMessages(data)),
    resetErrorMessages: () => dispatch(resetErrorMessages()),
    startAddContact: (data) => dispatch(startAddContact(data)),
});

export default connect(mapStateToProps, mapDispatchToProps)(CardEnrollmentIframe)