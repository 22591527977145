/*
 * INTEL CONFIDENTIAL
 * Copyright 2022 Intel Corporation.
 * This software and the related documents are Intel copyrighted materials, and
 * your use of them is governed by the express license under which they were
 * provided to you (License). Unless the License provides otherwise, you may not
 * use, modify, copy, publish, distribute, disclose or transmit this software or
 * the related documents without Intel's prior written permission. This software
 * and the related documents are provided as is, with no express or implied
 * warranties, other than those that are expressly stated in the License.
 */
import * as constants from "@pcwallet/common/constants"

const initialState = {
    saved: [],
    connected: null,
    isCommunicating: false, // communicate w/ plugin 
    isLoading: false, // loading from backend 
    error: false,
    success: false, 
    errorMessage: '',
    activeDevice: null,
    deviceState: '',
    isLoadingModal: false,
    jobId:"",
    showModal: false,
    setupCanceled:false,
    showForm:false,
    setupWalletMsg:''
}

export default function devicesUpdate(state = initialState, { type, payload }) {
    switch (type) {
        case constants.GET_DEVICES:
            return { ...state, isLoading: true }
        case constants.GET_DEVICES_SUCCESS:
            return { 
                ...state,
                errorMessage: '',
                saved: payload, 
                isLoading: true,
                error: false,
            }
        case constants.GET_DEVICES_FAILED:
            return {
                ...state,
                isLoading: false,
                error: true
            }
        case constants.DETECT_DEVICE:
            return { 
                ...state, 
                errorMessage: '',
                isCommunicating: true,
                isLoading: true,
            }
        case constants.DETECT_DEVICE_COMPLETE:
            return { 
                ...state, 
                errorMessage: '',
                error:false,
                isLoading: false,
            }
        case constants.DETECT_DEVICE_SUCCESS:
          return { 
            ...state,
            connected: {
                connected:payload.connected,
                deviceId: payload.deviceId,
                enrolledFlag: payload.registered,
                owner:payload.owner,
                publicId:payload.publicId,
                displayName:payload.displayName,
                walletSetupProgress:payload.walletSetupProgress,
                walletSetupStatus:payload.walletSetupStatus,
                cards:payload.cards,
                contacts:payload.contacts,
                state:payload.state,
                pendingState:payload.pendingState,
                capability:payload.capability,
                pendingCapability:payload.pendingCapability,
            },
            isLoading:true,
            isCommunicating: false,
            errorMessage: '',
            error: false
        }
        case constants.DETECT_DEVICE_FAILED:
            return {
                ...state,
                isCommunicating: false,
                isLoading:false,
                error: true,
                errorMessage: payload
            }
        case constants.PREPARE_REGISTRATION:
            return {
                ...state,
                errorMessage: '',
                activeDevice: null,
                success: false,
                isLoading: true
            }
        case constants.PREPARE_REGISTRATION_SUCCESS:
            return {
                ...state,
                errorMessage: '',
                isLoading: false,
                error: false
            }
        case constants.PREPARE_REGISTRATION_FAILED:
            return {
                ...state,
                isLoading: false,
                success: false,
                error: true,
                errorMessage: payload
            }
        case constants.SETUP_WALLET_MSG:
            return {
                ...state,
                setupWalletMsg: payload
            }
        case constants.ADD_DEVICE:
            return {
                ...state,
                errorMessage: '',
                activeDevice: null,
                success: false,
                isLoading: true,
                setupWalletMsg: payload
            }
        case constants.ADD_DEVICE_SUCCESS:
            return {
                ...state,
                errorMessage: '',
                isLoading: false,
                error: false
            }
        case constants.ADD_DEVICE_FAILED:
            return {
                ...state,
                isLoading: false,
                success: false,
                error: true,
                errorMessage: payload
            }
        case constants.UPDATE_DEVICE:
            return {
                ...state,
                errorMessage: '',
                success: false,
                isLoading: true
            }
        case constants.UPDATE_DEVICE_SUCCESS:
            return {
                ...state,
                connected: {
                    ...state.connected,
                    ...payload
                },
                isLoading: false,
                success: true,
                error: false,
                errorMessage: ''
            }
        case constants.UPDATE_DEVICE_FAILED:
            return {
                ...state,
                isLoading: false,
                success: false,
                error: true,
                errorMessage:payload
            }
        case constants.DELETE_DEVICE:
            return {
                ...state,
                errorMessage: '',
                success: false,
                isLoading: false,
                isLoadingModal: true,
            }
        case constants.DELETE_DEVICE_PENDING: 
            return {
                ...state,
                success: true,
                errorMessage: '',
                isLoading: false,
                isLoadingModal: false,
                deviceState: constants.DELETE_DEVICE_PENDING,
                showModal:false
            }
        case constants.DELETE_DEVICE_SUCCESS:
            return {
                ...state,
                isLoading: false,
                isLoadingModal: false,
                errorMessage: '',
                success: true,
                error: false,
                activeDevice:null,
                saved:[],
                connected:null,
                deviceState: constants.DELETE_DEVICE_SUCCESS,
                showModal:false
            }
        case constants.DELETE_DEVICE_FAILED:
            return {
                ...state,
                isLoading: false,
                isLoadingModal: false,
                success: false,
                error: true,
                errorMessage: payload
            }
        case constants.DELETE_DEVICE_CANCEL:
            return {
                ...state,
                isLoading: false,
                isLoadingModal: false,
                errorMessage: '',
                success: false,
                error: false,
                activeDevice:null,
                deviceState:'',
                showModal:false
            }
        case constants.REPORT_DEVICE:
            return {
                ...state,
                errorMessage: '',
                success: false,
                isLoading: true
            }
        case constants.REPORT_DEVICE_SUCCESS:
            return {
                ...state,
                isLoading: false,
                errorMessage: '',
                success: true,
                error: false
            }
        case constants.REPORT_DEVICE_FAILED:
            return {
                ...state,
                isLoading: false,
                success: false,
                error: payload
            }
        case constants.START_DEVICE_UPDATE: 
            return {
                ...state,
                success: false,
                errorMessage: '',
                activeDevice: payload,
                deviceState: constants.DEVICE_STATE_UPDATE
            }
        case constants.START_DEVICE_DELETE: 
            return {
                ...state,
                success: false,
                errorMessage: '',
                activeDevice: payload.device,
                deviceState: constants.DEVICE_STATE_DELETE,
                showModal: payload.showModal
            }
        case constants.START_DEVICE_REGISTER: 
            return {
                ...state,
                success: false,
                errorMessage: '',
                deviceState: constants.DEVICE_STATE_REGISTER
            }
        case constants.START_DEVICE_REPORT: 
            return {
                ...state,
                success: false,
                errorMessage: '',
                activeDevice: payload,
                deviceState: constants.DEVICE_STATE_REPORT
            }
        case constants.WALLET_SETUP_COMPLETE: 
            return {
                ...state,
                isLoading:true,
                success: false,
                error: false,
                deviceState: constants.WALLET_SETUP_COMPLETE
            }
        case constants.DEVICE_STATE_CLEAR: 
            return {
                ...state,
                deviceState: ''
            }
        case constants.LOCK_WALLET_START: 
            return {
                ...state,
                success: false,
                errorMessage: '',
                activeDevice: payload.device,
                deviceState: constants.DEVICE_STATE_LOCK,
                showModal: true
            }
        case constants.LOCK_WALLET_LOADING: 
            return {
                ...state,
                isLoading: false,
                isLoadingModal: true,
            }
        case constants.LOCK_WALLET_PENDING:
            return {
                ...state,
                isLoading: false,
                isLoadingModal: false,
                errorMessage: '',
                success: true,
                error: false,
                activeDevice:null,
                deviceState: constants.DEVICE_STATE_LOCK_PENDING,
                showModal:false
            }
        case constants.LOCK_WALLET_SUCCESS:
            return {
                ...state,
                isLoading: false,
                isLoadingModal: false,
                errorMessage: '',
                success: true,
                error: false,
                activeDevice:null,
                jobId:payload.jobId,
                deviceState: constants.LOCK_WALLET_SUCCESS,
                showModal:false
            }
        case constants.LOCK_WALLET_FAILED:
            return {
                ...state,
                isLoading: false,
                isLoadingModal: false,
                success: false,
                error: true,
                errorMessage: payload
            }
        case constants.LOCK_WALLET_CANCEL:
            return {
                ...state,
                isLoading: false,
                isLoadingModal: false,
                errorMessage: '',
                success: false,
                error: false,
                activeDevice:null,
                deviceState:'',
                showModal:false
            }
        case constants.UNLOCK_WALLET_START: 
            return {
                ...state,
                success: false,
                errorMessage: '',
                activeDevice: payload,
                deviceState: constants.DEVICE_STATE_UNLOCK,
                showModal: true
            }
        case constants.UNLOCK_WALLET_LOADING: 
            return {
                ...state,
                isLoading: false,
                isLoadingModal: true,
            }
        case constants.UNLOCK_WALLET_SUCCESS:
            return {
                ...state,
                isLoading: false,
                isLoadingModal: false,
                errorMessage: '',
                success: true,
                error: false,
                activeDevice:null,
                jobId:payload.jobId,
                deviceState: constants.UNLOCK_WALLET_SUCCESS,
                showModal: false
            }
        case constants.UNLOCK_WALLET_FAILED:
            return {
                ...state,
                isLoading: false,
                isLoadingModal: false,
                success: false,
                error: true,
                errorMessage: payload
            }
        case constants.UNLOCK_WALLET_CANCEL:
            return {
                ...state,
                isLoading: false,
                isLoadingModal: false,
                errorMessage: '',
                success: false,
                error: false,
                activeDevice:null,
                deviceState:'',
                showModal: false
            }
        case constants.WALLET_SETUP_PROGRESS_SUCCESS:
            return {
                ...state,
                isLoading: false,
                isLoadingModal: false,
                errorMessage: '',
                success: false,
                error: false,
                connected: {
                    ...state.connected,
                    ...payload
                }
            }
        case constants.WALLET_SETUP_CANCELLED:
            return {
                ...state,
                isLoading: false,
                isLoadingModal: false,
                errorMessage: '',
                success: false,
                error: false,
                setupCanceled:payload,
                showForm:!payload
            }
        case constants.RESET_ERROR_STATES:
            return{
                ...state,
                isLoading:false,
                errorMessage:'',
                error: false,
            }
        default:
          return state
    }
}
