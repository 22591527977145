/*
 * INTEL CONFIDENTIAL
 * Copyright 2022 Intel Corporation.
 * This software and the related documents are Intel copyrighted materials, and
 * your use of them is governed by the express license under which they were
 * provided to you (License). Unless the License provides otherwise, you may not
 * use, modify, copy, publish, distribute, disclose or transmit this software or
 * the related documents without Intel's prior written permission. This software
 * and the related documents are provided as is, with no express or implied
 * warranties, other than those that are expressly stated in the License.
 */
import { Formik, Form } from 'formik';
import React, { Component } from 'react'
import { connect } from 'react-redux'
import { Link } from 'react-router-dom'
import {Container, Alert} from 'react-bootstrap';
import * as Yup from 'yup';

import { login, getUserDetails, resetErrorMessages } from '../actions/user'
import {passwordRegex, emailRegEx,emailLengthValidator, notAllowedPswRegex} from '../validationEngine'
import { analyticsPageView } from '../utils'

import Button from './inputs/Button';
import ErrorMessage from './shared/ErrorMessage';
import TextInput from './inputs/TextInput';
import VerifyForm from './shared/VerifyForm';


export class LoginContainer extends Component {
  constructor(props) {
    super(props);
    this.state = {
      hidden: true,
    };
    this.toggleShow = this.toggleShow.bind(this);
  }

  toggleShow() {
    this.setState({ hidden: !this.state.hidden });
  }

  componentDidMount(){
    //initializing analytics
    analyticsPageView('/login') 
  }

  componentWillUnmount(){
    this.props.resetErrorMessages()
  }

  render() {
    return (
      <div className="wrapper">
        <div className="content">
        {
            (this.props.authError && this.props.authErrorMessage)|| (this.props.verifyState.error && this.props.verifyState.errorMessage)?
            <ErrorMessage
              show = {this.props.authError || this.props.verifyState.error}
              msg = {this.props.authErrorMessage || this.props.verifyState.errorMessage}
              onClose ={()=>{
                  this.props.resetErrorMessages()
              }}
            />
            :<></>
          }
          <div className="main-wrapper"> 
          <h1 className="px-5">Sign In</h1>
          <p className="divider my-4"/>
          <div className="px-5 pb-5">
          {
            this.props.userTimedout ? 
            <Alert className='w-100 font-weight-bold' dismissible={false}> For your protection, you were automatically logged off after a period of inactivity. Please sign in again to continue.</Alert>
            :
            <></>
          }
          {
            <Formik
            initialValues={{
              username: '',
              password: ''
            }}
            validationSchema={Yup.object({
              username: Yup.string()
              .email('invalid email address')
              .test('valid email length','maximum character length exceeded',function(value){
                  return emailLengthValidator(value)
                })
              .matches(emailRegEx,'email contains invalid character')
              .required('Required')
              ,
              password: Yup.string()
              .min(8)
              .max(64)
              .test('invalid special character',`The special character you entered is not allowed`,value => {
                if(value !== null && value !== undefined){
                  let res = value.toString().match(notAllowedPswRegex);
                  if(res !== null && res.length > 0){
                    return false;
                  }
                }
                return true;
              })
              .matches(passwordRegex, 'The password should consist of letter, numberics or special character')             
              .label('Password')
              .required('Required')
              ,
            })}
            onSubmit={(values, { setSubmitting }) => {
              this.props.login({
                username: values.username.trim().toLowerCase(),
                password: values.password.trim(),
                devicePort: this.props.devicePortDiscovery.devicePort,
              })
              setTimeout(()=>{
                setSubmitting(false) 
            },1000)
            }}
            >
              {({values, dirty, isValid, isSubmitting}) => (
                !this.props.authLogin ? 
                <>
                <Form>
                  <Container>
                    <label className='form-required-instruction-label'>All Fields Required</label>
                    <TextInput
                      label="Email Address"
                      name="username"
                      type="email"
                      placeholder="email@website.com"
                      />
                    <TextInput
                      label="Password"
                      name="password"
                      type={this.state.hidden ? 'password' : 'text'}
                      toggleshow={this.toggleShow}
                      hidestate={this.state.hidden.toString()}
                      placeholder="Password"
                    />
                  <div className="m-2 text-right">
                    <p><Link to="/forgot-password" className='link'>Forgot Password?</Link></p>
                  </div>
                  <div className="button-wrapper d-flex justify-content-between">
                    <div style={{marginTop:'0.8rem'}}>
                      <p>No account? <Link className="link"
                      to="/register"
                      >Sign up</Link></p>
                    </div>
                    <Button
                      disabled={  isSubmitting || !dirty || !isValid || this.props.isLoading}
                      type="submit"
                    >
                      {this.props.isLoading && <span className="spinner-border spinner-border-sm mr-1" role="status" aria-hidden="true" />}
                      Sign in
                    </Button>
                  </div>
                  </Container>
                </Form>
                </>
                
                :
                <VerifyForm
                  onVerified = {() => {
                    this.props.getUserDetails()
                  }}
                  verifyLogin={true}
                  username={values.username.trim().toLowerCase()}
                  password={values.password.trim()}
                />
              )}
            </Formik>
          }
          </div>
        </div>
      </div>
    </div>
    ) 
  }
}

const mapStateToProps = state => ({
  userTimedout: state.user.timedout,
  authData: state.user.data,
  authError: state.user.error,
  authErrorMessage: state.user.errorMessage,
  authLogin: state.user.login,
  isLoading: state.user.isLoading,
  username:state.user.username,
  devicePortDiscovery: state.devicePortDiscovery,
  verifyState: state.verify
})

const mapDispatchToProps = dispatch => ({
  login: (data) => dispatch(login(data)),
  getUserDetails: () => dispatch(getUserDetails()),
  resetErrorMessages: (data) => dispatch(resetErrorMessages(data)),
})

export default connect(mapStateToProps, mapDispatchToProps)(LoginContainer)

