import locationHelperBuilder from 'redux-auth-wrapper/history4/locationHelper'
import { connectedRouterRedirect } from 'redux-auth-wrapper/history4/redirect'
import connectedAuthWrapper from 'redux-auth-wrapper/connectedAuthWrapper'

import Loading from './components/shared/Loading'
import * as constants from './constants'
import {logout, refreshToken} from './actions/user'
import {getDeviceTokenFromCookie} from '@pcwallet/common/utils';

const locationHelper = locationHelperBuilder({})

const userIsAuthenticatedDefaults = {
  authenticatedSelector: state => state.user.data !== null,
  authenticatingSelector: state => state.user.isLoading,
  wrapperDisplayName: 'UserIsAuthenticated'
}

export const userIsAuthenticated = connectedAuthWrapper(userIsAuthenticatedDefaults)

export const userIsAuthenticatedRedir = connectedRouterRedirect({
  ...userIsAuthenticatedDefaults,
  AuthenticatingComponent: Loading,
  redirectPath: '/login'
})

const userIsNotAuthenticatedDefaults = {
  // Want to redirect the user when they are done loading and authenticated
  authenticatedSelector: state => state.user.data === null && state.user.isLoading === false,
  wrapperDisplayName: 'UserIsNotAuthenticated'
}

export const userIsNotAuthenticated = connectedAuthWrapper(userIsNotAuthenticatedDefaults)

export const userIsNotAuthenticatedRedir = connectedRouterRedirect({
  ...userIsNotAuthenticatedDefaults,
  redirectPath: (state, ownProps) => locationHelper.getRedirectQueryParam(ownProps) || '/dashboard',
  allowRedirectBack: false
})

const deviceIsUnlockedDefaults = {
    authenticatedSelector: state => state.devices.connected && state.devices.connected.state !== 'LOCKED',
    wrapperDisplayName: 'deviceIsUnlocked'
  }
  
export const deviceIsUnlockedRedir = connectedRouterRedirect({
    ...deviceIsUnlockedDefaults,
    AuthenticatingComponent: Loading,
    redirectPath:'/dashboard',
    allowRedirectBack: false
  })

let previousActionBuffer = [];
export const jwtRefreshMiddleware = store => next =>action =>{
    previousActionBuffer.push(action);
    if(action.type === constants.ACCESS_TOKEN_INVALID){
        let states = store.getState();

        // Among JWT tokens that are stored in cookie, only deviceToken is NOT httpOnly
        // So, retrieve deviceToken for the condition check.
        const token = getDeviceTokenFromCookie();
        if(token){
            if(!states.user.fetchingRefreshToken){
                store.dispatch({type:constants.REFRESH_TOKEN_GET})
                refreshToken(store.dispatch)
                .then((resp)=>{
                    store.dispatch({ type: constants.REFRESH_TOKEN_GET_SUCCESS })
                    let index = previousActionBuffer.map(e => e.type).indexOf(constants.ACCESS_TOKEN_INVALID) - 1;
                    for(var i=index; i>-1;i-=1){
                        if(typeof previousActionBuffer[i] === 'function'){
                            store.dispatch({
                                type:'RESEND',
                                action:previousActionBuffer[i](store.dispatch)
                            });
                            break;
                        }
                    }
                    previousActionBuffer = [];
                }).catch((error)=>{
                    throw error
                })
            }                                 
        }
    }
    else if (action.type === constants.REFRESH_TOKEN_GET_FAILED || action.type === constants.REFRESH_TOKEN_EXPIRED){
        previousActionBuffer = [];
        logout(store.dispatch)
    } else{
        if(previousActionBuffer.length > 20){
            previousActionBuffer.splice(0, previousActionBuffer.length - 20);
        }
        return next(action)
    }

}