/*
 * INTEL CONFIDENTIAL
 * Copyright 2022 Intel Corporation.
 * This software and the related documents are Intel copyrighted materials, and
 * your use of them is governed by the express license under which they were
 * provided to you (License). Unless the License provides otherwise, you may not
 * use, modify, copy, publish, distribute, disclose or transmit this software or
 * the related documents without Intel's prior written permission. This software
 * and the related documents are provided as is, with no express or implied
 * warranties, other than those that are expressly stated in the License.
 */
import React, { Component } from 'react'
import { connect } from 'react-redux'
import { Container} from 'react-bootstrap'
import { Link } from 'react-router-dom'
import { Formik, Form } from 'formik';
import * as Yup from 'yup';

import { register, resetErrorMessages } from '../../actions/user'
import { emailRegEx,emailLengthValidator,alphabetsOnlyRegEx} from '../../validationEngine'
import { analyticsPageView } from '../../utils';

import Button from '../inputs/Button';
import ErrorMessage from '../shared/ErrorMessage';
import TextInput from '../inputs/TextInput';
export class RegisterContainer extends Component {

  componentDidMount(){
    //initializing analytics
    analyticsPageView('/register');
  }

  componentDidUpdate(prevProps) {
    if(this.props.registerError != prevProps.registerError) {
      console.log("error updated")
    }
  }

  componentWillUnmount(){
    this.props.resetErrorMessages()
  }

  render() {
    const { history } = this.props;
    return (
    <div className="wrapper">
      <div className="content">
      {
        this.props.registerError && this.props.registerErrorMessage?
        <ErrorMessage
          show={this.props.registerError}
          msg={this.props.registerErrorMessage}
          onClose={()=>{
              this.props.resetErrorMessages()
          }}
        />
        :<></>
      }
      <div className="main-wrapper">       
      <h1 className="px-5">Create Account</h1>
      <p className="divider my-4"/>
        <div className="px-5 pb-5">
          <Container fluid className="p-1 pt-0">
            <label className='form-required-instruction-label'>All Fields Required</label>    
          <Formik
            initialValues={{
              firstName:'',
              lastName:'',
              email: ''
            }}
            validationSchema={Yup.object({
              firstName: Yup.string()
                .required('Required')
                .matches(alphabetsOnlyRegEx,'field contains invalid character'),
              lastName: Yup.string()
                .required('Required')
                .matches(alphabetsOnlyRegEx,'field contains invalid character'),
              email: Yup.string()
                .email('invalid email address')
                .test('valid email length','maximum character length exceeded',function(value){
                    return emailLengthValidator(value)
                  })
                .matches(emailRegEx,'email contains invalid character')
                .required('Required'),
            })}

            onSubmit={(values, {setSubmitting}) => {
                this.props.register({
                  firstName:values.firstName.trim(),
                  lastName: values.lastName.trim(),
                  username: values.email.trim().toLowerCase(),
                  history
                })
                setTimeout(()=>{
                  setSubmitting(false) 
              },1000)
            }}
          >
            {({ isValid, dirty, isSubmitting }) => (
              <Form>
                  <TextInput
                    required = {true}
                    label='First name'
                    name='firstName'
                    type='text'
                  />
                  <TextInput
                      required = {true}
                      label='Last name'
                      name='lastName'
                      type='text'
                  />
                  <TextInput
                    label="Email Address"
                    name="email"
                    type="email"
                  />               
                <div className="button-wrapper d-flex justify-content-between">
                    <div>
                      <Link to='/home'>
                        <button
                            type="button"
                            className="btn btn-outline-primary btn-lg button-outline"
                          >
                            Previous
                          </button>
                      </Link>
                    </div>
                    <div>
                      <Button
                        type="submit"
                        disabled={  isSubmitting || !dirty || !isValid || this.props.isLoading}
                      >
                        {this.props.isLoading && <span className="spinner-border spinner-border-sm mr-1" role="status" aria-hidden="true" />}
                        Next
                      </Button>
                      </div>
                </div>
                <div className="mt-3">
                    <p>Already Registered? <Link to="/login" className='link'>Sign In</Link></p>
                  </div> 
              </Form>
            )
            }
          </Formik>
          </Container>
        </div>
    </div>
    </div>
    </div>
    )
  }
}

const mapStateToProps = state => ({
  registerErrorMessage: state.user.errorMessage,
  registerError: state.user.error,
  isLoading: state.user.isLoading
})

const mapDispatchToProps = (dispatch) => ({
  register: (data) => dispatch(register(data)),
  resetErrorMessages : () => dispatch(resetErrorMessages()),
})

export default connect(mapStateToProps, mapDispatchToProps)(RegisterContainer)

