/*
 * INTEL CONFIDENTIAL
 * Copyright 2022 Intel Corporation.
 * This software and the related documents are Intel copyrighted materials, and
 * your use of them is governed by the express license under which they were
 * provided to you (License). Unless the License provides otherwise, you may not
 * use, modify, copy, publish, distribute, disclose or transmit this software or
 * the related documents without Intel's prior written permission. This software
 * and the related documents are provided as is, with no express or implied
 * warranties, other than those that are expressly stated in the License.
 */
import React, {Component} from 'react'
import { Container,Col, Row } from 'react-bootstrap';
import { Link } from 'react-router-dom'
import Button from './inputs/Button';
import {getDeviceTokenFromCookie} from '@pcwallet/common/utils';
import storage from '@pcwallet/common/assets/icon_private.png'
import prefill from '@pcwallet/common/assets/icon_prefill.png'
import cards from '@pcwallet/common/assets/icon_trusted.png'
import mfa from '@pcwallet/common/assets/icon_safe.png'
import * as constants from '../constants'
import Logo from '@pcwallet/common/assets/PCW Standard Logo.png';


export  class Home extends Component {

  constructor(props){
    super(props);
    this.state = {
      showLogin:true,
    }

  }

  
  componentDidMount(){
      // Among JWT tokens that are stored in cookie, only deviceToken is NOT httpOnly
      // So, retrieve deviceToken for the condition check.
      const deviceToken = getDeviceTokenFromCookie();

      if (deviceToken) {
        //have JWT redirecting to dashboard
        this.setState({showLogin:false})
        this.props.history.push('/dashboard')
      }
  }

  render(){
  return (
    <div className="wrapper">
    <div className="content">
      <div className="main-wrapper">
        <h1 className="px-5">Welcome to Intel Pay</h1>
        <p className="divider my-4"/>
        <div className="px-5 pb-5">
          <Container fluid>
            <Row>
            <Col className="p-2">
              <Row className="p-2 d-flex justify-content-between  align-items-center text-left">
                <Col className="p-0">
                <img className="img-fluid img-thumbnail icons" src={mfa} alt="mfa"/>
                </Col>
                <Col sm={9}>
                  <p><b>Safe</b></p>
                  <p>Check out securely with one-click or a simple tap, authorize payments using your fingerprint or a PIN.</p>
                </Col>
              </Row>
              <Row className="mt-5 p-2 d-flex justify-content-between  align-items-center text-left">
                <Col className="p-0">
                <img className="img-fluid img-thumbnail icons" src={prefill} alt="prefill"/>
                </Col>
                <Col sm={9} >
                  <p><b>Convenient</b></p>
                  <p>Only enter your information once - to use across merchants for fast and easy checkout.</p>
                </Col>
              </Row> 
            </Col>
            <Col className="p-2">
              <Row className="p-2 d-flex justify-content-between align-items-center text-left">
                <Col className="p-0">
                <img className="img-fluid img-thumbnail icons" src={storage} alt="storage"/>
                </Col>
                <Col sm={9}>
                  <p><b>Private</b></p>
                  <p>Encrypted storage of your card data and identity safeguarded and not shared with merchants</p>
                </Col>
              </Row>
              <Row className="mt-5 p-2 d-flex justify-content-between align-items-center text-left">
                <Col className="p-0">
                <img className="img-fluid img-thumbnail icons" src={cards} alt="cards"/>
                </Col>
                <Col sm={9}>
                  <p><b>Trusted</b></p>
                  <p>Intel Pay enables easy, fast and more secure online checkout</p>
                </Col>
              </Row> 
            </Col>
            </Row>
          </Container>
          <div className="mt-4 d-flex justify-content-center">
            <Link to="/register">
              <div>
                <Button>
                  Get started
                </Button>
              </div>
            </Link>
          </div>
          {
            this.state.showLogin?
            <div className="mt-3 text-center">
                <p>Already Registered? <Link to="/login" className="link">Sign In</Link></p>
            </div>
            :
            <></>
          }         
        </div>
        </div>
      </div>
    </div>
  )
}
}

export default Home
