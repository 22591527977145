/*
 * INTEL CONFIDENTIAL
 * Copyright 2022 Intel Corporation.
 * This software and the related documents are Intel copyrighted materials, and
 * your use of them is governed by the express license under which they were
 * provided to you (License). Unless the License provides otherwise, you may not
 * use, modify, copy, publish, distribute, disclose or transmit this software or
 * the related documents without Intel's prior written permission. This software
 * and the related documents are provided as is, with no express or implied
 * warranties, other than those that are expressly stated in the License.
 */
import user from './user'
import devices from './devices'
// import register from './register'
import verify from './verify'
import cards from './cards'
import contacts from './contacts'
import security from './security'
import devicePortDiscovery from './devicePortDiscovery'
import * as constants from '@pcwallet/common/constants'
import { combineReducers } from 'redux'

export const allReducers = combineReducers({
  user,
  // register, 
  devices,
  verify,
  cards,
  security,
  contacts,
  devicePortDiscovery
});

export const rootReducer = (state, action) => {   
  // Clear all data in redux store to initial expect port discovery information.
  if(action.type === constants.CLEAR_SESSION_LOGOUT){
    const { devicePortDiscovery } = state;
    state = { devicePortDiscovery };
  }
// Clear all data except user details, device info and port in redux store.
  if(action.type === constants.CLEAR_SESSION_DELETE_DEVICE){
    const { user, devices, devicePortDiscovery } = state;
    state = { user, devices, devicePortDiscovery };
  }

  return allReducers(state, action);
};
export default rootReducer;