/*
 * INTEL CONFIDENTIAL
 * Copyright 2022 Intel Corporation.
 * This software and the related documents are Intel copyrighted materials, and
 * your use of them is governed by the express license under which they were
 * provided to you (License). Unless the License provides otherwise, you may not
 * use, modify, copy, publish, distribute, disclose or transmit this software or
 * the related documents without Intel's prior written permission. This software
 * and the related documents are provided as is, with no express or implied
 * warranties, other than those that are expressly stated in the License.
 */
import React, { Component } from 'react'
import { connect } from 'react-redux'
import { Formik, Form } from 'formik';
import * as Yup from 'yup';
import * as constants from '@pcwallet/common/constants'

import { preRegisterDevice, startRegisterDevice, prepareDeviceForRegistration, queryStatusFromDevice, clearDeviceState, reportWalletSetupProgress, cancelSetup } from '../../actions/devices';
import { analyticsPageView } from '../../utils'
import {Container, Row, Col} from 'react-bootstrap'
import { isStepCompleted } from '../enrollment/WalletSetupProgressUtil';
import { testPINStrength } from '@pcwallet/common/validationEngine';
import { clearSecurityState, managePin } from '../../actions/security';

import ErrorMessage from '../shared/ErrorMessage';
import Loading from '../shared/Loading';
import Notification from '../shared/Notification';
import SetupProgressIndicator from '../shared/SetupProgressIndicator'
import TextInput from '../inputs/TextInput';


export class RegisterDevice extends Component {

    constructor(props) {
        super(props);
        this.state = {
          showToast:false,
          buttonDisable:false,
          proceedTo:null,
          backTo:null,
          walletRegistered:false,
          pincodeHide:true,
          confirmPincodeHide:true,
          strengthResult:0,
          setup:false,
          showForm:false,
          setupCanceled:false
        }
        this.hideNotification = this.hideNotification.bind(this);
        this.togglePincodeHide = this.togglePincodeHide.bind(this);
        this.toggleConfirmPincodeHide = this.toggleConfirmPincodeHide.bind(this);
        this.handleStrengthTest = this.handleStrengthTest.bind(this);
        this.handleNavigation = this.handleNavigation.bind(this)
      }

    hideNotification () {
        this.setState({showToast:false})
        this.props.clearSecurityState();
    }
    
    componentDidMount() {
        if(this.props.devices.connected && this.props.devices.connected.deviceId  && this.props.devices.connected.walletSetupProgress){
            if(this.props.devices.connected.walletSetupProgress !== constants.DONE) {
                this.setState({ setup: true })
                let walletRegistered = isStepCompleted(constants.WALLET_REGISTRATION, this.props.devices.connected.walletSetupStatus);
                this.setState({walletRegistered : walletRegistered})
                if(walletRegistered){
                    this.setState({ showForm : true })
                }
                this.handleNavigation(constants.WALLET_REGISTRATION)
                
            }
                        
        }
        if(this.props.devices.deviceState === constants.DELETE_DEVICE_SUCCESS){
            this.setState({showToast:true})
        }
        //initializing analytics
        analyticsPageView('/register-device');
    }

    togglePincodeHide() {
        this.setState({ pincodeHide: !this.state.pincodeHide });
      }
    
    toggleConfirmPincodeHide() {
    this.setState({ confirmPincodeHide: !this.state.confirmPincodeHide });
    }

    handleStrengthTest(event,handleChange){
        if(event.target.value != null){
            let testResult = testPINStrength(event.target.value)
            this.setState(({strengthResult: testResult}))
        }
        handleChange(event)
    }


    handleNavigation(data, values, history) {
        this.props.reportWalletSetupProgress({
            deviceId:this.props.devices.connected.deviceId,
            walletSetupProgress:data,
            callback:()=>{
                if(values, history){
                    this.handlePinSetup(values, history)
                }
            }
        })
    }

    handlePinSetup(values, history){
        this.props.managePin(
            {
                pincode:values.pincode,
                deviceId:this.props.devices.connected.deviceId,
                devicePort: this.props.devicePortDiscovery.devicePort,
                callback:()=>{
                    if(!this.props.pin.error || !this.props.pin.errorMessage) {
                        this.props.clearSecurityState();
                        history.push('/add-fp')
                    }
                }
            }
        )
    }

    render() {
        const { history } = this.props;
        return <div className="wrapper">
        <div className="content">
            {
                this.state.showForm && this.props.devices.connected?
                <SetupProgressIndicator 
                    devices={this.props.devices}
                    showForm= {this.state.showForm}
                    isStepCompleted={isStepCompleted}
                    />
                :
                <></>
            }
            {
              (this.props.devices.error && this.props.devices.errorMessage) || (this.props.pin.error && this.props.pin.errorMessage)?
              <ErrorMessage
                show = {this.props.devices.error || this.props.pin.error}
                msg = {this.props.devices.errorMessage || this.props.pin.errorMessage}
                onClose = {()=>{
                    this.props.resetErrorMessages()
                }}
              />
              :<></>
            } 
            <div className="main-wrapper">
            <h1 className="px-5">
                {
                    (this.state.showForm || this.props.devices.showForm) && (this.props.devices.connected && ((this.props.devices.connected.state === "REGISTERED" && this.props.devices.connected.enrolledFlag && this.props.devices.connected.owner) || (!this.props.devices.connected.enrolledFlag && !this.props.devices.connected.owner))) ?
                    'Welcome to Intel Pay!'
                    :
                    this.props.devices.connected && this.props.devices.connected.enrolledFlag && !this.props.devices.connected.owner?
                    'Set Up your Wallet'
                    :
                    'Create your Wallet'
                }

            </h1>
            <p className="divider my-4"/>
            {
                this.state.showToast && this.props.devices.success && this.props.devices.deviceState === constants.DELETE_DEVICE_SUCCESS?
                            <Notification 
                            show={this.state.showToast}
                            hide={this.hideNotification}
                            msg={"wallet was successfully deleted."}/>                            
                        :
                        <></>
            }
            {
                this.props.devices.isLoading || this.props.pin.isLoading ? 
                <div className='d-flex flex-column align-items-center justify-content-center' style={{minHeight:'350px'}}>
                    <div className='loading-logo-in-card'><Loading ></Loading></div>
                    <p className='mt-1 p-2'>
                        <strong>{this.props.devices.setupWalletMsg}</strong>
                    </p>
                </div> :
                <div className="px-5 pb-5">
                    {
                        <div>
                            {                            
                                (this.state.showForm || this.props.devices.showForm) && (this.props.devices.connected && ((this.props.devices.connected.state === "REGISTERED" && this.props.devices.connected.enrolledFlag && this.props.devices.connected.owner) || (!this.props.devices.connected.enrolledFlag && !this.props.devices.connected.owner))) ?
                                <Formik
                                    initialValues={{
                                        deviceName: this.props.devices.connected && this.props.devices.connected.displayName? this.props.devices.connected.displayName :"",
                                        pincode: '',
                                        confirmPincode:'',
                                    }}
                                    validationSchema={Yup.object({
                                        deviceName: Yup.string()
                                            .max(64)
                                            .matches(/^[a-zA-Z0-9.\-_\s+']+$/,'field contains invalid character')
                                            .required('Required'),
                                        pincode: Yup.string()
                                            .max(64, 'PIN should have maximum 64 characters')
                                            .min(4, 'PIN should have minimum 4 characters')
                                            .required('Required'),
                                        confirmPincode: Yup.string()
                                            .max(64, 'PIN should have maximum 64 characters')
                                            .min(4, 'PIN have minimum 4 characters')
                                            .test('PIN-match', 'PIN must match.', function(value) {
                                                return this.parent.pincode === value;
                                            })
                                            .required('Required'),
                                        
                                    })}
                                    onSubmit={(values) => {
                                        if(this.props.devices.connected && this.props.devices.connected.displayName){
                                            //wallet setup done, only setup pin
                                            this.handlePinSetup(values, history)
                                        }
                                        else{
                                            this.props.preRegisterDevice({
                                                displayName: values.deviceName? values.deviceName : "My Wallet",
                                                deviceId: this.props.devices.connected.deviceId,
                                                devicePort: this.props.devicePortDiscovery.devicePort,
                                                history,
                                                callback:()=>{
                                                    //get device id and navigation details
                                                    if((!this.props.devices.error || !this.props.devices.errorMessage) && (this.props.devices.connected)) {
                                                        if(this.props.devices.connected && this.props.devices.connected.deviceId  && this.props.devices.connected.walletSetupProgress){
                                                            if(this.props.devices.connected.walletSetupProgress !== constants.DONE) {
                                                                this.setState({ setup: true })
                                                                let walletRegistered = isStepCompleted(constants.WALLET_REGISTRATION, this.props.devices.connected.walletSetupStatus);
                                                                this.setState({walletRegistered : walletRegistered})
                                                                if(walletRegistered){
                                                                    this.setState({ showForm : true })
                                                                }
                                                                this.handleNavigation(constants.WALLET_REGISTRATION, values, history)
                                                            }
                                                                        
                                                        }
                                                    }
                                                }     
                                            })
                                        }
                                    }}
                                >
                                    {({ values, dirty, isValid, isSubmitting, handleChange }) => (
                                        <div>
                                            <div className="pt-3">Let's set up your wallet.</div>
                                            <Form className="mt-5">
                                                <Container fluid className="pl-0">
                                                    <label className='form-required-instruction-label'>All Fields Required</label>
                                                    {
                                                        (this.props.devices.connected && this.props.devices.connected.displayName)?
                                                        <Row className='text-left font-weight-bold pb-4 mb-2'>
                                                            <Col className= 'text-capitalize'> Wallet Name</Col>
                                                            <Col sm={9} className='text-left d-inline text-capitalize'>{this.props.devices.connected.displayName}</Col>
                                                        </Row>
                                                        :
                                                        <TextInput
                                                            label="Wallet Name"
                                                            name="deviceName"
                                                            type="text"
                                                            placeholder="My Wallet"
                                                        />

                                                    }
                                                    <TextInput
                                                        label="PIN Code"
                                                        name="pincode"
                                                        autoComplete="off"
                                                        type={this.state.pincodeHide ? 'password' : 'text'}
                                                        toggleshow={this.togglePincodeHide}
                                                        hidestate={this.state.pincodeHide.toString()}
                                                        onChange={event=>{
                                                            this.handleStrengthTest(event,handleChange)
                                                        }}
                                                        result={this.state.strengthResult}   
                                                    />
                                                    <TextInput
                                                        label="Confirm PIN Code"
                                                        name="confirmPincode"
                                                        autoComplete="off"
                                                        type={this.state.confirmPincodeHide ? 'password' : 'text'}
                                                        toggleshow={this.toggleConfirmPincodeHide}
                                                        hidestate={this.state.confirmPincodeHide.toString()}
                                                    />
                                                    <Row>
                                                        <Col>
                                                        </Col>
                                                        <Col sm={9} className="ml-5 pl-4">
                                                            <ul className="px-3 mb-1">
                                                            <li>Minimum of 4 characters.</li>
                                                            <li>Maximum of 64 characters.</li>
                                                            <li>Any combination of letters, numerics and allowed special characters <br/> (! @ # $ % ^ &amp; * ! () - _ + ).</li>
                                                            </ul>
                                                        </Col>
                                                    </Row>
                                                <div 
                                                    className = {(this.props.devices.connected && this.props.devices.saved.length != 0 && !this.props.devices.connected.enrolledFlag && !this.props.devices.connected.owner)?
                                                        "button-wrapper d-flex justify-content-between"
                                                        :
                                                        "button-wrapper d-flex justify-content-end"}>
                                                    {
                                                        (this.props.devices.connected && this.props.devices.saved.length != 0 && !this.props.devices.connected.enrolledFlag && !this.props.devices.connected.owner)?
                                                        <button
                                                            className="btn btn-outline-primary button-outline"
                                                            type="button"
                                                            disabled={(this.props.devices.isLoading || this.props.pin.isLoading) }
                                                            onClick={()=>{
                                                                this.setState({
                                                                    buttonDisable:true,
                                                                })
                                                                //skip wallet registration and go to dashboard
                                                                this.props.cancelSetup(true)
                                                                history.push('/device-dashboard')
                                                            }}>
                                                            Cancel
                                                        </button>
                                                        :
                                                        <></>
                                                    }
                                                    <button
                                                        className="btn btn-primary btn-lg button"
                                                        disabled={isSubmitting || (!values) || !isValid || this.props.devices.isLoading || this.props.pin.isLoading}
                                                        type="submit"
                                                    >
                                                        {(this.props.devices.isLoading || this.props.pin.isLoading) && <span className="spinner-border spinner-border-sm mr-1" role="status" aria-hidden="true" />}
                                                        Next                                                            
                                                    </button>
                                                </div>
                                                </Container>
                                            </Form>
                                        </div>
                                    )}
                                </Formik>
                                :
                                <div>
                                    {
                                    //Not an owner
                                    (this.props.devices.connected && this.props.devices.connected.enrolledFlag && !this.props.devices.connected.owner)?
                                        <div className="pt-3">
                                            This device will be formatted for your usage. Any existing data will be deleted.
                                        </div>
                                    :
                                    //2nd or more devices setup
                                    (this.props.devices.connected && this.props.devices.saved.length != 0 && !this.props.devices.connected.enrolledFlag && !this.props.devices.connected.owner)?
                                        <div className="pt-3">Would like to set up your new wallet now ? </div>
                                    :
                                    //on delete device
                                    this.props.devices.connected && this.props.devices.deviceState === constants.DELETE_DEVICE_SUCCESS?
                                    <div className="pt-3">Your previous wallet has been deleted. You can now create a new wallet.</div>
                                    //1st device
                                    :
                                    <div className="pt-3">Would you like to set up your new wallet now? In order to use Intel Pay, you need at least one active wallet</div>
                                    }
                                   <div 
                                        className={((this.props.devices.connected.enrolledFlag && !this.props.devices.connected.owner)||
                                            this.props.devices.saved.length != 0 && !this.props.devices.connected.enrolledFlag && !this.props.devices.connected.owner)?
                                            "button-wrapper d-flex justify-content-between"
                                            :"button-wrapper d-flex justify-content-end"}>
                                   {
                                        (this.props.devices.connected.enrolledFlag && !this.props.devices.connected.owner) ||
                                        (this.props.devices.connected && this.props.devices.saved.length != 0 && !this.props.devices.connected.enrolledFlag && !this.props.devices.connected.owner)?
                                            <button
                                                className="btn btn-outline-primary button-outline"
                                                type="button"
                                                disabled={this.state.buttonDisable}
                                                onClick={()=>{
                                                    this.setState({
                                                        buttonDisable:true,
                                                    })
                                                    //skip wallet registration and go to dashboard
                                                    this.props.cancelSetup(true)
                                                    history.push('/device-dashboard')
                                                }}>
                                                    {(this.props.devices.connected && this.props.devices.saved.length != 0 && !this.props.devices.connected.enrolledFlag && !this.props.devices.connected.owner)?'Set Up Later':'Cancel'}
                                            </button>
                                        :
                                        <></>
                                    }
                                        <button
                                            className="btn btn-primary btn-lg button"
                                            type="button"
                                            disabled={this.state.buttonDisable}
                                            onClick={()=>{
                                                this.setState({
                                                    buttonDisable:true
                                                })
                                                if(this.props.devices.connected && this.props.devices.deviceState === constants.DELETE_DEVICE_SUCCESS){
                                                    this.props.clearDeviceState();  
                                                }
                                                if (this.props.devices.connected.enrolledFlag && !this.props.devices.connected.owner){
                                                    this.props.prepareDeviceForRegistration({
                                                        devicePort: this.props.devicePortDiscovery.devicePort,
                                                        history,
                                                        callback:this.setState({
                                                            buttonDisable:false,
                                                            showForm :true
                                                        })
                                                    })
                                                }
                                                else{
                                                    this.setState({showForm :true})
                                                }
                                            }}>
                                                {
                                                    this.props.devices.connected && this.props.devices.connected.enrolledFlag && !this.props.devices.connected.owner?
                                                    'Continue'
                                                    :
                                                    'Create Wallet'
                                                }
                                        </button>
                                    </div>                                
                                </div>
                            }
                        </div>
                    }
                </div>
            }
        </div>
        </div>
        </div>
    }
}


const mapStateToProps = state => ({
    devices: state.devices,
    userIdleFlag: state.user.userIdle,
    devicePortDiscovery: state.devicePortDiscovery,
    pin: state.security,

})

const mapDispatchToProps = (dispatch) => ({
    queryStatus:(data)=>dispatch(queryStatusFromDevice(data)) ,
    preRegisterDevice: (data) => dispatch(preRegisterDevice(data)),
    prepareDeviceForRegistration: (data) => dispatch(prepareDeviceForRegistration(data)),
    startRegisterDevice: () => dispatch(startRegisterDevice),
    clearDeviceState: () => dispatch(clearDeviceState()),
    reportWalletSetupProgress: (data) => dispatch(reportWalletSetupProgress(data)),
    managePin: (data) => dispatch(managePin(data)),
    clearSecurityState: () => dispatch(clearSecurityState()),
    cancelSetup: (data) => dispatch(cancelSetup(data))
})

export default connect(mapStateToProps, mapDispatchToProps)(RegisterDevice)

