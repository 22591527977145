let countries = [
  { "countryName": "United States", "countryCode": "US" },
  { "countryName": "Afghanistan", "countryCode": "AF" },
  { "countryName": "Åland Islands", "countryCode": "AX" },
  { "countryName": "Albania", "countryCode": "AL" },
  { "countryName": "Algeria", "countryCode": "DZ" },
  { "countryName": "American Samoa", "countryCode": "AS" },
  { "countryName": "AndorrA", "countryCode": "AD" },
  { "countryName": "Angola", "countryCode": "AO" },
  { "countryName": "Anguilla", "countryCode": "AI" },
  { "countryName": "Antarctica", "countryCode": "AQ" },
  { "countryName": "Antigua and Barbuda", "countryCode": "AG" },
  { "countryName": "Argentina", "countryCode": "AR" },
  { "countryName": "Armenia", "countryCode": "AM" },
  { "countryName": "Aruba", "countryCode": "AW" },
  { "countryName": "Australia", "countryCode": "AU" },
  { "countryName": "Austria", "countryCode": "AT" },
  { "countryName": "Azerbaijan", "countryCode": "AZ" },
  { "countryName": "Bahamas", "countryCode": "BS" },
  { "countryName": "Bahrain", "countryCode": "BH" },
  { "countryName": "Bangladesh", "countryCode": "BD" },
  { "countryName": "Barbados", "countryCode": "BB" },
  { "countryName": "Belarus", "countryCode": "BY" },
  { "countryName": "Belgium", "countryCode": "BE" },
  { "countryName": "Belize", "countryCode": "BZ" },
  { "countryName": "Benin", "countryCode": "BJ" },
  { "countryName": "Bermuda", "countryCode": "BM" },
  { "countryName": "Bhutan", "countryCode": "BT" },
  { "countryName": "Bolivia", "countryCode": "BO" },
  { "countryName": "Bosnia and Herzegovina", "countryCode": "BA" },
  { "countryName": "Botswana", "countryCode": "BW" },
  { "countryName": "Bouvet Island", "countryCode": "BV" },
  { "countryName": "Brazil", "countryCode": "BR" },
  { "countryName": "British Indian Ocean Territory", "countryCode": "IO" },
  { "countryName": "Brunei Darussalam", "countryCode": "BN" },
  { "countryName": "Bulgaria", "countryCode": "BG" },
  { "countryName": "Burkina Faso", "countryCode": "BF" },
  { "countryName": "Burundi", "countryCode": "BI" },
  { "countryName": "Cambodia", "countryCode": "KH" },
  { "countryName": "Cameroon", "countryCode": "CM" },
  { "countryName": "Canada", "countryCode": "CA" },
  { "countryName": "Cape Verde", "countryCode": "CV" },
  { "countryName": "Cayman Islands", "countryCode": "KY" },
  { "countryName": "Central African Republic", "countryCode": "CF" },
  { "countryName": "Chad", "countryCode": "TD" },
  { "countryName": "Chile", "countryCode": "CL" },
  { "countryName": "China", "countryCode": "CN" },
  { "countryName": "Christmas Island", "countryCode": "CX" },
  { "countryName": "Cocos (Keeling) Islands", "countryCode": "CC" },
  { "countryName": "Colombia", "countryCode": "CO" },
  { "countryName": "Comoros", "countryCode": "KM" },
  { "countryName": "Congo", "countryCode": "CG" },
  { "countryName": "Congo, The Democratic Republic of the", "countryCode": "CD" },
  { "countryName": "Cook Islands", "countryCode": "CK" },
  { "countryName": "Costa Rica", "countryCode": "CR" },
  { "countryName": "Cote D'Ivoire", "countryCode": "CI" },
  { "countryName": "Croatia", "countryCode": "HR" },
  { "countryName": "Cuba", "countryCode": "CU" },
  { "countryName": "Cyprus", "countryCode": "CY" },
  { "countryName": "Czech Republic", "countryCode": "CZ" },
  { "countryName": "Denmark", "countryCode": "DK" },
  { "countryName": "Djibouti", "countryCode": "DJ" },
  { "countryName": "Dominica", "countryCode": "DM" },
  { "countryName": "Dominican Republic", "countryCode": "DO" },
  { "countryName": "Ecuador", "countryCode": "EC" },
  { "countryName": "Egypt", "countryCode": "EG" },
  { "countryName": "El Salvador", "countryCode": "SV" },
  { "countryName": "Equatorial Guinea", "countryCode": "GQ" },
  { "countryName": "Eritrea", "countryCode": "ER" },
  { "countryName": "Estonia", "countryCode": "EE" },
  { "countryName": "Ethiopia", "countryCode": "ET" },
  { "countryName": "Falkland Islands (Malvinas)", "countryCode": "FK" },
  { "countryName": "Faroe Islands", "countryCode": "FO" },
  { "countryName": "Fiji", "countryCode": "FJ" },
  { "countryName": "Finland", "countryCode": "FI" },
  { "countryName": "France", "countryCode": "FR" },
  { "countryName": "French Guiana", "countryCode": "GF" },
  { "countryName": "French Polynesia", "countryCode": "PF" },
  { "countryName": "French Southern Territories", "countryCode": "TF" },
  { "countryName": "Gabon", "countryCode": "GA" },
  { "countryName": "Gambia", "countryCode": "GM" },
  { "countryName": "Georgia", "countryCode": "GE" },
  { "countryName": "Germany", "countryCode": "DE" },
  { "countryName": "Ghana", "countryCode": "GH" },
  { "countryName": "Gibraltar", "countryCode": "GI" },
  { "countryName": "Greece", "countryCode": "GR" },
  { "countryName": "Greenland", "countryCode": "GL" },
  { "countryName": "Grenada", "countryCode": "GD" },
  { "countryName": "Guadeloupe", "countryCode": "GP" },
  { "countryName": "Guam", "countryCode": "GU" },
  { "countryName": "Guatemala", "countryCode": "GT" },
  { "countryName": "Guernsey", "countryCode": "GG" },
  { "countryName": "Guinea", "countryCode": "GN" },
  { "countryName": "Guinea-Bissau", "countryCode": "GW" },
  { "countryName": "Guyana", "countryCode": "GY" },
  { "countryName": "Haiti", "countryCode": "HT" },
  { "countryName": "Heard Island and Mcdonald Islands", "countryCode": "HM" },
  { "countryName": "Holy See (Vatican City State)", "countryCode": "VA" },
  { "countryName": "Honduras", "countryCode": "HN" },
  { "countryName": "Hong Kong", "countryCode": "HK" },
  { "countryName": "Hungary", "countryCode": "HU" },
  { "countryName": "Iceland", "countryCode": "IS" },
  { "countryName": "India", "countryCode": "IN" },
  { "countryName": "Indonesia", "countryCode": "ID" },
  { "countryName": "Iran, Islamic Republic Of", "countryCode": "IR" },
  { "countryName": "Iraq", "countryCode": "IQ" },
  { "countryName": "Ireland", "countryCode": "IE" },
  { "countryName": "Isle of Man", "countryCode": "IM" },
  { "countryName": "Israel", "countryCode": "IL" },
  { "countryName": "Italy", "countryCode": "IT" },
  { "countryName": "Jamaica", "countryCode": "JM" },
  { "countryName": "Japan", "countryCode": "JP" },
  { "countryName": "Jersey", "countryCode": "JE" },
  { "countryName": "Jordan", "countryCode": "JO" },
  { "countryName": "Kazakhstan", "countryCode": "KZ" },
  { "countryName": "Kenya", "countryCode": "KE" },
  { "countryName": "Kiribati", "countryCode": "KI" },
  { "countryName": "Korea, Democratic People's Republic of", "countryCode": "KP" },
  { "countryName": "Korea, Republic of", "countryCode": "KR" },
  { "countryName": "Kuwait", "countryCode": "KW" },
  { "countryName": "Kyrgyzstan", "countryCode": "KG" },
  { "countryName": "Lao People's Democratic Republic", "countryCode": "LA" },
  { "countryName": "Latvia", "countryCode": "LV" },
  { "countryName": "Lebanon", "countryCode": "LB" },
  { "countryName": "Lesotho", "countryCode": "LS" },
  { "countryName": "Liberia", "countryCode": "LR" },
  { "countryName": "Libyan Arab Jamahiriya", "countryCode": "LY" },
  { "countryName": "Liechtenstein", "countryCode": "LI" },
  { "countryName": "Lithuania", "countryCode": "LT" },
  { "countryName": "Luxembourg", "countryCode": "LU" },
  { "countryName": "Macao", "countryCode": "MO" },
  { "countryName": "Macedonia, The Former Yugoslav Republic of", "countryCode": "MK" },
  { "countryName": "Madagascar", "countryCode": "MG" },
  { "countryName": "Malawi", "countryCode": "MW" },
  { "countryName": "Malaysia", "countryCode": "MY" },
  { "countryName": "Maldives", "countryCode": "MV" },
  { "countryName": "Mali", "countryCode": "ML" },
  { "countryName": "Malta", "countryCode": "MT" },
  { "countryName": "Marshall Islands", "countryCode": "MH" },
  { "countryName": "Martinique", "countryCode": "MQ" },
  { "countryName": "Mauritania", "countryCode": "MR" },
  { "countryName": "Mauritius", "countryCode": "MU" },
  { "countryName": "Mayotte", "countryCode": "YT" },
  { "countryName": "Mexico", "countryCode": "MX" },
  { "countryName": "Micronesia, Federated States of", "countryCode": "FM" },
  { "countryName": "Moldova, Republic of", "countryCode": "MD" },
  { "countryName": "Monaco", "countryCode": "MC" },
  { "countryName": "Mongolia", "countryCode": "MN" },
  { "countryName": "Montserrat", "countryCode": "MS" },
  { "countryName": "Morocco", "countryCode": "MA" },
  { "countryName": "Mozambique", "countryCode": "MZ" },
  { "countryName": "Myanmar", "countryCode": "MM" },
  { "countryName": "Namibia", "countryCode": "NA" },
  { "countryName": "Nauru", "countryCode": "NR" },
  { "countryName": "Nepal", "countryCode": "NP" },
  { "countryName": "Netherlands", "countryCode": "NL" },
  { "countryName": "Netherlands Antilles", "countryCode": "AN" },
  { "countryName": "New Caledonia", "countryCode": "NC" },
  { "countryName": "New Zealand", "countryCode": "NZ" },
  { "countryName": "Nicaragua", "countryCode": "NI" },
  { "countryName": "Niger", "countryCode": "NE" },
  { "countryName": "Nigeria", "countryCode": "NG" },
  { "countryName": "Niue", "countryCode": "NU" },
  { "countryName": "Norfolk Island", "countryCode": "NF" },
  { "countryName": "Northern Mariana Islands", "countryCode": "MP" },
  { "countryName": "Norway", "countryCode": "NO" },
  { "countryName": "Oman", "countryCode": "OM" },
  { "countryName": "Pakistan", "countryCode": "PK" },
  { "countryName": "Palau", "countryCode": "PW" },
  { "countryName": "Palestinian Territory, Occupied", "countryCode": "PS" },
  { "countryName": "Panama", "countryCode": "PA" },
  { "countryName": "Papua New Guinea", "countryCode": "PG" },
  { "countryName": "Paraguay", "countryCode": "PY" },
  { "countryName": "Peru", "countryCode": "PE" },
  { "countryName": "Philippines", "countryCode": "PH" },
  { "countryName": "Pitcairn", "countryCode": "PN" },
  { "countryName": "Poland", "countryCode": "PL" },
  { "countryName": "Portugal", "countryCode": "PT" },
  { "countryName": "Puerto Rico", "countryCode": "PR" },
  { "countryName": "Qatar", "countryCode": "QA" },
  { "countryName": "Reunion", "countryCode": "RE" },
  { "countryName": "Romania", "countryCode": "RO" },
  { "countryName": "Russian Federation", "countryCode": "RU" },
  { "countryName": "RWANDA", "countryCode": "RW" },
  { "countryName": "Saint Helena", "countryCode": "SH" },
  { "countryName": "Saint Kitts and Nevis", "countryCode": "KN" },
  { "countryName": "Saint Lucia", "countryCode": "LC" },
  { "countryName": "Saint Pierre and Miquelon", "countryCode": "PM" },
  { "countryName": "Saint Vincent and the Grenadines", "countryCode": "VC" },
  { "countryName": "Samoa", "countryCode": "WS" },
  { "countryName": "San Marino", "countryCode": "SM" },
  { "countryName": "Sao Tome and Principe", "countryCode": "ST" },
  { "countryName": "Saudi Arabia", "countryCode": "SA" },
  { "countryName": "Senegal", "countryCode": "SN" },
  { "countryName": "Serbia and Montenegro", "countryCode": "CS" },
  { "countryName": "Seychelles", "countryCode": "SC" },
  { "countryName": "Sierra Leone", "countryCode": "SL" },
  { "countryName": "Singapore", "countryCode": "SG" },
  { "countryName": "Slovakia", "countryCode": "SK" },
  { "countryName": "Slovenia", "countryCode": "SI" },
  { "countryName": "Solomon Islands", "countryCode": "SB" },
  { "countryName": "Somalia", "countryCode": "SO" },
  { "countryName": "South Africa", "countryCode": "ZA" },
  { "countryName": "South Georgia and the South Sandwich Islands", "countryCode": "GS" },
  { "countryName": "Spain", "countryCode": "ES" },
  { "countryName": "Sri Lanka", "countryCode": "LK" },
  { "countryName": "Sudan", "countryCode": "SD" },
  { "countryName": "SuricountryName", "countryCode": "SR" },
  { "countryName": "Svalbard and Jan Mayen", "countryCode": "SJ" },
  { "countryName": "Swaziland", "countryCode": "SZ" },
  { "countryName": "Sweden", "countryCode": "SE" },
  { "countryName": "Switzerland", "countryCode": "CH" },
  { "countryName": "Syrian Arab Republic", "countryCode": "SY" },
  { "countryName": "Taiwan, Province of China", "countryCode": "TW" },
  { "countryName": "Tajikistan", "countryCode": "TJ" },
  { "countryName": "Tanzania, United Republic of", "countryCode": "TZ" },
  { "countryName": "Thailand", "countryCode": "TH" },
  { "countryName": "Timor-Leste", "countryCode": "TL" },
  { "countryName": "Togo", "countryCode": "TG" },
  { "countryName": "Tokelau", "countryCode": "TK" },
  { "countryName": "Tonga", "countryCode": "TO" },
  { "countryName": "Trinidad and Tobago", "countryCode": "TT" },
  { "countryName": "Tunisia", "countryCode": "TN" },
  { "countryName": "Turkey", "countryCode": "TR" },
  { "countryName": "Turkmenistan", "countryCode": "TM" },
  { "countryName": "Turks and Caicos Islands", "countryCode": "TC" },
  { "countryName": "Tuvalu", "countryCode": "TV" },
  { "countryName": "Uganda", "countryCode": "UG" },
  { "countryName": "Ukraine", "countryCode": "UA" },
  { "countryName": "United Arab Emirates", "countryCode": "AE" },
  { "countryName": "United Kingdom", "countryCode": "GB" },
  { "countryName": "United States", "countryCode": "US" },
  { "countryName": "United States Minor Outlying Islands", "countryCode": "UM" },
  { "countryName": "Uruguay", "countryCode": "UY" },
  { "countryName": "Uzbekistan", "countryCode": "UZ" },
  { "countryName": "Vanuatu", "countryCode": "VU" },
  { "countryName": "Venezuela", "countryCode": "VE" },
  { "countryName": "Viet Nam", "countryCode": "VN" },
  { "countryName": "Virgin Islands, British", "countryCode": "VG" },
  { "countryName": "Virgin Islands, U.S.", "countryCode": "VI" },
  { "countryName": "Wallis and Futuna", "countryCode": "WF" },
  { "countryName": "Western Sahara", "countryCode": "EH" },
  { "countryName": "Yemen", "countryCode": "YE" },
  { "countryName": "Zambia", "countryCode": "ZM" },
  { "countryName": "Zimbabwe", "countryCode": "ZW" }
]

export default countries;
