/*
 * INTEL CONFIDENTIAL
 * Copyright 2022 Intel Corporation.
 * This software and the related documents are Intel copyrighted materials, and
 * your use of them is governed by the express license under which they were
 * provided to you (License). Unless the License provides otherwise, you may not
 * use, modify, copy, publish, distribute, disclose or transmit this software or
 * the related documents without Intel's prior written permission. This software
 * and the related documents are provided as is, with no express or implied
 * warranties, other than those that are expressly stated in the License.
 */
import React, { Component } from 'react'
import { connect } from 'react-redux'
import { Formik, Form } from 'formik';
import * as Yup from 'yup';
import {Container} from 'react-bootstrap'

import * as constants from '@pcwallet/common/constants'
import fingerScanStart from '@pcwallet/common/assets/fingerScanStart.png'
import fingerScan from '@pcwallet/common/assets/fingerScan.gif'
import fingerScanComplete from '@pcwallet/common/assets/fingerScanComplete.png'
import fp1 from '@pcwallet/common/assets/fp-states/fp-1.png'
import fp2 from '@pcwallet/common/assets/fp-states/fp-2.png'
import fp3 from '@pcwallet/common/assets/fp-states/fp-3.png'
import fp4 from '@pcwallet/common/assets/fp-states/fp-4.png'
import fp5 from '@pcwallet/common/assets/fp-states/fp-5.png'
import fp6 from '@pcwallet/common/assets/fp-states/fp-6.png'
import fp7 from '@pcwallet/common/assets/fp-states/fp-7.png'
import fp8 from '@pcwallet/common/assets/fp-states/fp-8.png'
import fp9 from '@pcwallet/common/assets/fp-states/fp-9.png'
import fp10 from '@pcwallet/common/assets/fp-states/fp-10.png'
import fp11 from '@pcwallet/common/assets/fp-states/fp-11.png'

import { fingerprintEnrollmentStart, enrollFingerprint, nameFingerprint, cancelFingerEnrollment, resetErrorMessages} from '../../actions/security' 
import { analyticsPageView } from '../../utils'
import {inputRegEx} from '../../validationEngine'
import { getNextComponent, getPreviousComponent } from './WalletSetupProgressUtil';
import { reportWalletSetupProgress } from '../../actions/devices';
import { isStepCompleted } from './WalletSetupProgressUtil';

import TextInput from '../inputs/TextInput';
import ErrorMessage from '../shared/ErrorMessage';
import SetupProgressIndicator from '../shared/SetupProgressIndicator'


export class AddFingerprintContainer extends Component {    
    constructor(props) {
        super(props)
        this.state = {
            disableButton:false,
            proceedTo:{componentUrl:'/security'},
            backTo:{componentUrl:'/security'},
            setup:false
        }
        this.handleNavigation = this.handleNavigation.bind(this) 
    }

    componentDidMount(){    
        //initializing analytics
        if(this.props.security.authState === constants.FP_STATE_ADD){
            analyticsPageView('/add-fp');
        }
        else{
            analyticsPageView('/update-fp');
        }

        //Starting finger enrollment
        if(this.props.devices.connected){
            this.props.enrollFingerprint({
                action: 'START',
                publicId : this.props.devices.connected.publicId,
                devicePort:this.props.devicePortDiscovery.devicePort
            })
            sessionStorage.setItem('FpEnrollState',this.props.security.enrollmentState)
            if(this.props.devices.connected.walletSetupProgress !== constants.DONE) {
                this.setState({ setup : true })
                this.handleNavigation(constants.FINGERPRINT_ENROLL)
            } 
        }

        window.addEventListener("beforeunload", ()=>{ 
                    if(this.props.security.enrollmentState !== "stop" && 
                        this.props.security.authState === constants.FP_STATE_ADD) {
                        this.props.cancelFingerEnrollment({
                            action:"CANCEL",
                            devicePort:this.props.devicePortDiscovery.devicePort,
                        })
                    }
                }, { capture: true });

    }

    handleNavigation(data) {
        this.props.reportWalletSetupProgress({
            deviceId:this.props.devices.connected.deviceId,
            walletSetupProgress:data,
            callback:()=>{
                let walletSetupProgress = this.props.devices.connected.walletSetupProgress;
                let proceedTo = getNextComponent(this.props.devices.connected.walletSetupStatus, walletSetupProgress);
                this.setState({proceedTo : proceedTo})
                let backTo = getPreviousComponent(this.props.devices.connected.walletSetupStatus, walletSetupProgress)
                this.setState({backTo : backTo})
                this.setState({isLoading:false}) 
            }
        })
    }

    componentWillUnmount() {
        if(this.props.security.enrollmentState !== "stop" && this.props.security.authState === constants.FP_STATE_ADD) {
            this.props.cancelFingerEnrollment({
                action:"CANCEL",
                devicePort:this.props.devicePortDiscovery.devicePort
            })
        }
        if (!this.state.setup){
            this.props.resetErrorMessages()
        }

        window.removeEventListener("beforeunload", (event) => {
            return (event.returnValue = "");
            }, {capture:true})

    }
    
    getfingerName(){
        var pos = this.props.security.fingerList.length + 1
        return "Fingerprint "+ pos
    }

    render() {
        const {history} = this.props;
        return (
        <div className="wrapper">
        <div className="content">
            {
                this.state.setup && this.props.devices.connected && this.props.devices.connected.walletSetupProgress === constants.FINGERPRINT_ENROLL?
                <SetupProgressIndicator 
                    devices={this.props.devices}
                    isStepCompleted={isStepCompleted}
                    />
                :
                <></>
            }
            {
                this.props.security.error && this.props.security.errorMessage?
                <ErrorMessage
                    show = {this.props.security.error}
                    msg = {this.props.security.errorMessage}
                    onClose = {()=>{
                        this.props.resetErrorMessages()
                    }}
                />
                :<></>
            }
            <div className="main-wrapper">
            <div className="d-flex justify-content-between align-items-center">
            <h1 className="px-5">Add Fingerprint</h1>
            {
                this.state.setup && !this.props.security.showNameFinger?
                <div
                    className="button-tertiary-outline mt-4 mr-5"
                    type="button"
                    onClick = {()=>{
                        this.props.cancelFingerEnrollment({
                            setup:this.state.setup,
                            action:"CANCEL",
                            devicePort:this.props.devicePortDiscovery.devicePort,
                            callback:()=>{
                                if(this.state.proceedTo){
                                    history.push(this.state.proceedTo.componentUrl)
                                }
                                else{
                                    history.push('/dashboard')
                                }
                            }
                        })
                    }}
                    ><b>Skip</b>
                </div>
                :
                ''
            }
            </div>
            <p className="divider my-4"/>
            {
                <div className="px-5 pb-5">
                    <div className="my-3 d-flex justify-content-betwwen">
                        <div className="p-2">
                            {
                                this.props.security.enrollmentState === 'scanning' && this.props.security.percentage === 0?
                                <img className="finger-scan-gif" src={fp1} alt='fingerprintScanning'/>
                                :
                                this.props.security.enrollmentState === 'scanning' && this.props.security.percentage>0 && this.props.security.percentage<=10?
                                <img className="finger-scan-gif" src={fp2} alt='fingerprintScanning'/>
                                :
                                this.props.security.enrollmentState === 'scanning' && this.props.security.percentage>10 && this.props.security.percentage<=20?
                                <img className="finger-scan-gif" src={fp3} alt='fingerprintScanning'/>
                                :
                                this.props.security.enrollmentState === 'scanning' && this.props.security.percentage>20 && this.props.security.percentage<=30?
                                <img className="finger-scan-gif" src={fp4} alt='fingerprintScanning'/>
                                :
                                this.props.security.enrollmentState === 'scanning' && this.props.security.percentage>30 && this.props.security.percentage<=40?
                                <img className="finger-scan-gif" src={fp5} alt='fingerprintScanning'/>
                                :
                                this.props.security.enrollmentState === 'scanning' && this.props.security.percentage>40 && this.props.security.percentage<=50?
                                <img className="finger-scan-gif" src={fp6} alt='fingerprintScanning'/>
                                :
                                this.props.security.enrollmentState === 'scanning' && this.props.security.percentage>50 && this.props.security.percentage<=60?
                                <img className="finger-scan-gif" src={fp7} alt='fingerprintScanning'/>
                                :
                                this.props.security.enrollmentState === 'scanning' && this.props.security.percentage>60 && this.props.security.percentage<=70?
                                <img className="finger-scan-gif" src={fp8} alt='fingerprintScanning'/>
                                :
                                this.props.security.enrollmentState === 'scanning' && this.props.security.percentage>70 && this.props.security.percentage<=80?
                                <img className="finger-scan-gif" src={fp9} alt='fingerprintScanning'/>
                                :
                                this.props.security.enrollmentState === 'scanning' && this.props.security.percentage>80 && this.props.security.percentage<=90?
                                <img className="finger-scan-gif" src={fp10} alt='fingerprintScanning'/>
                                :
                                this.props.security.enrollmentState === 'scanning' && this.props.security.percentage>90 && this.props.security.percentage<=100?
                                <img className="finger-scan-gif" src={fp11} alt='fingerprintScanning'/>
                                :
                                this.props.security.enrollmentState === 'complete'?
                                <img className="finger-scan-gif" src={fingerScanComplete} alt='fingerprintComplete'/>
                                :
                                <img className="finger-scan-gif" src={fingerScanStart} alt='fingerprintStart'/>
                            }                                    
                        </div> 
                        <div className="ml-3">
                            {
                                this.props.security.showNameFinger ? <></> :
                                <p className="mt-2 p-2">Set up your fingerprint for faster and more secure online checkout. 
                                <br/> Please place your finger on the fingerprint sensor, then repeatedly lift and replace your finger on the sensor until your fingerprint is fully recognized.</p>
                            }
                        {
                            (this.props.security.instruction) === "Duplicate enrollment detected" || (this.props.security.instruction) === "Enrollment canceled, click button to retry." || (this.props.security.instruction) === "Operation canceled"?
                            <p className= "mt-1 px-2 error" ><strong>{this.props.security.instruction}</strong></p>
                            :
                            <p 
                                className= "mt-1 p-2 bold-instruction-font">
                                {this.props.security.instruction}
                            </p>
                        }
                        </div>
                    </div>
                    <div className="ml-5 pl-5">                               
                        <Formik
                            initialValues={{
                                fingerName: this.getfingerName()
                            }}
                            validationSchema={Yup.object({
                                fingerName: Yup.string()
                                    .max(64)
                                    .matches(inputRegEx,'field contains invalid character')
                                    .required('Required')
                            })}
                            onSubmit={(values, { setSubmitting }) => {
                                this.props.nameFingerprint(
                                    {
                                        publicId:this.props.devices.connected?this.props.devices.connected.publicId:'',
                                        fingerName:values.fingerName,
                                        templateId:this.props.security.templateId,
                                        setup: this.state.setup,
                                        devicePort:this.props.devicePortDiscovery.devicePort,
                                        history,
                                        callback:()=>{
                                            history.push(this.state.proceedTo.componentUrl)
                                        }
                                    }
                                )
                                setTimeout(()=>{
                                    setSubmitting(false) 
                                },1000)
                            }}
                        >
                            {({ values, isValid, isSubmitting, errors }) => (
                                <div>
                                    {
                                    this.props.security.showNameFinger?
                                    <Form>
                                        <Container className= "mt-4">
                                            <TextInput
                                                label="Fingerprint name"
                                                name="fingerName"
                                                type="text"
                                                value={values.fingerName}/>
                                        <div className="button-wrapper d-flex justify-content-end">
                                            <button
                                            className="btn btn-primary btn-lg button"
                                            disabled={isSubmitting || !isValid || this.props.security.isLoadingModal}
                                            type="submit"
                                        >
                                            {this.props.security.isLoadingModal && <span className="spinner-border spinner-border-sm mr-1" role="status" aria-hidden="true" />}
                                            {this.state.setup? "Next":"Save"}
                                        </button>                                                    
                                        </div>
                                        </Container>
                                    </Form>
                                    :
                                    <Form>
                                        <div className="button-wrapper d-flex justify-content-end">
                                        {
                                                this.state.setup || this.props.security.error?
                                                ''                                                       
                                                :
                                                 this.props.security.enrollmentState === 'stop'?
                                                 <button
                                                 className="btn-outline-primary btn-lg button-outline"
                                                 type="button"
                                                 onClick = {()=>{
                                                    if(this.props.devices.connected){
                                                        this.props.enrollFingerprint({
                                                            action: 'START',
                                                            publicId : this.props.devices.connected.publicId,
                                                            devicePort:this.props.devicePortDiscovery.devicePort
                                                        })
                                                        sessionStorage.setItem('FpEnrollState',this.props.security.enrollmentState)
                                                    }
                                                 }}
                                             >
                                                 Start
                                             </button>
                                                 :
                                                 <button
                                                    className="btn-outline-primary btn-lg button-outline"
                                                    type="button"
                                                    disabled={this.props.security.isLoading}
                                                    onClick = {()=>{
                                                        this.props.cancelFingerEnrollment({
                                                                action:"CANCEL",
                                                                devicePort:this.props.devicePortDiscovery.devicePort,
                                                                history
                                                        })
                                                    }}
                                                >
                                                    Cancel
                                                </button>
                                        }
                                        </div>
                                    </Form>
                                    }
                                </div>
                            )}
                        </Formik> 
                    </div>
                </div>
            }
        </div>
    </div>
    </div>
    )}
}


const mapStateToProps = state => ({
    authData: state.user.data,
    devices: state.devices,
    cards: state.cards,
    security: state.security,
    userIdleFlag: state.user.userIdle,
    otpverify:state.verify,
    devicePortDiscovery: state.devicePortDiscovery
})

const mapDispatchToProps = (dispatch) => ({
    fingerprintEnrollmentStart: (data) => dispatch(fingerprintEnrollmentStart(data)),
    enrollFingerprint: (data) => dispatch(enrollFingerprint(data)),
    nameFingerprint: (data) => dispatch(nameFingerprint(data)),
    cancelFingerEnrollment: (data) => dispatch(cancelFingerEnrollment(data)),
    resetErrorMessages: () => dispatch(resetErrorMessages()),
    reportWalletSetupProgress: (data) => dispatch(reportWalletSetupProgress(data))
})

export default connect(mapStateToProps, mapDispatchToProps)(AddFingerprintContainer)

