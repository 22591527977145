/*
 * INTEL CONFIDENTIAL
 * Copyright 2022 Intel Corporation.
 * This software and the related documents are Intel copyrighted materials, and
 * your use of them is governed by the express license under which they were
 * provided to you (License). Unless the License provides otherwise, you may not
 * use, modify, copy, publish, distribute, disclose or transmit this software or
 * the related documents without Intel's prior written permission. This software
 * and the related documents are provided as is, with no express or implied
 * warranties, other than those that are expressly stated in the License.
 */
import * as constants from "@pcwallet/common/constants"
import * as messages from '@pcwallet/common/endUserMessages'
import { queryStatusFromDevice } from './devices'
import { getCsrfCookieValue, getDeviceTokenFromCookie, customNumberParser } from "@pcwallet/common/utils"
import { parse, stringify, LosslessNumber } from 'lossless-json'
import { getCardListFromServer } from './cards'
// import {base64EncodedJson, base64ToJson} from '../utils'

export const startGetContacts = data => dispatch => {
    dispatch({
        type: constants.CONTACTS_GET_START
    })
    data.history.push('/contacts')
}

export const getContacts = (data)=>(dispatch) => {
    dispatch({
        type:constants.CONTACTS_GET_START
    })
    getContactsFromServer(data,dispatch);
}

//Read Contacts
export const getContactsFromServer = (data,dispatch) => {
    const cookieValue = getCsrfCookieValue()
    fetch(`${constants.INUS_ADDRESS}/us/v1/readContacts`, {
        method: 'POST',
        credentials : 'include',
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            'X-XSRF-TOKEN' : `${cookieValue ? cookieValue : ''}`
        },
        body: stringify({
            publicId:data.publicId
        })
    })
    .then((response) => {
        if (response.status === 401) {
            dispatch({
                type: constants.ACCESS_TOKEN_INVALID,
            })
            throw response.status
        }
        else {
            return response.text()
        }
    })
    .then((response) => {
        return parse(response, null, customNumberParser)
    }
    )
    .then((response) => {
        if (response.errorCode !== "OK") {
            dispatch({
                type: constants.CONTACTS_GET_FAILED,
                payload : response.message
            });
        } else {
            data.astp=response.astp
            data.sessionId=response.adminSessionId
            getContactsFromDevice(data,dispatch)
        }
    })
    .catch((error) => {
        if(error !==  401) {
            console.error('Error:', error);
            dispatch({
                type: constants.CONTACTS_GET_FAILED,
                payload: messages.CONTACTS_GET_FAILED
            });
        }
    });
}

const getContactsFromDevice = (data,dispatch) => {
    const deviceToken = getDeviceTokenFromCookie();
    const requestURL = `${constants.MTO_ADDRESS_HOSTNAME}:${String(data.devicePort)}/v1/command`
    fetch(requestURL,{
        method: 'POST',
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
        },
        body:stringify(
            {
                token: deviceToken,
                astp:data.astp,
                cmd:constants.GET_NEXT_SCRIPT
            })
        })
        .then((response)=> {
            if (response.status >= 200 && response.status < 300) {
            getContactsResult(data,dispatch)
            }
            else if (response.status === 401) {
                    dispatch({
                        type: constants.ACCESS_TOKEN_INVALID
                    })
                    throw response.status
                }
            else if (response.status === 403) {
                dispatch({
                    type: constants.USER_ACTION_FORBIDDEN
                })
            }                
            else {
                dispatch({
                    type: constants.CONTACTS_GET_FAILED,
                    payload: "Failed to add contact. Please retry or come back later."
                });
                dispatch({
                    type: constants.DETECT_DEVICE_COMPLETE,
                });
            }
    })
    .catch((error) => {
        if(error !==  401) {
            console.error('Error:', error);
            dispatch({
                type: constants.CONTACTS_GET_FAILED,
                payload: messages.CONTACTS_GET_FAILED
            });
        }
        dispatch({
            type: constants.DETECT_DEVICE_COMPLETE,
        });
    });
}

export const getContactsResult = (data ,dispatch) => {
    const cookieValue = getCsrfCookieValue()
    fetch(`${constants.INUS_ADDRESS}/us/v1/readContactsResult`, {
        method: 'POST',
        credentials : 'include',
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            'X-XSRF-TOKEN' : `${cookieValue ? cookieValue : ''}`
        },
        body:stringify({
            deviceId : new LosslessNumber(data.deviceId).valueOf(),
            sessionId : data.sessionId
        })
        
    })
    .then((response) => {
        if (response.status === 401) {
            dispatch({
                type: constants.ACCESS_TOKEN_INVALID,
            })
            throw response.status
        }
        else {
            return response.text()
        }
    })
    .then((response) => {
        return parse(response, null, customNumberParser)
    })
    .then((response) => {
            if(response.errorCode !== "OK"){
                dispatch({
                    type: constants.CONTACTS_GET_FAILED,
                    payload: response.message ? response.message : "Failed to load address list. Please retry or come back later"
                });
                dispatch({
                    type: constants.DETECT_DEVICE_COMPLETE,
                });
                if(data.callback) {
                    data.callback();
                }
            }                
            else{
                let defaultShippingContactId = response.contacts.find(contact =>{return (contact.defaultShipping === true)})? response.contacts.find(contact =>{return (contact.defaultShipping === true)}).id: null
                dispatch({
                type: constants.CONTACTS_GET_SUCCESS,
                payload: {
                    contacts: response.contacts ? response.contacts : [],
                    defaultShippingContactId:defaultShippingContactId ,
                    activeContact:data.lastActiveContact
                }});
                if(data.updateTokenContact){
                    dispatch({
                        type: constants.DETECT_DEVICE_COMPLETE,
                    });
                    data.history.push('/cards')
                }
                else{
                    dispatch({
                        type: constants.DETECT_DEVICE_COMPLETE,
                    });
                    if(data.callback) {
                        data.callback();
                    }
                }                
            }
    })
    .catch((error) => {
        if(error !==  401) {
            console.error('Error:', error);
            dispatch({
                type: constants.CONTACTS_GET_FAILED,
                payload: messages.CONTACTS_GET_FAILED
            });
        }
    });
}

export const startAddContact = data => dispatch => {
    dispatch({
        type: constants.CONTACT_ADD_START,
        payload:data
    })
    data.callback()
}

export const cancelAddContact = data => dispatch => {
    dispatch({
        type: constants.CONTACT_ADD_CANCEL
    })
    if(data.updateTokenContact){
        data.history.push('/cards')
    }
    else{
        data.history.push('/contacts')
    }
}

// Add Contact - save in SE simulator using GP session

export const addContactFromServer = data => (dispatch) => {
    dispatch({
        type: constants.CONTACT_ADD_STARTED
    })
    const cookieValue = getCsrfCookieValue()    
    fetch(`${constants.INUS_ADDRESS}/us/v1/storeContact`, {
        method: 'POST',
        credentials : 'include',
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            'X-XSRF-TOKEN' : `${cookieValue ? cookieValue : ''}`
        }, 
        body: stringify({
            contact:{
                "defaultShipping": data.markAsDefaultShippingContact,
                "addressLine1":data.contact.addressLine1,
                "addressLine2":data.contact.addressLine2,
                "city":data.contact.city,
                "state":data.contact.state,
                "country":data.contact.country,
                "postalCode":data.contact.postalCode,
                "phoneNumber":data.contact.phoneNumber,
                "email":data.contact.email,
                "givenName":data.contact.givenName,
                "familyName":data.contact.familyName,
            },
            "publicId":data.publicId   

        })
    })
    .then((response) => {
        if (response.status === 401) {
            dispatch({
                type: constants.ACCESS_TOKEN_INVALID,
            })
            throw response.status
        }
        else {
            return response.text()
        }
    })
    .then((response) => {
        return parse(response, null, customNumberParser)
    })
    .then((response) => {
        
        data.sessionId=response.adminSessionId
        data.astp=response.astp
        if(response.errorCode !== "OK"){
            dispatch({
                type: constants.CONTACT_ADD_FAILED,
                payload: response.message
            })
        }
        else{
            addContactToDevice(data,dispatch);
        }
    })
    .catch((error) => {
        if(error !==  401) {
            console.error('Error:', error);
            dispatch({
                type: constants.CONTACT_ADD_FAILED,
                payload:messages.CONTACT_ADD_FAILED
            });
        }
    });
}

//start admin session to add contact
 const addContactToDevice = function(data,dispatch) {
    const deviceToken = getDeviceTokenFromCookie();
    const requestURL = `${constants.MTO_ADDRESS_HOSTNAME}:${String(data.devicePort)}/v1/command`
    fetch(requestURL,{
        method: 'POST',
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
        },
        body:stringify(
            {
                token: deviceToken,
                astp:data.astp,
                cmd:constants.GET_NEXT_SCRIPT
            })
        })
        .then((response)=> {
            if (!response.status) {
                dispatch({
                    type: constants.CONTACT_ADD_FAILED,
                    payload: "Failed to add address. Please retry or come back later."
                });
            }   
            else {
                getStoreContactResult(data,dispatch)
            }
    })
    .catch((error) => {
        console.error('Error:', error);
        dispatch({
            type: constants.CONTACT_ADD_FAILED,
            payload:messages.CONTACT_ADD_FAILED
        });
    });
}

///get results from server

export const getStoreContactResult = (data ,dispatch) => {
    const cookieValue = getCsrfCookieValue()
    fetch(`${constants.INUS_ADDRESS}/us/v1/storeContactResult`, {
        method: 'POST',
        credentials : 'include',
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            'X-XSRF-TOKEN' : `${cookieValue ? cookieValue : ''}`
        },
        body:stringify({
            deviceId : new LosslessNumber(data.deviceId).valueOf(),
            sessionId : data.sessionId
        })
    })
    .then((response) => {
        if (response.status === 401) {
            dispatch({
                type: constants.ACCESS_TOKEN_INVALID,
            })
            throw response.status
        }
        else {
            return response.text()
        }
    })
    .then((response) => {
        return parse(response,null,customNumberParser)
    })
    .then((response) => {  
        if(response.errorCode !== "OK"){
            dispatch({
                type: constants.CONTACT_ADD_FAILED,
                payload: response.message ? response.message : "Failed to add address. Please retry or come back later."
            });
            dispatch({
                type: constants.DETECT_DEVICE_COMPLETE,
            });
        }
        else if(response.errorCode ==="US-100-1002"){
            dispatch({
                type: constants.CONTACT_ADD_FAILED,
                payload: response.message ? response.message : "Failed to add address. Please retry or come back later."
            });
            dispatch({
                type: constants.DETECT_DEVICE_COMPLETE,
            });
        }
        else{
            dispatch({
                type: constants.CONTACT_ADD_SUCCESS,//To dO - change when device side implemenented
                payload:response.contact
            });
            if(data.setup){
                if(response.contact && data.tokenId && data.walletSetupProgress === constants.BILLING_ADDRESS_ENROLL){
                    data.contactId=response.contact.id
                    data.contact=response.contact
                    dispatch(linkBillingtoCard(data))
                }
                else{
                    data.callback()                
                }
            }
            else if(data.selectNewBilling && data.tokenId ){
                data.contactId=response.contact.id
                data.contact=response.contact
                dispatch(linkBillingtoCard(data))
            }
            else{
                if(data.walletSetupStatus < 127 && (data.isShippingEnrolled || !data.isBillingEnrolled)) {
                    dispatch(queryStatusFromDevice(data))
                }
                data.history.push('/contacts')
            }
        }
    })
    .catch((error) => {
        if(error !==  401) {
            console.error('Error:', error);
            dispatch({
                type: constants.CONTACT_ADD_FAILED,
                payload:messages.CONTACT_ADD_FAILED
            });
        }
    });
}

export const linkBillingtoCard = (data) => (dispatch) => {
    dispatch({
        type: constants.LINK_BILLING_TO_CARD,
        payload:{
            selectNewBilling:data.selectNewBilling
        }
    });
    const cookieValue = getCsrfCookieValue();
    let requestBody = stringify(
        {
            tokenId:new LosslessNumber(data.tokenId).valueOf(),
            billingContactId:new LosslessNumber(data.contactId).valueOf()
        })
    fetch(`${constants.INUS_ADDRESS}/us/ws/updateTokenProperties`, {
        method: 'POST',
        credentials : 'include',
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            'X-XSRF-TOKEN' : `${cookieValue ? cookieValue : ''}`
        },
        body:requestBody
    })
    .then((response) => {
        if (response.status === 401) {
            dispatch({
                type: constants.ACCESS_TOKEN_INVALID,
            })
            throw response.status
        }
        else {
            return response.text()
        }
    })
    .then((response) => {
        return parse(response, null, customNumberParser)
    })
    .then((response) => {
        if(response.errorCode !== "OK"){
            dispatch({
                type: constants.LINK_BILLING_TO_CARD_FAILED,
            });
        }
        //if link card to billing fails proceeds anyways
        if(data.setup){
            data.selectNewBilling = false
            data.updateTokenContact = false
            data.addContact = false
            dispatch({
                type: constants.CONTACT_ADD_START,
                payload:data
            })
            data.callback()
            
        }
        else{
            if(data.selectNewBilling){
                dispatch({
                    type: constants.LINK_BILLING_TO_CARD_SUCCESS,
                    payload:{
                        selectNewBilling:false,
                        updateTokenContact:false
                    }
                });
                if(data.deviceId){                    
                    //get updated card list
                    data.lastActiveCardId=data.tokenId
                    dispatch(getCardListFromServer(data))
                    if(data.walletSetupStatus < 127 && (data.isShippingEnrolled || !data.isBillingEnrolled)) {
                        dispatch(queryStatusFromDevice(data))
                    }
                    
                }
            }
            else{
                dispatch({
                    type: constants.CONTACT_ADD_SUCCESS,//To dO - change when device side implemenented
                    payload:data.contact
                });
                data.callback()
            }                
        }                
    })
    .catch((error) => {
        if(error !==  401) {
            console.error('Error:', error);
            dispatch({
                type: constants.CONTACT_ADD_FAILED,
                payload: messages.CONTACT_ADD_FAILED
            });
        }
    });
}



export const startDeleteContact = data => (dispatch) => {
    let isBillingContact = null;
    isBillingContact = data.cards.find(card => {
        return card.billingContactId === data.activeContact.id
    })
    if(isBillingContact) {
        dispatch({
            type: constants.CONTACT_DELETE_DENIED
        });
    }
    else {
        dispatch({
            type: constants.CONTACT_DELETE_START
        });
    }
    
}


export const deleteContact = data => (dispatch) => {
    dispatch({
        type: constants.CONTACT_DELETE_LOADING
    });
    const cookieValue = getCsrfCookieValue()
    let requestBody = stringify({
        publicId: data.publicId,
        contactId: new LosslessNumber(data.contactId).valueOf(), 
    })
    fetch(`${constants.INUS_ADDRESS}/us/v1/removeContact`, {
        method: 'POST',
        credentials : 'include',
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            'X-XSRF-TOKEN' : `${cookieValue ? cookieValue : ''}`
        }, 
        body: requestBody
    })
    .then((response) => {
        if (response.status === 401) {
            dispatch({
                type: constants.ACCESS_TOKEN_INVALID,
            })
            throw response.status
        }
        else {
            return response.text()
        }
    })
    .then((response) => {
        return parse(response, null, customNumberParser)
    })
    .then((response) => {     
        if(response.errorCode !== "OK"){
            dispatch({
                type: constants.CONTACT_DELETE_FAILED,
                payload: response.message ? response.message : "Failed to delete address. Please retry or come back later."
            });
        } else {
            data.sessionId=response.adminSessionId
            data.astp=response.astp
            getNextScript(data,dispatch)
        }
    })
    .catch((error) => {
        if(error !==  401) {
            console.error('Error:', error);
            dispatch({
                type: constants.CONTACT_DELETE_FAILED,
                payload: messages.CONTACT_DELETE_FAILED
            });
        }
    });
}

const getDeleteContactResult = (data ,dispatch) => {
    const cookieValue = getCsrfCookieValue()
    fetch(`${constants.INUS_ADDRESS}/us/v1/removeContactResult`, {
        method: 'POST',
        credentials : 'include',
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            'X-XSRF-TOKEN' : `${cookieValue ? cookieValue : ''}`
        },
        body:stringify({
            deviceId : new LosslessNumber(data.deviceId).valueOf(),
            sessionId : data.sessionId
        })
    })
    .then((response) => {
        if (response.status === 401) {
            dispatch({
                type: constants.ACCESS_TOKEN_INVALID,
            })
            throw response.status
        }
        else {
            return response.text()
        }
    })
    .then((response) => {
        return parse(response, null, customNumberParser)
    })
    .then((response) => {
            if(response.errorCode !== "OK"){
                dispatch({
                    type: constants.CONTACT_DELETE_FAILED,
                    payload: response.message ? response.message : "Failed to delete address. Please retry or come back later."
                });
            }
            else{
                dispatch({
                    type: constants.CONTACT_DELETE_SUCCESS
                });
                data.callback()
            }
    })
    .catch((error) => {
        if(error !==  401) {
            console.error('Error:', error);
            dispatch({
                type: constants.CONTACT_DELETE_FAILED,
                payload: messages.CONTACT_DELETE_FAILED
            });
        }
    });
}

export const setDefaultContact = data => (dispatch) => {
    dispatch({
        type: constants.SET_DEFAULT_CONTACT_START
   })
}

export const updateDefaultContactFromServer = data => (dispatch) => {
    const cookieValue = getCsrfCookieValue()
    fetch(`${constants.INUS_ADDRESS}/us/v1/storeContact`, {
        method: 'POST',
        credentials : 'include',
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            'X-XSRF-TOKEN' : `${cookieValue ? cookieValue : ''}`
        }, 
        body: stringify({
            contact:{
                "id":new LosslessNumber(data.defaultContact.id).valueOf(),
                "defaultShipping": false,
                "addressLine1":data.defaultContact.addressLine1,
                "addressLine2":data.defaultContact.addressLine2,
                "city":data.defaultContact.city,
                "state":data.defaultContact.state,
                "country":data.defaultContact.country,
                "postalCode":data.defaultContact.postalCode,
                "phoneNumber":data.defaultContact.phoneNumber,
                "email":data.defaultContact.email,
                "givenName":data.defaultContact.givenName,
                "familyName":data.defaultContact.familyName
            },
            "publicId":data.publicId      
        })
    })
    .then((response) => {
        if (response.status === 401) {
            dispatch({
                type: constants.ACCESS_TOKEN_INVALID,
            })
            throw response.status
        }
        else {
            return response.text()
        }
    })
    .then((response) => {
        return parse(response, null, customNumberParser)
    })
    .then((response) => {     
        data.sessionId=response.adminSessionId
        data.astp=response.astp
        if(response.errorCode !== "OK"){
            dispatch({
                type: constants.CONTACT_UPDATE_FAILED,
                payload: response.message ? response.message : "Failed to update address. Please retry or come back later."
            });
        }
        else {
            getNextScript(data, dispatch);
        } 
    })
    .catch((error) => {
        if(error !==  401) {
            console.error('Error:', error);
            dispatch({
                type: constants.CONTACT_UPDATE_FAILED,
                payload: messages.CONTACT_UPDATE_FAILED
            });
        }
    });
}


export const startUpdateContact = data => dispatch => {
    dispatch({
        type: constants.CONTACT_UPDATE_START,
        payload: data
    })
    data.history.push('/add-shipping')
}

export const cancelUpdateContact = data => dispatch => {
    dispatch({
    type: constants.CONTACT_UPDATE_CANCEL
    })
    data.history.push('/contacts')

}


//UpdateContact via INIS
export const updateContactFromServer = data => (dispatch) => {
    dispatch({
        type: constants.CONTACT_UPDATE_LOADING,
    })
    const cookieValue = getCsrfCookieValue()
    fetch(`${constants.INUS_ADDRESS}/us/v1/storeContact`, {
        method: 'POST',
        credentials : 'include',
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            'X-XSRF-TOKEN' : `${cookieValue ? cookieValue : ''}`
        }, 
        body: stringify({
            contact:{
            "id": data.contact.id?new LosslessNumber(data.contact.id).valueOf():null,
            "defaultShipping": data.markAsDefaultShippingContact,
            "addressLine1":data.contact.addressLine1,
            "addressLine2":data.contact.addressLine2,
            "city":data.contact.city,
            "state":data.contact.state,
            "country":data.contact.country,
            "postalCode":data.contact.postalCode,
            "phoneNumber":data.contact.phoneNumber,
            "email":data.contact.email,
            "givenName":data.contact.givenName,
            "familyName":data.contact.familyName },
            "publicId":data.publicId  
        })
    })
    .then((response) => {
        if (response.status === 401) {
            dispatch({
                type: constants.ACCESS_TOKEN_INVALID,
            })
            throw response.status
        }
        else {
            return response.text()
        }
    })
    .then((response) => {
        return parse(response, null, customNumberParser)
    })
    .then((response) => {
        data.sessionId=response.adminSessionId
        data.astp=response.astp
        if(response.errorCode !== "OK"){
            dispatch({
                type: constants.CONTACT_UPDATE_FAILED,
                payload: response.message ? response.message : "Failed to update address. Please retry or come back later."
            });
        }
        else {
            getNextScript(data, dispatch);            
        } 
    })
    .catch((error) => {
        if(error !==  401) {
            console.error('Error:', error);
            dispatch({
                type: constants.CONTACT_UPDATE_FAILED,
                payload: messages.CONTACT_UPDATE_FAILED
            });
        }
    });
}

//UpdateContact request to device
const getNextScript = function (data, dispatch) {
    const deviceToken = getDeviceTokenFromCookie();
    const requestURL = `${constants.MTO_ADDRESS_HOSTNAME}:${String(data.devicePort)}/v1/command`
    fetch(requestURL,{
        method: 'POST',
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
        },
        body:stringify(
            {
                token: deviceToken,
                astp:data.astp,
                cmd:constants.GET_NEXT_SCRIPT
            })
        })
        .then((response)=>{
            return response.json()
        })
        .then((response)=> {            
            if (!response.status) {
                dispatch({
                    type: constants.CONTACT_UPDATE_FAILED,
                    payload: response.message ?  response.message : "Failed to update address. Please retry or come back later."
                });
            }
            else {
                if(data.setDefaultContact){
                    data.setDefaultContact=false
                    dispatch(updateContactFromServer(data))
                }
                else if(data.deleteContact){
                    data.deleteContact=false
                    getDeleteContactResult(data,dispatch)
                }
                else{
                    getupdateContactResult(data,dispatch)
                }               
            }
    })
    .catch((error) => {
        console.error('Error:', error);
        dispatch({
            type: constants.CONTACT_UPDATE_FAILED,
            payload: messages.CONTACT_UPDATE_FAILED
        });
    });
}

export const getupdateContactResult = (data ,dispatch) => {
    const cookieValue = getCsrfCookieValue()
    fetch(`${constants.INUS_ADDRESS}/us/v1/storeContactResult`, {
        method: 'POST',
        credentials : 'include',
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            'X-XSRF-TOKEN' : `${cookieValue ? cookieValue : ''}`
        },
        body:stringify({
            deviceId : new LosslessNumber(data.deviceId).valueOf(),
            sessionId : data.sessionId
        })
    })
    .then((response) => {
        if (response.status === 401) {
            dispatch({
                type: constants.ACCESS_TOKEN_INVALID,
            })
            throw response.status
        }
        else {
            return response.text()
        }
    })
    .then((response) => {
        return parse(response, null, customNumberParser)
    })
    .then((response) => {
            if(response.errorCode !== "OK"){
                dispatch({
                    type: constants.CONTACT_UPDATE_FAILED,
                    payload: response.message ? response.message : "Failed to update address. Please retry or come back later."
                });
            }
            else{
                if(data.markAsDefaultShippingContact){
                    dispatch({
                        type: constants.SET_DEFAULT_CONTACT_SUCCESS
                    });
                }
                if(data.selectNewBilling){
                    data.contactId=response.contact.id
                    dispatch(linkBillingtoCard(data))
                }
                else {
                    dispatch({
                        type: constants.CONTACT_UPDATE_SUCCESS,
                        payload:{
                            contact:response.contact
                        }
                    });
                    data.callback()
                }
            }
    })
    .catch((error) => {
        if(error !==  401) {
            console.error('Error:', error);
            dispatch({
                type: constants.CONTACT_UPDATE_FAILED,
                payload: messages.CONTACT_UPDATE_FAILED
            });
        }
    });
}


export const showContactDetails = (data) => (dispatch) =>{
    dispatch({
        type: constants.CONTACT_SHOW_DETAILS,
        payload:data
    });
}

export const clearContactState = (data) => dispatch => {
    dispatch({
        type: constants.CONTACT_STATE_CLEAR,
    })
}

export const resetContactErrorMessages = () => (dispatch)=>{
    dispatch({
        type: constants.RESET_ERROR_STATES,
      })
}
