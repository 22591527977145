/*
 * INTEL CONFIDENTIAL
 * Copyright 2022 Intel Corporation.
 * This software and the related documents are Intel copyrighted materials, and
 * your use of them is governed by the express license under which they were
 * provided to you (License). Unless the License provides otherwise, you may not
 * use, modify, copy, publish, distribute, disclose or transmit this software or
 * the related documents without Intel's prior written permission. This software
 * and the related documents are provided as is, with no express or implied
 * warranties, other than those that are expressly stated in the License.
 */
import { Formik, Form, Field } from 'formik';
import {Container} from 'react-bootstrap'
import { Link } from 'react-router-dom'
import React, { Component } from 'react'
import { connect } from 'react-redux'
import * as Yup from 'yup';

import { activateCard, resetErrorMessages, selectIdvMethod,  selectVerificationMethod, verifyCardLater } from '../../actions/cards'
import { login } from '../../actions/user'
import { resetVerifyReducerState, verify } from '../../actions/verify'

import RadioButton from '../inputs/RadioButton';
import RadioButtonGroup from '../inputs/RadioButtonGroup';
import TextInput from '../inputs/TextInput';

import { alphaNumericRegEx } from '@pcwallet/common/validationEngine';

function maskEmail(email){
    if(email){
        let splitEmail = email.trim().split("@")
        let username=splitEmail[0].substr(0,1)+ splitEmail[0].substr(1,splitEmail[0].length-2).replace(/./g,'*') + splitEmail[0].substr(-1)
        let maskedEmail = username+"@"+splitEmail[1]
        return maskedEmail
    }
    else{
        return email
    }
}

export class VerifyCard extends Component {

    componentDidMount() {
        this.props.resetVerifyReducerState();
    }

    render() {
        const { history } = this.props;
        return (
            <div>
                {
                    this.props.verifyState.codeSentSuccess && this.props.verifyState.selectedMethod?                        
                    <div>
                            {   
                                this.props.verifyState.selectedMethod.type.trim() === "TEXT_TO_CARDHOLDER_NUMBER" ||
                                 this.props.verifyState.selectedMethod.type.trim() === "EMAIL_TO_CARDHOLDER_ADDRESS" || 
                                 this.props.verifyState.selectedMethod.type.trim() === "ISSUER_TO_CALL_CARDHOLDER_NUMBER"
                                ?
                                <div>
                                <Formik
                                initialValues={{
                                    verificationCode: '',
                                }}
                                validationSchema={Yup.object({
                                    verificationCode: Yup.string()
                                        .required('Required')
                                        .matches(alphaNumericRegEx,'code format is invalid')
                                        .min(1,"minimum 1 character allowed")
                                        .max(32,"maximum 32 characters allowed")
                                })}
                                onSubmit={(values, {setSubmitting}) => {
                                    this.props.activateCard(
                                        {
                                            tokenId:this.props.cards.tokenId,
                                            code: values.verificationCode.trim(),
                                            setup:this.props.setup,
                                            sessionId:this.props.cards.sessionId,
                                            deviceId:this.props.devices.connected.deviceId,
                                            devicePort: this.props.devicePortDiscovery.devicePort,
                                            contactId: this.props.contacts.defaultShippingContactId,
                                            history
                                        }
                                    )
                                    setTimeout(()=>{
                                        setSubmitting(false) 
                                    },1000)
                                }}
                            >
                                {({ dirty, isValid, isSubmitting, values, touched, setFieldValue }) => (
                                    <Form>
                                        <Container fluid className='p-2 py-2'>
                                            <div className="pb-4">
                                                <p className='pb-2'> A code was send to &nbsp;<b>{this.props.verifyState.selectedMethod.type === "EMAIL_TO_CARDHOLDER_ADDRESS" ? maskEmail(this.props.verifyState.selectedMethod.value):this.props.verifyState.selectedMethod.value}</b>. Didn’t receive a code?<br/>Check your spam folder or <span className="link" onClick={
                                                     ()=>{
                                                        this.props.resetErrorMessages();
                                                        this.props.selectVerificationMethod();                                                  }
                                                }>Resend Code</span>
                                                </p>
                                            </div>
                                            <TextInput
                                                onChange={(event) => {
                                                    setFieldValue('verificationCode', event.target.value)
                                                }}
                                                label="Verification Code"
                                                name="verificationCode"
                                                type="text"/>
                                        <div className="button-wrapper d-flex justify-content-between">
                                            <button
                                            className="btn btn-outline-primary btn-lg button-outline"
                                            disabled={this.props.cards.addCardLoading}
                                            type='button'
                                            onClick={()=>{
                                                this.props.resetErrorMessages();
                                                this.props.selectVerificationMethod();
                                            }}>
                                                Previous
                                            </button>
                                            <button
                                                className="btn btn-primary btn-lg button"
                                                disabled={isSubmitting ||!values.verificationCode || !isValid || this.props.cards.addCardLoading}
                                                type="submit"
                                            >
                                                {this.props.cards.addCardLoading && <span className="spinner-border spinner-border-sm mr-1" role="status" aria-hidden="true" />}
                                                Verify
                                            </button>
                                        </div>
                                        </Container>
                                    </Form>
                                )}
                                </Formik>
                                </div>
                            :
                            <div className='p-3 py-2'>
                            <div>                    
                            Please call <b>{this.props.verifyState.selectedMethod.value}</b> number to activate the card. Once complete please press done.
                            </div>
                            <div className={"button-wrapper d-flex justify-content-between"}>
                                        <button
                                        className="btn btn-outline-primary btn-lg button-outline"
                                        type='button'
                                        onClick={()=>{this.props.selectVerificationMethod()}}>
                                            Previous
                                        </button>
                                    <div>
                                        <Link to='/cards'>
                                        <button
                                            className="btn btn-primary btn-lg button"                                        
                                            type="button"
                                        > Done</button>
                                        </Link>
                                    </div>                            
                                </div>
                            </div> 
                            }
                    </div>
                    :
                    <div>
                    <Formik
                    initialValues={{
                        verifyRadioGroup: '',
                    }}
                    validationSchema={Yup.object({
                        verifyRadioGroup: Yup.string().required('Required')
                    })}
                    onSubmit={(values, {setSubmitting}) => {
                        this.props.selectIdvMethod(
                            {
                                tokenId: this.props.cards.tokenId,
                                selectedMethod: this.props.verifyMethods.find((method)=>{ 
                                    if(method.verificationMethodIdentifier === values.verifyRadioGroup){
                                        return method}}),
                                methodId:values.verifyRadioGroup
                            }
                        )
                        setTimeout(()=>{
                            setSubmitting(false) 
                        },1000)
                    }}
                >
                    {({ dirty, isValid, isSubmitting, submitForm, values, touched, setFieldValue }) => (
                        <Form>
                            {
                                <div>
                                <p className ="my-5">Card added to wallet. Verify card to activate it for purchase. Choose a verification method :</p>
                                <RadioButtonGroup
                                    id="verifyRadioGroup"
                                    value={values.verifyRadioGroup}
                                    touched={touched.verifyRadioGroup}
                                >
                                    {
                                        this.props.verifyMethods?
                                        this.props.verifyMethods.map((method, idx)=>{
                                            return(
                                                <div key={idx}>
                                                    <Field
                                                    disabled={method.value===null}
                                                    className="radio-option p-2"
                                                    component={RadioButton}
                                                    name="verifyRadioGroup"
                                                    id={method.verificationMethodIdentifier}/> &nbsp; &nbsp;&nbsp;
                                                    <label>
                                                    {
                                                        method.type.trim() === "EMAIL_TO_CARDHOLDER_ADDRESS"?
                                                        "Email Code to : " + maskEmail(method.value)
                                                        :
                                                        method.type.trim() === "TEXT_TO_CARDHOLDER_NUMBER"?
                                                        "Text code to :  " + method.value
                                                        :
                                                        method.type.trim() === "ISSUER_TO_CALL_CARDHOLDER_NUMBER"?
                                                        "Get Code by Call : " + method.value
                                                        :
                                                        method.type.trim() === "CARDHOLDER_TO_CALL_AUTOMATED_NUMBER" || method.type.trim() === "CARDHOLDER_TO_CALL_MANNED_NUMBER"?
                                                        "Call Number to Activate : " + method.value
                                                        :<></>
                                                    }
                                                    </label>
                                                </div>                                                                
                                            )}
                                        )
                                        :
                                        ''
                                    }                                                 
                                </RadioButtonGroup>
                                <div className={(this.props.setup && !this.props.backTo)?"button-wrapper d-flex justify-content-end":"button-wrapper d-flex justify-content-between"}>
                                        {
                                            !this.props.setup?
                                            <Link to='/cards'>
                                                <button
                                                className="btn btn-outline-primary btn-lg button-outline"
                                                type='button'
                                                onClick={()=>{this.props.verifyCardLater()}}>
                                                    Verify Later
                                                </button>
                                            </Link>
                                            :
                                            (this.props.setup && this.props.backTo && this.props.backTo.componentUrl)?
                                                <Link to={this.props.backTo.componentUrl}>
                                                    <button
                                                    className="btn btn-outline-primary btn-lg button-outline"
                                                    type='button'
                                                    >
                                                        Back
                                                    </button>
                                                </Link>
                                            :
                                            <></>
                                        }
                                        
                                        <div>
                                            <button
                                                className="btn btn-primary btn-lg button"                                        
                                                disabled={isSubmitting || !values.verifyRadioGroup && !this.props.verifyLogin}
                                                type="submit"
                                            > 
                                            {this.props.verifyState.isLoading && <span className="spinner-border spinner-border-sm mr-1" role="status" aria-hidden="true" />}
                                            Continue</button>
                                        </div>                            
                                    </div>
                                </div>          
                            }
                        </Form>
                    )}
                    </Formik>
                    </div>
                }
            </div>
        )
    }
}

const mapStateToProps = state => ({
    authData: state.user,
    verifyState: state.verify,
    cards: state.cards,
    devices: state.devices,
    devicePortDiscovery: state.devicePortDiscovery,
    contacts:state.contacts
})

const mapDispatchToProps = dispatch => ({
    resetVerifyReducerState: () => dispatch(resetVerifyReducerState),
    verify: (code, callback) => dispatch(verify(code, callback)),
    login: (userInfo) => dispatch(login(userInfo)),
    activateCard: (code, callback) => dispatch(activateCard(code, callback)),
    selectIdvMethod:(data) => dispatch(selectIdvMethod(data)),
    selectVerificationMethod:(data) => dispatch(selectVerificationMethod(data)),
    verifyCardLater:(data) => dispatch(verifyCardLater(data)),
    resetErrorMessages: () => dispatch(resetErrorMessages())

})

export default connect(mapStateToProps, mapDispatchToProps)(VerifyCard) 