var usStates = [
    { stateName: 'ALABAMA', stateCode: 'AL' },
    { stateName: 'ALASKA', stateCode: 'AK' },
    { stateName: 'AMERICAN SAMOA', stateCode: 'AS' },
    { stateName: 'ARIZONA', stateCode: 'AZ' },
    { stateName: 'ARKANSAS', stateCode: 'AR' },
    { stateName: 'CALIFORNIA', stateCode: 'CA' },
    { stateName: 'COLORADO', stateCode: 'CO' },
    { stateName: 'CONNECTICUT', stateCode: 'CT' },
    { stateName: 'DELAWARE', stateCode: 'DE' },
    { stateName: 'DISTRICT OF COLUMBIA', stateCode: 'DC' },
    { stateName: 'FEDERATED STATES OF MICRONESIA', stateCode: 'FM' },
    { stateName: 'FLORIDA', stateCode: 'FL' },
    { stateName: 'GEORGIA', stateCode: 'GA' },
    { stateName: 'GUAM', stateCode: 'GU' },
    { stateName: 'HAWAII', stateCode: 'HI' },
    { stateName: 'IDAHO', stateCode: 'ID' },
    { stateName: 'ILLINOIS', stateCode: 'IL' },
    { stateName: 'INDIANA', stateCode: 'IN' },
    { stateName: 'IOWA', stateCode: 'IA' },
    { stateName: 'KANSAS', stateCode: 'KS' },
    { stateName: 'KENTUCKY', stateCode: 'KY' },
    { stateName: 'LOUISIANA', stateCode: 'LA' },
    { stateName: 'MAINE', stateCode: 'ME' },
    { stateName: 'MARSHALL ISLANDS', stateCode: 'MH' },
    { stateName: 'MARYLAND', stateCode: 'MD' },
    { stateName: 'MASSACHUSETTS', stateCode: 'MA' },
    { stateName: 'MICHIGAN', stateCode: 'MI' },
    { stateName: 'MINNESOTA', stateCode: 'MN' },
    { stateName: 'MISSISSIPPI', stateCode: 'MS' },
    { stateName: 'MISSOURI', stateCode: 'MO' },
    { stateName: 'MONTANA', stateCode: 'MT' },
    { stateName: 'NEBRASKA', stateCode: 'NE' },
    { stateName: 'NEVADA', stateCode: 'NV' },
    { stateName: 'NEW HAMPSHIRE', stateCode: 'NH' },
    { stateName: 'NEW JERSEY', stateCode: 'NJ' },
    { stateName: 'NEW MEXICO', stateCode: 'NM' },
    { stateName: 'NEW YORK', stateCode: 'NY' },
    { stateName: 'NORTH CAROLINA', stateCode: 'NC' },
    { stateName: 'NORTH DAKOTA', stateCode: 'ND' },
    { stateName: 'NORTHERN MARIANA ISLANDS', stateCode: 'MP' },
    { stateName: 'OHIO', stateCode: 'OH' },
    { stateName: 'OKLAHOMA', stateCode: 'OK' },
    { stateName: 'OREGON', stateCode: 'OR' },
    { stateName: 'PALAU', stateCode: 'PW' },
    { stateName: 'PENNSYLVANIA', stateCode: 'PA' },
    { stateName: 'PUERTO RICO', stateCode: 'PR' },
    { stateName: 'RHODE ISLAND', stateCode: 'RI' },
    { stateName: 'SOUTH CAROLINA', stateCode: 'SC' },
    { stateName: 'SOUTH DAKOTA', stateCode: 'SD' },
    { stateName: 'TENNESSEE', stateCode: 'TN' },
    { stateName: 'TEXAS', stateCode: 'TX' },
    { stateName: 'UTAH', stateCode: 'UT' },
    { stateName: 'VERMONT', stateCode: 'VT' },
    { stateName: 'VIRGIN ISLANDS', stateCode: 'VI' },
    { stateName: 'VIRGINIA', stateCode: 'VA' },
    { stateName: 'WASHINGTON', stateCode: 'WA' },
    { stateName: 'WEST VIRGINIA', stateCode: 'WV' },
    { stateName: 'WISCONSIN', stateCode: 'WI' },
    { stateName: 'WYOMING', stateCode: 'WY' }
]

export default usStates;