/*
 * INTEL CONFIDENTIAL
 * Copyright 2022 Intel Corporation.
 * This software and the related documents are Intel copyrighted materials, and
 * your use of them is governed by the express license under which they were
 * provided to you (License). Unless the License provides otherwise, you may not
 * use, modify, copy, publish, distribute, disclose or transmit this software or
 * the related documents without Intel's prior written permission. This software
 * and the related documents are provided as is, with no express or implied
 * warranties, other than those that are expressly stated in the License.
 */
import React, { Component } from 'react'
import { connect } from 'react-redux'
import { Formik, Form } from 'formik';
import { Link } from 'react-router-dom'
import * as Yup from 'yup';

import {passwordRegex, testPasswordStrength} from '@pcwallet/common/validationEngine';
import { changePassword, showAccount } from '../../actions/user';
import { startChangePincode } from '../../actions/security'

import Button from '../inputs/Button';
import TextInput from '../inputs/TextInput';

export class ChangePasswordContainer extends Component {

  constructor(props) {
    super(props)
    this.state = {
        currentPasswordHide: true,
        passwordHide: true,
        confirmPasswordHide: true,
      }
      this.toggleCurrentPasswordHide = this.toggleCurrentPasswordHide.bind(this);
      this.togglePasswordHide = this.togglePasswordHide.bind(this);
      this.toggleConfirmPasswordHide = this.toggleConfirmPasswordHide.bind(this);
      this.handleStrengthTest = this.handleStrengthTest.bind(this);
  }

  toggleCurrentPasswordHide() {
    this.setState({ currentPasswordHide: !this.state.currentPasswordHide });
  }
  togglePasswordHide() {
    this.setState({ passwordHide: !this.state.passwordHide });
  }

  toggleConfirmPasswordHide() {
    this.setState({ confirmPasswordHide: !this.state.confirmPasswordHide });
  }

  handleStrengthTest(event,handleChange){
    if(event.target.value != null){
        let testResult = testPasswordStrength(event.target.value)
        this.setState(({strengthResult: testResult}))
    }
    handleChange(event)
  }

  componentDidMount(){
    //initializing analytics
  }

  render() {
    const { history } = this.props;
    return (
      <div>
        <div className='d-flex align-items-center' style={{marginBottom: '0.25rem'}}>
          <p>
            Note:  This will change the password for your wallet account.  To change the PIN for your device, select 
            <Link to='/add-pin'> <span onClick={() => {
              this.props.startChangePincode({
                history
              })
            }}> change PIN</span></Link>.
          </p>
        </div>
          <div className="pb-3">
            <div>New Password must have :</div>
            <div className='ml-4'>
              <ul className="px-3 mb-2">
                <li>Minimum of 8 characters.</li>
                <li>Maxmimum password length is 64 characters.</li>
                <li>Any combination of letters, numerics and allowed special characters (! @ # $ % ^ &amp; * ! () - _ + ).</li>
              </ul>
            </div>
          </div>
            {
              <Formik
              initialValues={{
                  currentPassword:'',
                  newPassword:'',
                  confirmPassword:''
              }}
              validationSchema={Yup.object({
                currentPassword: Yup.string()
                .matches(passwordRegex,'field contains invalid character')
                .required('Required'),
                newPassword: Yup.string()
                .label('Password')
                .min(8, 'Minimum password length is 8 characters')
                .max(64, 'Maxmimum password length is 64 characters')
                .matches(passwordRegex, 'You need at least one uppercase letter, one lowercase letter, one number and one special character')
                .required('Required'),
                confirmPassword: Yup.string()
                .label('Confirm password')
                .test('passwords-match', 'Passwords must match.', function(value) {
                  return this.parent.newPassword === value;
                })
                .required('Required')
              })}
              onSubmit={(values, { setSubmitting, resetForm }) => {
                  this.props.changePassword({
                    userId:this.props.user.data.id,
                    password:values.currentPassword.trim(),
                    newPassword:values.newPassword.trim(),
                  })
                setSubmitting(false);
                resetForm()
              }}
              >
                {({values, dirty, isValid, isSubmitting, handleChange}) => ( 
                  <Form className="change-pwd-label">
                    <label className='form-required-instruction-label'>All Fields Required</label>
                    <TextInput
                      // disabled={true}
                      label="Current Password"
                      name="currentPassword"
                      type={this.state.currentPasswordHide ? 'password' : 'text'}
                      toggleshow={this.toggleCurrentPasswordHide}
                      hidestate={this.state.currentPasswordHide.toString()}
                    />
                    <TextInput
                      // disabled={true}
                      label="New Password"
                      name="newPassword"
                      type={this.state.passwordHide ? 'password' : 'text'}
                      toggleshow={this.togglePasswordHide}
                      hidestate={this.state.passwordHide.toString()}
                      onChange={event=>{
                        this.handleStrengthTest(event,handleChange)
                      }}
                      result={this.state.strengthResult}
                      fieldname="password"
                    />
                    <TextInput
                      // disabled={true}
                      label="Confirm Password"
                      name="confirmPassword"
                      type={this.state.confirmPasswordHide ? 'password' : 'text'}
                      toggleshow={this.toggleConfirmPasswordHide}
                      hidestate={this.state.confirmPasswordHide.toString()}
                    />
                    <div className="mt-2 d-flex justify-content-end">
                    <div>
                      <Button
                        disabled={  isSubmitting || !dirty || !isValid || this.props.user.isLoading}
                        type="submit"
                      >
                        {this.props.user.isLoading && <span className="spinner-border spinner-border-sm mr-1" role="status" aria-hidden="true" />}
                        Change Password
                      </Button>
                      </div>
                    </div>
                  </Form>
                )}
              </Formik>
            }     
          </div>
    ); 
  }
}

const mapStateToProps = state => ({
  user:state.user
})

const mapDispatchToProps = (dispatch) => ({
  changePassword:(data)=>dispatch(changePassword(data)),
  showAccount:(data)=>dispatch(showAccount(data)),
  startChangePincode: (data) => dispatch(startChangePincode(data))
})

export default connect(mapStateToProps, mapDispatchToProps )(ChangePasswordContainer)