/*
 * INTEL CONFIDENTIAL
 * Copyright 2022 Intel Corporation.
 * This software and the related documents are Intel copyrighted materials, and
 * your use of them is governed by the express license under which they were
 * provided to you (License). Unless the License provides otherwise, you may not
 * use, modify, copy, publish, distribute, disclose or transmit this software or
 * the related documents without Intel's prior written permission. This software
 * and the related documents are provided as is, with no express or implied
 * warranties, other than those that are expressly stated in the License.
 */
import * as constants from '@pcwallet/common/constants'

const initialState = {
  data: null,
  isLoading: false,
  error: false,
  errorMessage: null,
  showSuccess: false,
  forgotPassword: false,
  timedout: false,
  login:false,
  userIdle:false,
  tokenIsValid: false,
	fetchingRefreshToken: false,
  username:'',
  showAccount:false,
  email:'',
  otp:'',
  message:'',
  verified:false,
  resendCode:false,
  accountState:''
}

export default function userUpdate(state = initialState, { type, payload }) {
  switch (type) {
    case constants.USER_LOGOUT: 
      return {
        ...initialState,
      }
    case constants.USER_LOGOUT_SUCCESS: 
      return {
        ...state,
        errorMessage: null,
        error: false,
      }
    case constants.USER_IDLE: 
      return {
        ...state,
        userIdle: true,
      }
    case constants.USER_TIMED_OUT: 
      return {
        ...initialState,
        timedout: true,
      }
    case constants.USER_LOGIN:
      return {
        ...state,
        errorMessage: null,
        isLoading: true,
        error: false,
      }
    case constants.USER_LOGIN_SUCCESS:
      return {
        ...state,
        errorMessage: null,
        isLoading: false,
        login: true,
        logout: false,
        error: false,
        timedout: false,
        username:payload.username
      }
    case constants.USER_LOGIN_FAILED:
      return {
        ...state,
        errorMessage: payload,
        isLoading: false,
        error: true
      }
    case constants.USER_DETAILS:
      return {
        ...state,
        errorMessage: null,
        isLoading: true,
        error: false,
      }
    case constants.USER_DETAILS_SUCCESS:
      return {
        ...state,
        data: payload,
        login:true,
        verified:payload.verified,
        errorMessage: null,
        isLoading: false,
        error: false
      }
    case constants.USER_DETAILS_FAILED:
      return {
        ...state,
        isLoading: false,
        errorMessage: payload,
        error: true
      }
    case constants.USER_FORGOT_PASSWORD:
      return {
        ...state,
        forgotPassword:false,
        error: false,
        errorMessage: null,
        isForgotPwdLoading: true,
        isLoading: false
      }
    case constants.USER_FORGOT_PASSWORD_SUCCESS:
      return {
        ...state,
        forgotPassword:true,
        username:payload.username,
        resendCode:payload.resendCode,
        error: false,
        errorMessage: null,
        isForgotPwdLoading: false,
        isLoading: false
      }
    case constants.USER_FORGOT_PASSWORD_FAILED:
      return {
        ...state,
        forgotPassword:false,
        username:payload.username,
        error: true,
        errorMessage: payload.message,
        isForgotPwdLoading: false,
        isLoading: false
      }
    case constants.USER_RESET_PASSWORD:
      return {
        ...state,
        forgotPassword:false,
        error: false,
        errorMessage: null,
        isResetPwdLoading: true,
        isLoading: false
      }
    case constants.USER_RESET_PASSWORD_CANCEL:
      return {
        ...state,
        forgotPassword: false,
        error: false,
        errorMessage: null,
        isForgotPwdLoading: false,
        isResetPwdLoading: false,
        isLoading: false
      }
    case constants.USER_RESET_PASSWORD_SUCCESS:
      return {
        ...state,
        forgotPassword:false,
        error: false,
        errorMessage: null,
        isForgotPwdLoading: false,
        isResetPwdLoading: false,
        isLoading: false
      }
    case constants.USER_RESET_PASSWORD_FAILED:
      return {
        ...state,
        forgotPassword:false,
        error: true,
        errorMessage: payload,
        isResetPwdLoading: false,
        isLoading: false
      }   
    case constants.USER_CHANGE_PASSWORD:
      return {
        ...state,
        error: false,
        errorMessage: null,
        isLoading: true
      }
    case constants.USER_CHANGE_PASSWORD_SUCCESS:
      return {
        ...state,
        showSuccess:true,
        accountState:constants.USER_CHANGE_PASSWORD_SUCCESS,
        error: false,
        errorMessage: null,
        isLoading: false
      }
    case constants.USER_CHANGE_PASSWORD_FAILED:
      return {
        ...state,
        error: true,
        errorMessage: payload,
        isLoading: false
      }   
    case constants.UPDATE_ACCOUNT:
      return {
        ...state,
        isLoading: true,
        errorMessage: null,
        error: false,
        showSuccess: false,
      }
    case constants.UPDATE_ACCOUNT_SUCCESS:
      return {
        ...state,
        data: payload,
        accountState:constants.UPDATE_ACCOUNT_SUCCESS,
        errorMessage: null,
        isLoading: false,
        showSuccess: true,
        error: false
      }
    case constants.UPDATE_ACCOUNT_FAILED:
      return {
        ...state,
        isLoading: false,
        errorMessage: payload,
        error: true
      }
    case constants.USER_ACTION_FORBIDDEN:
      return{
        ...state,
        isLoading: false,
        errorMessage:payload.message,
        error: true
      }
    case constants.REFRESH_TOKEN_GET:
      return{
        ...state,
        fetchingRefreshToken: true,
        tokenIsValid: false,
        errorMessage: null,
        error: false
      }
    case constants.REFRESH_TOKEN_GET_SUCCESS:
      return{
        ...state,
        fetchingRefreshToken: false,
        tokenIsValid: true,
        errorMessage: null,
        error: false
      }
    case constants.REFRESH_TOKEN_GET_FAILED:
      return{
        ...state,
        error: true,
        errorMessage: payload,
        fetchingRefreshToken: false,
        tokenIsValid: false
        }
    case constants.REFRESH_TOKEN_EXPIRED:
      return{
        ...state,
        error: true,
        errorMessage: payload,
        fetchingRefreshToken: false,
        tokenIsValid: false
        }
    case constants.VERIFY_DEVICE_OWNER_START:
      return {
        ...state,
        errorMessage: null,
        isLoading: true,
        error: false,
      }
    case constants.VERIFY_DEVICE_OWNER_SUCCESS:
        return {
          ...state,          
          errorMessage: null,
          isLoading: true,
          error: false,
          login: true,
          logout: false,
          verified:true
        }
    case constants.VERIFY_DEVICE_OWNER_FAILED:
      return {
        ...state,
        isLoading: false,
        error: true,
        errorMessage: payload,
      }
    case constants.USER_EMAIL_REGISTER: 
      return {
        ...state,
        isLoading: true,
        error:false,
        errorMessage: null,
      }
    case constants.USER_EMAIL_REGISTER_SUCCESS: 
      return {
        ...state,
        email:payload.username,
        message:payload.message,
        resendCode:payload.resendCode,
        isLoading: false,
        errorMessage: null,
        error: false,
      }
    case constants.USER_EMAIL_REGISTER_FAILED: 
      return {
        ...state,
        isLoading: false,
        error: true,
        errorMessage: payload,
      }
    case constants.USER_EMAIL_REGISTER_FAILED: 
      return {
        ...state,
        isLoading: false,
        resendCode:false,
        error: false,
        errorMessage:'',
      }
    case constants.USER_REGISTER_CODE_SAVE: 
      return {
        ...state,
        isLoading: false,
        error: false,
        errorMessage: null,
        otp:payload
      }
    case constants.USER_REGISTER: 
      return {
        ...state,
        isLoading: true,
        error:false,
        errorMessage: null,
      }
    case constants.USER_REGISTER_SUCCESS: 
      return {
        ...state,
        data: {
          ...state.data,
          id: payload.user
        },
        isLoading: false,
        errorMessage: null,
        error: false,
      }
    case constants.USER_REGISTER_FAILED: 
      return {
        ...state,
        isLoading: false,
        error: true,
        errorMessage: payload,
      }
    case constants.USER_ACCOUNT_SHOW:
      return{
        ...state,
        isLoading: false,
        errorMessage:'',
        error: false,
        showAccount:payload
      }
    case constants.RESET_ERROR_STATES:
      return{
        ...state,
        errorMessage:'',
        error: false,
      }
    case constants.USER_ACCOUNT_RESET_SHOW_SUCCESS:
      return{
        ...state,
        isLoading: false,
        errorMessage:'',
        error: false,
        showSuccess:payload,
        accountState:''
      }
    default:
      return state
  }
}