export const ADD_CARD_FAILED_RETRY_AGAIN = "The card could not be added to the wallet. Please re-enter card details and retry" 
export const ADD_CARD_FAILED_GENERIC_MSG = "The card could not be added to the wallet at this time, please return later and try again."
export const ADD_CARD_LOADING_MSG1 = "Preparing to add card " 
export const ADD_CARD_LOADING_MSG2 = "Contacting issuer bank" 
export const ADD_CARD_LOADING_MSG3 = "Submitting response to issuer bank" 
export const ADD_CARD_LOADING_MSG4 = "Adding card to wallet"
export const ADD_CARD_TIMEOUT_MSG1 = "The card could not be added to the wallet at this time, please return later and try again." 
export const ADD_CARD_TIMEOUT_MSG2 = "Meantime, you can use manage/modify other settings in your wallet"

export const CARD_DELETE_FAILED = "The card could not be deleted from the wallet at this time, please return later and try again."
export const CARD_GET_LIST_FAILED = "Card List could not be loaded at this time. Please reload page or come back later"
export const CARD_SET_AS_DEFAULT_FAILED = "The card could not be set as default to the wallet at this time, please return later and try again."

export const CARD_STATUS_FAILED = "FAILED"
export const CARD_STATUS_INACTIVE = "INACTIVE" 
export const CARD_STATUS_PENDING = "PENDING" 
export const CARD_STATUS_VERIFY = "VERIFY" 

export const CONTACT_ADD_FAILED = "The address could not be added to wallet at this time, please return later and try again." 
export const CONTACT_DELETE_FAILED = "The address could not be deleted to wallet at this time, please return later and try again."
export const CONTACT_UPDATE_FAILED = "The address could not be updated to wallet at this time, please return later and try again."
export const CONTACTS_GET_FAILED = "Address list could not be loaded at this time, please return later and try again."

export const DEVICE_ADD_FAILED = "Wallet creation could not be completed at this time, please return later and try again."
export const DEVICE_DELETE_FAILED = "Wallet deletion could not be completed at this time, please return later and try again."
export const DEVICE_GET_LIST_FAILED = "Wallet information could not be loaded, please return later and try again."
export const DEVICE_LOCK_FAILED = "Lock wallet could not be completed at this time, please return later and try again."
export const DEVICE_UNLOCK_FAILED = "Unlock wallet could not be completed at this time, please return later and try again."
export const DEVICE_UPDATE_FAILED = "Wallet update could not be completed at this time, please return later and try again."
export const DEVICE_WALLET_CREATE_LOADING_MSG = 'Creating your wallet'
export const DEVICE_WALLET_NAME_LOADING_MSG = 'Naming your wallet'


export const FINGERPRINT_DELETE_FAILED = "The fingerprint could not be deleted at this time, please return later and try again."
export const FINGERPRINT_ENROLLMENT_FAILED = "The fingerprint could not be added at this time, please return later and try again."
export const FINGERPRINT_GET_LIST_FAILED = "Fingerprint list could not be loaded at this time, please return later and try again."
export const FINGERPRINT_NAME_FAILED = "The fingerprint could not be named at this time, please return later and try again."

export const GET_NEXT_JOB_FAILED = "Action could not be completed at this time. Please return later and retry. "

export const PIN_SETUP_FAILED = "PIN setup could not be completed at this time, please return later and try again."
export const PIN_SETUP_LOADING_MSG = 'Setting up your pin'

export const UPDATE_DEVICE_NAME_MIN_LENGTH_ERROR ="wallet name is required"
export const UPDATE_DEVICE_NAME_MAX_LENGTH_ERROR ="maximum 64 characters allowed"
export const UPDATE_DEVICE_NAME_INVALID_CHAR_ERROR ="wallet name contains invalid character"
export const UPDATE_NICK_NAME_MAX_LENGTH_ERROR = "maximum 32 characters allowed"
export const UPDATE_NICK_NAME_INVALID_CHAR_ERROR = "nick name contains invalid character"

export const USER_CHANGE_PASSWORD_FAILED = "Password could not be changed at this time, please return later and try again."
export const USER_FORGOT_PASSWORD_FAILED = "Email for reset password could be sent at this time, please return later and try again."
export const USER_LOGIN_FAILED = "User login could not be completed at this time, please return later and try again."
export const USER_REFRESH_TOKEN_FAILED = "Login session has expired, please login and try again."
export const USER_REGISTER_FAILED = "User registration could not be completed at this time, please return later and try again."
export const USER_RESET_PASSWORD_FAILED = "Reset password could not be completed at this time, please return later and try again."
export const USER_UPDATE_ACCOUNT_FAILED = "User account details could not be updated at this time, please return later and try again."

export const VERIFY_REQUEST_CODE_FAILED = "Verification code could not be sent at this time, please return later and try again."
export const VERIFY_SUBMIT_CODE_FAILED = "Verification code could not be submitted at this time, please return later and try again."
