import  badPasswordList from "../common/assets/nbp_1000.js"
export const inputRegEx = /^[a-zA-Z0-9.\-_\s+]+$/
export const alphabetsOnlyRegEx = /^[A-Za-z ]+$/
export const digitOnlyRegEx = /^[0-9 ]+$/
export const emailRegEx = /^[a-zA-Z0-9.-_\s@+]+$/
// https://stackoverflow.com/questions/19605150/regex-for-password-must-contain-at-least-eight-characters-at-least-one-number-a
// export const passwordRegex = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,}$/
export const passwordRegex = /^[a-zA-Z0-9!@#$%^&*()_+\-]+$/
export const phoneRegEx = /^[0-9 ()-]+$/
export const codeRegEx = /^[0-9a-zA-Z\-]{8,12}$/
export const alphaNumericRegEx = /^[0-9a-zA-Z]+$/
export const deviceNameRegEx = /^[a-zA-Z0-9.\-_\s@#$!]+$/

export const nickNameRegEx = /^[a-zA-Z0-9.\-_\s@#$!]+$/

//Strong PIN includes at least 1 of each (1 upper, 1 lower, 1 num, 1 symbol) with length between 8-64 
const strongPINRegex = /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#$%^&*()_+\-]).{8,64}$/
//Fair PIN include at least 1 of each, but less than 8 characters 
const fairPINRegex = /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#$%^&*()_+\-]).{6,}$/
const pinLength = 6

//Strong password includes at least 1 of each (1 upper, 1 lower, 1 num, 1 symbol) with length between 9-64 
const strongPasswordRegex = /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#$%^&*()_+\-]).{9,64}$/
//Fair PIN include at least 1 of each, atleast 8 characters
const fairPasswordRegex = /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#$%^&*()_+\-]).{8,}$/
const passwordLength = 8

export const emailCharacterValidator = (email)=>{

    if(email){
        const local = email.split("@")[0];
        if(!local.match(inputRegEx)){
            return false
        }
    }
    return true
}

export const emailLengthValidator = (email)=>{
    if(email){    
        const local = email.split("@")[0];
        if(email.length >254){
            return false
        }
        else{
            if(local.length > 64){
                return false
            }
        }
    }
    return true

}

export const checkPhoneLength = (data) =>{
    if(data) 
    {
        let d = data.trim().replace(/[()-/ ]/gi,'')
        if(d.length >= 11 || d.length < 10){
            return false
        }
        else{
            return true
        }
    }
    return true
}

export const checkCCNlength = (data) =>{
    if(data) 
    {
        let d = data.trim().replace(/ /g, '')
        if(d.length > 16 || d.length < 15){
            return false
        }
        else{
            return true
        }
    }
    return true
}

export const testPINStrength = ( data) => {

    if(data){
        // bad password directory check
        if(badPasswordList.find((string) => (data === string))) {
            return 1;
        }
        else if(data.match(strongPINRegex)) {
            return 3;
        }
        else if(data.match(fairPINRegex)) { 
            return 2;
        }
        //Weak Password does not include atleast 1 of upper, lower, num or symbol and is too short ( less than defined length)
        else if(data.length < pinLength || !data.match(fairPINRegex)) {
            return 1;
        }
        else{
            return 1;
        }
    }
}

export const testPasswordStrength = (data) => {

    if(data){
        if(data.match(strongPasswordRegex)) {
            return 3;
        }
        else if(data.match(fairPasswordRegex)) { 
            return 2;
        }
        //Weak Password does not include atleast 1 of upper, lower, num or symbol and is too short ( less than defined length)
        else if(data.length < passwordLength || !data.match(fairPasswordRegex)) {
            return 1;
        }
        else{
            return 1;
        }
    }
}

export const validateNickName = (nickName) => {
    if (nickName.length == 0) {
      return 0
    } else if (nickName.length >= 32) {
      return 2
    } else if (!nickName.match(nickNameRegEx)) {
      return 3
    } else {
      return 1
    }
}

export const validateDeviceName = (displayName) => {
    if (displayName.length == 0) {
      return 0
    } else if (displayName.length >= 32) {
      return 2
    } else if (!displayName.match(deviceNameRegEx)) {
      return 3
    } else {
      return 1
    }
  }
