/*
 * INTEL CONFIDENTIAL
 * Copyright 2022 Intel Corporation.
 * This software and the related documents are Intel copyrighted materials, and
 * your use of them is governed by the express license under which they were
 * provided to you (License). Unless the License provides otherwise, you may not
 * use, modify, copy, publish, distribute, disclose or transmit this software or
 * the related documents without Intel's prior written permission. This software
 * and the related documents are provided as is, with no express or implied
 * warranties, other than those that are expressly stated in the License.
 */
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Formik, Form, Field } from 'formik';
import { Container } from 'react-bootstrap';
import * as Yup from 'yup'
;
import { verify, requestVerificationCode, resetVerifyReducerState } from '../../actions/verify';
import { login } from '../../actions/user';
import { selectIdvMethod } from '../../actions/cards';
import { codeRegEx } from '@pcwallet/common/validationEngine';
import { MFA } from '@pcwallet/common/constants';

import Notification from '../shared/Notification';
import RadioButton from '../inputs/RadioButton';
import RadioButtonGroup from '../inputs/RadioButtonGroup';
import TextInput from '../inputs/TextInput';

function maskEmail(email){
    if(email){
        let splitEmail = email.trim().split("@")
        let username=splitEmail[0].substr(0,1)+ splitEmail[0].substr(1,splitEmail[0].length-2).replace(/./g,'*') + splitEmail[0].substr(-1)
        let maskedEmail = username+"@"+splitEmail[1]
        return maskedEmail
    }
    else{
        return email
    }
}

export class VerifyForm extends Component {
    constructor(props) {
        super(props)
        this.state = {
            showToast:false
        }
        this.showToast = this.showToast.bind(this);
    }

    componentDidMount() {
        this.props.resetVerifyReducerState()
    }
    showToast(){
        this.setState({
            showToast:true,
        })
    }

    render() {
        return (
            <div>
                <Notification 
                    show={this.state.showToast} 
                    hide={()=>{this.setState({showToast:false})}} 
                    msg="Code succesfully resent to provided email">
                </Notification>         
                <Formik
                    initialValues={{
                        verifyRadioGroup: this.props.verifyLogin ? 'verify-email' : '',
                    }}
                    validationSchema={Yup.object({
                        verifyRadioGroup: Yup.string().required('Required')
                    })}
                    onSubmit={(values, {setSubmitting, resetForm }) => {
                        this.props.requestVerificationCode({
                            otpPurpose : MFA
                        })
                        setTimeout(()=>{
                            setSubmitting(false) 
                        },1000)
                        resetForm();
                    }}
                >
                    {({ dirty, isValid, isSubmitting, submitForm, errors, values, touched, setFieldValue }) => (
                        <Form>
                            {
                                this.props.verifyLogin ?
                                    <div>
                                        <p>An email was sent to <strong>{this.props.authData.username}</strong> with a verification code. </p>
                                    </div>
                                    :
                                    <div>
                                        {
                                             <div>
                                                  <p className ="my-5">Choose a verification method :</p>
                                                    <RadioButtonGroup
                                                        id="verifyRadioGroup"
                                                        value={values.verifyRadioGroup}
                                                        error={errors.verifyRadioGroup}
                                                        touched={touched.verifyRadioGroup}
                                                    >
                                                        <Field
                                                                disabled={this.props.phone===null}
                                                                className="radio-option"
                                                                component={RadioButton}
                                                                name="verifyRadioGroup"
                                                                id="verify-phone"
                                                            /> &nbsp; &nbsp;&nbsp;
                                                            <label>
                                                            {`Phone number: ${this.props.phone===null ? 'Not set' : this.props.phone}`}
                                                            </label>
                                                            <br/>
                                                            <br/>
                                                            <Field
                                                                className="radio-option"
                                                                component={RadioButton}
                                                                name="verifyRadioGroup"
                                                                id="verify-email"
                                                            /> &nbsp; &nbsp;&nbsp;
                                                            <label>
                                                                {`Email address: ${this.props.username}`}
                                                            </label>
                                                    </RadioButtonGroup>
                                             </div>
                                        }
                                       
                                    </div>
                            }
                            <div>
                                {
                                    (!this.props.verifyState.codeSentSuccess && !this.props.verifyLogin) ?
                                    <div className="button-wrapper d-flex justify-content-end">                                    
                                    <div>
                                        <button
                                            className="btn btn-primary btn-lg button"                                        
                                            disabled={isSubmitting || !values.verifyRadioGroup && !this.props.verifyLogin}
                                            type="submit"
                                        > Send code</button>
                                    </div>
                                    </div>
                                    :
                                    <></>
                                }                                                          
                            </div>
                        </Form>
                    )}
                </Formik>
                {
                        <Formik
                            initialValues={{
                                verificationCode: '',
                            }}
                            validationSchema={Yup.object({
                                verificationCode: Yup.string()
                                    .required('Required')
                                    .matches(codeRegEx,'code format is invalid')
                            })}
                            onSubmit={(values, {setSubmitting}) => {
                                this.props.verify({
                                    code: values.verificationCode.trim(),
                                    callback: this.props.onVerified
                                });
                                setTimeout(()=>{
                                    setSubmitting(false) 
                                },1000)
                            }}
                        >
                            {({ dirty, isValid, isSubmitting, errors, values, touched, setFieldValue, resetForm }) => (
                                <Form>
                                    <div className="my-5">
                                        <p>
                                        Didn’t receive a code?<br/>
                                        Check your spam folder or <span className="link" 
                                        onClick={()=>{
                                            this.props.requestVerificationCode({
                                                otpPurpose : MFA.trim(),
                                                resendCode: true,
                                                callback:this.showToast
                                            })
                                            resetForm()
                                            }}>Resend Code</span>
                                        </p>
                                    </div>
                                    <Container fluid>
                                        <TextInput
                                            onChange={(event) => {
                                                setFieldValue('verificationCode', event.target.value)
                                            }
                                            }
                                            label="Verification Code"
                                            name="verificationCode"
                                            type="text"/>
                                    <div className="button-wrapper d-flex justify-content-end">
                                        <button
                                            className="btn btn-primary btn-lg button"
                                            disabled={isSubmitting ||!values.verificationCode || !isValid || this.props.verifyState.isLoading}
                                            type="submit"
                                        >
                                            {this.props.verifyState.isLoading && <span className="spinner-border spinner-border-sm mr-1" role="status" aria-hidden="true" />}
                                            Verify
                                        </button>
                                    </div>
                                    </Container>
                                </Form>
                            )}
                        </Formik>
                }
            </div>
        )
    }
}

const mapStateToProps = state => ({
    authData: state.user,
    verifyState: state.verify,
    cards: state.cards,
    devices: state.devices,
    devicePortDiscovery: state.devicePortDiscovery
})

const mapDispatchToProps = dispatch => ({
    requestVerificationCode: (data) => dispatch(requestVerificationCode(data)),
    resetVerifyReducerState: () => dispatch(resetVerifyReducerState),
    verify: (code, callback) => dispatch(verify(code, callback)),
    login: (userInfo) => dispatch(login(userInfo)),
    selectIdvMethod:(data) => dispatch(selectIdvMethod(data)),
})

export default connect(mapStateToProps, mapDispatchToProps)(VerifyForm) 