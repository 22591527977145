/*
 * INTEL CONFIDENTIAL
 * Copyright 2022 Intel Corporation.
 * This software and the related documents are Intel copyrighted materials, and
 * your use of them is governed by the express license under which they were
 * provided to you (License). Unless the License provides otherwise, you may not
 * use, modify, copy, publish, distribute, disclose or transmit this software or
 * the related documents without Intel's prior written permission. This software
 * and the related documents are provided as is, with no express or implied
 * warranties, other than those that are expressly stated in the License.
 */
import React, { Component } from 'react'
import { connect } from 'react-redux'
import moment from 'moment'
import * as constants from '@pcwallet/common/constants'
import * as messages from '@pcwallet/common/endUserMessages'
import LoadingErrorModule from '../shared/LoadingErrorModule';
import { startAddCard, startDeleteCard, deleteCard, startSetAsDefaultCard, startUpdateCardNickName, setAsDefaultCard, showCardDetails, showTransactions, getCardListFromServer, verifyCard, clearCardState} from '../../actions/cards';
import { analyticsPageView } from '../../utils'
import { clearContactState, startAddContact } from '../../actions/contacts';
import { validateNickName } from '@pcwallet/common/validationEngine';
import { Row, Col, Modal, Table, Alert } from 'react-bootstrap'
import { queryStatusFromDevice, clearDeviceState } from '../../actions/devices'
import Image from 'react-bootstrap/Image'
import MasterCardArt from '@pcwallet/common/assets/MasterCardArt.png'
import VisaCardArt from '@pcwallet/common/assets/VisaCardArt.png'
import DiscoverCardArt from '@pcwallet/common/assets/DiscoverCardArt.png'
import AmexCardArt from '@pcwallet/common/assets/AmexCardArt.png'
import DefaultCardArt from '@pcwallet/common/assets/grey-card.png'
import demoStoreLogo from '@pcwallet/common/assets/PCWallet-demo-store.png'
import {RiDeleteBinLine} from 'react-icons/ri'
import {TiEdit} from 'react-icons/ti'
import {AiFillCheckCircle} from 'react-icons/ai'
import NumberFormat from 'react-number-format';
import Loading from '../shared/Loading'
import cardsPromo from '@pcwallet/common/assets/cards-promo.png'
import fpo from '@pcwallet/common/assets/FPO.png'
import Notification from '../shared/Notification';
import { Link } from 'react-router-dom'
import SelectContact from '../enrollment/SelectContact';
import Button from '../inputs/Button';
import { BiErrorCircle } from 'react-icons/bi'
import {  isStepCompleted } from '../enrollment/WalletSetupProgressUtil';

export class CardsContainer extends Component {

    constructor(props) {
        super(props)
        this.state = {
            showModal:false,
            showToast:false,
            disableButton:false,
            nickNameEditable:false,
            updateNickNameErrorMsg:''
          }
          this.handleClose = this.handleClose.bind(this)
          this.hideNotification = this.hideNotification.bind(this)
          this.getCardArtSrc = this.getCardArtSrc.bind(this)
          this.handleValidateNickName = this.handleValidateNickName.bind(this)
          this.handleKeyDown = this.handleKeyDown.bind(this)
          this.udpateTokenInfo = this.udpateTokenInfo.bind(this)
          this.handleClickEditButton = this.handleClickEditButton.bind(this)
          this.showCardStatus = this.showCardStatus.bind(this)
    }
      
    handleClose(){
        this.setState({
        showModal:false,
        disableButton:false,
        showToast:true,
        })
        const { history } = this.props;
        this.props.getCardListFromServer({
            history,
            deviceId:this.props.devices.connected.deviceId,
            walletSetupProgress:this.props.devices.connected.walletSetupProgress,
            tokenAdded:this.props.cards.tokenId,
            lastActiveContact:this.props.contacts.activeContact,
            publicId:this.props.devices.connected.publicId,
            devicePort: this.props.devicePortDiscovery.devicePort,
            cardState:this.props.cards.cardState
        })    
    }

    hideNotification () {
        this.setState({showToast:false})
        this.props.clearDeviceState()

        if(this.props.contacts.contactState === constants.LINK_BILLING_TO_CARD_SUCCESS) {
            this.props.clearContactState();
        }
        if(this.props.cards.activeCard && 
            this.props.cards.cardState !== constants.PROVISION_CARD_TIMED_OUT  &&
            this.props.contacts.contactState !== constants.LINK_BILLING_TO_CARD_SUCCESS) {
            this.props.clearCardState();
            if( this.props.cards.activeCard.status === "ACTIVE") {
            this.props.showTransactions({
                card:this.props.cards.activeCard})
            }
            else {
                this.props.showCardDetails({
                    card:this.props.cards.activeCard
                })
            }
        }
    }

    getCardArtSrc(card){
        if(card.prototypeMode || (!card.productConfig || !card.productConfig.cardArt || !card.productConfig.cardArt.data)){
            switch(card.cardNetwork){
                case "VISA":
                    return VisaCardArt
                case "DISCOVER":
                    return DiscoverCardArt
                case "MASTERCARD":
                    return MasterCardArt
                case "AMEX" :
                    return AmexCardArt
                default:
                    return DefaultCardArt
            }
        }
        else{
            return "data:image/png;base64,"+ card.productConfig.cardArt.data   
        }
    }

    handleClickEditButton() {
        this.setState({nickNameEditable: true})
    }

    updateName(nickName){
        this.props.startUpdateCardNickName(
            {
              "card": this.props.cards.activeCard,
              "updateInfo": {
                  "nickname": nickName
              },
              "cards": this.udpateTokenInfo(nickName)       
            }
          )
        
    }
    handleValidateNickName(event){
        if(event.target.value != null){
            let testResult = validateNickName(event.target.value)
            if (testResult === 2 ) {
                this.setState({
                    updateNickNameErrorMsg:messages.UPDATE_NICK_NAME_MAX_LENGTH_ERROR
                })
            }
            else if (testResult === 3 ) {
                this.setState({
                    updateNickNameErrorMsg:messages.UPDATE_NICK_NAME_INVALID_CHAR_ERROR
                })
            }
            else{
                this.setState({
                    updateNickNameErrorMsg:""
                })
            }
        }
    }

    handleKeyDown(e) {
        const nickName = e.target.value;
        if(e.keyCode === undefined || e.keyCode === 13){
            const statusCode = validateNickName(nickName);
            if(statusCode === 0){
                this.setState({nickNameEditable: false,updateNickNameErrorMsg:''});
                this.updateName(this.props.cards.activeCard.id !== this.props.cards.defaultCardId? "Card" : "Default Card");
            }
            else if(statusCode === 1){
                this.setState({nickNameEditable: false,updateNickNameErrorMsg:''});
                this.updateName(nickName);
            }
        }
    }

    udpateTokenInfo(nickName){
        this.props.cards.cards.find(card => card.id === this.props.cards.activeCard.id).nickname = nickName
        return this.props.cards.cards;
        
    }
    showCardStatus(card){
        switch(card.status){
            case "ACTIVE" :
                return "";
            case "INACTIVE":
                if(card.pendingVerification){
                    return messages.CARD_STATUS_VERIFY 
                }
                else{
                    return messages.CARD_STATUS_PENDING
                }
            case "DIGITIZE_PENDING":
                return messages.CARD_STATUS_PENDING
            case "DIGITIZE_FAILED":
                return messages.CARD_STATUS_FAILED
            default :
                return ""
        }
    }

    componentDidMount() {
        const { history } = this.props;
        if(this.props.devices.connected){
            if( this.props.cards.cards.length === 0 || 
                this.props.cards.cardState === constants.CARD_STATE_ADDED_SUCCESSFULLY || 
                this.props.cards.cardState === constants.CARD_STATE_VERIFY_LATER ||
                this.props.cards.cardState === constants.CARD_STATE_DIGITIZE_PENDING || 
                this.props.devices.deviceState === constants.WALLET_SETUP_COMPLETE) {
                this.props.getCardListFromServer({
                    history,
                    deviceId:this.props.devices.connected.deviceId,
                    state:this.props.devices.connected.state,
                    walletSetupProgress:this.props.devices.connected.walletSetupProgress,
                    tokenAdded:this.props.cards.tokenId,
                    lastActiveContact:this.props.contacts.activeContact,
                    publicId:this.props.devices.connected.publicId,
                    devicePort: this.props.devicePortDiscovery.devicePort,
                    cardState:this.props.cards.cardState
                })
            }
        }
        // else{
        //     this.props.queryStatus({
        //         history,
        //         detectDevice:true,
        //         tokenAdded:this.props.cards.tokenId,
        //         devicePort: this.props.devicePortDiscovery.devicePort,
        //         cardState:this.props.cards.cardState
        //     })
        // }
        if(this.props.cards.cardState === constants.CARD_STATE_ADDED_SUCCESSFULLY || 
            this.props.devices.deviceState === constants.WALLET_SETUP_COMPLETE || 
            this.props.devices.deviceState === constants.UNLOCK_WALLET_SUCCESS ||
            this.props.contacts.contactState === constants.LINK_BILLING_TO_CARD_SUCCESS){
            this.setState({
                showToast:true
            })
        }
        //initializing analytics
        analyticsPageView('/cards')
    }

    componentDidUpdate(prevProps){
        if(this.props.contacts.contactState !== prevProps.contacts.contactState &&
            this.props.contacts.contactState === constants.LINK_BILLING_TO_CARD_SUCCESS
            ){
            this.setState({
                showToast:true
            })
        }
    }

    
    render() {
        const { history } = this.props;
        return (
            <div>
                {
                this.props.devices.isLoading || this.props.cards.isLoading || this.props.contacts.isLoading ?
                Loading()
                :
                <Row className="dashboard-wrapper">
                    {
                        this.state.showToast && this.props.cards.cardState === constants.CARD_STATE_ADDED_SUCCESSFULLY?
                            <Notification 
                                show={this.state.showToast}
                                msg={"New card ending with "+ this.props.cards.activeCard.panLastFewDigits + " was added successfully!"}
                                hide={this.hideNotification}
                                /> 
                        :this.state.showToast && this.props.devices.deviceState === constants.WALLET_SETUP_COMPLETE?
                            <Notification 
                                show={this.state.showToast}
                                hide={this.hideNotification}
                                msg="New wallet setup successfully!"/> 
                        :this.state.showToast && this.props.cards.success && this.props.cards.cardState === constants.DELETE_CARD_SUCCESS?
                           <Notification 
                                show={this.state.showToast}
                                hide={this.hideNotification}
                                msg={"Card ending with "+ this.props.cards.cardDeleted + " was successfully deleted."}/>                            
                        :this.state.showToast && this.props.cards.success && this.props.cards.cardState === constants.SET_DEFAULT_CARD_SUCCESS?
                           <Notification 
                            show={this.state.showToast}
                            hide={this.hideNotification}
                            msg={"Card ending with "+ this.props.cards.activeCard.panLastFewDigits + " was successfully set as default."}/>                            
                        :
                        this.state.showToast && this.props.devices.success && this.props.devices.deviceState === constants.UNLOCK_WALLET_SUCCESS?
                        <Notification 
                                show={this.state.showToast}
                                hide={this.hideNotification}
                                msg={"wallet was succesfully unlocked."}/>
                        :
                        this.state.showToast && this.props.contacts.success && this.props.contacts.contactState === constants.LINK_BILLING_TO_CARD_SUCCESS?
                        <Notification 
                                show={this.state.showToast}
                                hide={this.hideNotification}
                                msg={"Billing address successfully updated."}/>
                        :
                        <></>
                        }
                   <Col sm={3} className="dashboard-list-wrapper">
                    {
                        this.props.cards.cards
                        .filter((card)=>card.id === this.props.cards.defaultCardId && card.status === "ACTIVE").length>0?
                        <Row className="p-2 flex-column align-items-center">
                            <p className="mb-4 card-section-label"><strong>Default Card</strong></p>
                            <div>
                                {
                                    this.props.cards.cards
                                    .filter((card)=>card.id === this.props.cards.defaultCardId && card.status === "ACTIVE")
                                    .map((card,index)=>{
                                        return (
                                            <div 
                                            key={index}
                                            className={
                                                this.props.cards.activeCard && this.props.cards.activeCard.id === card.id &&  this.props.cards.activeCard.status === "ACTIVE" ?"active-default-card":"inactive-active-default-card"}
                                            onClick={()=>{
                                                this.props.showCardDetails({
                                                    card:card
                                                })
                                            }}>
                                                <div className="cards-container">
                                                    {
                                                        <Image 
                                                        className="default-card-art"
                                                        src ={this.getCardArtSrc(card)} 
                                                        alt="cardArt"/>
                                                    }
                                                    <p className="py-3 px-0 default-card-art-text ">**** {card.panLastFewDigits ? card.panLastFewDigits : '-'}</p>
                                                </div>
                                            </div>
                                            )})
                                }
                            </div> 
                            {/* :
                            <div className={"inactive-active-default-card"}>
                                <p className="m-5 p-5">
                                    No Default Card
                                </p>
                            </div> */}
                        </Row>
                        :
                        <></>
                        }
                        {
                            this.props.cards.cards.filter((card)=>card.id !== this.props.cards.defaultCardId && !card.pendingVerification && card.status === "ACTIVE").length>0?
                            <Row className="flex-column align-items-center">
                            <p className="my-4 card-section-label"><strong>Other Cards</strong></p>
                             <div 
                                className={
                                    this.props.cards.activeCard && this.props.cards.activeCard.id !== this.props.cards.defaultCardId && this.props.cards.activeCard.status === "ACTIVE" ?"active-list-card ":"inactive-list-card"}
                                >
                                {
                                    this.props.cards.cards.filter((card)=>card.id !== this.props.cards.defaultCardId && !card.pendingVerification && card.status === "ACTIVE").length<2?
                                    this.props.cards.cards
                                    .filter((card)=>card.id !== this.props.cards.defaultCardId && !card.pendingVerification && card.status === "ACTIVE")
                                    .map((card,index)=>{
                                        return (
                                            <div 
                                            key={index}
                                            onClick={()=>{
                                                this.props.showCardDetails({
                                                    card:card})
                                            }}>
                                                <div className="cards-container">
                                                {
                                                <Image 
                                                className="default-card-art"
                                                src ={this.getCardArtSrc(card)} 
                                                alt="cardArt"/>
                                                }
                                                    <p className="pb-2 px-2 card-art-text" ><span className=''>**** {card.panLastFewDigits}</span></p>
                                                </div>
                                            </div>
                                            )})
                                        :
                                        this.props.cards.cards
                                        .filter((card)=>card.id !== this.props.cards.defaultCardId && !card.pendingVerification && card.status === "ACTIVE")
                                        .sort((a, b) => {
                                            if (this.props.cards.activeCard) {
                                                if (a.id === this.props.cards.activeCard.id) {
                                                    return 1;
                                                }
                                                if (b.id === this.props.cards.activeCard.id) {
                                                    return -1;
                                                }
                                            }
                                            return a.id - b.id;
                                        })
                                        .map((card,index) =>
                                            <div key={index} >
                                                <div
                                                    className={
                                                    index===0?this.props.cards.activeCard && this.props.cards.activeCard.id !== this.props.cards.defaultCardId && !card.pendingVerification && card.status === "ACTIVE" ?"active-list-top-padding":'inactive-list-padding'
                                                    :
                                                    index === this.props.cards.cards.filter((card)=>card.id !== this.props.cards.defaultCardId && !card.pendingVerification && card.status === "ACTIVE").length-1 && this.props.cards.activeCard && this.props.cards.activeCard.id !== this.props.cards.defaultCardId && this.props.cards.activeCard.status === "ACTIVE"?'active-list-bottom-padding'
                                                    :''}
                                                    onClick={()=>{
                                                        this.props.showCardDetails({
                                                            card:card
                                                        })
                                                    }}>
                                                    {
                                                        index === this.props.cards.cards.filter((card)=>card.id !== this.props.cards.defaultCardId && !card.pendingVerification).length-1 && this.props.cards.activeCard && this.props.cards.activeCard.id !== this.props.cards.defaultCardId && !this.props.cards.activeCard.pendingVerification && this.props.cards.activeCard.status === "ACTIVE"?
                                                        <div className="cards-container pb-2">
                                                            {
                                                               <Image 
                                                               className="card-stack top-card"
                                                               src ={this.getCardArtSrc(card)}
                                                               alt="cardArt"/>

                                                            }
                                                            <p className="pb-5 px-2 card-art-text top-card-text" ><span className=''>**** {card.panLastFewDigits}</span></p>
                                                        </div>
                                                        :
                                                        <div className="cards-container pb-2">
                                                            {
                                                                <Image 
                                                                className="card-stack"
                                                                src ={this.getCardArtSrc(card)}
                                                                alt="cardArt"/>
                                                            }
                                                            <p className="pb-3 px-3 card-art-text" ><span className=''>**** {card.panLastFewDigits}</span></p>

                                                        </div>
                                                    }
                                                </div>
                                            </div>                                            
                                        )}
                                </div>
                            </Row>
                            :
                            <></>
                        }
                        {
                            this.props.cards.cards.filter((card)=> (card.pendingVerification || card.status !== "ACTIVE")).length>0?
                            <Row className="flex-column align-items-center">
                            <p className="my-4 card-section-label"><strong>Pending /Unverified Cards</strong></p>
                             <div 
                                className={
                                    this.props.cards.activeCard && (this.props.cards.activeCard.pendingVerification || this.props.cards.activeCard.status !== "ACTIVE")?"active-list-card":"inactive-list-card"}
                                >
                                {
                                    this.props.cards.cards.filter((card)=>(card.pendingVerification || card.status !== "ACTIVE")).length<2?
                                        this.props.cards.cards
                                        .filter((card)=> (card.pendingVerification || card.status !== "ACTIVE"))
                                        .map((card,index)=>{
                                            return (
                                                <div 
                                                key={index}
                                                onClick={()=>{
                                                    this.props.showCardDetails({
                                                        card:card})
                                                }}>
                                                    <div className="cards-container">
                                                    {
                                                        <Image 
                                                        className="default-card-art"
                                                        src ={this.getCardArtSrc(card)}
                                                        alt="cardArt"/>
                                                    }
                                                        <p className="pb-2 px-2 card-art-text" ><span className="card-status-label">{this.showCardStatus(card)}</span><span>**** {card.panLastFewDigits}</span></p>                                                    </div>
                                                </div>
                                                )})
                                    :
                                        this.props.cards.cards
                                        .filter((card)=>(card.pendingVerification || card.status !== "ACTIVE"))
                                        .sort((a, b) => {
                                            if (this.props.cards.activeCard) {
                                                if (a.id === this.props.cards.activeCard.id) {
                                                    return 1;
                                                }
                                                if (b.id === this.props.cards.activeCard.id) {
                                                    return -1;
                                                }
                                            }
                                            return a.id - b.id;
                                        })
                                        .map((card,index) =>
                                            <div key={index} >
                                                <div
                                                    className={
                                                    index===0?this.props.cards.activeCard  && (this.props.cards.activeCard.pendingVerification || this.props.cards.activeCard.status !== "ACTIVE") ?"active-list-top-padding"
                                                    :'inactive-list-padding':
                                                    index === this.props.cards.cards.filter((card)=>card.pendingVerification || card.status !== "ACTIVE").length-1 && this.props.cards.activeCard && (this.props.cards.activeCard.pendingVerification || this.props.cards.activeCard.status !== "ACTIVE")?'active-list-bottom-padding'
                                                    :''}
                                                    onClick={()=>{
                                                        this.props.showCardDetails({
                                                            card:card
                                                        })
                                                    }}>
                                                    {
                                                        index === this.props.cards.cards.filter((card)=> card.pendingVerification || card.status !== "ACTIVE").length-1 && this.props.cards.activeCard && (this.props.cards.activeCard.pendingVerification || this.props.cards.activeCard.status !== "ACTIVE")?
                                                        <div className="cards-container pb-2">
                                                            {
                                                                <Image 
                                                                className="card-stack top-card"
                                                                src ={this.getCardArtSrc(card)}
                                                                alt="cardArt"/>
                                                            }
                                                            <p className="pb-5 px-2 card-art-text top-card-text" ><span className="card-status-label">{this.showCardStatus(card)}</span><span>**** {card.panLastFewDigits}</span></p>                                                        </div>
                                                        :
                                                        <div className="cards-container pb-2">
                                                            {
                                                                <Image 
                                                                className="card-stack"
                                                                src ={this.getCardArtSrc(card)} 
                                                                alt="cardArt"/> 
                                                            }
                                                            <p className="pb-3 px-3 card-art-text" ><span className="card-status-label">{this.showCardStatus(card)}</span><span>**** {card.panLastFewDigits}</span></p>
                                                        </div>
                                                    }
                                                </div>
                                            </div>                                            
                                        )}
                                </div>
                            </Row>
                            :
                            <></>
                        }
                        <Row className="justify-content-center align-items-center">
                                    <div className="mt-4"><button className="btn-outline-primary btn-lg button-outline" onClick={
                                        () => {
                                            this.props.startAddCard({
                                                history
                                            })
                                    }}>Add Card</button></div>
                        </Row>
                        <Row className="justify-content-center align-items-center">
                            <div className="mt-4" >
                                <img className = "cards-promo-watermark" src= {fpo}/>
                                <img className='cards-promo' src={cardsPromo} alt="cc-promo"/>
                            </div>
                        </Row>
                    </Col>
                     {/* Show Card details  */}
                    <Col sm={9} className="dashboard-card-wrapper">
                        <div className="card-wrapper">
                            {
                            this.props.cards.activeCard?
                            <div className="card">
                                <div className="active-card-header">
                                    <div className="d-flex justify-content-between align-items-center text-capitalize">
                                             <h1 className='d-flex align-items-center m-0'>
                                                {
                                                this.state.nickNameEditable ?
                                                <div>
                                                    <input className="input-text text-break-word" style={{"marginRight":"1rem","width":"fix-content"}}
                                                        placeholder={
                                                            this.props.cards.activeCard.nickname ? this.props.cards.activeCard.nickname : 
                                                            this.props.cards.activeCard.id !== this.props.cards.defaultCardId? "Card" : "Default Card"  
                                                        } 
                                                        defaultValue={
                                                            this.props.cards.activeCard.nickname ? this.props.cards.activeCard.nickname : 
                                                            this.props.cards.activeCard.id !== this.props.cards.defaultCardId? "Card" : "Default Card"                                          
                                                        }
                                                        onKeyUp={this.handleValidateNickName}
                                                        onChange={this.handleValidateNickName}
                                                        onClick={this.handleValidateNickName}
                                                        onBlur={this.handleKeyDown}
                                                        onKeyDown={this.handleKeyDown} type='text' autoFocus/>
                                                    <div className="input-text-error">{ this.state.updateNickNameErrorMsg ? this.state.updateNickNameErrorMsg : '' }</div>
                                                </div>
                                                :
                                                <div className="text-break-word transparent-border" style={{"marginRight":"1rem"}}>
                                                    {
                                                    this.props.cards.activeCard.nickname ? this.props.cards.activeCard.nickname : 
                                                    this.props.cards.activeCard.id !== this.props.cards.defaultCardId? "Card" : "Default Card"  
                                                    }
                                                </div>    
                                                }
                                                {
                                                    !this.state.nickNameEditable ?
                                                    <TiEdit className="edit-icon" onClick={ this.handleClickEditButton } size="1.1em"/>
                                                    :
                                                    <></>
                                                }
                                            </h1>                                            
                                        {
                                            //don't show default and delete options for pending digitize status
                                             (this.props.cards.activeCard.status === "ACTIVE" || this.props.cards.activeCard.status === "INACTIVE")?
                                                <div className="d-flex justify-content-between align-items-center">
                                                <div className="px-5">
                                                    {
                                                        !this.props.cards.defaultCardId|| this.props.cards.defaultCardId !== this.props.cards.activeCard.id?
                                                        <label className="d-flex align-items-center font-weight-bold m-0" htmlFor="makeDefault">
                                                            <input
                                                                name="makeDefault" 
                                                                type='checkbox'
                                                                checked={false}
                                                                disabled={this.props.cards.activeCard.prototypeMode?true:false}
                                                                onClick={() => {
                                                                    this.setState({showModal: true})
                                                                    this.props.startSetAsDefaultCard({
                                                                        card:this.props.cards.activeCard,
                                                                        history
                                                                    })
                                                                }}
                                                                />&nbsp;
                                                                Default Card</label>
                                                        :
                                                        <label className="green-label align-items-center m-0" htmlFor="Default" >Default Card</label>
                                                    }
                                                </div>
                                                {
                                                //  !this.props.cards.defaultCardId || this.props.cards.defaultCardId !== this.props.cards.activeCard.id?
                                                <div
                                                    className="delete-icon"
                                                    onClick={()=>{
                                                        this.setState({showModal: true})
                                                        this.props.startDeleteCard({
                                                            card: this.props.cards.activeCard,
                                                            history
                                                        })}}>
                                                            <RiDeleteBinLine size="1.5em"/>
                                                </div>
                                                // :
                                                // <div>
                                                //     <RiDeleteBinLine color="grey" size="1.5em"/>
                                                // </div>
                                                }
                                                </div>
                                            :
                                            <></>
                                        }
                                    </div>
                                    <div className = "card-link-bar">
                                        <div 
                                            className ={this.props.cards.cardState === constants.SHOW_TRANSACTIONS?"mt-3 active":"mt-3"}
                                            onClick={()=>{
                                                this.props.showTransactions({
                                                    card:this.props.cards.activeCard
                                                })
                                            }}
                                            >Transactions</div>
                                        <div 
                                            className ={this.props.cards.cardState === constants.SHOW_CARD_DETAILS?"mt-3 ml-5 active" :"mt-3 ml-5"}
                                            onClick={()=>{
                                                this.props.showCardDetails({
                                                    card:this.props.cards.activeCard                                                })
                                            }}
                                            >Card Details</div>
                                    </div>
                                </div>
                                <p className="divider"></p>
                                <div className ="active-card-content">
                                    {
                                        this.props.cards.cardState === constants.SHOW_CARD_DETAILS?
                                        <div>
                                            <div className="active-card-details">
                                                <div>
                                                    <div className="mb-3"><strong>Card Information</strong></div>
                                                    <p>{`**** **** **** - ${this.props.cards.activeCard.panLastFewDigits ? this.props.cards.activeCard.panLastFewDigits  : '-' }`}</p>
                                                    <p>{ this.props.cards.activeCard.productConfig && this.props.cards.activeCard.productConfig.issuerName ? this.props.cards.activeCard.productConfig.issuerName :this.props.cards.activeCard.cardNetwork}</p>
                                                    <p>{this.props.cards.activeCard.exp}</p>
                                                    <br/>
                                                    {
                                                        this.props.cards.activeCard.pendingVerification?
                                                        <Alert className='w-100 font-weight-bold' dismissible={false}> In order to set up cards to make purchases, cards need to be verified by your credit card company first : 
                                                            <Link to="/add-card">
                                                                <span 
                                                                onClick={()=>{this.props.verifyCard({
                                                                    verificationMethods:this.props.cards.activeCard.verificationMethods,
                                                                    tokenId:this.props.cards.activeCard.id,
                                                                    skipVerify:false})}}><b> Verify Card Now </b></span>
                                                            </Link> 
                                                        </Alert>
                                                        :
                                                        (this.props.cards.activeCard.status === "DIGITIZE_PENDING" || this.props.cards.activeCard.status === "INACTIVE")?
                                                        <Alert className='w-100' dismissible={false} >
                                                            <div className='font-weight-bold'> We are working on adding this card to your wallet. Card will be available for purchase shortly.</div>                        
                                                        </Alert>
                                                        :this.props.cards.activeCard.status === "DIGITIZE_FAILED"?
                                                        <p>
                                                            <b style={{"color":"#F44343"}}>
                                                                Failed to add card to wallet &nbsp;&nbsp;
                                                                <Link
                                                                    to="/add-card"
                                                                    onClick={()=>{
                                                                        // deleteToken transparent to user
                                                                        this.props.deleteCard({
                                                                            cardId:this.props.cards.activeCard.id,
                                                                            cardData:this.props.cards.activeCard.panLastFewDigits,
                                                                            devicePort: this.props.devicePortDiscovery.devicePort,
                                                                        })
                                                                    }}
                                                                    >
                                                                    Try Again
                                                                </Link>
                                                            </b> 
                                                        </p>
                                                        :
                                                        <></>
                                                    }
                                                </div>
                                            </div>
                                                {

                                                    this.props.cards.activeCard.status === "ACTIVE" || this.props.cards.activeCard.status === "INACTIVE" ?                                                 
                                                    <div
                                                    key={this.props.cards.activeCard.id} 
                                                    className="active-card-details divider-top">
                                                        <div>
                                                            <div className="mb-3"><strong>Billing Address</strong></div>
                                                            <SelectContact
                                                                tokenId={this.props.cards.activeCard.id}
                                                                activeBillingContact={this.props.contacts.contacts.find(contact =>{return contact.id === this.props.cards.activeCard.billingContactId})}
                                                                billingEnrolled={isStepCompleted(constants.BILLING_ADDRESS_ENROLL, this.props.devices.connected.walletSetupStatus)}
                                                                isShippingEnrolled={isStepCompleted(constants.SHIPPING_ADDRESS_ENROLL, this.props.devices.connected.walletSetupStatus)}
                                                                onAddNewBilling={()=>{
                                                                    this.props.startAddContact({
                                                                        addContact:true,
                                                                        billingEnrolled:isStepCompleted(constants.BILLING_ADDRESS_ENROLL, this.props.devices.connected.walletSetupStatus),
                                                                        contact:this.props.contacts.contacts.find(contact =>{return contact.id === this.props.cards.activeCard.billingContactId}),
                                                                        isShippingEnrolled:isStepCompleted(constants.SHIPPING_ADDRESS_ENROLL, this.props.devices.connected.walletSetupStatus),
                                                                        selectNewBilling:true,
                                                                        tokenId:this.props.cards.activeCard.id,
                                                                        updateTokenContact:true,
                                                                        callback:()=>{
                                                                            history.push('/add-billing')
                                                                        }            
                                                                    })
                                                                }}
                                                                />
                                                        </div>
                                                    </div>
                                                    :<></>          
                                                    
                                                }
                                                {
                                                    this.props.cards.activeCard.status === "ACTIVE"?                                                 
                                                    <div>
                                                        <div className="active-card-details divider-top"/>
                                                        <div className="mb-3"><strong>Bank Information</strong></div>
                                                        { this.props.cards.activeCard.productConfig ?                                                       
                                                        <div>
                                                                <p>{ this.props.cards.activeCard.productConfig.shortDescription ? this.props.cards.activeCard.productConfig.shortDescription : ''}</p>
                                                                <p>{ this.props.cards.activeCard.productConfig.longDescription ? this.props.cards.activeCard.productConfig.longDescription : ''}</p>
                                                                <p>{ this.props.cards.activeCard.productConfig.customerServiceEmail ? this.props.cards.activeCard.productConfig.customerServiceEmail : ''}</p>
                                                                <p><NumberFormat format="(###) ###-####" displayType={'text'} value ={this.props.cards.activeCard.productConfig.customerServicePhoneNumber ? this.props.cards.activeCard.productConfig.customerServicePhoneNumber :''}/></p>
                                                                <br/>
                                                                {
                                                                    this.props.cards.activeCard.productConfig.termsAndConditionsUrl ?
                                                                    <p className ="link" onClick={()=>{window.open( this.props.cards.activeCard.productConfig.customerServiceUrl)}}>{ this.props.cards.activeCard.productConfig.customerServiceUrl}</p>
                                                                    :
                                                                    <></>

                                                                }
                                                                {
                                                                    this.props.cards.activeCard.productConfig.termsAndConditionsUrl?
                                                                    <p className ="link" onClick={()=>{ window.open( )}}>Terms And Conditions</p>
                                                                    :
                                                                    <></>

                                                                }
                                                                {
                                                                    this.props.cards.activeCard.productConfig.privacyPolicyUrl?
                                                                    <p className ="link" onClick={()=>{window.open( this.props.cards.activeCard.productConfig.privacyPolicyUrl)}}> Privacy Policy</p>
                                                                    :
                                                                    <></>
                                                                }
                                                            </div>
                                                            :
                                                            <></>
                                                            }                                                     
                                                        </div>
                                                    :
                                                    <></>
                                                }
                                                
                                            </div>
                                        :
                                        this.props.cards.transactions.length>0?
                                        <div>
                                            <Table bordered hover className='mt-4'>
                                                <thead>
                                                    <tr className="text-capitalize font-weight-bold">
                                                        <th>
                                                            store name
                                                        </th>
                                                        <th >
                                                            store details
                                                        </th>
                                                        <th>
                                                            transaction details
                                                        </th>
                                                        <th>
                                                            amount
                                                        </th>
                                                    </tr>
                                            </thead>
                                            <tbody>
                                            {
                                                this.props.cards.transactions.map((tx, index )=> {
                                                    return(
                                                        <tr key={index}>
                                                            <th>
                                                                <img className="demo-store-logo mr-2"src={demoStoreLogo} alt="storeLogo"/>
                                                                {tx.merchantDisplayName}
                                                            </th>
                                                            <th className='align-items-center link'>
                                                                {tx.merchantDomainName}
                                                            </th>
                                                            <th>
                                                                {moment(tx.createdAt).format('MMMM Do, YYYY, h:mm:ss a')}
                                                            </th>
                                                            <th>
                                                                ${(tx.confirmedTotal/100).toFixed(2)}
                                                            </th>
                                                        </tr>
                                                    )
                                                })
                                            }
                                            </tbody>
                                            </Table>
                                        </div>
                                        :
                                        <div className="m-5 p-5 text-center">
                                            No Recent Transactions
                                        </div>
                                    }
                                </div>
                                {/* Card Management Features */}
                                <Modal 
                                    show={this.state.showModal} 
                                    onHide={this.handleClose}
                                    backdrop="static"
                                    keyboard={false}
                                    centered
                                >
                                {                    
                                this.props.cards.cardState === constants.CARD_STATE_DELETE ? 
                                <div>
                                    <Modal.Header className="justify-content-center"><Modal.Title >Delete Card</Modal.Title></Modal.Header>
                                    <Modal.Body>
                                            <div className="p-4  text-left">
                                                <div><p><b> Please confirm you want to delete card ending in : {this.props.cards.activeCard.panLastFewDigits} </b></p></div>
                                                <div className="button-wrapper">
                                                    <div className="d-flex justify-content-between">
                                                        <button
                                                            className="btn btn-outline-primary button-outline"
                                                            type="button"
                                                            onClick={this.handleClose}>Cancel
                                                        </button>
                                                        <button
                                                            className="btn btn-primary button"
                                                            type="button"
                                                            disabled={this.state.disableButton}
                                                            onClick={() => {
                                                                this.setState({disableButton:true})
                                                                this.props.deleteCard({
                                                                    cardId:this.props.cards.activeCard.id,
                                                                    cardData:this.props.cards.activeCard.panLastFewDigits,
                                                                    devicePort: this.props.devicePortDiscovery.devicePort,
                                                                    callback:this.handleClose
                                                                })
                                                                }}>
                                                                {this.props.cards.isLoadingModal && <span className="spinner-border spinner-border-sm mr-1" role="status" aria-hidden="true" />}
                                                                Delete Card
                                                        </button>
                                                    </div>
                                                </div>
                                                <LoadingErrorModule
                                                    error={this.props.cards.error}
                                                    errorMessage={this.props.cards.errorMessage}
                                                />
                                            </div>
                                    </Modal.Body>
                                </div>
                                :this.props.cards.cardState === constants.CARD_STATE_SET_DEFAULT_CARD ?
                                <div>
                                    <Modal.Header className="justify-content-center"><Modal.Title >Set Card As Default</Modal.Title></Modal.Header>
                                    <Modal.Body>
                                            <div className="pt-4 px-4 text-left">
                                                <div ><p><b>{`Set the card ending with ${this.props.cards.activeCard.panLastFewDigits} as default card?`}</b></p></div>
                                                <div className="py-4">
                                                    <div className="d-flex justify-content-between pt-4 align-items-stretch">
                                                        <button
                                                            className="btn btn-outline-primary button-outline"
                                                            type="button"
                                                            onClick={this.handleClose}>Cancel
                                                        </button>
                                                        <button
                                                            className="btn btn-primary button"
                                                            type="button"
                                                            disabled={this.state.disableButton}
                                                            onClick={() => {
                                                                this.setState({disableButton:true})
                                                                this.props.setAsDefaultCard({
                                                                    card: this.props.cards.activeCard,
                                                                    defaultPaymentMethod:true,
                                                                    devicePort: this.props.devicePortDiscovery.devicePort,
                                                                    callback:this.handleClose
                                                                })}}>
                                                            OK
                                                        </button>
                                                    </div>
                                                    <LoadingErrorModule 
                                                        loading={this.props.cards.isLoadingModal}
                                                        error={this.props.cards.error}
                                                        errorMessage={this.props.cards.errorMessage}
                                                    />
                                                </div>
                                            </div>
                                    </Modal.Body> 
                                </div>
                                :
                                <></>                                        
                                }                                                                   
                                </Modal>
                            </div>
                            : 
                            !this.props.cards.activeCard && this.props.cards.cardState === constants.PROVISION_CARD_TIMED_OUT?
                            <div className="card w-75">
                                <h1 className="mt-4 pt-1 px-5">
                                    <BiErrorCircle size="1.25em"/>
                                    &nbsp;Failed to Add Card</h1>
                                <p className="divider my-4"/>
                                <div  className="px-5 pb-5">
                                    <br/>
                                    <p><b style={{"color":"#F44343"}}>{this.props.cards.errorMessage}</b></p>
                                    <br/> 
                                    <p><b style={{"color":"#F44343"}}>{messages.ADD_CARD_TIMEOUT_MSG2}</b></p>
                                    <br/>
                                </div>
                            </div>
                            :!this.props.cards.activeCard && (this.props.devices.connected && 
                                this.props.devices.connected.walletSetupProgress === constants.DONE && !isStepCompleted(constants.CARD_DIGITIZE, this.props.devices.connected.walletSetupStatus))?
                                <Alert className='mx-5 w-50'  dismissible={false} >
                                    <div className='px-2 font-weight-bold'><Link className ="link" to="/add-card"> Add a card</Link> to start using your wallet.</div>                        
                                </Alert>                 
                            :
                            ''
                            }
                        </div>
                    </Col>                         
                </Row>
            }
            </div>
        )}
}

const mapStateToProps = state => ({
    authData: state.user.data,
    devices: state.devices,
    cards: state.cards,
    userIdleFlag: state.user.userIdle,
    terms:state.cards.resources,
    idvMethodList:state.cards.verifyMethods,
    contacts: state.contacts,
    devicePortDiscovery: state.devicePortDiscovery
})

const mapDispatchToProps = (dispatch) => ({
    startAddCard: (data) => dispatch(startAddCard(data)),
    startSetAsDefaultCard: (data) => dispatch(startSetAsDefaultCard(data)),
    startUpdateCardNickName: (data) => dispatch(startUpdateCardNickName(data)),
    setAsDefaultCard: (data) => dispatch(setAsDefaultCard(data)),      
    startDeleteCard: (data) => dispatch(startDeleteCard(data)),
    deleteCard: (data) => dispatch(deleteCard(data)),
    queryStatus:(data)=>dispatch(queryStatusFromDevice(data)),
    showCardDetails : (data)=>dispatch(showCardDetails(data)),
    showTransactions : (data)=>dispatch(showTransactions(data)),
    getCardListFromServer: (data) => dispatch(getCardListFromServer(data)),
    clearDeviceState: () => dispatch(clearDeviceState()),
    verifyCard: (data) => dispatch(verifyCard(data)),
    startAddContact: (data) => dispatch(startAddContact(data)),
    clearCardState: () => dispatch(clearCardState()),
    clearContactState: () => dispatch(clearContactState()),


})

export default connect(mapStateToProps, mapDispatchToProps)(CardsContainer)
