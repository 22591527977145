/*
 * INTEL CONFIDENTIAL
 * Copyright 2022 Intel Corporation.
 * This software and the related documents are Intel copyrighted materials, and
 * your use of them is governed by the express license under which they were
 * provided to you (License). Unless the License provides otherwise, you may not
 * use, modify, copy, publish, distribute, disclose or transmit this software or
 * the related documents without Intel's prior written permission. This software
 * and the related documents are provided as is, with no express or implied
 * warranties, other than those that are expressly stated in the License.
 */
import React, { Component } from 'react'
import { Link } from 'react-router-dom'
import { connect } from 'react-redux'
import { Formik, Form } from 'formik';
import * as Yup from 'yup';

import { forgotPassword, resetErrorMessages } from '../actions/user'
import { Redirect } from 'react-router';
import {emailRegEx, emailLengthValidator}  from '../validationEngine'
import { analyticsPageView } from '../utils'

import Button from './inputs/Button';
import ErrorMessage from './shared/ErrorMessage';
import TextInput from './inputs/TextInput';

export class ForgotPasswordContainer extends Component {
  constructor(props){
    super(props);
    this.handleBack = this.handleBack.bind(this)
  }
  handleBack() {
    this.props.history.push('/login')
  }
  componentDidMount(){
    //initializing analytics
    analyticsPageView('/forgot-password'); 
  }

  componentWillUnmount(){
    this.props.resetErrorMessages()
  } 
  render() {
      if(!this.props.user.error && this.props.user.forgotPassword){
        return (<Redirect to ='reset-password'/>)
      }
      return (
        <div className="wrapper">
          <div className="content">
            {
              this.props.forgotPasswordError && this.props.forgotPasswordErrorMessage?
              <ErrorMessage
                show = {this.props.forgotPasswordError}
                msg = {this.props.forgotPasswordErrorMessage}
                onClose = {()=>{
                    this.props.resetErrorMessages()
                }}
              />
              :<></>
            }
            <div className="main-wrapper">
            <h1 className="px-5" >Forgot Password</h1>
            <p className="divider my-4"/>
            <div className="px-5 pb-5">
            <p className="pb-5">Please enter your email address to reset your password.</p>
              <Formik
              initialValues={{
                  email:''
              }}
              validationSchema={Yup.object({
                email: Yup.string()
                .email('invalid email address')
                .test('valid email length','maximum character length exceeded',function(value){
                    return emailLengthValidator(value)
                  })
                .matches(emailRegEx,'email contains invalid character')
                .required('Required')
                ,
              })}
              onSubmit={(values) => {
                  this.props.forgotPassword({
                    username:values.email
                  })
              }}
              >
                {({values, dirty, isValid, isSubmitting}) => ( 
                  <Form>
                    <TextInput
                      label="Email Address"
                      name="email"
                      type="email"
                    />
                  <div className="button-wrapper d-flex justify-content-between">
                     <Link to='/login'>
                        <button
                            className="btn btn-outline-primary btn-lg button-outline"
                            type="button"
                          >
                            Cancel
                        </button>
                      </Link>
                      <Button
                        disabled={ isSubmitting || !dirty || !isValid || this.props.isLoading}
                        type="submit"
                      >
                         {this.props.isLoading && <span className="spinner-border spinner-border-sm mr-1" role="status" aria-hidden="true" />}
                        Send Email
                      </Button>
                    </div>
                  </Form>
                )}
              </Formik>
            </div>
          </div>
        </div>
      </div>
      ); 
    }
  }

const mapStateToProps = state => ({
  user : state.user,
  forgotPasswordErrorMessage: state.user.errorMessage,
  forgotPasswordError: state.user.error,
  isLoading: state.user.isForgotPwdLoading
})

const mapDispatchToProps = (dispatch) => ({
  forgotPassword: (data) => dispatch(forgotPassword(data)),
  resetErrorMessages: () => dispatch(resetErrorMessages())
})

export default connect(mapStateToProps , mapDispatchToProps)(ForgotPasswordContainer)
