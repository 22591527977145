/*
 * INTEL CONFIDENTIAL
 * Copyright 2022 Intel Corporation.
 * This software and the related documents are Intel copyrighted materials, and
 * your use of them is governed by the express license under which they were
 * provided to you (License). Unless the License provides otherwise, you may not
 * use, modify, copy, publish, distribute, disclose or transmit this software or
 * the related documents without Intel's prior written permission. This software
 * and the related documents are provided as is, with no express or implied
 * warranties, other than those that are expressly stated in the License.
 */
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { Modal } from 'react-bootstrap';

import { showWalletSettings } from '../../actions/security';
import { queryStatusFromDevice, deleteDevice, clearDeleteDeviceState, lockWallet, cancelLockWallet, unlockWallet, cancelUnlockWallet, clearDeviceState, cancelSetup } from '../../actions/devices'
import { clearSecurityState} from '../../actions/security';
import * as constants from '../../constants';

import  Accordion  from 'react-bootstrap/Accordion';
import LoadingErrorModule from '../shared/LoadingErrorModule';
import RemoteManageWallet from '../shared/RemoteManageWallet';

export class ManageWallets extends Component {

  constructor(props) {
    super(props);
    this.state = {
      showModal:false,
      disableButton:false,
      disableLockButton:false,
      disableDeleteButton:false,
    }
    this.handleClose = this.handleClose.bind(this)
}

handleClose(){
    this.setState({
        showModal:false,
        disableButton:false,
    })
    const { history } = this.props;
    if(this.props.devices.deviceState === constants.UNLOCK_WALLET_SUCCESS){
        this.props.queryStatus({
            detectDevice:true,
            devicePort: this.props.devicePortDiscovery.devicePort,
            history
        })
        history.push('/cards')
    }
    if(this.props.devices.deviceState === constants.DEVICE_STATE_LOCK_PENDING ){
        this.setState({
            disableLockButton:true,
        })
    }
    if(this.props.devices.deviceState === constants.DELETE_DEVICE_PENDING ){
        this.setState({
            disableLockButton:true,
            disableDeleteButton:true,
        })
    }
    else if(this.props.devices.deviceState === constants.DELETE_DEVICE_SUCCESS){
        history.push('/dashboard')
    }
}

  componentDidMount(){
    //initializing analytics
  }

  componentDidUpdate(prevProps){
    if(this.props.devices.showModal!== prevProps.devices.showModal && this.props.devices.showModal){
        this.setState({
            showModal:true
        })           
    }
    else if(this.props.devices.deviceState !== prevProps.devices.deviceState && this.props.devices.success){
        this.setState({
            showToast:true
        })
    }
}

  render() {
    const { history } = this.props;
    return (
      <div>
        <Accordion defaultActiveKey={this.props.devices.connected?this.props.devices.connected.deviceId:"0"} flush>
        {
             (this.props.devices.connected && this.props.devices.setupCanceled && 
                //not an owner case
                ((this.props.devices.connected.enrolledFlag && !this.props.devices.connected.owner) ||
                //multi-device case
            (this.props.devices.saved.length != 0 && !this.props.devices.connected.enrolledFlag && !this.props.devices.connected.owner))) ?
            <Accordion.Item>
                <Accordion.Header><b>New Wallet</b></Accordion.Header>
                <Accordion.Body><Link className ="link" to='/register-device' onClick={()=>{this.props.cancelSetup(false)}}>Setup New Wallet</Link></Accordion.Body>
            </Accordion.Item>
            :
            ''
        }
        {
            this.props.devices.saved
            .sort((a, b) => {
                if (this.props.devices.connected && this.props.devices.connected.deviceId) {
                    if (a.deviceId === this.props.devices.connected.deviceId) {
                        return -1;
                    }
                    if (b.deviceId === this.props.devices.connected.deviceId) {
                        return 1;
                    }
                }
                return a.deviceId - b.deviceId;
            })
            .map((device,index) =>
            <Accordion.Item key={index} eventKey={device.deviceId}>
                <Accordion.Header>
                <b className="text-capitalize">{device.displayName}</b>&nbsp;&nbsp;&nbsp;
                {
                device.connected && (device.state === "LOCKED"|| device.pendingState === "LOCKED" || (parseInt(device.pendingCapability) & 2) === 0 )?
                    <span className='locked-status'>LOCKED</span> 
                :
                <></>
            
                }
                </Accordion.Header>
                <Accordion.Body>
                {
                    device.connected === true && device.pendingState !== "LOCKED" && (parseInt(device.pendingCapability) & 2) > 0 ?
                    <div className="px-5 py-2">
                    To manage connected wallet, go to <Link className='link font-weight-bold' to='/security' onClick={()=>{
                        this.props.showWalletSettings()
                    }}>security settings</Link>.
                    </div>
                    :
                    <RemoteManageWallet device={device}/>
                }
                </Accordion.Body>
            </Accordion.Item>
            )
        }
        </Accordion>
        <Modal 
          show={this.state.showModal} 
          onHide={this.handleClose}
          backdrop="static"
          keyboard={false}
          centered
          >
          {
              this.props.devices.deviceState === constants.DEVICE_STATE_LOCK ? 
                  <div>
                      <Modal.Header className="justify-content-center"><Modal.Title >Lock Wallet</Modal.Title></Modal.Header>
                      <Modal.Body>
                            <div className="p-4 text-left">
                    <div><p><b> Are you sure you want to lock this wallet?<br /> This will temporarily disable all cards, contacts, and fingerprints in this wallet. </b></p></div>
                                  <div className="button-wrapper">
                                      <div className="d-flex justify-content-between">
                                          <button
                                              className="btn btn-outline-primary button-outline"
                                              type="button"
                                              disabled={this.props.devices.isLoadingModal}
                                              onClick={()=>{
                                                  this.props.cancelLockWallet({
                                                      history
                                                  })
                                                  this.handleClose()
                                              }}>Cancel
                                          </button>
                                          <button
                                              className="btn btn-primary button"
                                              type="button"
                                              disabled={this.state.disableButton && this.props.devices.isLoadingModal && !this.props.devices.error}
                                              onClick={() => {
                                                  this.setState({
                                                      disableButton:true})
                                                  this.props.lockWallet({
                                                      device: this.props.devices.activeDevice,
                                                      devicePort: this.props.devicePortDiscovery.devicePort,
                                                      callback:this.handleClose
                                                  })}}>
                                                    {this.props.devices.isLoadingModal && <span className="spinner-border spinner-border-sm mr-1" role="status" aria-hidden="true" />}
                                                    Lock Wallet
                                          </button>
                                      </div>
                                  </div>
                                  <LoadingErrorModule
                                      error={this.props.devices.error}
                                      errorMessage={this.props.devices.errorMessage}/>
                              </div>
                          </Modal.Body>
                  </div>
              :
              this.props.devices.deviceState === constants.DEVICE_STATE_UNLOCK ? 
                  <div>
                      <Modal.Header className="justify-content-center"><Modal.Title >Unlock Wallet</Modal.Title></Modal.Header>
                      <Modal.Body>
                              <div className="p-4 text-left">
                      <div><p><b> Are you sure you want to unlock this wallet?<br /> This will enable all cards, contacts, and fingerprints in this wallet. </b></p></div>
                                  <div className="button-wrapper">
                                      <div className="d-flex justify-content-between">
                                          <button
                                              className="btn btn-outline-primary button-outline"
                                              type="button"
                                              disabled={this.props.devices.isLoadingModal}
                                              onClick={()=>{
                                                  this.props.cancelUnlockWallet({
                                                      history
                                                  })
                                                  this.handleClose()
                                              }}>Cancel
                                          </button>
                                          <button
                                              className="btn btn-primary button"
                                              type="button"
                                              disabled={this.state.disableButton && this.props.devices.isLoadingModal && !this.props.devices.error}
                                              onClick={() => {
                                                  this.setState({
                                                      disableButton:true})
                                                      this.props.unlockWallet({
                                                          device: this.props.devices.activeDevice,
                                                          updateCapablity : ((parseInt(this.props.devices.activeDevice.pendingCapability) & 2) === 0)?true:false,
                                                          devicePort: this.props.devicePortDiscovery.devicePort,
                                                          callback:this.handleClose
                                                      })
                                              }}>
                                                {this.props.devices.isLoadingModal && <span className="spinner-border spinner-border-sm mr-1" role="status" aria-hidden="true" />}
                                                Unlock Wallet
                                          </button>
                                      </div>
                                  </div>
                                  <LoadingErrorModule
                                    error={this.props.devices.error}
                                    errorMessage={this.props.devices.errorMessage}/>
                              </div>
                          </Modal.Body>
                  </div>
              :  
              this.props.devices.deviceState === constants.DEVICE_STATE_DELETE ? 
                  <div>
                      <Modal.Header className="justify-content-center"><Modal.Title >Delete Wallet</Modal.Title></Modal.Header>
                      <Modal.Body>
                              <div className="p-4 text-left">
                        <div><p><b> Are you sure you want to delete this wallet?<br /> This will delete all information including cards, addresses, and fingerprints. </b></p></div>
                                  <div className="button-wrapper">
                                      <div className="d-flex justify-content-between">
                                          <button
                                              className="btn btn-outline-primary button-outline"
                                              type="button"
                                              disabled={this.props.devices.isLoadingModal}
                                              onClick={()=>{
                                                  this.props.clearDeleteDeviceState({
                                                      history
                                                  })
                                                  this.handleClose()
                                              }}>Cancel
                                          </button>
                                          <button
                                              className="btn btn-primary button"
                                              type="button"
                                              disabled={this.state.disableButton && this.props.devices.isLoadingModal && !this.props.devices.error}
                                              onClick={() => {
                                                  this.setState({
                                                      disableButton:true})
                                                  this.props.deleteDevice({
                                                      device: this.props.devices.activeDevice,
                                                      devicePort: this.props.devicePortDiscovery.devicePort,
                                                      callback:this.handleClose
                                                  })}}>
                                                    {this.props.devices.isLoadingModal && <span className="spinner-border spinner-border-sm mr-1" role="status" aria-hidden="true" />}
                                                    Delete Wallet
                                          </button>
                                      </div>
                                  </div>
                                  <LoadingErrorModule
                                      error={this.props.devices.error}
                                      errorMessage={this.props.devices.errorMessage}/>
                              </div>
                          </Modal.Body>
                  </div>
              :  
                  <></> 
          }                                                                   
        </Modal>
      </div>
    ); 
  }
}

const mapStateToProps = state => ({
  user:state.user,
  devices:state.devices,
  cards:state.cards,
  devicePortDiscovery: state.devicePortDiscovery
})

const mapDispatchToProps = (dispatch) => ({
  showWalletSettings: (data) => dispatch(showWalletSettings(data)),
  deleteDevice: (data) => dispatch(deleteDevice(data)),
  clearDeleteDeviceState: (data) => dispatch(clearDeleteDeviceState(data)),
  queryStatus:(data)=>dispatch(queryStatusFromDevice(data)),
  clearSecurityState:(data)=>dispatch(clearSecurityState(data)),
  lockWallet: (data) => dispatch(lockWallet(data)),
  cancelLockWallet: (data) => dispatch(cancelLockWallet(data)),
  unlockWallet: (data) => dispatch(unlockWallet(data)),
  cancelUnlockWallet: (data) => dispatch(cancelUnlockWallet(data)),
  clearDeviceState: () => dispatch(clearDeviceState()),
  cancelSetup: (data) => dispatch(cancelSetup(data)),

})

export default connect(mapStateToProps, mapDispatchToProps )(ManageWallets)
