/*
 * INTEL CONFIDENTIAL
 * Copyright 2022 Intel Corporation.
 * This software and the related documents are Intel copyrighted materials, and
 * your use of them is governed by the express license under which they were
 * provided to you (License). Unless the License provides otherwise, you may not
 * use, modify, copy, publish, distribute, disclose or transmit this software or
 * the related documents without Intel's prior written permission. This software
 * and the related documents are provided as is, with no express or implied
 * warranties, other than those that are expressly stated in the License.
 */
import * as constants from "@pcwallet/common/constants"
import * as messages from '@pcwallet/common/endUserMessages'

import { getContacts } from './contacts'
import { getCsrfCookieValue, getDeviceTokenFromCookie, customNumberParser } from "@pcwallet/common/utils";
import { stringify, parse , LosslessNumber } from 'lossless-json'
import { reportWalletSetupProgress } from "./devices";

// called when entering add card flow 
export const startAddCard = data => dispatch => {
    dispatch({
        type: constants.START_CARD_ADD,
        payload: data
    })
    data.history.push('/add-card')
}

// called when entering delete card flow 
export const startDeleteCard = data => dispatch => {
    dispatch({
        type: constants.START_CARD_DELETE,
        payload: data
    })
    data.history.push('/cards')
}

export const deleteCard = data => (dispatch) => {
    dispatch({
        type: constants.DELETE_CARD
    })
    let requestBody = stringify({
        tokenId: new LosslessNumber(data.cardId).valueOf(), 
    })
    const cookieValue = getCsrfCookieValue()
    const requestURL = `${constants.INUS_ADDRESS}/us/ws/deleteToken`
    fetch(requestURL, {
        method: 'POST',
        credentials : 'include',
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            'X-XSRF-TOKEN' : `${cookieValue ? cookieValue : ''}`
        },
        body: requestBody
    })
    .then((response) => {
        if (response.status === 401) {
            dispatch({
                type: constants.ACCESS_TOKEN_INVALID,
            })
            throw response.status
        }
        else {
            return response.text()
        }
    })
    .then((response) => {
        return parse(response, null, customNumberParser)
    })
    .then((response) => {
        if (response.errorCode !== "OK") {
            dispatch({
                type: constants.DELETE_CARD_FAILED,
                payload:response.message
            })
        } else {
            data.cardDelete=true
            getNextJob(data,dispatch)            
        }
    })
    .catch((error) => {
        if(error !==  401) {
            dispatch({
                type: constants.DELETE_CARD_FAILED,
                payload: messages.CARD_DELETE_FAILED
            });
        }
    })
}

export const startUpdateCardNickName = data => dispatch => {
    dispatch({
        type:constants.CARD_NICKNAME_UPDATE,
        payload:data
    })

    let requestBody = stringify({
        tokenId: new LosslessNumber(data.card.id).valueOf(),
        nickname:data.updateInfo.nickname
    })
    const cookieValue = getCsrfCookieValue()
    const requestURL = `${constants.INUS_ADDRESS}/us/ws/updateTokenProperties`
    fetch(requestURL, {
        method: 'POST',
        credentials : 'include',
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            'X-XSRF-TOKEN' : `${cookieValue ? cookieValue : ''}`
        },
        body:requestBody
    })
    .then((response) =>{
        if (response.status === 401) {
            dispatch({
                type: constants.ACCESS_TOKEN_INVALID,
            })
            throw response.status
        }
        else {
            return response.text()
        }
    })
    .then((response) => {
        return parse(response, null, customNumberParser)
    })
    .then((response) => {
        if (response.errorCode !== "OK") {
            dispatch({
                type: constants.CARD_NICKNAME_UPDATE_FAILED,
                payload:response
            })            
        } else {
            dispatch({
                type: constants.CARD_NICKNAME_UPDATE_SUCCESS,
                payload:data
            });        
        }
    })
    .catch((error) => {
        if(error !==  401) {
            dispatch({
                type: constants.SET_DEFAULT_CARD_FAILED,
                payload: messages.CARD_SET_AS_DEFAULT_FAILED
            });
        }
    })
}


// called when entering setting default card flow 
export const startSetAsDefaultCard = data => dispatch => {
    dispatch({
        type: constants.START_SET_DEFAULT_CARD,
        payload: data
    })
    data.history.push('/cards')
}

export const setAsDefaultCard = data => (dispatch) => {
    dispatch({
        type: constants.SET_DEFAULT_CARD
    })
    let requestBody = stringify({
        tokenId: new LosslessNumber(data.card.id).valueOf(),
        defaultPaymentMethod:data.defaultPaymentMethod
    })
    const cookieValue = getCsrfCookieValue()
    const requestURL = `${constants.INUS_ADDRESS}/us/ws/updateTokenProperties`
    fetch(requestURL, {
        method: 'POST',
        credentials : 'include',
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            'X-XSRF-TOKEN' : `${cookieValue ? cookieValue : ''}`
        },
        body: requestBody
    })
    .then((response) =>{
        if (response.status === 401) {
            dispatch({
                type: constants.ACCESS_TOKEN_INVALID,
            })
            throw response.status
        }
        else {
            return response.text()
        }
    })
    .then((response) => {
        return parse(response, null, customNumberParser)
    })
    .then((response) => {
        if (response.errorCode !== "OK") {
            dispatch({
                type: constants.SET_DEFAULT_CARD_FAILED,
                payload:response
            })            
        } else {
            dispatch({
                type: constants.SET_DEFAULT_CARD_SUCCESS,
                payload:{
                    card:data.card
                }
            });
            data.callback()            
        }
    })
    .catch((error) => {
        if(error !==  401) {
            dispatch({
                type: constants.SET_DEFAULT_CARD_FAILED,
                payload: messages.CARD_SET_AS_DEFAULT_FAILED
            });
        }
    })
}

export const processDecision = (data) => (dispatch) => {

    if(data.decision === 'RETRY') {
        if(data.setup){
            if(data.tokenId){
                dispatch(reportWalletSetupProgress({
                    deviceId: data.deviceId,
                    walletSetupProgress:constants.BILLING_ADDRESS_ENROLL,
                    callback:()=>{
                        data.history.push('/add-billing')  
                    }
                }))
            }
            else{
                data.history.push('/add-shipping')
            }
        }
        else{
            //digitize pending go to dashboard
            dispatch({
                type:constants.PROVISION_CARD_PENDING,
                payload:data.tokenId
            })
            data.history.push('/cards')
        }
    } 
    else if(data.decision === 'REQUIRE_ADDITIONAL_AUTHENTICATION') {
            setTimeout(()=>{
                dispatch({
                    type:constants.PROVISION_CARD_VERIFY,
                    payload:{
                        verificationMethods:data.verificationMethods,
                        tokenId:data.tokenId,
                        skipVerify:false
                    }
                })
            },10000)
    }
    else if(data.decision === 'APPROVED' ) {
        isScriptReady({
            tokenId: data.tokenId,
            deviceId: data.deviceId,
            count: 1,
            devicePort: data.devicePort
        },dispatch)

        if(!data.setup) {
            setTimeout(()=>{
                dispatch({
                    type:constants.ADD_CARD_SUCCESS,
                    payload:{
                        skipVerify:true,
                        tokenId:data.tokenId
                    }
                })
                data.selectNewBilling = true
                data.updateTokenContact = true
                data.addContact = false
                dispatch({
                    type: constants.CONTACT_ADD_START,
                    payload:data
                })
                data.history.push('/add-billing')
            },10000)
        }
        else{
            //get updated walletSetup status before redirecting
            dispatch(reportWalletSetupProgress({
                deviceId: data.deviceId,
                walletSetupProgress:constants.BILLING_ADDRESS_ENROLL,
                callback:()=>{
                    data.selectNewBilling = false
                    data.updateTokenContact = false
                    data.addContact = true
                    dispatch({
                        type: constants.CONTACT_ADD_START,
                        payload:data
                    })
                    data.history.push('/add-billing')  
                }
            }))
        }
    }
    else if(data.decision === 'TIMED_OUT'){
        dispatch({
            type: constants.PROVISION_CARD_TIMED_OUT,
            payload:data.message? data.message : messages.ADD_CARD_TIMEOUT_MSG1
        })
        // if wallet setup flow show shipping address
        if(data.setup){
            data.history.push('/add-shipping')           
        }
    }
}

export const getNextScript = (data,dispatch)=> {
    dispatch({
        type:constants.GET_NEXT_SCRIPT
    })
    const deviceToken = getDeviceTokenFromCookie();
    const requestURL = `${constants.MTO_ADDRESS_HOSTNAME}:${String(data.devicePort)}:${String(data.devicePort)}/v1/command`
    fetch(requestURL,{
        method: 'POST',
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
        },
        body:stringify(
            {
                token: deviceToken,
                astp:data.astp,
                cmd:constants.GET_NEXT_SCRIPT
            })
        })
        .then((response)=>{
            if (response.status === 401) {
                dispatch({
                    type: constants.ACCESS_TOKEN_INVALID,
                })
                throw response.status
            }
            else {
                return response.text()
            }
        })
        .then((response) => {
            return parse(response, null, customNumberParser)
        })
        .then((response) => {
            if (response.errorCode !== "OK") {
                dispatch({
                    type: constants.GET_NEXT_SCRIPT_FAILED,
                    payload: response.message ? response.message : messages.ADD_CARD_FAILED_GENERIC_MSG
                });
            }
            else {
                dispatch({
                    type:constants.GET_NEXT_SCRIPT_SUCCESS,
                })                
            }
        })
        .catch((error=>{
            if(error !==  401) {
                console.log(error)
                dispatch({
                    type: constants.GET_NEXT_SCRIPT_FAILED,
                    payload: messages.ADD_CARD_FAILED_GENERIC_MSG
                });
            }
        }))
}

export const selectIdvMethod = data => (dispatch) => {
    const cookieValue = getCsrfCookieValue()
    const requestURL = `${constants.INUS_ADDRESS}/us/ws/requestActivationCode`
    fetch(requestURL,{
        method: 'POST',
        credentials : 'include',
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            'X-XSRF-TOKEN' : `${cookieValue ? cookieValue : ''}`
        },
        body:stringify(
            {
                tokenId: new LosslessNumber(data.tokenId).valueOf(),
                authMethodId: data.methodId
            }
        )
    })
    .then(response => {
        if (response.status === 401) {
            dispatch({
                type: constants.ACCESS_TOKEN_INVALID,
            })
            throw response.status
        }
        else {
            return response.text()
        }
    })
    .then((response) => {
        return parse(response, null, customNumberParser)
    })
    .then((response) => {
        if (response.errorCode !== "OK") {
            dispatch({
                type: constants.VERIFY_REQUEST_CODE_FAILED,
                payload:response.message
            });
        } 
        else {
            dispatch({
                type: constants.VERIFY_REQUEST_CODE_SUCCESS,
                payload:data.selectedMethod
            });
        }
    })
    .catch((error) => {
        if(error !==  401) {
            dispatch({
                type: constants.VERIFY_SUBMIT_CODE_FAILED,
                payload:messages.ADD_CARD_FAILED_GENERIC_MSG
            });
            console.log(error)
        }
    })
}


export const activateCard = data => (dispatch) => {
    dispatch({
        type: constants.ACTIVATE_CARD,
    });
    const cookieValue = getCsrfCookieValue();
    const requestURL = `${constants.INUS_ADDRESS}/us/ws/activateToken`
    fetch(requestURL,{
        method: 'POST',
        credentials : 'include',
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            'X-XSRF-TOKEN' : `${cookieValue ? cookieValue : ''}`
        },
        body:stringify({
            tokenId: new LosslessNumber(data.tokenId).valueOf(),
            authenticationCode:data.code
        })
    })
    .then(response => {
        if (response.status === 401) {
            dispatch({
                type: constants.ACCESS_TOKEN_INVALID,
            })
            throw response.status
        }
        else {
            return response.text()
        }
    })
    .then((response) => {
        return parse(response, null, customNumberParser)
    })
    .then((response) => {
        if (response.errorCode !== "OK") {
            dispatch({
                type: constants.ACTIVATE_CARD_FAILED,
                payload:response.message
            });
        } 
        else {
            dispatch({
                type: constants.ACTIVATE_CARD_SUCCESS,
            });
            isScriptReady({
                tokenId: data.tokenId,
                deviceId: data.deviceId,
                count: 1,
                devicePort: data.devicePort
            },dispatch)
            if(!data.setup){                     
                dispatch({
                    type:constants.ADD_CARD_SUCCESS,
                    payload:{
                        skipVerify:true,
                        tokenId:data.tokenId
                    }
                })
                data.selectNewBilling = true
                data.updateTokenContact = true
                data.addContact = false
                dispatch({
                    type: constants.CONTACT_ADD_START,
                    payload:data
                })
            }
            else{
                dispatch(reportWalletSetupProgress({
                    deviceId: data.deviceId,
                    walletSetupProgress:constants.BILLING_ADDRESS_ENROLL,
                    callback:()=>{
                        data.selectNewBilling = false
                        data.updateTokenContact = false
                        data.addContact = true
                        dispatch({
                            type: constants.CONTACT_ADD_START,
                            payload:data
                        })
                        data.history.push('/add-billing')  
                    }
                })) 
            }   
        }
    })
    .catch((error) => {
        if(error != 401){
            dispatch({
                type: constants.ACTIVATE_CARD_FAILED,
                payload: messages.ADD_CARD_FAILED_GENERIC_MSG
            });
            console.log(error)
        }      
    })
}

export const isScriptReady =(data, dispatch) => {
    const requestURL = `${constants.INUS_ADDRESS}/us/ws/isScriptReady`
    const cookieValue = getCsrfCookieValue();
    fetch(requestURL,{
        method: 'POST',
        credentials : 'include',
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            'X-XSRF-TOKEN' : `${cookieValue ? cookieValue : ''}`
        },
        body:stringify({
            tokenId : new LosslessNumber(data.tokenId).valueOf(),
        })
    })
    .then(response => {
        if (response.status === 401) {
            dispatch({
                type: constants.ACCESS_TOKEN_INVALID,
            })
            throw response.status
        }
        else {
            return response.text()
        }          
    })
    .then((response) => {
        return parse(response, null, customNumberParser)
    })
    .then((response) => {
        
        if (response.errorCode !== "OK" ) {
            dispatch({
                type: constants.GET_NEXT_SCRIPT_FAILED,
                payload:response.message
            });
            if(data.count<=10){
                data.count=data.count+1
                setTimeout(()=>{
                    isScriptReady(data,dispatch)
                },10000)                   
            }
        } else {
            if(response.scriptStatus === "PENDING") {
                data.isScriptReady=true
                getNextJob(data,dispatch)
            }
            else if(response.scriptStatus === "NONE") {
                if(data.count<=10){
                    data.count=data.count+1
                    setTimeout(()=>{
                        isScriptReady(data,dispatch)
                    },10000)                   
                }
            }               
            }
    })
    .catch((error) => {
        if(error !==  401) {
            dispatch({
                type: constants.GET_NEXT_SCRIPT_FAILED,
                payload: messages.ADD_CARD_FAILED_GENERIC_MSG
            });
        }
    })
}

export const checkPendingJob =(data, dispatch) => {
    const cookieValue = getCsrfCookieValue();    
    const requestURL = `${constants.INUS_ADDRESS}/us/ws/checkPendingJob`
    fetch(requestURL,{
        method: 'POST',
        credentials : 'include',
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            'X-XSRF-TOKEN' : `${cookieValue ? cookieValue : ''}`
        },
        body:stringify({
            deviceId : new LosslessNumber(data.deviceId).valueOf(),
        })
        
    })
    .then(response => {
        if (response.status === 401) {
            dispatch({
                type: constants.ACCESS_TOKEN_INVALID,
            })
            throw response.status
        }
        else {
            return response.text()
        }
    })
    .then((response) => {
        return parse(response, null, customNumberParser)
    })
    .then((response) => {        
        if (response.errorCode !== "OK" ) {
            dispatch({
                type: constants.GET_NEXT_SCRIPT_FAILED,
                payload:response.message
            });
            if(data.count<=10){
                data.count=data.count+1
                setTimeout(()=>{
                    checkPendingJob(data,dispatch)
                },10000)                   
            }
        } else {
            if(response.pendingJobFlag) {
				data.checkPendingJob=true
                data.isScriptReady=false
                getNextJob(data,dispatch)
            }
            else if(data.count<=10){
                data.count=data.count+1
                setTimeout(()=>{
                    checkPendingJob(data,dispatch)
                },10000)                   
            }               
            }
    })
    .catch((error) => {
        if(error !==  401) {
            dispatch({
                type: constants.GET_NEXT_SCRIPT_FAILED,
                payload: messages.ADD_CARD_FAILED_GENERIC_MSG
            });
        }
    })
}

export const getNextJob = (data,dispatch) => {
    const deviceToken = getDeviceTokenFromCookie();
    let requestBody = stringify({
        token: deviceToken,
        cmd: constants.GET_NEXT_JOB,
    })
    const requestURL = `${constants.MTO_ADDRESS_HOSTNAME}:${String(data.devicePort)}/v1/command`
    fetch(requestURL, {
        method: 'POST',
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
        },
        body: requestBody
    })
    .then((response) => response.json())
    .then((response) => {
        if (!response.status) {
            dispatch({
                type: constants.GET_NEXT_JOB_FAILED,
                payload:response
            })
        } else {
            dispatch({
                type: constants.GET_NEXT_JOB_SUCCESS,
            });
            if(data.cardDelete){
                dispatch({
                    type: constants.DELETE_CARD_SUCCESS,
                    payload:data.cardData
                });
                data.callback();
            }
            else if (data.isScriptReady) {
                data.count = 0;
                checkPendingJob(data,dispatch)
            }
            else if(data.checkPendingJob) {
                //get updated card list on checkPendingJob completed
                getCardListFromServer({
                    deviceId:data.deviceId
                })
            }
        }
    })
    .catch((error) => {
        console.log(error)
        dispatch({
            type: constants.GET_NEXT_JOB_FAILED,
            payload : messages.ADD_CARD_FAILED_GENERIC_MSG
        });
    })
}

export const getCardList = data => dispatch => {
    data.history.push('/cards')
}

export const showCardDetails = (data) => (dispatch) =>{
    dispatch({
        type: constants.SHOW_CARD_DETAILS,
        payload:data
    });
}

export const showTransactions = (data) => (dispatch) =>{
    dispatch({
        type: constants.SHOW_TRANSACTIONS,
        payload:data
    });
    dispatch({
        type: constants.TRANSACTIONS_LOADED,
        payload:[]
    })
    // getTransactions(data.card.id, transactions => {
    //     dispatch({
    //         type: constants.TRANSACTIONS_LOADED,
    //         payload: transactions
    //     })
    // });
}

// export const getTransactions = (cardId, callback) => {
//     const cookieValue = getCookieValue(document.cookie);
//     fetch(`${constants.INPS_ADDRESS}/v1/getTransactions`, {
//         method: 'POST',
//         credentials : 'include',
//         headers: {
//             'Accept': 'application/json',
//             'Content-Type': 'application/json',
//             'X-XSRF-TOKEN' : `${cookieValue ? cookieValue : ''}`
//         },
//         body: stringify({cardId: cardId })
//     })
//     .then(response => response.text()) 
//     .then(response => {
//         if(response.status) {
//             callback(response.transactions);
//         } else {
//             console.error(response.message);
//         }
//     })
//     .catch(error => {
//         console.error(error);
//     })
// }

export const getCardListFromServer = (data) => (dispatch) => {
    dispatch({
        type: constants.GET_PAYMENT_CARDS
    });
    const cookieValue = getCsrfCookieValue();
    fetch(`${constants.INUS_ADDRESS}/us/ws/getTokensByDevice`, {
        method: 'POST',
        credentials : 'include',
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            'X-XSRF-TOKEN' : `${cookieValue ? cookieValue : ''}`
        },
        body:stringify({
            deviceId:new LosslessNumber(data.deviceId).valueOf(),
        })
    })
    .then((response) => {
        if (response.status === 401) {
            dispatch({
                type: constants.ACCESS_TOKEN_INVALID,
            })
            throw response.status
        }
        else {
            return response.text()
        }
    })
    .then((response) => {
        return parse(response, null, customNumberParser)
    }
    )
    .then((response) => { 

        if(response.errorCode !== "OK"){
                if(response.errorCode === "TOKEN_NOT_FOUND")//if token list is empty
                {
                    dispatch({
                        type: constants.GET_PAYMENT_CARDS_SUCCESS_LIST_EMPTY,
                    });                
                    dispatch({
                        type: constants.DETECT_DEVICE_COMPLETE,
                    });
                    if(data.callback){
                        data.callback(true)                               
                    }              
                }
                else{
                    dispatch({
                        type: constants.GET_PAYMENT_CARDS_FAILED,
                        payload: response.message
                    });
                }                
            }
            else {
                data.tokens=response.tokens 
                let dcid = response.tokens.find(card =>{return (card.defaultPaymentMethod === true)})?response.tokens.find(card =>{return (card.defaultPaymentMethod === true)}).id:''
                let activeCardId=dcid;
                if(data.tokenAdded){
                    activeCardId = response.tokens.find(card =>{return card.id === data.tokenAdded})?response.tokens.find(card =>{return card.id === data.tokenAdded}).id:dcid
                }
                else if(data.lastActiveCardId){
                    activeCardId = data.lastActiveCardId
                }
                dispatch({
                    type: constants.GET_PAYMENT_CARDS_SUCCESS,
                    payload: {
                        cards:response.tokens,
                        dcdid: dcid,
                        activeCardId:activeCardId ,
                }});
                // show active card details if card status is pending for reloaded webpage
                if((data.cardState !== constants.CARD_STATE_ADDED_SUCCESSFULLY || data.detectDevice) && activeCardId){
                    let activeCard = response.tokens.find(card => {return card.id === activeCardId})? response.tokens.find(card => {return card.id === activeCardId}) : null
                    if(activeCard.status !== "ACTIVE") {
                        dispatch(showCardDetails({
                            card:activeCard}))
                    }
                }
                // if(dcid){
                //     getTransactions(dcid, transactions => {
                //         dispatch({
                //             type: constants.TRANSACTIONS_LOADED,
                //             payload: transactions
                //         })
                //     });
                // }
                if(response.hasPendingJob){
                    getNextJob(data,dispatch)
                }
                if(data.detectDevice || data.state === "REGISTERED" || data.updateTokenContact || (data.billingEnrolled && data.walletSetupProgress !== "DONE")){
                    dispatch(getContacts(data))                                 
                }
                else{
                    dispatch({
                        type: constants.DETECT_DEVICE_COMPLETE,
                    });
                    if(data.callback){
                        data.callback(false)                               
                    }
                }
            }
    })
    .catch((error) => {
        if(error !==  401) {
            console.error('Error:', error);
            dispatch({
                type: constants.GET_PAYMENT_CARDS_FAILED,
                payload: messages.CARD_GET_LIST_FAILED
            });
        }
    });
}

export const resetErrorMessages = () => (dispatch)=>{
    dispatch({
        type: constants.RESET_ERROR_STATES,
      })
}

export const verifyCardLater = () => dispatch => {
    dispatch({
        type: constants.PROVISION_CARD_VERIFY_LATER
    })
}

export const verifyCard = (data) => dispatch => {
    dispatch({
        type:constants.PROVISION_CARD_VERIFY,
        payload:{
            verificationMethods:data.verificationMethods,
            tokenId:data.tokenId,
            skipVerify:false
        }
    })
    if(data.walletSetupProgress === "DONE"){
        dispatch({
            type: constants.DETECT_DEVICE_COMPLETE,
        });
    }
    else if(data.callback){
        data.callback()
    }
}

export const selectVerificationMethod = () => dispatch => {
    dispatch({
        type: constants.VERIFY_RESET
    })
}

export const clearCardState = (data) => dispatch => {
    dispatch({
        type: constants.CARD_STATE_CLEAR,
    })
}

export const showErrorMessages = (data) => dispatch => {
    dispatch({
        type:constants.SHOW_ERROR_MESSAGES,
        payload:data
    })
}