/*
 * INTEL CONFIDENTIAL
 * Copyright 2022 Intel Corporation.
 * This software and the related documents are Intel copyrighted materials, and
 * your use of them is governed by the express license under which they were
 * provided to you (License). Unless the License provides otherwise, you may not
 * use, modify, copy, publish, distribute, disclose or transmit this software or
 * the related documents without Intel's prior written permission. This software
 * and the related documents are provided as is, with no express or implied
 * warranties, other than those that are expressly stated in the License.
 */
import React, { Component } from 'react'
import { connect } from 'react-redux'

import { Row, Col, Modal, Alert } from 'react-bootstrap'
import {RiDeleteBinLine} from 'react-icons/ri'
import {TiEdit} from 'react-icons/ti'
import { Link } from 'react-router-dom'
import NumberFormat from 'react-number-format';

import { getContacts,startDeleteContact, deleteContact, setDefaultContact, startAddContact, startUpdateContact, showContactDetails, updateDefaultContactFromServer ,updateContactFromServer, clearContactState} from '../../actions/contacts'
import { queryStatusFromDevice } from '../../actions/devices'
import * as constants from "@pcwallet/common/constants"
import {  isStepCompleted } from '../enrollment/WalletSetupProgressUtil';
import LoadingErrorModule from '../shared/LoadingErrorModule';
import { analyticsPageView } from '../../utils'

import Loading from '../shared/Loading'
import Notification from '../shared/Notification';


export class ContactsContainer extends Component {

    constructor(props) {
        super(props)
        this.state = {
            showModal:false,
            showToast:false,
            disableButton:false,
            setAsDefaultCheckbox:false
          }
          this.handleClose = this.handleClose.bind(this)
          this.hideNotification = this.hideNotification.bind(this)
    }
      
    handleClose(){
        this.setState({
        showModal:false,
        disableButton:false,
        showToast:true,
        setAsDefaultCheckbox:false
        })
        const { history } = this.props;
        this.props.getContacts({
            history,
            deviceId:this.props.devices.connected.deviceId,
            walletSetupProgress:this.props.devices.connected.walletSetupProgress,
            lastActiveContact:this.props.contacts.activeContact,
            publicId:this.props.devices.connected.publicId,
            devicePort: this.props.devicePortDiscovery.devicePort
        })
    }

    hideNotification () {
        this.setState({showToast:false})
        this.props.clearContactState();
    }

    componentDidMount() {
        const { history } = this.props;
        if(!this.props.devices.connected){
            this.props.queryStatus({
                history,
                detectDevice:true,
                lastActiveContact:this.props.contacts.activeContact,
                devicePort: this.props.devicePortDiscovery.devicePort
            })           
        }
        else{            
            if(this.props.contacts.contacts.length === 0
                || this.props.contacts.contactState === constants.CONTACT_ADD_SUCCESS
                || this.props.contacts.contactState === constants.SET_DEFAULT_CONTACT_SUCCESS
                || this.props.contacts.contactState === constants.CONTACT_UPDATE_SUCCESS) {
                this.props.getContacts({
                    history,
                    deviceId:this.props.devices.connected.deviceId,
                    walletSetupProgress:this.props.devices.connected.walletSetupProgress,
                    lastActiveContact:this.props.contacts.activeContact,
                    publicId:this.props.devices.connected.publicId,
                    devicePort: this.props.devicePortDiscovery.devicePort,
                })
            }
            if(this.props.contacts.contactState === constants.CONTACT_UPDATE_SUCCESS) {
                this.setState({
                    showToast:true
                })
            }
        }
        //initializing analytics
        analyticsPageView('/contacts')
    }

    componentWillUnmount(){
        if(this.props.contacts.contactState){
            this.props.clearContactState();
        }
    }

    render() {
        const { history } = this.props;
        return (
            <div>
                {
                    this.props.devices.isLoading || this.props.cards.isLoading || this.props.contacts.isLoading ?
                    Loading()
                    :
                    <Row className="dashboard-wrapper">
                        {
                        this.state.showToast && this.props.contacts.success && this.props.contacts.contactState === constants.CONTACT_DELETE_SUCCESS?
                           <Notification 
                                show={this.state.showToast}
                                hide={this.hideNotification}
                                msg={"Address was successfully deleted."}/>                            
                        :this.state.showToast && this.props.contacts.success && this.props.contacts.contactState === constants.SET_DEFAULT_CONTACT_SUCCESS?
                           <Notification 
                                show={this.state.showToast}
                                hide={this.hideNotification}
                                msg={"Address was successfully set as default."}/>                            
                        :this.state.showToast && this.props.contacts.success && this.props.contacts.contactState === constants.CONTACT_UPDATE_SUCCESS?
                           <Notification 
                                show={this.state.showToast}
                                hide={this.hideNotification}
                                msg={"Address was successfully updated."}/>                            
                        :
                        <></>
                        }
                    <Col sm={3} className="dashboard-list-wrapper">
                        {
                            <Row className="px-2 m-auto flex-column">
                                {
                                    this.props.contacts.contacts
                                        .filter(contact=>{return (contact.email || contact.phoneNumber)})
                                        .sort((a, b) => {
                                            if (this.props.contacts.defaultShippingContactId) {
                                                if (a.id === this.props.contacts.defaultShippingContactId) {
                                                    return -1;
                                                }
                                                if (b.id === this.props.contacts.defaultShippingContactId) {
                                                    return 1;
                                                }
                                            }
                                            return a.id - b.id;
                                        })
                                        .map((contact,index) =>
                                        <div key={index}>
                                            <div className='mb-4'>
                                                {
                                                    this.props.contacts.defaultShippingContactId && this.props.contacts.defaultShippingContactId === contact.id
                                                    ?
                                                    <p className=""><strong>Default Shipping Addresses</strong>
                                                    </p>
                                                    :
                                                    index===1?
                                                    <p className="mt-4"><strong>Other Shipping Addresses</strong></p>
                                                    :
                                                    null
                                                }
                                            </div>
                                            <div 
                                                className={this.props.contacts.activeContact?this.props.contacts.activeContact.id === contact.id?"active-default-card" :"list-card":"list-card"}
                                                onClick={()=>{
                                                    this.props.showContactDetails({
                                                        contact:contact
                                                    })
                                                }}>
                                                <p className='text-capitalize'>{contact.givenName}&nbsp;{contact.familyName}</p>
                                                <p>{contact.addressLine1}</p>
                                                <p>{contact.city},&nbsp;{contact.state},&nbsp;{contact.country}&nbsp;{contact.postalCode}</p>
                                            </div>
                                        </div>                                            
                                    )
                                }
                                <Row className="p-2 m-auto">
                                    <div className="mt-5">
                                        <button className="btn-outline-primary btn-lg button-outline" 
                                            onClick={() => {
                                                if( this.props.devices.connected.walletSetupProgress === constants.DONE &&  this.props.contacts.activeContact && !this.props.contacts.defaultShippingContactId) {
                                                    //skipped setup contact state, added card first so has billingAdress, start update contact
                                                    this.props.startUpdateContact({
                                                        contact: this.props.contacts.activeContact,
                                                        history
                                                    })
                                                }
                                                else {
                                                    this.props.startAddContact({
                                                        contact:this.props.contacts.activeContact,
                                                        addContact: true,
                                                        selectNewBilling:false,
                                                        updateTokenContact:false,
                                                        callback:()=>{
                                                            history.push('/add-shipping')
                                                        }
                                                    })
                                                }                                   
                                                
                                            }}>
                                            Add Address
                                        </button>
                                    </div>
                                </Row>
                            </Row>
                        }
                    </Col>
                    <Col sm={9} className="dashboard-card-wrapper">
                        <div className="card-wrapper">
                            {
                            this.props.contacts.activeContact && (this.props.contacts.activeContact.email || this.props.contacts.activeContact.phoneNumber)?
                            <div className="card">
                                <div className="active-card-flex-header">
                                    <h1>{this.props.contacts.activeContact.id !== this.props.contacts.defaultShippingContactId? "Shipping Address": "Default Shipping Address"}</h1>
                                    <div className="d-flex justify-content-between">
                                        <div className="px-5">
                                            {
                                                !this.props.contacts.defaultShippingContactId || this.props.contacts.defaultShippingContactId !== this.props.contacts.activeContact.id ?
                                                        <label className="d-flex align-items-center font-weight-bold" htmlFor="makeDefault">
                                                        <input
                                                            className='align-items-center'
                                                            name="makeDefault" 
                                                            type='checkbox'
                                                            checked={this.state.setAsDefaultCheckbox}
                                                            onClick={() => {
                                                                this.setState({
                                                                    setAsDefaultCheckbox:true,
                                                                    showModal: true
                                                                })
                                                                this.props.setDefaultContact()
                                                            }}
                                                            />&nbsp;
                                                            Default Address</label>
                                                    :
                                                    <label className="green-label align-items-center m-0" htmlFor="Default" >Default Address</label>
                                                }
                                            </div>
                                            {
                                                !this.props.contacts.defaultShippingContactId || this.props.contacts.defaultShippingContactId !== this.props.contacts.activeContact.id ?
                                                <div
                                                    className="delete-icon"
                                                    onClick={()=>{
                                                        this.setState({showModal: true})
                                                        this.props.startDeleteContact(
                                                            {
                                                                cards:this.props.cards.cards,
                                                                activeContact: this.props.contacts.activeContact,
                                                            }
                                                        )
                                                        }}
                                                    >
                                                    <RiDeleteBinLine size="1.5em"/>
                                                </div>
                                                :
                                                <div>
                                                     <RiDeleteBinLine color="grey" size="1.5em"/>
                                                 </div>
                                            } 
                                        </div>
                                </div>
                                <p className="divider"></p>
                                <div className ="active-card-content">
                                    <div className="active-card-details">
                                        <div>
                                            <div className="mb-3"><strong>Shipping Information</strong></div>
                                            <p className='text-capitalize'>{this.props.contacts.activeContact.givenName}&nbsp;{this.props.contacts.activeContact.familyName}</p>
                                            <p>{this.props.contacts.activeContact.addressLine1}</p>
                                            <p>{this.props.contacts.activeContact.addressLine2}</p>
                                            <p>{this.props.contacts.activeContact.city},&nbsp;{this.props.contacts.activeContact.state},&nbsp;{this.props.contacts.activeContact.country}&nbsp;{this.props.contacts.activeContact.postalCode}</p>
                                            <p>{this.props.contacts.activeContact.email}</p>
                                            <p><NumberFormat format="(###) ###-####" displayType={'text'} value ={this.props.contacts.activeContact.phoneNumber}/></p>
                                        </div>
                                        <div
                                            className="edit-icon"
                                            onClick={()=>{
                                                this.props.startUpdateContact({
                                                    contact: this.props.contacts.activeContact,
                                                    history
                                                })}}>
                                            <TiEdit size="1.5em"/>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            :
                            (this.props.devices.connected && 
                                this.props.devices.connected.walletSetupProgress === constants.DONE && !isStepCompleted(constants.SHIPPING_ADDRESS_ENROLL, this.props.devices.connected.walletSetupStatus))?
                                <Alert className='mx-5 w-50'  dismissible={false} >
                                    <div className='px-2 font-weight-bold'>
                                    <span className="link" 
                                            onClick={() => {
                                                if( this.props.devices.connected.walletSetupProgress === constants.DONE &&  this.props.contacts.activeContact && !this.props.contacts.defaultShippingContactId) {
                                                    //skipped setup contact state, added card first so has billingAdress, start update contact
                                                    this.props.startUpdateContact({
                                                        contact: this.props.contacts.activeContact,
                                                        isShippingEnrolled:isStepCompleted(constants.SHIPPING_ADDRESS_ENROLL, this.props.devices.connected.walletSetupStatus),
                                                        history
                                                    })
                                                }
                                                else {
                                                    this.props.startAddContact({
                                                        contact:this.props.contacts.activeContact,
                                                        addContact: true,
                                                        selectNewBilling:false,
                                                        updateTokenContact:false,
                                                        callback:()=>{
                                                            history.push('/add-shipping')
                                                        }
                                                    })
                                                }                                   
                                                
                                            }}>
                                            Add a Shipping Address
                                        </span>
                                        &nbsp; to your wallet for faster checkout.
                                        {/* <Link className ="link" to="/add-shipping"> Add a Shipping Address</Link> to your wallet for faster checkout. */}
                                        </div>                        
                                </Alert>                 
                            :
                            ''
                            }
                        </div>
                        <Modal 
                            show={this.state.showModal} 
                            onHide={this.handleClose}
                            backdrop="static"
                            keyboard={false}
                            centered
                            >
                                {
                                this.props.contacts.contactState === constants.CONTACT_DELETE_DENIED ? 
                                <div>
                                    <Modal.Header className="justify-content-center"><Modal.Title >Address cannot be deleted</Modal.Title></Modal.Header>
                                    <Modal.Body>
                                                <div className="p-4 text-left">
                                                <div><p><b> This address is currently associated as a billing address for an existing card. Please update billing addresses on any cards that use this address. Then you will be able to delete this address. </b></p></div>
                                                <div className="button-wrapper">
                                                    <div className="d-flex justify-content-center">
                                                        <button
                                                            className="btn btn-primary button"
                                                            type="button"
                                                            onClick={()=>{
                                                                this.setState({
                                                                    showModal:false,
                                                                    disableButton:false,
                                                                    showToast:true,
                                                                    })
                                                            }}
                                                            >OK
                                                        </button>
                                                    </div>
                                                </div>
                                                <LoadingErrorModule 
                                                    loading={this.props.contacts.isLoadingModal}
                                                    error={this.props.contacts.error}
                                                    errorMessage={this.props.contacts.errorMessage}/>
                                            </div>
                                        </Modal.Body>
                                </div>
                                :
                                this.props.contacts.contactState === constants.DELETE_CONTACT ? 
                                <div>
                                    <Modal.Header className="justify-content-center"><Modal.Title >Delete Address</Modal.Title></Modal.Header>
                                    <Modal.Body>
                                            <div className="p-4 text-left">
                                                <div><p><b> Please confirm you want to delete this address: <br/>{this.props.contacts.activeContact.addressLine1},&nbsp;{this.props.contacts.activeContact.city},&nbsp;{this.props.contacts.activeContact.state},&nbsp;{this.props.contacts.activeContact.country}&nbsp;{this.props.contacts.activeContact.postalCode}</b></p></div>
                                                <div className="button-wrapper">
                                                    <div className="d-flex justify-content-between">
                                                        <button
                                                            className="btn btn-outline-primary button-outline"
                                                            type="button"
                                                            disabled={this.props.contacts.isLoadingModal}
                                                            onClick={()=>{
                                                                this.setState({
                                                                    showModal:false,
                                                                    disableButton:false,
                                                                    showToast:true,
                                                                    })
                                                            }}>Cancel
                                                        </button>
                                                        <button
                                                            className="btn btn-primary button"
                                                            type="button"
                                                            disabled={this.state.disableButton || this.props.contacts.isLoadingModal}
                                                            onClick={() => {
                                                                this.setState({disableButton:true})
                                                                this.props.deleteContact({
                                                                    deleteContact:true,
                                                                    contactId: this.props.contacts.activeContact.id,
                                                                    publicId:this.props.devices.connected? this.props.devices.connected.publicId: '',
                                                                    deviceId:this.props.devices.connected.deviceId,
                                                                    devicePort: this.props.devicePortDiscovery.devicePort,
                                                                    callback:this.handleClose
                                                                })}}>
                                                                    {this.props.contacts.isLoadingModal && <span className="spinner-border spinner-border-sm mr-1" role="status" aria-hidden="true" />}
                                                                    Delete Address
                                                        </button>
                                                    </div>
                                                </div>
                                                <LoadingErrorModule 
                                                    error={this.props.contacts.error}
                                                    errorMessage={this.props.contacts.errorMessage}/>
                                            </div>
                                        </Modal.Body>
                                </div>
                                : this.props.contacts.contactState === constants.SET_DEFAULT_CONTACT ?
                                <div>
                                    <Modal.Header className="justify-content-center"><Modal.Title >Set Address As Default</Modal.Title></Modal.Header>
                                    <Modal.Body>
                                            <div className="pt-4 px-4 text-left">
                                                <div><p><b> Please confirm you want to set this address as default : <br/>{this.props.contacts.activeContact.addressLine1},&nbsp;{this.props.contacts.activeContact.city},&nbsp;{this.props.contacts.activeContact.state},&nbsp;{this.props.contacts.activeContact.country}&nbsp;{this.props.contacts.activeContact.postalCode}</b></p></div>
                                                <div className="py-4">
                                                    <div className="d-flex justify-content-between pt-4 align-items-stretch">
                                                        <button
                                                            className="btn btn-outline-primary button-outline"
                                                            type="button"
                                                            disabled={this.props.contacts.isLoadingModal}
                                                            onClick={()=>{
                                                                this.setState({
                                                                    showModal:false,
                                                                    disableButton:false,
                                                                    showToast:true,
                                                                    setAsDefaultCheckbox:false
                                                                    })
                                                            }}>Cancel
                                                        </button>
                                                        <button
                                                            className="btn btn-primary button"
                                                            type="button"
                                                            disabled={this.state.disableButton || this.props.contacts.isLoadingModal}
                                                            onClick={() => {
                                                                this.setState({disableButton:true})
                                                                if(this.props.contacts.defaultShippingContactId){
                                                                    this.props.updateDefaultContactFromServer({
                                                                        setDefaultContact:true,
                                                                        defaultContact:this.props.contacts.defaultShippingContactId? this.props.contacts.contacts.find(contact =>{return contact.id === this.props.contacts.defaultShippingContactId}):this.props.contacts.activeContact,
                                                                        contact: this.props.contacts.activeContact,
                                                                        markAsDefaultShippingContact:true,
                                                                        publicId:this.props.devices.connected? this.props.devices.connected.publicId: '',
                                                                        deviceId:this.props.devices.connected? this.props.devices.connected.deviceId: '',
                                                                        devicePort: this.props.devicePortDiscovery.devicePort,
                                                                        callback:this.handleClose
                                                                    })

                                                                }
                                                                else{ 
                                                                    this.props.updateContactFromServer({
                                                                    contact: this.props.contacts.activeContact,
                                                                    markAsDefaultShippingContact:true,
                                                                    publicId:this.props.devices.connected? this.props.devices.connected.publicId: '',
                                                                    deviceId:this.props.devices.connected? this.props.devices.connected.deviceId: '',
                                                                    devicePort: this.props.devicePortDiscovery.devicePort,
                                                                    callback:this.handleClose
                                                                })                                                                    
                                                                }
                                                                }}>
                                                                    {this.props.contacts.isLoadingModal && <span className="spinner-border spinner-border-sm mr-1" role="status" aria-hidden="true" />}
                                                                    OK
                                                        </button>
                                                    </div>
                                                    <LoadingErrorModule 
                                                        error={this.props.contacts.error}
                                                        errorMessage={this.props.contacts.errorMessage}/>
                                                </div>
                                            </div>
                                    </Modal.Body> 
                                </div>
                                :
                                <></>
                                }                                                                   
                        </Modal>
                    </Col>
                    </Row>
                }
            </div>
            )
        }

}

const mapStateToProps = state => ({
    devices: state.devices,
    cards:state.cards,
    contacts: state.contacts,
    devicePortDiscovery: state.devicePortDiscovery
})

const mapDispatchToProps = dispatch => ({
    queryStatus: (data) => dispatch(queryStatusFromDevice(data)),
    getContacts: (data) => dispatch(getContacts(data)),
    startAddContact: (data) => dispatch(startAddContact(data)),
    startUpdateContact: (data) => dispatch(startUpdateContact(data)),
    deleteContact: (data) => dispatch(deleteContact(data)),
    startDeleteContact: (data) => dispatch(startDeleteContact(data)),
    setDefaultContact: (data) => dispatch(setDefaultContact(data)),
    showContactDetails: (data) => dispatch(showContactDetails(data)),
    updateContactFromServer: (data) => dispatch(updateContactFromServer(data)),
    updateDefaultContactFromServer: (data) => dispatch(updateDefaultContactFromServer(data)),
    clearContactState: (data) => dispatch(clearContactState(data)),

})

export default connect(mapStateToProps, mapDispatchToProps)(ContactsContainer)
