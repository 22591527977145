/*
 * INTEL CONFIDENTIAL
 * Copyright 2022 Intel Corporation.
 * This software and the related documents are Intel copyrighted materials, and
 * your use of them is governed by the express license under which they were
 * provided to you (License). Unless the License provides otherwise, you may not
 * use, modify, copy, publish, distribute, disclose or transmit this software or
 * the related documents without Intel's prior written permission. This software
 * and the related documents are provided as is, with no express or implied
 * warranties, other than those that are expressly stated in the License.
 */
import React, { Component } from 'react'

import { Alert } from 'react-bootstrap'
import { BiError } from 'react-icons/bi'


const ErrorMessage = (props) => {
    return (
        <div className='error-message'>
            <Alert 
                className="rounded me-4"
                variant="danger"
                onClose={props.onClose} 
                show={props.show} 
                >
                <p className='d-flex align-items-center'>
                    <BiError size="2rem" color="#5a2121"/>
                    <strong className='px-3'>{props.msg}</strong>
                </p>            
                </Alert>
         </div>
    )
    
}

export default ErrorMessage
