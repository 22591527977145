/*
 * INTEL CONFIDENTIAL
 * Copyright 2022 Intel Corporation.
 * This software and the related documents are Intel copyrighted materials, and
 * your use of them is governed by the express license under which they were
 * provided to you (License). Unless the License provides otherwise, you may not
 * use, modify, copy, publish, distribute, disclose or transmit this software or
 * the related documents without Intel's prior written permission. This software
 * and the related documents are provided as is, with no express or implied
 * warranties, other than those that are expressly stated in the License.
 */
import * as constants from '../constants'

const initialState = {
    devicePort: null,
    isLoading:false,
    discovering:false
}

export default function devicePortDiscovery(state = initialState, { type, payload }) {
  switch (type) {
    case constants.PORT_DISCOVERY:
        return { 
            ...state,
            isLoading:true
        }
    case constants.DEVICE_PORT_DISCOVERY_FAILED:
        return { 
            ...state,
            isLoading:false,
            discovering: false,
        }
    case constants.DEVICE_PORT_DISCOVERY_ERROR:
        return {
            ...state,
            isLoading: false,
            discovering: true,
        }
    case constants.DEVICE_PORT_VERIFICATION_SUCCESS:
        return { 
            ...state,
            isLoading:false,
            devicePort:payload,
            discovering: false,
        }
    default:
      return state
  }
}