/*
 * INTEL CONFIDENTIAL
 * Copyright 2022 Intel Corporation.
 * This software and the related documents are Intel copyrighted materials, and
 * your use of them is governed by the express license under which they were
 * provided to you (License). Unless the License provides otherwise, you may not
 * use, modify, copy, publish, distribute, disclose or transmit this software or
 * the related documents without Intel's prior written permission. This software
 * and the related documents are provided as is, with no express or implied
 * warranties, other than those that are expressly stated in the License.
 */
import React, { Component } from 'react'
import { connect } from 'react-redux'
import { Formik, Form} from 'formik';
import * as Yup from 'yup';
import { Container} from 'react-bootstrap';

import {inputRegEx, emailLengthValidator, checkPhoneLength, emailRegEx, phoneRegEx, alphabetsOnlyRegEx } from '../../validationEngine'
import { analyticsPageView } from '../../utils';
import countries from '@pcwallet/common/assets/countries'
import * as constants from '@pcwallet/common/constants'
import { getNextComponent, getPreviousComponent ,isStepCompleted } from './WalletSetupProgressUtil';
import { getCardListFromServer } from '../../actions/cards';
import { cancelAddContact, cancelUpdateContact, addContactFromServer, updateContactFromServer, updateDefaultContactFromServer, resetContactErrorMessages, getContacts, linkBillingtoCard, startAddContact } from '../../actions/contacts'
import { getDevices, queryStatusFromDevice, reportWalletSetupProgress } from '../../actions/devices'
import usStates from '@pcwallet/common/assets/states';

import Button from '../inputs/Button';
import CheckBox from '../inputs/CheckBox';
import ErrorMessage from '../shared/ErrorMessage';
import Loading from '../shared/Loading'
import SelectContact from './SelectContact';
import SelectInput from '../inputs/SelectInput';
import SetupProgressIndicator from '../shared/SetupProgressIndicator';
import TextInput from '../inputs/TextInput';


const countryList=() => {
    
    let list = countries.map(country => {
        return {
            value: country.countryCode,
            label: country.countryName
        }
    })
        return list
}

const usStateList= () => {
    
    let list = usStates.map(usState => {
        return {
            value: usState.stateCode,
            label: usState.stateName.toLowerCase()
        }
    })
    return list
}

export class AddShippingAddress extends Component {

    constructor(props) {
        super(props);
        this.state = {
            sameAsBilling:true,
            proceedTo:{componentUrl:'/contacts'},
            backTo:{componentUrl:'/contacts'},
            billingEnrolled:false,
            cardDigitized:false,
            setup:false,
            contactId:null,
            isShippingEnrolled:false
        }    
        this.sameAsBilling = this.sameAsBilling.bind(this);
        this.handleNavigation = this.handleNavigation.bind(this)
        this.syncWalletSetupProgress = this.syncWalletSetupProgress.bind(this)
        this.findDefaultBillingAddress = this.findDefaultBillingAddress.bind(this)

      }
    
    sameAsBilling(values) {    
        this.setState({
            sameAsBilling: !this.state.sameAsBilling
        });
        if(!this.state.sameAsBilling){
            if(this.props.contacts.activeContact ){
                values.addressLine1 = this.props.contacts.activeContact.addressLine1 
                values.addressLine2 = this.props.contacts.activeContact.addressLine2 
                values.city = this.props.contacts.activeContact.city 
                values.state = this.props.contacts.activeContact.state 
                values.country = this.props.contacts.activeContact.country 
                values.zip = this.props.contacts.activeContact.postalCode 
            }
        }
        else{
            values.addressLine1=''
            values.addressLine2=''
            values.city=''
            values.state=''
            values.zip=''
        }

      }
      
    componentDidMount() {
        //initializing analytics
        analyticsPageView('/add-shipping')
        if(!this.props.devices.connected){
            const { history } = this.props;
            this.props.queryStatus({
                history,
                detectDevice:true,
                devicePort: this.props.devicePortDiscovery.devicePort,
            })
        }
        else{
            if(this.props.devices.connected && this.props.devices.connected.walletSetupProgress){
                let billingEnrolled = isStepCompleted(constants.BILLING_ADDRESS_ENROLL,this.props.devices.connected.walletSetupStatus);
                this.setState({billingEnrolled : billingEnrolled})
                let isShippingEnrolled = isStepCompleted(constants.SHIPPING_ADDRESS_ENROLL,this.props.devices.connected.walletSetupStatus)
                this.setState({isShippingEnrolled : isShippingEnrolled})
                if(this.props.devices.connected.walletSetupProgress !== constants.DONE) {
                this.setState({setup : true})
                let cardDigitized = isStepCompleted(constants.CARD_DIGITIZE,this.props.devices.connected.walletSetupStatus);
                this.setState({cardDigitized : cardDigitized})
                    if(!cardDigitized) {
                        this.syncWalletSetupProgress(constants.SHIPPING_ADDRESS_ENROLL)
                    }                    
                    else if(!billingEnrolled) {
                        if(this.props.cards && this.props.cards.activeCard) {
                            this.syncWalletSetupProgress(constants.BILLING_ADDRESS_ENROLL)
                        }
                        else{
                            // tokenId not available get card list
                            this.props.getCardListFromServer({
                                deviceId:this.props.devices.connected.deviceId,
                                walletSetupProgress:constants.BILLING_ADDRESS_ENROLL,
                                billingEnrolled:billingEnrolled,
                                callback: () => {
                                    this.syncWalletSetupProgress(constants.BILLING_ADDRESS_ENROLL)
                                }
                            })
                        }
                    }
                    else {
                        if(this.props.contacts.length >= 1) {
                            this.syncWalletSetupProgress(constants.SHIPPING_ADDRESS_ENROLL)
                        }
                        else {
                            // getContacts for prefill
                            this.props.getContacts({
                                deviceId:this.props.devices.connected.deviceId,
                                publicId:this.props.devices.connected.publicId,
                                walletSetupProgress:this.props.devices.connected.walletSetupProgress,
                                devicePort: this.props.devicePortDiscovery.devicePort,
                                callback:()=> {
                                    this.syncWalletSetupProgress(constants.SHIPPING_ADDRESS_ENROLL)
                                }
                            })
                        }
                    }
                }                             
            } 
        }
    }

    syncWalletSetupProgress(data) {
        this.props.reportWalletSetupProgress({
            deviceId:this.props.devices.connected.deviceId,
            walletSetupProgress:data,
            callback:() => {
                let walletSetupProgress = this.props.devices.connected.walletSetupProgress;
                let proceedTo = getNextComponent(this.props.devices.connected.walletSetupStatus, walletSetupProgress);
                this.setState({proceedTo : proceedTo})         
                let backTo = getPreviousComponent(this.props.devices.connected.walletSetupStatus, walletSetupProgress)             
                this.setState({backTo : backTo})
                this.setState({isLoading:false}) 
            }
        })
    }

    handleNavigation() {
        const { history } = this.props;
        console.log(this.props.devices.connected.walletSetupProgress)
        if(this.props.devices.connected.walletSetupProgress !== constants.DONE){
            this.props.reportWalletSetupProgress({
                deviceId:this.props.devices.connected.deviceId,
                walletSetupProgress:this.state.proceedTo.setupStatus,
                callback:()=>{                                                     
                    history.push(this.state.proceedTo.componentUrl)
                    if(this.props.devices.connected && this.props.devices.connected.walletSetupProgress === constants.SHIPPING_ADDRESS_ENROLL){
                        let walletSetupProgress = this.props.devices.connected.walletSetupProgress;
                        let proceedTo = getNextComponent(this.props.devices.connected.walletSetupStatus, walletSetupProgress);
                        this.setState({proceedTo : proceedTo});
                        let backTo = getPreviousComponent(this.props.devices.connected.walletSetupStatus, walletSetupProgress)
                    }
                }
            })
        }
        else {
            //get updated walletSetupStatus
            if(this.props.devices.connected.walletSetupStatus < 127 && (!this.state.billingEnrolled || !this.state.isShippingEnrolled)) {
                this.props.queryStatus({
                    devicePort: this.props.devicePortDiscovery.devicePort,                    
                })
            }
             this.props.history.push(this.state.proceedTo.componentUrl)
        }
    }

    findDefaultBillingAddress() {
        let activeBillingAddress = null
        let defaultCard = this.props.cards.cards.find((card)=>{return card.defaultPaymentMethod})
        if(defaultCard && defaultCard.billingContactId){
            this.setState({contactId: defaultCard.billingContactId})
            activeBillingAddress = this.props.contacts.contacts.find(contact =>{return contact.id === defaultCard.billingContactId})
            console.debug(activeBillingAddress)
            return activeBillingAddress
        }
        return activeBillingAddress
    }

    componentDidUpdate(prevProps){
        if(this.props.devices.connected && (this.props.devices.connected.walletSetupProgress !== prevProps.devices.connected.walletSetupProgress || this.props.devices.connected.walletSetupStatus !== prevProps.devices.connected.walletSetupStatus)){
            // if(this.props.devices.connected.walletSetupProgress !== constants.DONE) {
                let cardDigitized = isStepCompleted(constants.CARD_DIGITIZE, this.props.devices.connected.walletSetupStatus);
                this.setState({cardDigitized : cardDigitized})
                let billingEnrolled = isStepCompleted(constants.BILLING_ADDRESS_ENROLL, this.props.devices.connected.walletSetupStatus);
                this.setState({billingEnrolled : billingEnrolled})
                let isShippingEnrolled = isStepCompleted(constants.SHIPPING_ADDRESS_ENROLL,this.props.devices.connected.walletSetupStatus)
                this.setState({isShippingEnrolled : isShippingEnrolled})
            // }
        }
        if(this.props.contacts.addContact !== prevProps.contacts.addContact) {
            console.log("addContact updated")
        }
        if(this.props.contacts.error !== prevProps.contacts.error){
            console.log("errors updated")
        }

    }

    componentWillUnmount() {
        if(this.props.contacts.error){
            this.props.resetContactErrorMessages();
        }
    }

    render() {
        const { history } = this.props;
        const countries = countryList();
        return (
            <div className="wrapper">
                <div className="content">
                    {
                        this.props.devices.isLoading || this.props.cards.isLoading || this.props.contacts.isLoading ?
                        Loading()
                        :
                        <div>
                            {
                                this.state.setup && this.props.devices.connected && (this.props.devices.connected.walletSetupProgress === constants.BILLING_ADDRESS_ENROLL || this.props.devices.connected.walletSetupProgress === constants.SHIPPING_ADDRESS_ENROLL)?
                                <SetupProgressIndicator 
                                    devices={this.props.devices}
                                    isStepCompleted={isStepCompleted}
                                    />
                                :
                                <></>
                            }
                            {
                                this.props.contacts.error && this.props.contacts.errorMessage?
                                <ErrorMessage
                                    show = {this.props.contacts.error}
                                    autohide = {false}
                                    msg = {this.props.contacts.errorMessage}
                                    onClose = {()=>{
                                        this.props.resetContactErrorMessages()
                                    }}
                                />
                                :<></>
                            }
                        <div className="main-wrapper">
                            <div className='d-flex align-items-center justify-content-between mt-4 pt-2 mx-5'>
                                <h1 className="m-0">
                                {

                                    (!this.props.contacts.addContact && this.props.contacts.updateContact && this.state.isShippingEnrolled)?
                                    `Update Shipping Address`
                                    : 
                                    `Add Shipping Address`
                                }
                                </h1>
                                {
                                    this.state.setup && (this.state.billingEnrolled || !this.state.cardDigitized)?
                                    <div
                                    className="button-tertiary-outline"
                                    type="button"
                                    onClick = {()=>{
                                        this.props.reportWalletSetupProgress({
                                            deviceId:this.props.devices.connected.deviceId,
                                            walletSetupProgress:constants.DONE,
                                            callback:()=>{
                                                history.push(this.state.proceedTo.componentUrl)
                                            }
                                        })
                                        }}
                                    ><b>Skip</b>
                                    </div>
                                    :
                                    <></>
                                }
                                </div>
                                <p className="divider my-4"/>
                                <div className="px-5 pb-5">
                                    <Formik
                                        initialValues={{
                                            // sameAsBilling:true,
                                            email: this.state.setup && this.props.userData && this.props.userData.username?this.props.userData.username: this.props.contacts.activeContact && !this.props.contacts.addContact? this.props.contacts.activeContact.email :'',
                                            phoneNumber: this.state.setup && this.props.userData && this.props.userData.phoneNumber?this.props.userData.phoneNumber: this.props.contacts.activeContact && !this.props.contacts.addContact? this.props.contacts.activeContact.phoneNumber :'',
                                            givenName:this.state.setup && this.props.userData && this.props.userData.givenName? this.props.userData.givenName: this.props.contacts.activeContact && !this.props.contacts.addContact?this.props.contacts.activeContact.givenName :'',
                                            familyName:this.state.setup && this.props.userData && this.props.userData.familyName ?this.props.userData.familyName: this.props.contacts.activeContact && !this.props.contacts.addContact? this.props.contacts.activeContact.familyName :'',
                                            addressLine1:  (this.state.setup || !this.props.contacts.addContact) && this.props.contacts.activeContact? this.props.contacts.activeContact.addressLine1 : '',
                                            addressLine2: (this.state.setup || !this.props.contacts.addContact) && this.props.contacts.activeContact? this.props.contacts.activeContact.addressLine2 : '',
                                            city: (this.state.setup || !this.props.contacts.addContact) && this.props.contacts.activeContact? this.props.contacts.activeContact.city : '',
                                            state: (this.state.setup || !this.props.contacts.addContact) && this.props.contacts.activeContact? this.props.contacts.activeContact.state : '',
                                            country: (this.state.setup || !this.props.contacts.addContact) && this.props.contacts.activeContact ? this.props.contacts.activeContact.country : countries[0].value,
                                            zip: (this.state.setup || !this.props.contacts.addContact) && this.props.contacts.activeContact? this.props.contacts.activeContact.postalCode : '',
                                            markAsDefaultShippingContact: this.props.contacts.activeContact 
                                                                                && this.props.contacts.defaultShippingContactId 
                                                                                && this.props.contacts.defaultShippingContactId === this.props.contacts.activeContact.id
                                                                                && !this.props.contacts.addContact? this.props.contacts.activeContact.id : false
                                        }}
                                        validationSchema={Yup.object().shape({
                                            email: Yup.string()
                                                .email('invalid email address')
                                                .test('valid email length','maximum character length exceeded',function(value){
                                                    return emailLengthValidator(value)
                                                })
                                                .matches(emailRegEx,{message:'email contains invalid character',excludeEmptyString:true})
                                                .required(),
                                            phoneNumber: Yup.string()
                                                .matches(phoneRegEx, "phone number allows only digits")
                                                .test('valid phone length','invalid phone number',function(value){
                                                    return checkPhoneLength(value)
                                                })
                                                .required(),
                                            givenName: Yup.string()
                                                .max(20)
                                                .matches(alphabetsOnlyRegEx,'field contains invalid character')
                                                .required(),
                                            familyName: Yup.string()
                                                .max(40)
                                                .matches(alphabetsOnlyRegEx,'field contains invalid character')
                                                .required(),
                                            addressLine1: Yup.string()
                                                .max(64)
                                                .matches(inputRegEx,'field contains invalid character')
                                                .required('Required'),
                                            addressLine2: Yup.string()
                                                .max(64)
                                                .matches(/^[a-zA-Z0-9.\-_\s+#]+$/,'field contains invalid character'),
                                            city: Yup.string()
                                                .max(64)
                                                .matches(inputRegEx,'field contains invalid character')
                                                .required('Required'),
                                            state: Yup.string()
                                                .max(64)
                                                .matches(inputRegEx,'field contains invalid character')
                                                .required('Required'),
                                            country: Yup.string()
                                                .max(64)
                                                .matches(inputRegEx,'field contains invalid character')
                                                .required('Required'),
                                            zip: Yup.number()
                                                .required('Required'),
                                        })}

                                        onSubmit={(values, { setSubmitting }) => {
                                            let contact = {
                                                    id: (!this.state.setup && !this.state.sameAsBilling && this.state.billingEnrolled && !this.state.isShippingEnrolled)?null: this.props.contacts.activeContact && ((this.state.setup && this.state.sameAsBilling) || (!values.markAsDefaultShippingContact || !this.state.setup ) && !this.props.contacts.addContact)?this.props.contacts.activeContact.id:null,
                                                    givenName:  values.givenName.trim() ,
                                                    familyName: values.familyName.trim(),
                                                    email: ((this.state.setup && (!this.state.cardDigitized || this.state.billingEnrolled)) || !this.state.setup) ? values.email.trim() :'',
                                                    phoneNumber:   ((this.state.setup && (!this.state.cardDigitized || this.state.billingEnrolled)) || !this.state.setup) ? values.phoneNumber.trim().replace(/[()-/ ]/gi,''):'',
                                                    addressLine1: values.addressLine1.trim(),
                                                    addressLine2: values.addressLine2?values.addressLine2.trim():'',
                                                    city: values.city.trim(),
                                                    state: values.state.trim(),
                                                    country: values.country.trim(),
                                                    postalCode: values.zip.trim()
                                                };
                                            
                                            if(this.props.contacts.addContact || (!this.state.setup && (this.props.contacts.contacts.length === 0 || !this.state.isShippingEnrolled))){
                                                if(values.markAsDefaultShippingContact && this.props.contacts.defaultShippingContactId){
                                                        console.log("updateDefaultContactFromServer")
                                                        this.props.updateDefaultContactFromServer({
                                                            setDefaultContact:true,
                                                            defaultContact:this.props.contacts.defaultShippingContactId? this.props.contacts.contacts.find(contact =>{return contact.id === this.props.contacts.defaultShippingContactId}):this.props.contacts.activeContact,
                                                            contact:contact,
                                                            markAsDefaultShippingContact:true,
                                                            publicId:this.props.devices.connected? this.props.devices.connected.publicId: '',
                                                            deviceId:this.props.devices.connected? this.props.devices.connected.deviceId: '',
                                                            devicePort: this.props.devicePortDiscovery.devicePort,
                                                            history,
                                                            callback:()=>{this.handleNavigation()}
                                                        })
                                                    }                        
                                                else{
                                                    console.log("addContactFromServer")
                                                    this.props.addContactFromServer({
                                                        contact: contact,
                                                        markAsDefaultShippingContact: (this.state.setup && this.state.billingEnrolled) || 
                                                        (!this.state.setup && (this.state.billingEnrolled || this.props.contacts.contacts.length === 0) && !this.state.isShippingEnrolled ) ||
                                                        (this.props.contacts.addContact && !this.props.contacts.selectNewBilling && !this.state.isShippingEnrolled)? true : values.markAsDefaultShippingContact,
                                                        setup:this.state.setup,
                                                        walletSetupProgress:this.props.devices.connected.walletSetupProgress,
                                                        walletSetupStatus:this.props.devices.connected.walletSetupStatus,
                                                        isShippingEnrolled:this.state.isShippingEnrolled,
                                                        billingEnrolled:this.state.billingEnrolled,
                                                        selectNewBilling:this.props.contacts.selectNewBilling,
                                                        updateTokenContact:this.props.contacts.updateTokenContact,
                                                        tokenId:(this.state.setup && this.props.cards.activeCard) ? this.props.cards.activeCard.id: (this.props.cards.activeCard && this.props.contacts.selectNewBilling && this.props.cards.tokenId) ? this.props.cards.tokenId :this.props.contacts.tokenId? this.props.contacts.tokenId: '',
                                                        deviceId:this.props.devices.connected? this.props.devices.connected.deviceId: '',
                                                        publicId:this.props.devices.connected? this.props.devices.connected.publicId: '',
                                                        devicePort: this.props.devicePortDiscovery.devicePort,
                                                        history,
                                                        callback:()=>{this.handleNavigation()}
                                                    })
                                                }
                                            }
                                            else{
                                                if((this.state.setup || !this.state.setup) && this.state.sameAsBilling && this.state.billingEnrolled){
                                                    console.log("updateContactFromServer")
                                                    this.props.updateContactFromServer({
                                                        contact: contact,
                                                        isShippingEnrolled:this.state.isShippingEnrolled,
                                                        billingEnrolled:this.state.billingEnrolled,
                                                        markAsDefaultShippingContact:true,
                                                        setup:this.state.setup,
                                                        selectNewBilling:this.props.contacts.selectNewBilling,
                                                        tokenId:this.state.setup && this.props.cards.activeCard? this.props.cards.activeCard.id: '',
                                                        deviceId:this.props.devices.connected? this.props.devices.connected.deviceId: '',
                                                        publicId:this.props.devices.connected? this.props.devices.connected.publicId: '',
                                                        devicePort: this.props.devicePortDiscovery.devicePort,
                                                        walletSetupStatus:this.props.devices.connected.walletSetupStatus,
                                                        history,
                                                        callback:()=>{this.handleNavigation()}
                                                    })

                                                }  
                                                else if(values.markAsDefaultShippingContact && this.props.contacts.defaultShippingContactId){
                                                    console.log("updateDefaultContactFromServer")
                                                    this.props.updateDefaultContactFromServer({
                                                        setDefaultContact:true,
                                                        defaultContact:this.props.contacts.defaultShippingContactId? this.props.contacts.contacts.find(contact =>{return contact.id === this.props.contacts.defaultShippingContactId}):this.props.contacts.activeContact,
                                                        contact:contact,
                                                        markAsDefaultShippingContact:true,
                                                        deviceId:this.props.devices.connected? this.props.devices.connected.deviceId: '',
                                                        publicId:this.props.devices.connected? this.props.devices.connected.publicId: '',
                                                        devicePort: this.props.devicePortDiscovery.devicePort,
                                                        history,
                                                        callback:()=>{this.handleNavigation()}
                                                    })
                                                }
                                                else{
                                                    console.log("updateContactFromServer")
                                                    this.props.updateContactFromServer({
                                                        contact: contact,
                                                        billingEnrolled:this.state.billingEnrolled,
                                                        isShippingEnrolled:this.state.isShippingEnrolled,
                                                        setup:this.state.setup,
                                                        selectNewBilling:this.props.contacts.selectNewBilling,
                                                        tokenId:this.state.setup && this.props.cards.activeCard? this.props.cards.activeCard.id: '',
                                                        deviceId:this.props.devices.connected? this.props.devices.connected.deviceId: '',
                                                        publicId:this.props.devices.connected? this.props.devices.connected.publicId: '',
                                                        devicePort: this.props.devicePortDiscovery.devicePort,
                                                        walletSetupStatus:this.props.devices.connected.walletSetupStatus,
                                                        history,
                                                        callback:()=>{this.handleNavigation()}
                                                    })

                                                    }                                                
                                                }
                                                setTimeout(()=>{
                                                    setSubmitting(false) 
                                                },1000)                                                                                    
                                                
                                            }}
                                        >
                                            {({ values, isValid, dirty, isSubmitting, setFieldValue,handleBlur }) => (
                                                <Form>
                                                    <Container fluid>
                                                        {
                                                             this.state.setup && ( this.state.billingEnrolled ) ?
                                                             <p className='mt-4 mb-3'>Add a default shipping address to your wallet. You can add more addresses later.</p>
                                                             :<></>
                                                        }
                                                        {
                                                            (this.state.setup && this.state.billingEnrolled ) || ( !this.state.setup && this.state.billingEnrolled && !this.state.isShippingEnrolled ) ?
                                                            <div className="d-flex align-items-center mb-4">
                                                                <label className="m-0" htmlFor="sameAsBilling">
                                                                <input
                                                                    name="sameAsBilling" 
                                                                    type="checkbox"
                                                                    checked={this.state.sameAsBilling}
                                                                    onChange={() => {
                                                                        this.sameAsBilling(values)
                                                                    }}
                                                                    />&nbsp;
                                                                    <b>Same As Billing Address</b></label>
                                                            </div>
                                                            :
                                                            <></> 
                                                        }
                                                        <label className='form-required-instruction-label'>&nbsp;<span className='required'> </span>&nbsp; Required Fields</label>                                                                                                   
                                                        <TextInput
                                                            label='First name'
                                                            name='givenName'
                                                            type='text'
                                                            showstar="true"
                                                        />
                                                        <TextInput
                                                            label='Last name'
                                                            name='familyName'
                                                            type='text'
                                                            showstar="true"
                                                        />
                                                        <TextInput
                                                            label='Phone number'
                                                            name='phoneNumber'
                                                            type='text'
                                                            format="(###) ###-####" 
                                                            showstar="true"
                                                            />
                                                        <TextInput
                                                            label='Email Address'
                                                            name='email'
                                                            type='email'
                                                            showstar="true"
                                                        />                                 
                                                        <TextInput
                                                            label='Address Line 1'
                                                            name='addressLine1'
                                                            type='text'
                                                            showstar="true"
                                                        />
                                                        <TextInput
                                                            label='Address Line 2'
                                                            name='addressLine2'
                                                            type='text'
                                                        />
                                                        <TextInput
                                                            label='City'
                                                            name='city'
                                                            type='text'
                                                            showstar="true"
                                                        />
                                                        <SelectInput
                                                        label="state"
                                                        name="state"
                                                        itemlist={usStateList()}
                                                        showstar="true"
                                                        />
                                                        <TextInput
                                                            required = {true}
                                                            label='Zip code'
                                                            name='zip'
                                                            type='number'
                                                            onChange={(event) => {
                                                                setFieldValue('zip', event.target.value)
                                                            }
                                                            }
                                                            showstar="true"
                                                        />
                                                        <SelectInput
                                                            label="country"
                                                            name="country"
                                                            type="text"
                                                            default={countries[0].label}
                                                            readOnly
                                                        />
                                                        {
                                                            (!this.state.setup && ((this.props.contacts.addContact && !this.props.contacts.selectNewBilling && this.state.isShippingEnrolled))) 
                                                            ?
                                                            <div className='pt-4'>
                                                            <CheckBox
                                                                className="input-margin"
                                                                name="markAsDefaultShippingContact">
                                                                Set as default shipping contact
                                                            </CheckBox>
                                                            </div> 
                                                            :
                                                            <></>
                                                        }
                                                        {
                                                            <div className="button-wrapper d-flex justify-content-between">
                                                            <div>
                                                                {
                                                                    this.state.setup ?
                                                                    <div>
                                                                        {
                                                                        this.state.backTo?
                                                                            <button 
                                                                            type='button'
                                                                            className="btn btn-outline-primary btn-lg button-outline" 
                                                                                onClick={() => { history.push(this.state.backTo.componentUrl)
                                                                                }}>
                                                                                Back
                                                                            </button>
                                                                            :<></>                                                                    
                                                                            }
                                                                    </div>
                                                                    :
                                                                    <button 
                                                                        type='button'
                                                                        className="btn btn-outline-primary btn-lg button-outline" 
                                                                            onClick={() => {
                                                                                this.props.contacts.addContact ? 
                                                                                this.props.cancelAddContact({
                                                                                    updateTokenContact:this.props.contacts.updateTokenContact,
                                                                                    history
                                                                                })
                                                                                :
                                                                                this.props.cancelUpdateContact({
                                                                                    history
                                                                                })
                                                                            }}>
                                                                            Cancel
                                                                    </button>
                                                                }                                                            
                                                            </div>
                                                            <div>
                                                                <Button 
                                                                type='submit'
                                                                disabled={isSubmitting || !dirty || !isValid || this.props.contacts.isLoading}
                                                                >
                                                                    {this.props.contacts.isLoading && <span className="spinner-border spinner-border-sm mr-1" role="status" aria-hidden="true" />}
                                                                    {this.state.setup?"Next":"Save"}
                                                                </Button>
                                                            </div>
                                                        </div>
                                                    }
                                                </Container>                                                
                                            </Form>
                                        )
                                        }
                                    </Formik>
                                </div>          
                        </div>
                        </div>
                    }
                </div>
            </div>
        )
    }

}

const mapStateToProps = state => ({
    contacts: state.contacts,
    cards:state.cards,
    userData:state.user.data,
    devices:state.devices,
    devicePortDiscovery: state.devicePortDiscovery
})

const mapDispatchToProps = dispatch => ({
    addContactFromServer:(data)=>dispatch(addContactFromServer(data)),
    cancelAddContact: (data) => dispatch(cancelAddContact(data)),
    updateContactFromServer:(data)=>dispatch(updateContactFromServer(data)),
    cancelUpdateContact: (data) => dispatch(cancelUpdateContact(data)),
    updateDefaultContactFromServer: (data) => dispatch(updateDefaultContactFromServer(data)),
    queryStatus:(data)=>dispatch(queryStatusFromDevice(data)),
    resetContactErrorMessages:(data)=>dispatch(resetContactErrorMessages(data)),
    reportWalletSetupProgress: (data) => dispatch(reportWalletSetupProgress(data)),
    getCardListFromServer: (data) => dispatch(getCardListFromServer(data)),
    getContacts: (data) => dispatch(getContacts(data)),
    linkBillingtoCard: (data) => dispatch(linkBillingtoCard(data)),
    startAddContact: (data) => dispatch(startAddContact(data)),

})

export default connect(mapStateToProps, mapDispatchToProps)(AddShippingAddress)

