/*
 * INTEL CONFIDENTIAL
 * Copyright 2022 Intel Corporation.
 * This software and the related documents are Intel copyrighted materials, and
 * your use of them is governed by the express license under which they were
 * provided to you (License). Unless the License provides otherwise, you may not
 * use, modify, copy, publish, distribute, disclose or transmit this software or
 * the related documents without Intel's prior written permission. This software
 * and the related documents are provided as is, with no express or implied
 * warranties, other than those that are expressly stated in the License.
 */
import React from 'react'
import { useField } from 'formik'

const CheckBox = ({ children, ...props }) => {
    const [field, meta] = useField({ ...props, type: 'checkbox' });
    return (
      <div className="input-field">
        <div className="checkbox-input">
          {meta.touched && meta.error ? (
            <div className="error" style={{ paddingLeft:'0.5rem'}}>{meta.error}</div>
          ) : null}
          <input type="checkbox" className={["checkbox", (meta.touched && meta.error) ? "checkbox-error-border" : ""].join(' ')} {...field} {...props} />
          <span className='selection-term-font'>{children}</span>
        </div>
        
      </div>
    );
  };

  export default CheckBox