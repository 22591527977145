import React, { Component } from 'react'
import { ProgressBar } from 'react-bootstrap'

export  class StrengthIndicator extends Component{

    constructor(props) {
        super(props);
        this.state = {
        };
        this.createLabel = this.createLabel.bind(this);
      }

    createLabel = (data) =>{
        switch(data){
            case 0 :
                return "Weak"
            case 1 :
                return "Weak"
            case 2 :
                return "Fair"
            case 3 :
                return "Strong"
            default:
                return "Weak"
        }
    }

    render(){
        return(
            <div className='w-100'>
                <ProgressBar>
                <ProgressBar variant="danger" now={ this.props.result === 1 ? 35 : 0} key={1} />
                <ProgressBar variant="warning" now={this.props.result === 2 ? 75 : 0} key={2} />
                <ProgressBar variant="success" now={this.props.result === 3 ? 100 : 0} key={3} />
                </ProgressBar>
                <label className='my-1'>
                    <b> {this.props.label} : {this.createLabel(this.props.result)}</b>
                </label>
            </div>
        )
    }
}