/*
 * INTEL CONFIDENTIAL
 * Copyright 2022 Intel Corporation.
 * This software and the related documents are Intel copyrighted materials, and
 * your use of them is governed by the express license under which they were
 * provided to you (License). Unless the License provides otherwise, you may not
 * use, modify, copy, publish, distribute, disclose or transmit this software or
 * the related documents without Intel's prior written permission. This software
 * and the related documents are provided as is, with no express or implied
 * warranties, other than those that are expressly stated in the License.
 */
import React, { Component } from 'react'
import { Modal } from 'react-bootstrap'
import { connect } from 'react-redux'
import { Prompt } from 'react-router'
import { Link } from 'react-router-dom'
import { Formik, Form } from 'formik';
import * as Yup from 'yup';

import * as constants from '@pcwallet/common/constants'
import { clearSecurityAuthState, managePin, resetErrorMessages, startResetPincode} from '../../actions/security' 
import { analyticsPageView } from '../../utils'
import { codeRegEx, testPINStrength } from '@pcwallet/common/validationEngine';
import { requestVerificationCode, resetVerifyReducerState } from '../../actions/verify';
import { reportWalletSetupProgress } from '../../actions/devices';
import { getNextComponent, getPreviousComponent } from './WalletSetupProgressUtil';

import Button from '../inputs/Button';
import Loading from '../shared/Loading';
import ErrorMessage from '../shared/ErrorMessage';
import LoadingErrorModule from '../shared/LoadingErrorModule';
import Notification from '../shared/Notification'
import TextInput from '../inputs/TextInput';

export class AddPINCodeContainer extends Component {    
    constructor(props) {
        super(props)
        this.state = {
            menu: 0,
            pincodeHide:true,
            confirmPincodeHide:true,
            currentPincodeHide:true,
            strengthResult:0,
            proceedTo:{componentUrl:'/security'},
            backTo:{componentUrl:'/security'},
            setup:false,
            showModal:false,
            showToast:false
        }
        this.togglePincodeHide = this.togglePincodeHide.bind(this);
        this.toggleConfirmPincodeHide = this.toggleConfirmPincodeHide.bind(this);
        this.toggleCurrentPincodeHide = this.toggleCurrentPincodeHide.bind(this);
        this.handleStrengthTest = this.handleStrengthTest.bind(this);
        this.handleCancel = this.handleCancel.bind(this);
    }

    componentDidMount(){     
        //initializing analytics
        if(this.props.pin.authState === constants.PIN_STATE_ADD){
            analyticsPageView('/add-pin');
        }
        else{
            analyticsPageView('/change-pin');
        }
        if(this.props.pin.authState === constants.PIN_STATE_RESET && !this.props.otpverify.codeSentSuccess) {
            this.setState({showModal:true})
        }

    }

    componentDidUpdate(prevProps) {
        if(this.props.otpverify.codeSentSuccess != prevProps.otpverify.codeSentSuccess && this.props.pin.authState === constants.PIN_STATE_RESET && this.props.otpverify.codeSentSuccess ){
            this.setState({
                showModal:false,
                showToast:true
            })
        }
        if(this.props.pin.error !== prevProps.pin.error){
            console.log("errors updated") 
        }
    }

    // componentWillUnmount() {
    //     this.props.clearSecurityAuthState();
    // }

    
    togglePincodeHide() {
        this.setState({ pincodeHide: !this.state.pincodeHide });
      }
    
    toggleConfirmPincodeHide() {
    this.setState({ confirmPincodeHide: !this.state.confirmPincodeHide });
    }

    toggleCurrentPincodeHide() {
    this.setState({ currentPincodeHide: !this.state.currentPincodeHide });
    }

    handleStrengthTest(event,handleChange){
        if(event.target.value != null){
            let testResult = testPINStrength(event.target.value)
            this.setState(({strengthResult: testResult}))
        }
        handleChange(event)
    }

    handleCancel(){
        this.setState({
          showModal:false,
        })
        this.props.resetVerifyReducerState()
        this.props.clearSecurityAuthState();
        this.props.history.push('/security')
    }

    render() {
        const { history } = this.props;
        return (
                <div className="wrapper">
                    <div className="content">
                        <Notification 
                            show={this.state.showToast} 
                            hide={()=>{
                                this.setState({showToast:false})
                                this.props.resetVerifyReducerState()
                            }} 
                            msg="Code succesfully sent to provided email">
                        </Notification>
                        {
                                this.props.pin.error && this.props.pin.errorMessage?
                                <ErrorMessage
                                    show = {this.props.pin.error}
                                    msg = {this.props.pin.errorMessage}
                                    onClose = {()=>{
                                        this.props.resetErrorMessages()
                                    }}
                                />
                                :<></>
                        }  
                        <div className="main-wrapper">
                        <h1 className="px-5">
                            {
                                this.props.pin.authState === constants.PIN_STATE_CHANGE ?
                                `Update PIN`
                                : this.props.pin.authState === constants.PIN_STATE_RESET ?
                                `Forgot PIN`
                                :
                                `Set Up PIN`
                            }
                        </h1>
                        <p className="divider my-4"/>
                        {
                            <div className="px-5 pb-5">
                                {
                                    <div>
                                        <div className="mt-3">                         
                                        
                                            {
                                                this.props.pin.authState === constants.PIN_STATE_CHANGE ?
                                                <p className="mt-3">Your PIN should have the following</p>
                                                :
                                                this.props.pin.authState === constants.PIN_STATE_RESET ?
                                                <p className="mt-3">An email was sent to <b> {this.props.authData.username} </b> with a verification code. Please enter the code below and set up a new PIN to authorize payments with Intel Pay</p>
                                                :
                                                <p className="mt-3"> Create a PIN to authorize payments with Intel Pay.</p>
                                            }

                                        <div>
                                            <ul className="mt-3">
                                                <li>Minimum of 4 characters.</li>
                                                <li>Maximum of 64 characters.</li>
                                                <li>Any combination of letters, numerics and allowed special characters (! @ # $ % ^ &amp; * ! () - _ + ).</li>
                                            </ul>
                                        </div>
                                        </div>
                                        <div>
                                            <Formik
                                                initialValues={{
                                                    otp:'',
                                                    currentPincode:'',
                                                    pincode: '',
                                                    confirmPincode:'',
                                                }}
                                                validationSchema={Yup.object({
                                                    otp : Yup.string()
                                                        .label('Registration Code')
                                                        .matches(codeRegEx,'code format is invalid'),
                                                        // .required('Required'),
                                                    currentPincode: Yup.string()
                                                        .max(64, 'PIN should have maximum 64 characters')
                                                        .min(4, 'PIN should have minimum 4 characters'),
                                                    pincode: Yup.string()
                                                        .max(64, 'PIN should have maximum 64 characters')
                                                        .min(4, 'PIN should have minimum 4 characters')
                                                        .required('Required'),
                                                    confirmPincode: Yup.string()
                                                        .max(64, 'PIN should have maximum 64 characters')
                                                        .min(4, 'PIN have minimum 4 characters')
                                                        .test('PIN-match', 'PIN must match.', function(value) {
                                                            return this.parent.pincode === value;
                                                        })
                                                        .required('Required'),
                                                })}
                                                onSubmit={(values, { setSubmitting }) => {
                                                    this.props.managePin(
                                                        {
                                                            authState:this.props.pin.authState,
                                                            otp:values.otp.trim(),
                                                            currentPincode:values.currentPincode,
                                                            pincode:values.pincode,
                                                            setup:this.state.setup,
                                                            deviceId:this.props.devices.connected? this.props.devices.connected.deviceId: '',
                                                            devicePort: this.props.devicePortDiscovery.devicePort,
                                                            history,
                                                            callback:()=>{
                                                                history.push(this.state.proceedTo.componentUrl)
                                                            }
                                                        }
                                                    )
                                                    setTimeout(()=>{
                                                        setSubmitting(false) 
                                                    },1000)
                                                }}
                                            >
                                                {({ values, dirty, isValid, isSubmitting, handleBlur, handleChange, resetForm  }) => (
                                                    <div>
                                                        {
                                                        <Form>
                                                                {
                                                                    this.props.pin.authState === constants.PIN_STATE_CHANGE ?
                                                                    <>
                                                                        <label className='form-required-instruction-label'>All Fields Required</label>
                                                                        <TextInput                                                        
                                                                        label="Current PIN"
                                                                        name="currentPincode"
                                                                        autoComplete="off"
                                                                        type={this.state.currentPincodeHide ? 'password' : 'text'}
                                                                        toggleshow={this.toggleCurrentPincodeHide}
                                                                        hidestate={this.state.currentPincodeHide.toString()}
                                                                        />
                                                                    </>
                                                                    
                                                                    :
                                                                    this.props.pin.authState === constants.PIN_STATE_RESET ?
                                                                    <div>
                                                                        <div className="my-2">
                                                                            <p>
                                                                            Didn’t receive a code?<br/>
                                                                            Check your spam folder or <span className="link" 
                                                                            onClick={()=>{
                                                                                this.props.requestVerificationCode({
                                                                                    otpPurpose : constants.RESET_PIN
                                                                                })
                                                                                resetForm()
                                                                                }}>Resend Code</span>
                                                                            </p>
                                                                        </div>
                                                                        <br/>
                                                                        <label className='form-required-instruction-label'>All Fields Required</label>
                                                                        <TextInput                                                        
                                                                            label="Verification Code"
                                                                            name="otp"
                                                                            autoComplete="off"
                                                                        />
                                                                    </div>
                                                                    
                                                                    :
                                                                    <>
                                                                        <label className='form-required-instruction-label'>All Fields Required</label>
                                                                    </>
                                                                }
                                                                <TextInput
                                                                    label="New PIN"
                                                                    name="pincode"
                                                                    autoComplete="off"
                                                                    type={this.state.pincodeHide ? 'password' : 'text'}
                                                                    toggleshow={this.togglePincodeHide}
                                                                    hidestate={this.state.pincodeHide.toString()}
                                                                    onChange={event=>{
                                                                        this.handleStrengthTest(event,handleChange)
                                                                    }}
                                                                    result={this.state.strengthResult}
                                                                    
                                                                />
                                                                <TextInput
                                                                    label="Confirm PIN"
                                                                    name="confirmPincode"
                                                                    autoComplete="off"
                                                                    type={this.state.confirmPincodeHide ? 'password' : 'text'}
                                                                    toggleshow={this.toggleConfirmPincodeHide}
                                                                    hidestate={this.state.confirmPincodeHide.toString()}
                                                                />
                                                                {
                                                                    this.state.setup?
                                                                        <div className="button-wrapper d-flex justify-content-end">                                                              
                                                                            <button
                                                                                className="btn btn-primary btn-lg button"
                                                                                disabled={isSubmitting || !dirty || !isValid || this.props.pin.isLoading}
                                                                                type="submit"
                                                                            >
                                                                            {this.props.pin.isLoading && <span className="spinner-border spinner-border-sm mr-1" role="status" aria-hidden="true" />}
                                                                            Next</button>                                                    
                                                                    </div>
                                                                    :
                                                                    <div className="button-wrapper d-flex justify-content-between">
                                                                    <div>
                                                                        <Link to='/security'>
                                                                        <button
                                                                            className="btn btn-outline-primary btn-lg button-outline"
                                                                            disabled={this.props.pin.isLoading}
                                                                            type="button"
                                                                            onClick={()=>{
                                                                                this.props.clearSecurityAuthState()
                                                                            }}
                                                                            >
                                                                            Cancel
                                                                            </button>
                                                                        </Link>
                                                                    </div>
                                                                    <button
                                                                        className="btn btn-primary btn-lg button"
                                                                        disabled={isSubmitting || !dirty || !isValid ||this.props.pin.isLoading}
                                                                        type="submit"
                                                                    >
                                                                    {this.props.pin.isLoading && <span className="spinner-border spinner-border-sm mr-1" role="status" aria-hidden="true" />}
                                                                    Save</button>                                                    
                                                                </div>
                                                                }
                                                        </Form>
                                                        }
                                                    </div>
                                                )}
                                            </Formik> 
                                        </div>
                                        <Modal 
                                                show={this.state.showModal} 
                                                onHide={()=>{this.setState({showModal:false})}}
                                                backdrop="static"
                                                keyboard={false}
                                                centered
                                            >
                                            {                    
                                            this.state.showModal? 
                                            <div>
                                                <Modal.Header className="justify-content-center"><Modal.Title >Request Verification Code</Modal.Title></Modal.Header>
                                                <Modal.Body>
                                                        <div className="p-4  text-left">
                                                            <div><p><b> An email will be to <b>{this.props.authData.username}</b> with a verification code. Please enter the code and set up a new PIN to authorize payments with Intel Pay </b></p></div>
                                                            <div className="button-wrapper">
                                                                <div className="d-flex justify-content-between">
                                                                    <button
                                                                        className="btn btn-outline-primary button-outline"
                                                                        type="button"
                                                                        onClick={this.handleCancel}>
                                                                            Cancel
                                                                    </button>
                                                                    <button
                                                                        className="btn btn-primary button"
                                                                        type="button"
                                                                        disabled={this.props.otpverify.isLoadingModal}
                                                                        onClick={() => {
                                                                            this.props.requestVerificationCode({
                                                                                otpPurpose : constants.RESET_PIN
                                                                            })}
                                                                            }>
                                                                        {
                                                                        this.props.otpverify.isLoadingModal && 
                                                                            <span className="spinner-border spinner-border-sm mr-1" role="status" aria-hidden="true" />
                                                                        }
                                                                        Send Email
                                                                    </button>                                  
                                                                </div>
                                                            </div>
                                                            <LoadingErrorModule
                                                                error={this.props.otpverify.error}
                                                                errorMessage={this.props.otpverify.errorMessage}
                                                            />
                                                        </div>
                                                </Modal.Body>
                                            </div>
                                            :
                                            <></>                                        
                                            }                                                                   
                                            </Modal>
                                    </div>

                                }
                            </div>
                        }
                        </div>
                    </div>
                </div>
               )
    }
}


const mapStateToProps = state => ({
    authData: state.user.data,
    devices: state.devices,
    cards: state.cards,
    pin: state.security,
    userIdleFlag: state.user.userIdle,
    otpverify:state.verify,
    devicePortDiscovery: state.devicePortDiscovery
})

const mapDispatchToProps = (dispatch) => ({
    managePin: (data) => dispatch(managePin(data)),
    startResetPincode: (data) => dispatch(startResetPincode(data)),
    clearSecurityAuthState: () => dispatch(clearSecurityAuthState()),
    requestVerificationCode: (data) => dispatch(requestVerificationCode(data)),
    reportWalletSetupProgress: (data) => dispatch(reportWalletSetupProgress(data)),
    resetVerifyReducerState: () => dispatch(resetVerifyReducerState),
    resetErrorMessages: () => dispatch(resetErrorMessages()),

})

export default connect(mapStateToProps, mapDispatchToProps)(AddPINCodeContainer)

