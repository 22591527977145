/*
 * INTEL CONFIDENTIAL
 * Copyright 2022 Intel Corporation.
 * This software and the related documents are Intel copyrighted materials, and
 * your use of them is governed by the express license under which they were
 * provided to you (License). Unless the License provides otherwise, you may not
 * use, modify, copy, publish, distribute, disclose or transmit this software or
 * the related documents without Intel's prior written permission. This software
 * and the related documents are provided as is, with no express or implied
 * warranties, other than those that are expressly stated in the License.
 */
import React, {Component} from 'react'
import {Row, Col, Modal , Button } from 'react-bootstrap'
import IdleTimer from 'react-idle-timer'
import { BrowserRouter as HashRouter, Route, NavLink, Switch } from 'react-router-dom'
import jwtDecode from 'jwt-decode'
import { Link } from 'react-router-dom'
import { connect } from 'react-redux'

import "@pcwallet/common/styles.css"
import { logout, login, getUserDetails,timedout, userIdle, extendSession, refreshToken } from '../actions/user'
import { userIsAuthenticatedRedir, userIsNotAuthenticatedRedir,
         userIsAuthenticated, deviceIsUnlockedRedir } from '../auth'
import { queryStatusFromDevice, updateDevice } from '../actions/devices'
import {BsPersonCircle} from 'react-icons/bs'
import {TiEdit} from 'react-icons/ti'
import { startDevicePortDiscovery } from '../actions/devicePortDiscovery'
import { validateDeviceName } from '@pcwallet/common/validationEngine'
import { cancelFingerEnrollment } from '../actions/security'
import * as constants from '@pcwallet/common/constants'
import * as messages from '@pcwallet/common/endUserMessages'
import {getDeviceTokenFromCookie, getPreDeviceTokenFromCookie} from '@pcwallet/common/utils';
import { analyticsPageView } from '../utils'

import LoginComponent from './Login'
import Register from './enrollment/Register'
import RegisterUser from './enrollment/RegisterUser'
import Home from './Home'
import DashboardComponent from './dashboard/Dashboard'
import RegisterDeviceComponent from './dashboard/RegisterDevice'
import AddCardComponent from './enrollment/AddCard'
import CardsComponent from './dashboard/Cards'
import ContactsComponent from './dashboard/Contacts'
import ForgotPasswordComponent from './ForgotPassword'
import ResetPasswordComponent from './ResetPassword'
import AddFingerprintComponent from './enrollment/AddFingerprint'
import AddPINCodeComponent from './enrollment/AddPINCode'
import SecurityComponent from './dashboard/Security'
import AddBillingAddressComponent  from './enrollment/AddBillingAddress'
import AddShippingAddressComponent from './enrollment/AddShippingAddress'
import AccountSettings from './dashboard/AccountSettings'
import PCWalletCCPromo  from './enrollment/PCWalletPromo'
import DeviceDashboard from './dashboard/DeviceDashboard'
import Logo from '@pcwallet/common/assets/intelPayLogo.png';
import Loading from './shared/Loading'
// import Logo from '@pcwallet/common/assets/PCW Standard Logo.png';


// Need to apply the hocs here to avoid applying them inside the render method
const Login = userIsNotAuthenticatedRedir(LoginComponent)
const Dashboard = userIsAuthenticatedRedir(DashboardComponent)
const RegisterDevice = deviceIsUnlockedRedir(userIsAuthenticatedRedir(RegisterDeviceComponent))
const AddCard = deviceIsUnlockedRedir(userIsAuthenticatedRedir(AddCardComponent))
const Cards = deviceIsUnlockedRedir(userIsAuthenticatedRedir(CardsComponent))
const Account = userIsAuthenticatedRedir(AccountSettings)
const Security = deviceIsUnlockedRedir(userIsAuthenticatedRedir(SecurityComponent))
const AddFingerprint = deviceIsUnlockedRedir(userIsAuthenticatedRedir(AddFingerprintComponent))
const AddPIN = deviceIsUnlockedRedir(userIsAuthenticatedRedir(AddPINCodeComponent))
const Contacts = deviceIsUnlockedRedir(userIsAuthenticatedRedir(ContactsComponent))
const AddBillingAddress = deviceIsUnlockedRedir(userIsAuthenticatedRedir(AddBillingAddressComponent))
const AddShippingAddress = deviceIsUnlockedRedir(userIsAuthenticatedRedir(AddShippingAddressComponent))
const ForgotPassword = userIsNotAuthenticatedRedir(ForgotPasswordComponent)
const ResetPassword = userIsNotAuthenticatedRedir(ResetPasswordComponent)
const PCWalletPromo = deviceIsUnlockedRedir(userIsAuthenticatedRedir(PCWalletCCPromo))
const Device = userIsAuthenticatedRedir(DeviceDashboard)

// Only show login when the user is not logged in and logout when logged in
// Could have also done this with a single wrapper and `FailureComponent`

const AccountLink = userIsAuthenticated(({icon,className}) => <NavLink className={className} to="/account-settings"><div className={icon?"account-icon-clicked":"account-icon"}><BsPersonCircle size="2.5em"/></div></NavLink> )
// const DashboardLink = userIsAuthenticated(() => <NavLink activeClassName="active" to="/dashboard">Dashboard</NavLink> )
const CardsLink = userIsAuthenticated(({className}) => <NavLink className={className} activeClassName="active" to="/cards">Credit and Debit Cards</NavLink> )
const ContactsLink = userIsAuthenticated(({className}) => <NavLink className={["ml-5", className].join(' ')}  activeClassName="active" to="/contacts">Addresses</NavLink> )
const SecurityLink = userIsAuthenticated(({className}) => <NavLink className={["ml-5", className].join(' ')} activeClassName="active" to="/security">Security</NavLink> )
const LogoutLink = ({ logout, cancelFingerEnrollment, enrollmentState, authState, devicePort,style,className}) => 
                      <button 
                        className={["btn nav-button" , className].join(' ')}
                        style={{ ...style }}
                        onClick={() => {
                          if(authState === constants.FP_STATE_ADD && enrollmentState !== "stop") {
                            cancelFingerEnrollment({
                              action:"CANCEL",
                              devicePort:devicePort,
                              callback:()=>{logout()}
                            })
                          }
                          else {
                            logout()
                          }
                        }}> Sign Out</button>

class App extends Component {
  constructor(props) {
    super(props);
    this.state = {
      menu: 0,
      loading: true,
      showModal:false,
      isTimedOut:false,
      editEnable: false,
      updateDeviceNameError:"",
    }
    
    this.shouldBlockNavigation = false
    this.idleTimer = null
    this.handleOnIdle = this.handleOnIdle.bind(this)
    this.handleClose = this.handleClose.bind(this)
    this.handleLogout = this.handleLogout.bind(this)
    this.handleOnSubmit = this.handleOnSubmit.bind(this)
    this.handleClickEditButton = this.handleClickEditButton.bind(this)
    this.handleOnDeviceUpdated = this.handleOnDeviceUpdated.bind(this)
    this.handleValidateDeviceName = this.handleValidateDeviceName.bind(this)
  }

  handleClose(){
    this.props.extendSession()
    this.setState({
      showModal:false,
      isTimedOut: false
    })
  }

  handleLogout() {
    this.setState({
      showModal:false,
      isTimedOut: false
    })
    this.props.logout()
  }

  handleOnIdle (event) {   
    if(this.props.userLoggedIn && (this.props.otpVerified || this.props.deviceVerified)){
      const isTimedOut = this.state.isTimedOut
      if(isTimedOut){
        this.setState({showModal: false})
        this.props.userIdle(); 
      }
      else{
        this.setState({showModal: true})
        this.idleTimer.reset();
        this.setState({isTimedOut: true})        
      }
    }
    else{
      //reseting timer if user not logged in
      this.idleTimer.reset()
    }
  }

  handleClickEditButton() {
    this.setState({editEnable: true})
  }

  handleOnSubmit(e) {
    const displayName = e.target.value
    if (e.keyCode == 13) {
      if(this.props.devices.connected && displayName === this.props.devices.connected.displayName){
        this.setState({
          editEnable:false,
          updateDeviceNameError:""           
        })
      }
      else{
        let testResult = validateDeviceName(displayName)
        if(testResult === 0 ){
          this.setState({
            updateDeviceNameError:messages.UPDATE_DEVICE_NAME_MIN_LENGTH_ERROR
          })
        }
        else if(testResult === 1){
          this.props.updateDevice(
            {
              "deviceId": this.props.devices.connected.deviceId,
              "updateInfo": {
                "displayName": displayName,
              },
              callback:this.handleOnDeviceUpdated
            }
          ) 
        }
      }     
    }
  }

  
  handleValidateDeviceName(event){
    if(event.target.value != null){
        let testResult = validateDeviceName(event.target.value)
        if (testResult === 0 ) {
          this.setState({
            updateDeviceNameError:messages.UPDATE_DEVICE_NAME_MIN_LENGTH_ERROR
          })
        }
        if (testResult === 2 ) {
          this.setState({
            updateDeviceNameError:messages.UPDATE_DEVICE_NAME_MAX_LENGTH_ERROR
          })
        }
        else if (testResult === 3 ) {
          this.setState({
            updateDeviceNameError:messages.UPDATE_DEVICE_NAME_INVALID_CHAR_ERROR
          })
        }
        else{
          this.setState({
            updateDeviceNameError:""
          })
        }
    }
    }

  handleOnDeviceUpdated() {
    if(this.props.devices.error){
      this.setState({
        updateDeviceNameError:this.props.devices.errorMessage
      })
    }
    else{
      this.setState({
        updateDeviceNameError:"",
        editEnable:false
      })
    }  
  }

  componentDidUpdate() {
      if(this.props.userIdleFlag){
        this.props.timedout();
        this.setState({isTimedOut: false}) 
      }
  }

  componentDidMount() {
    //initializing analytics
    window.addEventListener("focus", this.onFocus)
    analyticsPageView('/')
    const storedToken = getDeviceTokenFromCookie();
    if (storedToken) {
      var decoded = jwtDecode(storedToken);
      if (decoded.exp < Date.now()/1000) {
        this.setState({loading:false})
      } else {
        this.props.getUserDetails({
          onComplete: () => {
            // force to retrieve new refresh token once page is loaded
            refreshToken();
        
            this.setState({loading:false});
          }
        })
      }
    } else {
      this.setState({loading:false})
    }
    //start device port discovery in background
    const { history } = this.props;
    let tryPort = constants.DEVICE_PORT_RANGE_START
    let requestURL = `${window.REACT_APP_MTO_ADDRESS_HOSTNAME}:${String(tryPort)}/v1/public_command`
    this.props.startDevicePortDiscovery(
      {
        deviceRequestURL:requestURL,
        tryPort:tryPort,
        callback:()=>{
          if(storedToken && (!this.props.devices.connected || this.props.devices.saved.length === 0)){
            this.props.queryStatus({
              history,
              // detectDevice:true,
              devicePort: this.props.devicePortDiscovery.devicePort,
          })
          }
        }
      }
    );
  }

  componentWillUnmount() {
    window.removeEventListener("focus", this.onFocus)
  }

  onFocus = () => {
    //check if cookie still available to continue session else logout
    let storedToken = getDeviceTokenFromCookie();
    let storedPreJwt = getPreDeviceTokenFromCookie();
    if((!storedToken && !storedPreJwt && this.props.userLoggedIn)){
      this.props.logout()
    }
  }

  render() {
    return (
      <HashRouter
        basename="/#"
      >
        <div className='root'>
        <IdleTimer
          ref={ref => { this.idleTimer = ref }}
          timeout={1000 * 60 * constants.SESSION_TIMEOUT_IN_MINUTES}
          onIdle={this.handleOnIdle}
          debounce={250}
        />
        {
         (!this.props.userLoggedIn && (!this.props.otpVerified || !this.props.deviceVerified)) ||(!this.props.devices.connected)|| (this.props.devices.connected && this.props.devices.connected.walletSetupProgress !== "DONE") ||
          (this.props.devices.connected && (this.props.devices.connected.state === "LOCKED" || this.props.devices.connected.pendingState === "LOCKED"  || (this.props.devices.connected.pendingCapability & 2) === 0))||
           (this.props.devices.saved && this.props.devices.saved[0] && !this.props.devices.connected) || !this.props.devicePortDiscovery.devicePort?
          <div>
          <Row  className="setup-nav-container align-items-center">
          <Link className='text-decoration-none' to={`${this.props.user.data ? '/dashboard' : '/'}`}>
            <div className='intelPay-logo'>
              <img src={Logo} alt='Intel Pay Logo' className='intelpay-logo-icon'></img>
            </div>
          </Link>          
              
                <div className='setup-logout'>{
                  this.props.userLoggedIn || this.props.user.data !== null?
                  <LogoutLink 
                    logout = {this.props.logout}
                    cancelFingerEnrollment = {this.props.cancelFingerEnrollment}
                    enrollmentState = { this.props.security.enrollmentState}
                    authState = {this.props.security.authState}
                    devicePort = {this.props.devicePortDiscovery.devicePort}
                    />
                  :<></>
                  }</div>
          </Row>
          </div>
          :
          <Row className="navigation ">
            <Col className='header header-margin'>
              <div className='d-flex justify-content-between ' style={{height:"78px"}}>
                {/* logo */}
                <Col sm={3} style={{paddingLeft:'0'}}>
                <Link className='text-decoration-none ' to={`${this.props.user.data ? '/dashboard' : '/'}`}>
                  <div className='intelPay-logo'>
                      <img src={Logo} alt='Intel Pay Logo'></img>
                  </div>
                </Link> 
                </Col>
                {/* link */}
                <Col sm={9} className="header-wrapper d-flex justify-content-between">
                <>
                {
                    !this.props.devices.connected || !this.props.devices.connected.enrolledFlag?
                      <div className="active p-4" ></div>
                    :
                    <div className='d-flex align-items-center '>
                      <CardsLink className="header-link-text"/>
                      <ContactsLink className="header-link-text"/>
                      <SecurityLink className="header-link-text"/>
                    </div>
                  }
                </>
                
                {/* sign out and name account */}
                <div className="d-flex justify-content-between align-items-center">
                    <AccountLink className="account-margin-right" icon={this.props.user.showAccount} />
                    <div className='d-flex align-items-center'>
                      {
                        !this.state.editEnable?
                          <div className='header-text'>{this.props.devices.connected?this.props.devices.connected.displayName:''}&nbsp;&nbsp;&nbsp;{this.props.devices.connected && (this.props.devices.connected.state === "LOCKED" || this.props.devices.connected.pendingState === "LOCKED")?<span className='locked-status'>LOCKED</span> :''}</div>
                          :
                          <>
                              <input 
                              className="input-text" 
                              placeholder={this.props.devices.connected.displayName} 
                              defaultValue={this.props.devices.connected.displayName ? this.props.devices.connected.displayName : "-"} 
                              onKeyDown={this.handleOnSubmit} 
                              onBlur={this.handleOnSubmit}
                              onChange={this.handleValidateDeviceName}
                              onClick={this.handleValidateDeviceName}
                              type='text' autoFocus/>
                              <div 
                                className="input-text-error">
                                  {
                                    this.state.updateDeviceNameError != "" || this.props.devices.error ?
                                    this.state.updateDeviceNameError
                                    :
                                    ""
                                  }
                              </div>
                          </>
                      }
                      { 
                        (!this.state.editEnable && this.props.devices.connected)?
                          <Button className="account-edit-icon d-flex" onClick={this.handleClickEditButton}><TiEdit className="edit-button" size="1rem"/></Button>: ''
                      }
                    </div>

                    <LogoutLink 
                      className = "header-text"
                      style ={{marginLeft:'30px'}}
                      logout = {this.props.logout}
                      cancelFingerEnrollment = {this.props.cancelFingerEnrollment}
                      enrollmentState = { this.props.security.enrollmentState}
                      authState = {this.props.security.authState}
                      devicePort = {this.props.devicePortDiscovery.devicePort} />
                </div>
                </Col>
              </div>
            </Col>
          </Row>
        }

            {
              this.state.loading ? 
              Loading()
              :
              <Switch>
                <Route exact path="/" component={Home}/>
                <Route path="/login" component={Login}/>
                <Route path="/forgot-password" component ={ForgotPassword}/>
                <Route path="/reset-password" component ={ResetPassword}/>
                <Route path="/register" component={Register}/>
                <Route path="/registerUser" component={RegisterUser}/>
                {/* <Route path="/registerCode" component={RegisterCode}/> */}
                <Route path="/dashboard" component={Dashboard}/>
                <Route path="/register-device" component={RegisterDevice}/>
                <Route path="/add-card" component={AddCard}/>
                <Route path="/cards" component={Cards}/>
                <Route path="/account-settings" component={Account}/>
                <Route path="/security" component={Security}/>
                <Route path="/add-fp" component={AddFingerprint}/>
                <Route path="/add-pin" component={AddPIN}/>
                <Route path="/contacts" component={Contacts}/>
                <Route path="/add-billing" component={AddBillingAddress}/>
                <Route path="/add-shipping" component={AddShippingAddress}/>
                <Route path="/pcwallet-promo" component={PCWalletPromo}/>
                <Route path="/device-dashboard" component={Device}/>
                <Route component={Home}/>
              </Switch>
            }
            <div className="footer">
              <div className='d-flex p-2'>
              <img className="footer-img" src={Logo} alt='Intel Pay Logo'></img>
              <div className="text-left footer-links">
                {/* <p>Intel Corporation &copy;</p> */}
                <div className ="link" onClick={()=>{window.open("https://www.intel.com/content/www/us/en/legal/terms-of-use.html")}}>Terms of Use</div>
                <div className ="link" onClick={()=>{window.open("https://www.intel.com/content/www/us/en/privacy/intel-privacy-notice.html")}}> Privacy </div>
                <div className ="link" onClick={()=>{window.open("https://www.intel.com/content/www/us/en/privacy/intel-cookie-notice.html")}}> Cookies </div>
              </div>
              <div className="footer-content">
                Intel Pay stores an encrypted ID on your device that is shared during purchase transactions. Intel Pay does not store or share your credit card information. Your card will not be charged when enrolling in Intel Pay. Intel Pay uses fingerprint authentication, which is stored encrypted on your device and used to verify your identity for each purchase.
                Intel technologies may require enabled hardware, software or service activation. No product or component can be absolutely secure. Your costs and results may vary. Performance varies by use, configuration and other factors. See our complete <span className ="link m-0" onClick={()=>{window.open("https://www.intel.com/LegalNoticesAndDisclaimers")}}>Legal Notices and Disclaimers</span>.
                   Intel is committed to respecting human rights and avoiding complicity in human rights abuses. See <span className ="link m-0" onClick={()=>{window.open("https://www.intel.com/content/www/us/en/policy/policy-human-rights.html")}}>Intel’s Global Human Rights Principles</span>.
                  Intel’s products and software are intended only to be used in applications that do not cause or contribute to a violation of an internationally recognized human right.
              </div>
              </div>
            </div>
      </div>
      <Modal 
        show={this.state.showModal} 
        onHide={this.handleClose}
        backdrop="static"
        keyboard={false}
      >
        <Modal.Header className="justify-content-center">
          <b>Session Timeout Warning</b><br/>
        </Modal.Header>
        <Modal.Body>
            <div className="p-4 text-left">
              Your session is about to end due to inactivity. Select the <b>Extend</b> button to continue browsing the site or <b>Logout</b> button if finished.
            </div>
            <div className="button-wrapper">
              <div className='d-flex justify-content-between'>
                <button className="btn btn-outline-primary btn-lg button-outline" onClick={this.handleLogout} >
                  Logout
                </button>
                <button className="btn btn-primary btn-lg button" onClick={this.handleClose}>
                  Extend
                </button>
              </div>
            </div>
        </Modal.Body>
          
      </Modal>
      </HashRouter>
    )
  }
}

const mapStateToProps = state => ({
  user: state.user,
  global: state,
  userIdleFlag: state.user.userIdle,
  userLoggedIn: state.user.login,
  otpVerified:state.verify.verified,
  deviceVerified:state.user.verified,
  devices:state.devices,
  devicePortDiscovery: state.devicePortDiscovery,
  security:state.security
})

const mapDispatchToProps = dispatch => ({
  login: (data) => dispatch(login),
  logout: () => dispatch(logout),
  getUserDetails: (data) => dispatch(getUserDetails(data)),
  userIdle:()=>dispatch(userIdle),
  timedout: () => dispatch(timedout),
  queryStatus:(data)=>dispatch(queryStatusFromDevice(data)),
  extendSession:()=>dispatch(extendSession),
  updateDevice:(data)=>dispatch(updateDevice(data)),
  startDevicePortDiscovery:(data)=>dispatch(startDevicePortDiscovery(data)),
  cancelFingerEnrollment: (data) => dispatch(cancelFingerEnrollment(data)),

})

export default connect(mapStateToProps, mapDispatchToProps)(App)