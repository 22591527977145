/*
 * INTEL CONFIDENTIAL
 * Copyright 2022 Intel Corporation.
 * This software and the related documents are Intel copyrighted materials, and
 * your use of them is governed by the express license under which they were
 * provided to you (License). Unless the License provides otherwise, you may not
 * use, modify, copy, publish, distribute, disclose or transmit this software or
 * the related documents without Intel's prior written permission. This software
 * and the related documents are provided as is, with no express or implied
 * warranties, other than those that are expressly stated in the License.
 */
import React, { Component } from 'react'
import { connect } from 'react-redux'
import { Formik, Form } from 'formik';
import * as Yup from 'yup';

import { updateUserDetails, getUserDetails } from '../../actions/user'
import {emailRegEx, emailLengthValidator, alphabetsOnlyRegEx}  from '../../validationEngine'
import { analyticsPageView } from '../../utils'

import Button from '../inputs/Button';
import TextInput from '../inputs/TextInput';

export class UserDetailsContainer extends Component {

  componentDidMount() {
    this.props.getUserDetails()
    //initializing analytics
    analyticsPageView('/account');
  }

  render() { 

    return (
      <div className="mt-5 px-3">
        <Formik
          initialValues={{
            email: this.props.authData.email || '',
            givenName: this.props.authData.givenName || '',
            familyName: this.props.authData.familyName || '',
          }}
          validationSchema={Yup.object({
              email: Yup.string()
                .email('invalid email address')
                .test('valid email length','maximum character length exceeded',function(value){
                    return emailLengthValidator(value)
                  })
                .matches(emailRegEx,'email contains invalid character'),
              givenName: Yup.string()
                .max(20)
                .matches(alphabetsOnlyRegEx,'field contains invalid character'),
              familyName: Yup.string()
                .max(40)
                .matches(alphabetsOnlyRegEx,'field contains invalid character')
          })}

          onSubmit={(values, { setSubmitting}) => {
              this.props.updateUserDetails({
                userId: this.props.authData.id,
                email: values.email.trim(),
                givenName: values.givenName.trim(),
                familyName: values.familyName.trim(),
              })
              setSubmitting(false)
          }}
        >
          {({ isValid, dirty, isSubmitting, setFieldValue }) => (
            <Form>
              <TextInput
                label='First name'
                name='givenName'
                type='text'
              />
              <TextInput
                label='Last name'
                name='familyName'
                type='text'
              />
              <TextInput
                disabled={true}
                label='Email Address'
                name='email'
                type='email'
                placeholder='email@website.com'
              />
              <div className="button-wrapper d-flex justify-content-end">
                  <div>
                    <Button
                      disabled={isSubmitting || !dirty || !isValid || this.props.user.showSuccess || this.props.user.isLoading}
                      type="submit">
                        {this.props.user.isLoading && <span className="spinner-border spinner-border-sm mr-1" role="status" aria-hidden="true" />}
                        Update</Button>                  
                  </div>
              </div>  
            </Form>
          )
        }
        </Formik>
    </div>
    )
  }

}

const mapStateToProps = state => ({
    user: state.user,
    authData: state.user.data
})

const mapDispatchToProps = dispatch => ({
  updateUserDetails: (data) => dispatch(updateUserDetails(data)),
  getUserDetails: (data) => dispatch(getUserDetails(data)),
})

export default connect(mapStateToProps, mapDispatchToProps)(UserDetailsContainer)
