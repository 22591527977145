/*
 * INTEL CONFIDENTIAL
 * Copyright 2022 Intel Corporation.
 * This software and the related documents are Intel copyrighted materials, and
 * your use of them is governed by the express license under which they were
 * provided to you (License). Unless the License provides otherwise, you may not
 * use, modify, copy, publish, distribute, disclose or transmit this software or
 * the related documents without Intel's prior written permission. This software
 * and the related documents are provided as is, with no express or implied
 * warranties, other than those that are expressly stated in the License.
 */
import React from 'react'
import { useField } from 'formik'
import {Row, Col} from 'react-bootstrap'
import NumberFormat from 'react-number-format';
import { StrengthIndicator } from '../shared/StrengthIndicator'
import { AiFillEye, AiOutlineEyeInvisible } from 'react-icons/ai'


const TextInput = ({ label, ...props }) => {
    const [field, meta] = useField(props);
  const { toggleshow, error, ...restProps} = props
    return (
      <Row className='align-items-center'>
        <Col className={props.name==="cvv"?"pl-4 pr-0":""}>
          <label className={(props.showstar) ? "required pt-3 text-capitalize form-label-font" : 'form-label-font pt-3 text-capitalize'} htmlFor={props.id || props.name}>{label}</label>
        </Col>
        <Col sm={props.sm?props.sm:9} className={props.name==="cvv"?"pl-0":""}>
          <Row className='mx-0 w-100'>
            {
              meta.touched && meta.error ?
                <div className="error">{meta.error}</div>
                : props.errorMessage ?
                  <div className="error">{props.errorMessage}</div>
                  :
                  <div className="valid"></div>
            }
          </Row>
          
          <div className='d-flex align-items-flex-start'>
            {
              props.format ?
                <NumberFormat className={["text-input", meta.error ? "text-input-error-border" : ""].join(' ')} format={props.format} {...field} {...props}></NumberFormat>
                :
                <input className={["text-input", (meta.error && meta.touched) ? "text-input-error-border" : ""].join(' ')} {...field} {...restProps} />
            }
            {
              props.name === "password" || props.name === "confirmPassword" || props.name === "currentPassword" || props.name === "newPassword" ||
                props.name === "pincode" || props.name === "confirmPincode" || props.name === "currentPincode" ?
                <span className="pass-show-icon margin-pass-show-icon" onClick={props.toggleshow}>
                  {
                    props.hidestate === "true" ? <AiFillEye size="1.5em" color="#007bff" /> : <AiOutlineEyeInvisible size="1.5em" color="#007bff" />
                  }
                </span>
                :
                <></>
            }
          </div>
          <Row sm ={7} className='mx-0 my-2 w-100'>
            <Row className='mx-0 w-100'>
              {
                props.name === 'pincode' || props.fieldname === 'password'?
                  <StrengthIndicator className="w-100" result={props.result} label={props.name === 'pincode'? "PIN Strength" : "Password Strength"}/>
                :
                <></>
              }
            </Row>
            
          </Row>
        </Col> 
      </Row>
    );
  };

  export default TextInput