/*
 * INTEL CONFIDENTIAL
 * Copyright 2022 Intel Corporation.
 * This software and the related documents are Intel copyrighted materials, and
 * your use of them is governed by the express license under which they were
 * provided to you (License). Unless the License provides otherwise, you may not
 * use, modify, copy, publish, distribute, disclose or transmit this software or
 * the related documents without Intel's prior written permission. This software
 * and the related documents are provided as is, with no express or implied
 * warranties, other than those that are expressly stated in the License.
 */
import * as constants from '../constants'
import * as messages from '@pcwallet/common/endUserMessages'

import { getCsrfCookieValue, customNumberParser } from "@pcwallet/common/utils"
import { stringify, parse } from 'lossless-json'
import { refreshToken } from './user'

export const requestVerificationCode = (data) => (dispatch) => {
    dispatch({
        type: constants.VERIFY_REQUEST_CODE
    })
    const cookieValue = getCsrfCookieValue()
    fetch(`${constants.INUS_ADDRESS}/us/v1/sendOtp`, {
        method: 'POST',
        credentials: 'include',
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            'X-XSRF-TOKEN' : `${cookieValue ? cookieValue : ''}`
        },
        body: stringify({
            otpPurpose: data.otpPurpose
        })
    })
    .then((response) => {
        if (response.status === 401) {
            dispatch({
                type: constants.ACCESS_TOKEN_INVALID,
            })
            throw response.status
        }
        else {
            return response.text()
        }
        
    })
    .then((response) => {
        return parse(response, null, customNumberParser)
    })
    .then((response) => {
        if (response.errorCode !== "OK") {
            dispatch({
                type: constants.VERIFY_REQUEST_CODE_FAILED,
                payload: response.message ? response.message : messages.VERIFY_REQUEST_CODE_FAILED
            });
        }
        else {
            dispatch({
                type: constants.VERIFY_REQUEST_CODE_SUCCESS,
            });
           
            if(data.resendCode){
                data.callback()
            }
        }
    })
    .catch(error => {
        if (error !== 401) {
            dispatch({
                type: constants.VERIFY_REQUEST_CODE_FAILED,
                payload: messages.VERIFY_REQUEST_CODE_FAILED
            });
        }
    })
}

export const mockVerifySent = dispatch => {
    dispatch({
        type: constants.VERIFY_REQUEST_CODE_SUCCESS,
    });
}

export const verify = data => dispatch => {
    dispatch({
        type: constants.VERIFY_SUBMIT_CODE
    })

    let formData = new FormData();
    formData.append('code', data.code);
    const cookieValue = getCsrfCookieValue()
    fetch(`${constants.INUS_ADDRESS}/us/v1/verifyOtp`, {
        method: 'POST',
        credentials : 'include',
        headers: {
            'Accept': 'application/json',
            'X-XSRF-TOKEN' : `${cookieValue ? cookieValue : ''}`
        },
        body: formData
    })
    .then((response) => {
         if (response.status === 401) {
            dispatch({
                type: constants.ACCESS_TOKEN_INVALID,
            })
            throw response.status
        }
        else {
            return response.text()
        }
    })
    .then((response) => {
        return parse(response, null, customNumberParser)
    })
    .then((response) => {
        if (response.errorCode !== "OK") {
            dispatch({
                type: constants.VERIFY_SUBMIT_CODE_FAILED, 
                payload: response.message ? response.message : messages.VERIFY_SUBMIT_CODE_FAILED
            });
        } else {
            dispatch({
                type: constants.VERIFY_SUBMIT_CODE_SUCCESS,
            });

            // start to retrieve new access_token and refresh_token
            // in 9 minutes which is 1 minute before access_token expires.
            setTimeout(()=>{
                refreshToken(dispatch);
            }, 60000 * 9);

            if(data.fp){
                dispatch({
                    type: constants.START_FP_ADD
                })
                data.history.push('/add-fp')
            } else{
                data.callback();
            }
        }
    }).catch(error => {
            dispatch({
                type: constants.VERIFY_SUBMIT_CODE_FAILED,
                payload: messages.VERIFY_SUBMIT_CODE_FAILED
            });
    })
}

export const resetVerifyReducerState = dispatch => {
    dispatch({
        type: constants.VERIFY_RESET
    })
}