/*
 * INTEL CONFIDENTIAL
 * Copyright 2022 Intel Corporation.
 * This software and the related documents are Intel copyrighted materials, and
 * your use of them is governed by the express license under which they were
 * provided to you (License). Unless the License provides otherwise, you may not
 * use, modify, copy, publish, distribute, disclose or transmit this software or
 * the related documents without Intel's prior written permission. This software
 * and the related documents are provided as is, with no express or implied
 * warranties, other than those that are expressly stated in the License.
 */
export const inputRegEx = /^[a-zA-Z0-9.\-_\s+]+$/
export const alphabetsOnlyRegEx = /^[A-Za-z ]+$/
export const digitOnlyRegEx = /^[0-9 ]+$/
export const emailRegEx = /^[a-zA-Z0-9.\-_\s@+]+$/
// https://stackoverflow.com/questions/19605150/regex-for-password-must-contain-at-least-eight-characters-at-least-one-number-a
// export const passwordRegex = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@#$!%^&*?\-\+\(\)\_])[A-Za-z\d@#$!%^*?&\-\+\(\)\_]{8,64}$/
export const notAllowedPswRegex = /[^a-zA-Z0-9@#$!%^&*?\-\+\(\)\_]/
export const passwordRegex = /^[a-zA-Z0-9!@#$%^&*()_+\-]+$/
export const phoneRegEx = /^[0-9 ()-]+$/

export const emailCharacterValidator = (email)=>{

    if(email){
        const local = email.split("@")[0];
        if(!local.match(inputRegEx)){
            return false
        }
    }
    return true
}

export const emailLengthValidator = (email)=>{
    if(email){    
        const local = email.split("@")[0];
        if(email.length >254){
            return false
        }
        else{
            if(local.length > 64){
                return false
            }
        }
    }
    return true

}

export const checkPhoneLength = (data) =>{
    if(data) 
    {
        let d = data.trim().replace(/[()-/ ]/gi,'')
        if(d.length >= 11 || d.length < 10){
            return false
        }
        else{
            return true
        }
    }
    return true
}

export const checkCCNlength = (data) =>{
    if(data) 
    {
        let d = data.trim().replace(/ /g, '')
        if(d.length > 16 || d.length < 15){
            return false
        }
        else{
            return true
        }
    }
    return true
}

