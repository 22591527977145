/*
 * INTEL CONFIDENTIAL
 * Copyright 2022 Intel Corporation.
 * This software and the related documents are Intel copyrighted materials, and
 * your use of them is governed by the express license under which they were
 * provided to you (License). Unless the License provides otherwise, you may not
 * use, modify, copy, publish, distribute, disclose or transmit this software or
 * the related documents without Intel's prior written permission. This software
 * and the related documents are provided as is, with no express or implied
 * warranties, other than those that are expressly stated in the License.
 */
import React, { Component } from 'react'

import { Toast } from 'react-bootstrap'
import {AiFillCheckCircle} from 'react-icons/ai'


const Notification = (props) => {
    return (
        <div className='toast-div'>
            <Toast 
                className="rounded"
                onClose={props.hide} 
                show={props.show} 
                delay={5000} 
                autohide 
            >
            <div className='banner-header justify-content-between text-left'>
            <AiFillCheckCircle size="1.5em" color="#155724"/>
            <strong className='px-3'>{props.msg}</strong>
            </div>
            </Toast>
        </div>
    )
    
}

export default Notification