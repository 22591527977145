/*
 * INTEL CONFIDENTIAL
 * Copyright 2022 Intel Corporation.
 * This software and the related documents are Intel copyrighted materials, and
 * your use of them is governed by the express license under which they were
 * provided to you (License). Unless the License provides otherwise, you may not
 * use, modify, copy, publish, distribute, disclose or transmit this software or
 * the related documents without Intel's prior written permission. This software
 * and the related documents are provided as is, with no express or implied
 * warranties, other than those that are expressly stated in the License.
 */
import React, { Component } from 'react'
import { connect } from 'react-redux'
import { Formik, Form} from 'formik';
import * as Yup from 'yup';
import { Container} from 'react-bootstrap';

import {inputRegEx, emailLengthValidator, checkPhoneLength, emailRegEx, phoneRegEx, alphabetsOnlyRegEx } from '../../validationEngine'
import { analyticsPageView } from '../../utils';
import countries from '@pcwallet/common/assets/countries'
import * as constants from '@pcwallet/common/constants'
import { getNextComponent, getPreviousComponent ,isStepCompleted } from './WalletSetupProgressUtil';
import { getCardListFromServer } from '../../actions/cards';
import { cancelAddContact, cancelUpdateContact, addContactFromServer, updateContactFromServer, updateDefaultContactFromServer, resetContactErrorMessages, getContacts, linkBillingtoCard, startAddContact } from '../../actions/contacts'
import { getDevices, queryStatusFromDevice, reportWalletSetupProgress } from '../../actions/devices'
import usStates from '@pcwallet/common/assets/states';

import Button from '../inputs/Button';
import CheckBox from '../inputs/CheckBox';
import ErrorMessage from '../shared/ErrorMessage';
import Loading from '../shared/Loading'
import SelectContact from './SelectContact';
import SelectInput from '../inputs/SelectInput';
import SetupProgressIndicator from '../shared/SetupProgressIndicator';
import TextInput from '../inputs/TextInput';


const countryList=() => {
    
    let list = countries.map(country => {
        return {
            value: country.countryCode,
            label: country.countryName
        }
    })
        return list
}

const usStateList= () => {
    
    let list = usStates.map(usState => {
        return {
            value: usState.stateCode,
            label: usState.stateName.toLowerCase()
        }
    })
    return list
}

export class AddBillingAddress extends Component {

    constructor(props) {
        super(props);
        this.state = {
            proceedTo:{componentUrl:'/contacts'},
            backTo:{componentUrl:'/contacts'},
            billingEnrolled:false,
            cardDigitized:false,
            setup:false,
            contactId:null,
            isShippingEnrolled:false
        }    
        this.handleNavigation = this.handleNavigation.bind(this)
        this.syncWalletSetupProgress = this.syncWalletSetupProgress.bind(this)
        this.defaultSelectedAddress = this.defaultSelectedAddress.bind(this)

      }
    
      
    componentDidMount() {
        //initializing analytics
        analyticsPageView('/add-billing')
        if(!this.props.devices.connected){
            const { history } = this.props;
            this.props.queryStatus({
                history,
                detectDevice:true,
                devicePort: this.props.devicePortDiscovery.devicePort,
            })
        }
        else{
            if(this.props.devices.connected && this.props.devices.connected.walletSetupProgress){
                let billingEnrolled = isStepCompleted(constants.BILLING_ADDRESS_ENROLL,this.props.devices.connected.walletSetupStatus);
                this.setState({billingEnrolled : billingEnrolled})
                let isShippingEnrolled = isStepCompleted(constants.SHIPPING_ADDRESS_ENROLL,this.props.devices.connected.walletSetupStatus)
                this.setState({isShippingEnrolled : isShippingEnrolled})
                if(this.props.devices.connected.walletSetupProgress !== constants.DONE) {
                this.setState({setup : true})
                let cardDigitized = isStepCompleted(constants.CARD_DIGITIZE,this.props.devices.connected.walletSetupStatus);
                this.setState({cardDigitized : cardDigitized})
                    if(!cardDigitized) {
                        this.syncWalletSetupProgress(constants.SHIPPING_ADDRESS_ENROLL)
                    }                    
                    else if(!billingEnrolled) {
                        if(this.props.cards && this.props.cards.activeCard) {
                            this.syncWalletSetupProgress(constants.BILLING_ADDRESS_ENROLL)
                        }
                        else{
                            // tokenId not available get card list
                            this.props.getCardListFromServer({
                                deviceId:this.props.devices.connected.deviceId,
                                walletSetupProgress:constants.BILLING_ADDRESS_ENROLL,
                                billingEnrolled:billingEnrolled,
                                callback: () => {
                                    this.syncWalletSetupProgress(constants.BILLING_ADDRESS_ENROLL)
                                }
                            })
                        }
                    }
                    else {
                        if(this.props.contacts.length >= 1) {
                            this.syncWalletSetupProgress(constants.SHIPPING_ADDRESS_ENROLL)
                        }
                        else {
                            // getContacts for prefill
                            this.props.getContacts({
                                deviceId:this.props.devices.connected.deviceId,
                                publicId:this.props.devices.connected.publicId,
                                walletSetupProgress:this.props.devices.connected.walletSetupProgress,
                                devicePort: this.props.devicePortDiscovery.devicePort,
                                callback:()=> {
                                    this.syncWalletSetupProgress(constants.SHIPPING_ADDRESS_ENROLL)
                                }
                            })
                        }
                    }
                }                             
            } 
        }
    }

    syncWalletSetupProgress(data) {
        this.props.reportWalletSetupProgress({
            deviceId:this.props.devices.connected.deviceId,
            walletSetupProgress:data,
            callback:() => {
                let walletSetupProgress = this.props.devices.connected.walletSetupProgress;
                let proceedTo = getNextComponent(this.props.devices.connected.walletSetupStatus, walletSetupProgress);
                this.setState({proceedTo : proceedTo})         
                let backTo = getPreviousComponent(this.props.devices.connected.walletSetupStatus, walletSetupProgress)             
                this.setState({backTo : backTo})
                this.setState({isLoading:false}) 
            }
        })
    }

    handleNavigation() {
        const { history } = this.props;
        if(this.props.devices.connected.walletSetupProgress !== constants.DONE){
            this.props.reportWalletSetupProgress({
                deviceId:this.props.devices.connected.deviceId,
                walletSetupProgress:this.state.proceedTo.setupStatus,
                callback:()=>{                                                     
                    history.push(this.state.proceedTo.componentUrl)
                    if(this.props.devices.connected && this.props.devices.connected.walletSetupProgress === constants.SHIPPING_ADDRESS_ENROLL){
                        let walletSetupProgress = this.props.devices.connected.walletSetupProgress;
                        let proceedTo = getNextComponent(this.props.devices.connected.walletSetupStatus, walletSetupProgress);
                        this.setState({proceedTo : proceedTo});
                        let backTo = getPreviousComponent(this.props.devices.connected.walletSetupStatus, walletSetupProgress)
                    }
                }
            })
        }
        else {
            //get updated walletSetupStatus
            if(this.props.devices.connected.walletSetupStatus < 127 && (!this.state.billingEnrolled || !this.state.isShippingEnrolled)) {
                this.props.queryStatus({
                    devicePort: this.props.devicePortDiscovery.devicePort,                    
                })
            }
             this.props.history.push(this.state.proceedTo.componentUrl)
        }
    }

    defaultSelectedAddress() {
        let activeBillingAddress = null
        let defaultCard = this.props.cards.cards.find((card)=>{return card.defaultPaymentMethod})
        if(defaultCard && defaultCard.billingContactId){
            this.setState({contactId: defaultCard.billingContactId})
            activeBillingAddress = this.props.contacts.contacts.find(contact =>{return contact.id === defaultCard.billingContactId})
            return activeBillingAddress
        }
        else if (this.props.contacts.contacts.length>0) {
            let shippingAddress = this.props.contacts.contacts[0]
            shippingAddress = this.props.contacts.contacts.find(contact =>{return (contact.defaultShipping === true)})
            return shippingAddress
        }
        return activeBillingAddress
    }

    componentDidUpdate(prevProps){
        if(this.props.devices.connected && (this.props.devices.connected.walletSetupProgress !== prevProps.devices.connected.walletSetupProgress || this.props.devices.connected.walletSetupStatus !== prevProps.devices.connected.walletSetupStatus)){
            // if(this.props.devices.connected.walletSetupProgress !== constants.DONE) {
                let cardDigitized = isStepCompleted(constants.CARD_DIGITIZE, this.props.devices.connected.walletSetupStatus);
                this.setState({cardDigitized : cardDigitized})
                let billingEnrolled = isStepCompleted(constants.BILLING_ADDRESS_ENROLL, this.props.devices.connected.walletSetupStatus);
                this.setState({billingEnrolled : billingEnrolled})
                let isShippingEnrolled = isStepCompleted(constants.SHIPPING_ADDRESS_ENROLL,this.props.devices.connected.walletSetupStatus)
                this.setState({isShippingEnrolled : isShippingEnrolled})
            // }
        }
        if(this.props.contacts.addContact !== prevProps.contacts.addContact) {
            console.log("addContact updated")
        }
        if(this.props.contacts.error !== prevProps.contacts.error){
            console.log("errors updated")
        }

    }

    componentWillUnmount() {
        if(this.props.contacts.error){
            this.props.resetContactErrorMessages();
        }
    }

    render() {
        const { history } = this.props;
        const countries = countryList();
        return (
            <div className="wrapper">
                <div className="content">
                    {
                        this.props.devices.isLoading || this.props.cards.isLoading || this.props.contacts.isLoading ?
                        Loading()
                        :
                        <div>
                            {
                                this.state.setup && this.props.devices.connected && (this.props.devices.connected.walletSetupProgress === constants.BILLING_ADDRESS_ENROLL || this.props.devices.connected.walletSetupProgress === constants.SHIPPING_ADDRESS_ENROLL)?
                                <SetupProgressIndicator 
                                    devices={this.props.devices}
                                    isStepCompleted={isStepCompleted}
                                    />
                                :
                                <></>
                            }
                            {
                                this.props.contacts.error && this.props.contacts.errorMessage?
                                <ErrorMessage
                                    error = {true}
                                    show = {this.props.contacts.error}
                                    autohide = {false}
                                    msg = {this.props.contacts.errorMessage}/>
                                    :<></>
                            }
                        <div className="main-wrapper">
                            <div className='d-flex align-items-center justify-content-between mt-4 pt-2 mx-5'>
                                <h1 className="m-0">
                                {
                                    (this.state.setup && !this.state.billingEnrolled && this.state.cardDigitized) || (!this.state.setup && (this.props.contacts.addContact && ( !this.state.billingEnrolled || this.props.contacts.selectNewBilling)))?
                                    `Add Billing Address`
                                    :(!this.props.contacts.addContact && this.props.contacts.updateContact)?
                                    `Update Address`
                                    : 
                                    `Add Address`
                                }
                                </h1>
                                {
                                    this.state.setup && (this.state.billingEnrolled || !this.state.cardDigitized)?
                                    <div
                                    className="button-tertiary-outline"
                                    type="button"
                                    onClick = {()=>{
                                        this.props.reportWalletSetupProgress({
                                            deviceId:this.props.devices.connected.deviceId,
                                            walletSetupProgress:constants.DONE,
                                            callback:()=>{
                                                history.push(this.state.proceedTo.componentUrl)
                                            }
                                        })
                                        }}
                                    ><b>Skip</b>
                                    </div>
                                    :
                                    <></>
                                }
                                </div>
                                <p className="divider my-4"/>
                                <div className="px-5 pb-5">
                                {
                                    (!this.state.setup && !this.props.contacts.addContact &&  this.props.contacts.selectNewBilling && this.props.cards.cardState === constants.CARD_STATE_ADDED_SUCCESSFULLY && this.props.contacts.contacts.length > 0)?
                                    <div>
                                        <div className='pb-4'>Select a billing address or add a new one.</div>
                                        <SelectContact
                                            addCardSuccess = {this.props.cards.cardState === constants.CARD_STATE_ADDED_SUCCESSFULLY}
                                            tokenId={this.props.cards.tokenId}
                                            // activeBillingContact={this.defaultSelectedAddress}
                                            onNewBillingSelected = {(data)=>{
                                                this.setState({contactId: data.contactId})
                                            }}
                                            onAddNewBilling={()=>{
                                                this.props.startAddContact({
                                                    updateTokenContact:true,
                                                    selectNewBilling:true,
                                                    addContact: true,
                                                    tokenId:this.props.contacts.tokenId,
                                                    cardState:this.props.cards.cardState,
                                                    callback:()=>{
                                                        history.push('/add-billing')
                                                    }            
                                                })
                                            }}
                                            />
                                        <div className="button-wrapper d-flex justify-content-between">
                                            <button 
                                                type='button'
                                                className="btn btn-outline-primary btn-lg button-outline" 
                                                    onClick={() => {
                                                        this.props.contacts.addContact ? 
                                                        this.props.cancelAddContact({
                                                            updateTokenContact:this.props.contacts.updateTokenContact,
                                                            history
                                                        })
                                                        :
                                                        this.props.cancelUpdateContact({
                                                            history
                                                        })
                                                    }}>
                                                    Cancel
                                            </button>                                                            
                                            <Button
                                                onClick={()=>{
                                                    let defaultBillingAddress = this.defaultSelectedAddress();
                                                    if(this.props.contacts.tokenId) {
                                                        this.props.linkBillingtoCard({
                                                            selectNewBilling:true,
                                                            updateTokenContact:true,
                                                            contactId: this.state.contactId ? this.state.contactId :defaultBillingAddress?defaultBillingAddress.id:'',
                                                            tokenId: this.props.contacts.tokenId,
                                                            deviceId:this.props.devices.connected.deviceId,
                                                            publicId:this.props.devices.connected.publicId,
                                                            devicePort:this.props.devicePortDiscovery.devicePort,
                                                            cardState:this.props.cards.cardState,
                                                            history
                                                        })
                                                    }
                                                    else{
                                                        history.push('/cards')
                                                    }
                                                }}
                                            >
                                                {this.props.contacts.isLoading && <span className="spinner-border spinner-border-sm mr-1" role="status" aria-hidden="true" />}
                                                Continue
                                            </Button>
                                        </div>                                                        
                                    </div>
                                    :
                                    <Formik
                                        initialValues={{                  
                                            givenName:this.state.setup && this.props.userData && this.props.userData.givenName? this.props.userData.givenName: this.props.contacts.activeContact && !this.props.contacts.addContact?this.props.contacts.activeContact.givenName :'',
                                            familyName:this.state.setup && this.props.userData && this.props.userData.familyName ?this.props.userData.familyName: this.props.contacts.activeContact && !this.props.contacts.addContact? this.props.contacts.activeContact.familyName :'',
                                            addressLine1:  (this.state.setup || this.props.contacts.updateContact) && this.props.contacts.activeContact? this.props.contacts.activeContact.addressLine1 : '',
                                            addressLine2: (this.state.setup || !this.props.contacts.addContact) && this.props.contacts.activeContact? this.props.contacts.activeContact.addressLine2 : '',
                                            city: (this.state.setup || !this.props.contacts.addContact) && this.props.contacts.activeContact? this.props.contacts.activeContact.city : '',
                                            state: (this.state.setup || !this.props.contacts.addContact) && this.props.contacts.activeContact? this.props.contacts.activeContact.state : '',
                                            country: (this.state.setup || !this.props.contacts.addContact) && this.props.contacts.activeContact ? this.props.contacts.activeContact.country : countries[0].value,
                                            zip: (this.state.setup || !this.props.contacts.addContact) && this.props.contacts.activeContact? this.props.contacts.activeContact.postalCode : '',
                                        }}
                                        validationSchema={Yup.object().shape({
                                            givenName: Yup.string()
                                                .max(20)
                                                .matches(alphabetsOnlyRegEx,'field contains invalid character')
                                                .notRequired(),
                                            familyName: Yup.string()
                                                .max(40)
                                                .matches(alphabetsOnlyRegEx,'field contains invalid character')
                                                .notRequired(),
                                            addressLine1: Yup.string()
                                                .max(64)
                                                .matches(inputRegEx,'field contains invalid character')
                                                .required('Required'),
                                            addressLine2: Yup.string()
                                                .max(64)
                                                .matches(/^[a-zA-Z0-9.\-_\s+#]+$/,'field contains invalid character'),
                                            city: Yup.string()
                                                .max(64)
                                                .matches(inputRegEx,'field contains invalid character')
                                                .required('Required'),
                                            state: Yup.string()
                                                .max(64)
                                                .matches(inputRegEx,'field contains invalid character')
                                                .required('Required'),
                                            country: Yup.string()
                                                .max(64)
                                                .matches(inputRegEx,'field contains invalid character')
                                                .required('Required'),
                                            zip: Yup.number()
                                                .required('Required'),
                                        })}

                                        onSubmit={(values, { setSubmitting }) => {
                                            let contact = {
                                                    id:  this.props.contacts.activeContact && this.props.contacts.updateContact?this.props.contacts.activeContact.id:null,
                                                    givenName:  values.givenName.trim() ,
                                                    familyName: values.familyName.trim(),
                                                    addressLine1: values.addressLine1.trim(),
                                                    addressLine2: values.addressLine2?values.addressLine2.trim():'',
                                                    city: values.city.trim(),
                                                    state: values.state.trim(),
                                                    country: values.country.trim(),
                                                    postalCode: values.zip.trim()
                                                };
                                            
                                            if(this.props.contacts.addContact || (!this.state.setup && !this.state.billingEnrolled)){
                                                    console.log("addContactFromServer")
                                                    this.props.addContactFromServer({
                                                        contact: contact,
                                                        setup:this.state.setup,
                                                        walletSetupProgress:this.props.devices.connected.walletSetupProgress,
                                                        walletSetupStatus:this.props.devices.connected.walletSetupStatus,
                                                        isShippingEnrolled:this.state.isShippingEnrolled,
                                                        billingEnrolled:this.state.billingEnrolled,
                                                        selectNewBilling:this.props.contacts.selectNewBilling,
                                                        updateTokenContact:this.props.contacts.updateTokenContact,
                                                        tokenId:(this.state.setup && this.props.cards.activeCard) ? this.props.cards.activeCard.id: (this.props.cards.activeCard && this.props.contacts.selectNewBilling && this.props.cards.tokenId) ? this.props.cards.tokenId :this.props.contacts.tokenId? this.props.contacts.tokenId: '',
                                                        deviceId:this.props.devices.connected? this.props.devices.connected.deviceId: '',
                                                        publicId:this.props.devices.connected? this.props.devices.connected.publicId: '',
                                                        devicePort: this.props.devicePortDiscovery.devicePort,
                                                        history,
                                                        callback:()=>{this.handleNavigation()}
                                                    })                     
                                            }
                                            else{                                                 
                                                    console.log("updateContactFromServer")
                                                    this.props.updateContactFromServer({
                                                        contact: contact,
                                                        setup:this.state.setup,
                                                        selectNewBilling:this.props.contacts.selectNewBilling,
                                                        tokenId:this.state.setup && this.props.cards.activeCard? this.props.cards.activeCard.id: '',
                                                        deviceId:this.props.devices.connected? this.props.devices.connected.deviceId: '',
                                                        publicId:this.props.devices.connected? this.props.devices.connected.publicId: '',
                                                        devicePort: this.props.devicePortDiscovery.devicePort,
                                                        history,
                                                        callback:()=>{this.handleNavigation()}
                                                    })
                                               
                                                }
                                                setTimeout(()=>{
                                                    setSubmitting(false) 
                                                },1000)                                                                                    
                                                
                                            }}
                                        >
                                            {({ values, isValid, dirty, isSubmitting, setFieldValue,handleBlur }) => (
                                                <Form>
                                                    <Container fluid>                                                                                                      
                                                        <label className='form-required-instruction-label'>&nbsp;<span className='required'> </span>&nbsp; Required Fields</label>                                                                                                   
                                                        <TextInput
                                                            label='First name'
                                                            name='givenName'
                                                            type='text'
                                                            showstar="true"
                                                        />
                                                        <TextInput
                                                            label='Last name'
                                                            name='familyName'
                                                            type='text'
                                                            showstar="true"
                                                        />                                                         
                                                        <TextInput
                                                            label='Address Line 1'
                                                            name='addressLine1'
                                                            type='text'
                                                            showstar="true"
                                                        />
                                                        <TextInput
                                                            label='Address Line 2'
                                                            name='addressLine2'
                                                            type='text'
                                                        />
                                                        <TextInput
                                                            label='City'
                                                            name='city'
                                                            type='text'
                                                            showstar="true"
                                                        />
                                                        <SelectInput
                                                        label="state"
                                                        name="state"
                                                        itemlist={usStateList()}
                                                        showstar="true"
                                                        />
                                                        <TextInput
                                                            label='Zip code'
                                                            name='zip'
                                                            type='number'
                                                            onChange={(event) => {
                                                                setFieldValue('zip', event.target.value)
                                                            }
                                                            }
                                                            showstar="true"
                                                        />
                                                        <SelectInput
                                                            label="country"
                                                            name="country"
                                                            type="text"
                                                            default={countries[0].label}
                                                            readOnly
                                                        />                 
                                                        {
                                                            <div className="button-wrapper d-flex justify-content-between">
                                                            <div>
                                                                {
                                                                    this.state.setup ?
                                                                    <div>
                                                                        {
                                                                        this.state.backTo?
                                                                            <button 
                                                                            type='button'
                                                                            className="btn btn-outline-primary btn-lg button-outline" 
                                                                                onClick={() => { history.push(this.state.backTo.componentUrl)
                                                                                }}>
                                                                                Back
                                                                            </button>
                                                                            :<></>                                                                    
                                                                            }
                                                                    </div>
                                                                    :
                                                                    <button 
                                                                        type='button'
                                                                        className="btn btn-outline-primary btn-lg button-outline" 
                                                                            onClick={() => {
                                                                                this.props.contacts.addContact ? 
                                                                                this.props.cancelAddContact({
                                                                                    updateTokenContact:this.props.contacts.updateTokenContact,
                                                                                    history
                                                                                })
                                                                                :
                                                                                this.props.cancelUpdateContact({
                                                                                    history
                                                                                })
                                                                            }}>
                                                                            Cancel
                                                                    </button>
                                                                }                                                            
                                                            </div>
                                                            <div>
                                                                <Button 
                                                                type='submit'
                                                                disabled={isSubmitting || !dirty || !isValid || this.props.contacts.isLoading}
                                                                >
                                                                    {this.props.contacts.isLoading && <span className="spinner-border spinner-border-sm mr-1" role="status" aria-hidden="true" />}
                                                                    {this.state.setup?"Next":"Save"}
                                                                </Button>
                                                            </div>
                                                        </div>
                                                    }                                
                                                </Container>                                                
                                            </Form>
                                        )
                                        }
                                    </Formik>
                                }
                                </div>          
                        </div>
                        </div>
                    }
                </div>
            </div>
        )
    }

}

const mapStateToProps = state => ({
    contacts: state.contacts,
    cards:state.cards,
    userData:state.user.data,
    devices:state.devices,
    devicePortDiscovery: state.devicePortDiscovery
})

const mapDispatchToProps = dispatch => ({
    addContactFromServer:(data)=>dispatch(addContactFromServer(data)),
    cancelAddContact: (data) => dispatch(cancelAddContact(data)),
    updateContactFromServer:(data)=>dispatch(updateContactFromServer(data)),
    cancelUpdateContact: (data) => dispatch(cancelUpdateContact(data)),
    updateDefaultContactFromServer: (data) => dispatch(updateDefaultContactFromServer(data)),
    queryStatus:(data)=>dispatch(queryStatusFromDevice(data)),
    resetContactErrorMessages:(data)=>dispatch(resetContactErrorMessages(data)),
    reportWalletSetupProgress: (data) => dispatch(reportWalletSetupProgress(data)),
    getCardListFromServer: (data) => dispatch(getCardListFromServer(data)),
    getContacts: (data) => dispatch(getContacts(data)),
    linkBillingtoCard: (data) => dispatch(linkBillingtoCard(data)),
    startAddContact: (data) => dispatch(startAddContact(data)),

})

export default connect(mapStateToProps, mapDispatchToProps)(AddBillingAddress)
