/*
 * INTEL CONFIDENTIAL
 * Copyright 2022 Intel Corporation.
 * This software and the related documents are Intel copyrighted materials, and
 * your use of them is governed by the express license under which they were
 * provided to you (License). Unless the License provides otherwise, you may not
 * use, modify, copy, publish, distribute, disclose or transmit this software or
 * the related documents without Intel's prior written permission. This software
 * and the related documents are provided as is, with no express or implied
 * warranties, other than those that are expressly stated in the License.
 */
import React, { Component } from 'react'
import { connect } from 'react-redux'
import { queryStatusFromDevice } from '../../actions/devices'
// import DashboardDevice from './DashboardDevice'
import * as utils from '../../utils'
import { Redirect } from 'react-router';
import Loading from '../shared/Loading'

class Dashboard extends Component {

  componentDidMount() {
  //initializing analytics
  utils.analyticsPageView('/dashboard')
  }

  render() {
    const { history } = this.props;
    return (
    <div>
        {
          this.props.devices.isLoading || this.props.authData.isLoading || this.props.devicePortDiscovery.isLoading?
          Loading()
          : 
            <div>
              {
              this.props.devices.connected && this.props.devicePortDiscovery.devicePort != null ?
              this.props.devices.connected.connected && (this.props.devices.connected.state === "LOCKED" || this.props.devices.connected.pendingState === "LOCKED" || (this.props.devices.connected.pendingCapability & 2) === 0) ?
              <Redirect to ='/device-dashboard'/>
              :
              this.props.devices.connected.state === "REGISTERED" && this.props.devices.connected.enrolledFlag && this.props.devices.connected.owner ?
              this.props.devices.connected.walletSetupProgress === "WALLET_REGISTRATION"?
              <Redirect to ='/register-device'/>
              :
              this.props.devices.connected.walletSetupProgress === "FINGERPRINT_ENROLL"?
              <Redirect to ="/add-fp"/>  
              : this.props.devices.connected.walletSetupProgress === "CARD_DIGITIZE" ?
              <Redirect to ="/add-card"/>  
              :this.props.devices.connected.walletSetupProgress === "BILLING_ADDRESS_ENROLL" || this.props.devices.connected.walletSetupProgress === "SHIPPING_ADDRESS_ENROLL"?
              <Redirect to ="/add-shipping"/>          
              :
              <Redirect to ='cards'/>
              :
              //device is unregistered / user is not owner
              //not owner case- user clicks cancel OR  multidevice ui case - user clicks setup later
              this.props.devices.setupCanceled && ((this.props.devices.connected.enrolledFlag && !this.props.devices.connected.owner) || 
              (this.props.devices.saved.length != 0 && !this.props.devices.connected.enrolledFlag && !this.props.devices.connected.owner))?
              <Redirect to ='/device-dashboard'/>
              :
              <Redirect to ='/register-device'/>
              :
              //No device is connected
              <Redirect to ='/device-dashboard'/>

              }
            </div>
        }
    </div>
    )
  }
}

const mapStateToProps = state => ({
  authData: state.user.data,
  devices: state.devices,
  cards:state.cards,
  contacts:state.contacts,
  devicePortDiscovery: state.devicePortDiscovery
})

const mapDispatchToProps = (dispatch) => ({
  queryStatus:(data)=>dispatch(queryStatusFromDevice(data)),
})

export default connect(mapStateToProps, mapDispatchToProps)(Dashboard)