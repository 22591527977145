/*
 * INTEL CONFIDENTIAL
 * Copyright 2022 Intel Corporation.
 * This software and the related documents are Intel copyrighted materials, and
 * your use of them is governed by the express license under which they were
 * provided to you (License). Unless the License provides otherwise, you may not
 * use, modify, copy, publish, distribute, disclose or transmit this software or
 * the related documents without Intel's prior written permission. This software
 * and the related documents are provided as is, with no express or implied
 * warranties, other than those that are expressly stated in the License.
 */
import * as constants from "@pcwallet/common/constants"
import * as messages from '@pcwallet/common/endUserMessages'
// import {verify} from './verify'
import {base64ToJson} from '../utils'
import { getCsrfCookieValue , customNumberParser, getDeviceTokenFromCookie } from "@pcwallet/common/utils"
import { stringify, parse, LosslessNumber } from 'lossless-json'
import {requestVerificationCode} from '../actions/verify'

 
export const startAddFP = data => dispatch => {
    dispatch({
        type: constants.START_FP_ADD
    })
    data.history.push('/add-fp')
}


export const startUpdateFP = data => dispatch => {
    dispatch({
        type: constants.START_FP_UPDATE,
        payload: data.card
    })
    data.history.push('/update-fp')
}

export const fingerprintEnrollmentStart = data => (dispatch) => {
    dispatch({
        type: constants.FP_ENROLLMENT_START_SUCCESS
    })
}

export const enrollFingerprint = data => (dispatch) => {
    dispatch({
        type: constants.FP_ENROLLMENT_CALLBACK
    });
    enrollFingerprintServerStart(data, dispatch);
}

export const enrollFingerprintServerStart =  (data,dispatch) => {
    const cookieValue = getCsrfCookieValue()
    const deviceToken = getDeviceTokenFromCookie()
    
    fetch(`${constants.INUS_ADDRESS}/us/v1/fingerprint/enroll_fp`, {
        method: 'POST',
        credentials : 'include',
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            'X-XSRF-TOKEN' : `${cookieValue ? cookieValue : ''}`
        }, 
        body: stringify({
            cmd: constants.ENROLL_FINGERPRINT,
            publicId: data.publicId,
            data:stringify({action:data.action , token: deviceToken})
        })
    })
    .then((response) => {
        if (response.status === 401) {
            dispatch({
                type: constants.ACCESS_TOKEN_INVALID,
            })
            throw response.status
        }
        else {
            return response.text()
        }
    })
    .then((response) => {
        return parse(response, null, customNumberParser)
    })
    .then((response) => {  
        if (response.errorCode !== "OK") {
            dispatch({ 
                type: constants.FP_ENROLLMENT_CALLBACK_FAILED,
                payload: response.message ? response.message : "Failed to enroll finger. Please retry or come back later."
            });
        } else {
             //initiate fp enrollment with device
             enrollFingerprintDeviceStart(data, dispatch);
        }
    })
    .catch((error) => {
        if(error !==  401) {
            console.error('Error:', error);
            dispatch({
                type: constants.FP_ENROLLMENT_CALLBACK_FAILED,
                payload: messages.FINGERPRINT_ENROLLMENT_FAILED
            });
        }
    });
}

const enrollFingerprintDeviceStart = (data, dispatch) => {
    const deviceToken = getDeviceTokenFromCookie();
    let requestBody = stringify({
        token: deviceToken,
        cmd:constants.ENROLL_FINGERPRINT,
        action: data.action
    })
    fetch(`${constants.MTO_ADDRESS_HOSTNAME}:${String(data.devicePort)}/v1/command`, {
        method: 'POST',
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json'
        },
        body: requestBody
    })
        .then((response) => {
            if (response.status === 200) {
                return response.json();
            } else {
                dispatch({
                    type: constants.FP_ENROLLMENT_CALLBACK_FAILED,
                    payload: 'error enroll fingerprint'
                });
            }
        })
        .then((response) => {
            let instruction = '';
            var result = ((response.bioResult) >>> 0).toString(16);
            if(result === '0') {
                instruction="Enrollment started. Please keep tapping sensor to collect more samples…"
                dispatch({
                    type: constants.FP_ENROLLMENT_CALLBACK_SUCCESS,
                    payload: {
                        instruction: instruction,
                        percentage:0
                    }
                });
                let enrollState=sessionStorage.getItem('FpEnrollState')
                if(enrollState != 'cancelling'){
                    CaptureFingerprintSamples({
                        action: 'CONTINUE',
                        publicId:data.publicId,
                        devicePort:data.devicePort,
                    }, dispatch);
                }
            }
            else if(result === "80098004") {
                instruction="Operation canceled";
                dispatch({
                    type: constants.FP_ENROLLMENT_CANCELED,
                    payload: {
                        instruction: instruction
                    }
                });
            } else if(result === "8009801c")  {
                dispatch({
                    type: constants.FP_ENROLLMENT_COMPLETE_FAILED,
                    payload: "Duplicate enrollment detected"
                })
            }
            else if (result === "80098018"){
                dispatch({
                    type: constants.FP_ENROLLMENT_COMPLETE_FAILED,
                    payload: "Maximum finger enrollment limit reached , Delete one or more in order to add more"
                })
            }            
            else if (result === "80070005"){
                dispatch({
                    type: constants.FP_ENROLLMENT_COMPLETE_FAILED,
                    payload: "Failed to communicate with FP sensor. please retry or come back later"
                })
            }            
            else {
                dispatch({
                    type: constants.FP_ENROLLMENT_COMPLETE_FAILED,
                    payload: "Failed to enroll finger. Please retry or come back later."
                })
            }

            
        })
        .catch((error) => {
            console.error('Error:', error);
            dispatch({
                type: constants.FP_ENROLLMENT_CALLBACK_FAILED,
                payload: messages.FINGERPRINT_ENROLLMENT_FAILED
            });
        });
}


const CaptureFingerprintSamples = (data, dispatch) => {
    const deviceToken = getDeviceTokenFromCookie()
    let requestBody = stringify({
        token: deviceToken,
        cmd:constants.ENROLL_FINGERPRINT,
        action: data.action
    })
    fetch(`${constants.MTO_ADDRESS_HOSTNAME}:${String(data.devicePort)}/v1/command`, {
        method: 'POST',
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json'
        },
        body: requestBody
    })
        .then((response) => {
            if (response.status === 200) {
                return response.json();
            } else {
                dispatch({
                    type: constants.FP_ENROLLMENT_CALLBACK_FAILED,
                    payload: 'error enroll fingerprint'
                });
            }
        })
        .then((response) => {
            let instruction = '';
            if(Number(response.bioResult) < 0) {
                var result = ((response.bioResult) >>> 0).toString(16);
            } else {
                 result = response.bioResult.toString(16);
            }
            let enrollState=sessionStorage.getItem('FpEnrollState')
            if(result === "90001" || result === "80098008" || result === "589825") {
                    if(response.percentComplete>=0 && response.percentComplete<=10){
                        instruction="Lift and touch sensor again...";
                    }
                    else if(response.percentComplete>10 && response.percentComplete<=20){
                        instruction="More samples needed. Please keep placing and lifting your finger...";
                    }
                    else if(response.percentComplete>20 && response.percentComplete<=30){
                        instruction="Lift and place your finger on sensor again...";
                    }
                    else if(response.percentComplete>30 && response.percentComplete<=40){
                        instruction="More samples needed.Please lift and place your finger on sensor again...";
                    }
                    else if(response.percentComplete>40 && response.percentComplete<=50){
                        instruction="Continue to place your finger on the sensor and lift it repeatedly..."
                    }
                    else if(response.percentComplete>50 && response.percentComplete<60){
                        instruction="Lift and place finger on the sensor...";
                    }
                    else if(response.percentComplete>60 && response.percentComplete<70){
                        instruction="Please continue to roll your finger around the sensor to capture the sides of your fingerprint...";
                    }
                    else if(response.percentComplete>70 && response.percentComplete<80){
                        instruction="Capturing the sides of your fingerprint. Continue to roll your finger around sensor..";
                    }
                    else if(response.percentComplete>80 && response.percentComplete<90){
                        instruction="Continue to lift and place your finger on sensor, capturing the sides of your fingerprint...";
                    }
                    else if(response.percentComplete>90 && response.percentComplete<=100){
                        instruction="Lift and place your finger on sensor...";
                    }
                    else {
                        if(result === "80098008"){                       
                            if(data.toggle){
                                instruction="Lift and place your finger on sensor again...";
                            }
                            else{
                                instruction="More samples needed. Please keep resting and lifting your finger...";
                            }
                        }
                        else{
                            instruction="Lift and place your finger on sensor again...";
                            
                        }
                        
                    }
                if(enrollState != 'cancelling'){
                    dispatch({
                        type: constants.FP_ENROLLMENT_CALLBACK_SUCCESS,
                        payload: {
                            instruction: instruction,
                            percentage:response.percentComplete?response.percentComplete:data.percentComplete?data.percentComplete:0
                        }
                    })
                    CaptureFingerprintSamples({
                        percentComplete:response.percentComplete?response.percentComplete:data.percentComplete?data.percentComplete:0,
                        toggle:(result === "80098008" && data.toggle)?false:true,
                        action: 'CONTINUE',
                        publicId:data.publicId,
                        devicePort:data.devicePort
                    }, dispatch);
                }
            } 
            else if(result === "80098004")  {
                dispatch({
                    type: constants.FP_ENROLLMENT_CANCELED,
                    payload: 'Operation canceled"'
                })
            }
            else if (result === "8009802b") {;
                dispatch({
                    type: constants.FP_ENROLLMENT_CALLBACK_FAILED,
                    payload: "Duplicate template detected (Finger already enrolled)"
                })
            }
            else if (result === "0") {
                //fp enrollment complete, check with user service for result and then show option to name fingerprint
                enrollFpResult(data, dispatch);
            }               
            else {
                console.log("Enrollment Error code: " + result)
                dispatch({
                    type: constants.FP_ENROLLMENT_COMPLETE_FAILED,
                    payload: messages.FINGERPRINT_ENROLLMENT_FAILED
                })
            }
        })
        .catch((error) => {
            console.error('Error:', error);
            dispatch({
                type: constants.FP_ENROLLMENT_CALLBACK_FAILED,
                payload: messages.FINGERPRINT_ENROLLMENT_FAILED
            });
        });
}

export const enrollFpResult =  (data,dispatch) => {
    const cookieValue = getCsrfCookieValue()
    fetch(`${constants.INUS_ADDRESS}/us/v1/fingerprint/enroll_fp_result`, {
        method: 'POST',
        credentials : 'include',
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            'X-XSRF-TOKEN' : `${cookieValue ? cookieValue : ''}`
        }, 
        body: stringify({
            cmd: constants.ENROLL_FP_RESULT,
            publicId: data.publicId
        })
    })
    .then((response) => {
        if (response.status === 401) {
            dispatch({
                type: constants.ACCESS_TOKEN_INVALID,
            })
            throw response.status
        }
        else {
            return response.text()
        }
    })
    .then((response) => {
        return parse(response, null, customNumberParser)
    })
    .then((response) => {        
        if (response.errorCode !== "OK") {
            dispatch({ 
                type: constants.FP_ENROLLMENT_CALLBACK_FAILED,
                payload: "Failed to enroll finger. Please retry or come back later."
            });
        } else {
            let responseData = parse(response.data, null, customNumberParser)
            //initiate fp name process
            dispatch({
                type: constants.FP_ENROLLMENT_FINGERPRINT_NAME_START,
                payload:parseInt(responseData.templateId)
            })
        }
    })
    .catch((error) => {
        if(error !==  401) {
            console.error('Error:', error);
            dispatch({
                type: constants.FP_ENROLLMENT_CALLBACK_FAILED,
                payload: messages.FINGERPRINT_ENROLLMENT_FAILED
            });
        }
    });
}

export const cancelFingerEnrollment = (data) => (dispatch) => {
    dispatch({
        type: constants.FP_ENROLLMENT_CANCEL_START,
    });
    sessionStorage.setItem('FpEnrollState','cancelling') 
    const deviceToken = getDeviceTokenFromCookie()
    let requestBody = stringify({
        token: deviceToken,
        cmd:constants.ENROLL_FINGERPRINT,
        action: data.action,
    })
    fetch(`${constants.MTO_ADDRESS_HOSTNAME}:${String(data.devicePort)}/v1/command`, {
        method: 'POST',
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json'
        },
        body: requestBody
    })
        .then((response) => {
            if (response.status === 200) {
                return response.json();
            } else {
                dispatch({
                    type: constants.FP_ENROLLMENT_CANCEL_FAILED,
                    payload: 'error enroll fingerprint'
                });
            }
        })
        .then((response) => {
            //irrespective of result always show enrollment cancelled
            let instruction="Enrollment cancelled"
            dispatch({
                type: constants.FP_ENROLLMENT_CANCELED,
                payload: {
                    instruction: instruction
                }
            });
            if(data.callback){
                data.callback();
            }            
        })
        .catch((error) => {
            console.error('Error:', error);
                dispatch({
                    type: constants.FP_ENROLLMENT_CANCELED,
                });
            if(data.setup){
                data.callback();
             }     
        });
}

export const nameFingerprint = (data) => (dispatch) => {
    const deviceToken = getDeviceTokenFromCookie();
    dispatch({
        type: constants.NAME_FINGERPRINT_LOADING,
    })
    let requestBody = stringify({
        token: deviceToken,
        templateId: data.templateId,
        fingerName:data.fingerName,
        publicId:data.publicId,
    })
    const cookieValue = getCsrfCookieValue()
    fetch(`${constants.INUS_ADDRESS}/us/v1/fingerprint/${data.publicId}/template/${data.templateId}`, {
        method: 'PUT',
        credentials : 'include',
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            'X-XSRF-TOKEN' : `${cookieValue ? cookieValue : ''}`
        },
        body: requestBody
    })
        .then((response) => {
            if (response.status === 401) {
                dispatch({
                    type: constants.ACCESS_TOKEN_INVALID,
                })
                throw response.status
            }
            else {
                return response.text()
            }
        })
        .then((response) => {
            return parse(response, null, customNumberParser)
        })
        .then((response) => {
            if(response.errorCode !== "OK"){
                dispatch({
                    type: constants.NAME_FINGERPRINT_FAILED,
                    payload: response.message ? response.message :'error naming finger'
                });
            }
            else{
                dispatch({
                    type: constants.NAME_FINGERPRINT_SUCCESS,
                    payload:response.fingerprint
                });
                
                data.callback()             
            }            
        })
        .catch((error) => {
            if(error !==  401) {
                console.error('Error:', error);
                dispatch({
                    type: constants.NAME_FINGERPRINT_FAILED,
                    payload: messages.FINGERPRINT_NAME_FAILED
                });
            }
        });
}

export const startGetFingerList = data => dispatch => {
    dispatch({
        type: constants.FINGERPRINT_LIST_GET_START,
    })
    data.history.push('/security')
}

export const getFingerList = (data) => (dispatch) => {
    getFingerListFromServer(data,dispatch);
}

export const getFingerListFromServer = (data,dispatch) => {
    const cookieValue = getCsrfCookieValue()
    fetch(`${constants.INUS_ADDRESS}/us/v1/fingerprintList`, {
        method: 'POST',
        credentials : 'include',
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            'X-XSRF-TOKEN' : `${cookieValue ? cookieValue : ''}`
        },
        body:stringify({
            publicId : data.publicId
        })        
    })
    .then((response) => {
        if (response.status === 401) {
            dispatch({
                type: constants.ACCESS_TOKEN_INVALID,
            })
            throw response.status
        }
        else {
            return response.text()
        }
    })
    .then((response) => {
        return parse(response, null, customNumberParser)
    })
    .then((response) => {        
        if (response.errorCode !== "OK") {
            dispatch({ 
                type: constants.FINGERPRINTS_GET_FAILED,
                payload: response.message ? response.message : "Failed to load fingerprint list. Please retry or come back later."
            });            
        } else {
            dispatch({
                type: constants.FINGERPRINTS_GET_SUCCESS,
                payload: {
                    fingers: response.fingerprints? response.fingerprints :[]
                }
            });
        }
    })
    .catch((error) => {
        if(error !==  401) {
            console.error('Error:', error);
            dispatch({
                type: constants.FINGERPRINTS_GET_FAILED,
                payload: messages.FINGERPRINT_GET_LIST_FAILED
            });
        }
    });
}

export const getFingerListFromDevice = (data, dispatch) => {
    const deviceToken = getDeviceTokenFromCookie()
    let requestBody = stringify({
        token: deviceToken,
        cmd:constants.READ_FINGERPRINTS,
    })
    fetch(`${constants.MTO_ADDRESS_HOSTNAME}:${String(data.devicePort)}/v1/command`, {
        method: 'POST',
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json'
        }, 
        body: requestBody
    })
    .then((response) => response.json())
    .then((response) => {
        if (!response.status) {
            dispatch({
                type: constants.FINGERPRINTS_GET_FAILED//To dO
            });
        } else {
            getFingerListFromServer(dispatch);
        }
    })
    .catch((error) => {
        console.error('Error:', error);
        dispatch({
            type: constants.FINGERPRINTS_GET_FAILED,
            payload: messages.FINGERPRINT_GET_LIST_FAILED
        });
    });
}

export const startRenameFingerprint = (data) => dispatch => {
    dispatch({
        type: constants.FINGERPRINT_RENAME_START,
        payload:data
    })
}

export const startDeleteFingerprint = data => dispatch => {
    dispatch({
        type: constants.FINGERPRINT_DELETE_START,
        payload: data
    })
}

export const deleteFingerprint = data => dispatch => {
    dispatch({
        type: constants.FINGERPRINT_DELETE
    })
    let requestBody = stringify({
        cmd:constants.DELETE_FINGERPRINT,
        publicId:data.publicId,
        data:stringify({templateId:data.templateId,})
    })
    const cookieValue = getCsrfCookieValue()
    fetch(`${constants.INUS_ADDRESS}/us/v1/fingerprint/delete_fp`, {
        method: 'POST',
        credentials : 'include',
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            'X-XSRF-TOKEN' : `${cookieValue ? cookieValue : ''}`
        },
        body : requestBody
    })
    .then((response) => {
        if (response.status === 401) {
            dispatch({
                type: constants.ACCESS_TOKEN_INVALID,
            })
            throw response.status
        }
        else {
            return response.text()
        }
    })
    .then((response) => {
        return parse(response, null, customNumberParser)
    })
    .then((response) => {        
        if(response.errorCode !== "OK"){
            dispatch({
                type: constants.FINGERPRINT_DELETE_FAILED,
                payload: response.message
            }); 
        }
        else{ 
            deleteFpFromDevice(data,dispatch)
        }
    })
    .catch((error) => {
        if(error !==  401) {
            dispatch({
                type: constants.FINGERPRINT_DELETE_FAILED,
                payload: messages.FINGERPRINT_DELETE_FAILED
            });
        }
    })
}

export const deleteFpFromDevice = (data, dispatch) => {
    const deviceToken = getDeviceTokenFromCookie()
    const requestURL = `${constants.MTO_ADDRESS_HOSTNAME}:${String(data.devicePort)}/v1/command`
    let requestBody = stringify({
        token: deviceToken,
        cmd:constants.DELETE_FINGERPRINT,
        templateId:data.templateId
    })
    fetch(requestURL, {
        method: 'POST',
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json'
        },
        body : requestBody
    })
    .then((response) => {
        if (response.status === 200) {
            return response.json();
        } else {
            dispatch({
                type: constants.FINGERPRINT_DELETE_FAILED,
                payload: 'error delete fingerprint'
            });
        }
    })
    .then((response) => {
        if(response.message === "SUCCESS") {
            dispatch({
                type: constants.FINGERPRINT_DELETE_SUCCESS,
                payload: {
                    instruction:"Successfully deleted template",
                    templateId:data.templateId-1
                }
            });
            data.callback();
        }else{
            dispatch({
                type: constants.FINGERPRINT_DELETE_FAILED,
                payload: messages.FINGERPRINT_DELETE_FAILED
            });
        }
    })
    .catch((error) => {
        dispatch({
            type: constants.FINGERPRINT_DELETE_FAILED,
            payload: messages.FINGERPRINT_DELETE_FAILED
        });
    })
}


export const showWalletSettings = (data) => (dispatch) => {
    dispatch({
        type: constants.SHOW_WALLET_SETTINGS,
    });
    if(data){
        data.history.push('/security')
    }
}

export const showFingerprintSettings = (data) => (dispatch) => {
    dispatch({
        type: constants.SHOW_FINGERPRINT_SETTINGS
    });
    data.history.push('/security')
}

export const clearSecurityState = (data) => dispatch => {
    dispatch({
        type: constants.SECURITY_STATE_CLEAR,
    })
}

//PIN Management - enrollment , change, reset


export const showPINSettings = (data) => (dispatch) => {
    dispatch({
        type: constants.SHOW_PIN_SETTINGS,
    });
    data.history.push('/security')
}

export const startChangePincode = (data) => (dispatch) => {
    dispatch({
        type: constants.PIN_STATE_CHANGE_START,
    });
    if(data.history){
        data.history.push('/add-pin')
    }
}

export const startResetPincode = (data) => async (dispatch) => {
    dispatch({
        type: constants.PIN_STATE_RESET_START,
    });
    data.history.push('/add-pin')
}


export const managePin =  data => async (dispatch) => {
    console.log('setupPin');
    dispatch({
        type: constants.PIN_SETUP_LOADING,
    });
    dispatch({
        type: constants.SETUP_WALLET_MSG,
        payload: messages.PIN_SETUP_LOADING_MSG
    })
    let url = `${constants.INUS_ADDRESS}/us/v1/setPin`;
    let requestData = {
        deviceId:data.deviceId
    }

    if(data.authState == constants.PIN_STATE_CHANGE){
        console.log('changePin');
        url = `${constants.INUS_ADDRESS}/us/v1/changePin`;
        requestData.oldPin = data.currentPincode
        requestData.newPin = data.pincode

    }
    else  if(data.authState == constants.PIN_STATE_RESET){
        console.log('resetPin');
        url = `${constants.INUS_ADDRESS}/us/v1/resetPin`;
        requestData.otp = data.otp
        requestData.pin = data.pincode
    }
    else{
        requestData.pin = data.pincode

    }
    const deviceToken = getDeviceTokenFromCookie()
    const cookieValue = getCsrfCookieValue()
    let response = await  postAPI(url, cookieValue, requestData,dispatch)
    .catch( error => {
        if(error !==  401) {
            dispatch({
                type: constants.PIN_SETUP_FAILED,
                payload: messages.PIN_SETUP_FAILED
            });
        }
    });;

    if (response.errorCode !== "OK") {
        dispatch({
            type: constants.PIN_SETUP_FAILED,
            payload: response.message ? response.message : "Failed to setup PIN. Please retry or come back later."
        });
    }     
    else {
        if(typeof response.astp !== 'undefined') {
            data.astp = response.astp;
            data.sessionId = response.adminSessionId;
            data.deviceToken = deviceToken
            response = await getNextScript(data,dispatch);
            if (!response.status) {
                dispatch({
                    type: constants.PIN_SETUP_FAILED,
                    payload: response.message ? response.message : "Failed to setup PIN. Please retry or come back later."
                });
            }     
            else {               
                let response = await getPinResult(data,dispatch)
                if (response.errorCode !== "OK") {
                    dispatch({
                        type: constants.PIN_SETUP_FAILED,
                        payload: response.message ? response.message : "Failed to setup PIN. Please retry or come back later."
                    });
                }     
                else {
                    dispatch({
                        type: constants.PIN_SETUP_SUCCESS,
                    });
                    if(data.callback){
                        data.callback()
                    }
                }                  
            }
        } 
                
    }
}

const getPinResult = async (data, dispatch) => {
    console.log('getPinResult');
    const cookieValue = getCsrfCookieValue()
    const url = `${constants.INUS_ADDRESS}/us/v1/managePinResult`;
    let requestData = {
        deviceId: data.deviceId,
        sessionId: data.sessionId
    }

    return await  postAPI(url, cookieValue, requestData, dispatch)
    .catch( error => {
        if(error !==  401) {
            dispatch({
                type: constants.PIN_SETUP_FAILED,
                payload: messages.PIN_SETUP_FAILED
            });
        }
    });
}

const getNextScript = async (data,dispatch) => {
    console.log('getNextScript');
    const deviceToken = getDeviceTokenFromCookie();
    const url = `${constants.MTO_ADDRESS_HOSTNAME}:${String(data.devicePort)}/v1/command`
    return await postAPI(url, null, {
        token: deviceToken,
        astp: data.astp,
        cmd: constants.GET_NEXT_SCRIPT
    },dispatch);
}

const postAPI = async (url, cookieValue, data, dispatch) => {
    let headers = {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
    };


    if(cookieValue != null){
        headers["X-XSRF-TOKEN"]= cookieValue;
    }
    
    let response = await fetch(url, {
        method: 'POST',
        credentials :cookieValue? 'include':'omit',
        headers: headers,
        body: JSON.stringify(data)
    }).catch( error => {
        throw error;
    });

    if (response.status === 401) {
        dispatch({
            type: constants.ACCESS_TOKEN_INVALID,
        })
        throw response.status
    }
    else {
        return response.json()
    }
}


export const resetErrorMessages = () => (dispatch)=>{
    dispatch({
        type: constants.RESET_ERROR_STATES,
      })
}

export const clearSecurityAuthState = (data) => dispatch => {
    dispatch({
        type: constants.SECURITY_AUTH_STATE_CLEAR,
    })
}