import * as CBOR from 'cbor-js'
import * as constants from './constants'
import {LosslessNumber, stringify } from 'lossless-json'

export const formatCardExpirationDate = expirationDate => {
    // if expirationDate is formatted as yyMMdd, show "MM/yy" on user portal
    if (expirationDate.length >= 4) {
        return expirationDate.substring(2, 4) + "/" + expirationDate.substring(0, 2);
    }
    return expirationDate;
}

export function base64ToJson(base64) {
    var binary = window.atob(base64);
    var len = binary.length;
    var bytes = new Uint8Array(len);
    for (var i = 0; i < len; i++) {
        bytes[i] = binary.charCodeAt(i);
    }
    var data = String.fromCharCode.apply(String, bytes)
    return JSON.parse(data)
}

export function base64Decoded(str) {
    return decodeURIComponent(Array.prototype.map.call(window.atob(str), function(c) {
        return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2)
    }).join(''))
}

export function base64EncodedJson(data) {
    var jsonBody=stringify(data)
    return window.btoa( jsonBody )
}

export function base64EncodedData(data) {
    return window.btoa( data )
}

export function buf2hex(buffer) { // buffer is an ArrayBufferS
    return Array.prototype.map.call(new Uint8Array(buffer), x => ('00' + x.toString(16)).slice(-2)).join('');
  }

export const textToBinary = (str = '') => {
    let bin = '';
    bin = str.split('').map(char => {
       return char.charCodeAt(0).toString(2);
    }).join(' ');
    return bin;
 };

//adobe analytics 

export const analyticsPageView = (path)=>{
    try{
        // @ts-ignore
        //eslint-disable-next-line no-undef
       if ( wap_tms && wap_tms.custom && wap_tms.custom.trackGaVirtualPageView){   
            console.log('calling trackGaView for',path)    
            // @ts-ignore
            //eslint-disable-next-line no-undef   
            wap_tms.custom.trackGaVirtualPageView(path);
       }
       else{
           //waiting 1 second before retrying...
           console.log('retry in 1 second for',path)
           setTimeout(()=>{analyticsPageView(path)},1000)
       }
      }
      catch(err){
        console.log(err)
      }
}

export const getCsrfCookieValue = () =>{
    let cookieKey = 'XSRF-TOKEN_US'
    return getCookie(cookieKey)
}

export const getTsdCsrfCookieValue = () =>{
    let cookieKey = 'XSRF-TOKEN'
    return getCookie(cookieKey)
}

//PRE_DEVICE_TOKEN is only authorized to VERIFY_DEVICE_OWNER
export const getPreDeviceTokenFromCookie = () =>{
    let cookieKey = 'PRE_DEV'
    return getCookie(cookieKey);
}

export const getDeviceTokenFromCookie = () =>{
    let cookieKey = 'DEV';
    return getCookie(cookieKey);
}

const getCookie = (cookieKey) => {
    let b = document.cookie.match("(^|;)\\s*" + cookieKey + "\\s*=\\s*([^;]+)");
    return b ? b.pop() : "";
}

export function customNumberParser(value) {
    // let number = new LosslessNumber(value).valueOf()
    // return number
    return value
}