/*
 * INTEL CONFIDENTIAL
 * Copyright 2022 Intel Corporation.
 * This software and the related documents are Intel copyrighted materials, and
 * your use of them is governed by the express license under which they were
 * provided to you (License). Unless the License provides otherwise, you may not
 * use, modify, copy, publish, distribute, disclose or transmit this software or
 * the related documents without Intel's prior written permission. This software
 * and the related documents are provided as is, with no express or implied
 * warranties, other than those that are expressly stated in the License.
 */
import * as constants from '@pcwallet/common/constants'
import { customNumberParser } from "@pcwallet/common/utils"
import { stringify, parse } from 'lossless-json'

export const startDevicePortDiscovery = (data)=>(dispatch)=> {
    if(!data.retryPort){
        dispatch({
            type:constants.PORT_DISCOVERY,
        })
    }
    let deviceRequestURL = data.deviceRequestURL
    fetch(deviceRequestURL,{
        method: 'POST',
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
        },
        body:stringify({
            cmd: constants.PORT_DISCOVERY,
        }),
        signal: AbortSignal.timeout(5000) 
    })
    .then((response) => response.json())
    .then((response)=> {
        if(!response.status) {
            console.log(constants.DEVICE_PORT_DISCOVERY_FAILED)
            console.log(response.message)
            dispatch({
                type:constants.DEVICE_PORT_DISCOVERY_FAILED,
            })
        }
        else{
            if(response.data) {
                data.portVerificationData = parse(response.data)
                verifyDevicePort(data, dispatch)
            }
            else{
                console.log(constants.DEVICE_PORT_DISCOVERY_FAILED)
                dispatch({
                    type:constants.DEVICE_PORT_DISCOVERY_FAILED,
                })
            }
        }
    })
    .catch((error) => {
        console.log(constants.DEVICE_PORT_DISCOVERY_ERROR)
        console.log(error)
        //error with current port trying other port
        if((error.name ==="TimeoutError" || error.name ==="AbortError" || (error.name ==="TypeError" && error.message ==="Failed to fetch")) && data.tryPort && data.tryPort <= constants.DEVICE_PORT_RANGE_END) {
            console.log("trying port",data.tryPort)
            data.retryPort=true;
            data.tryPort = data.tryPort+1
            data.deviceRequestURL = `${constants.MTO_ADDRESS_HOSTNAME}:${String(data.tryPort)}/v1/public_command`
            dispatch({
                type: constants.DEVICE_PORT_DISCOVERY_ERROR,
            })
            dispatch(startDevicePortDiscovery(data))
        }else {
            dispatch({
                type: constants.DEVICE_PORT_DISCOVERY_FAILED,
            })
        }
    })
}

export const verifyDevicePort =(data, dispatch) => {
    const requestURL = `${constants.INUS_ADDRESS}/ds/v1/verifyDeviceHttpsPort`
    fetch(requestURL,{
        method: 'POST',
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
        },
        body: stringify( {
            xid:data.portVerificationData.ssId,
            encryptedData:data.portVerificationData.encryptedData
        })        
    })
    .then((response) => response.text())
    .then((response) => {
        return parse(response, null, customNumberParser)
    })
    .then((response)=> {
        if(response.errorCode !== "OK") {
            dispatch({
                type:constants.DEVICE_PORT_DISCOVERY_FAILED,
            })
            if(response.errorCode === "EMPTY_XID"){
                console.log(constants.DEVICE_PORT_DISCOVERY_FAILED)
            }        
            else{
                console.log(constants.DEVICE_PORT_DISCOVERY_FAILED)
            }            
        }
        else{
            //Port verification successful
            console.log(constants.DEVICE_PORT_VERIFICATION_SUCCESS)
            dispatch({
                type:constants.DEVICE_PORT_VERIFICATION_SUCCESS,
                payload:String(data.tryPort)
            })
        }
        data.callback();
    })
    .catch((error) => {
        console.log(constants.DEVICE_PORT_VERIFICATION_FAILED)
        console.log(error)
        dispatch({
            type:constants.DEVICE_PORT_DISCOVERY_FAILED,
        })
    })

}