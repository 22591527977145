/*
 * INTEL CONFIDENTIAL
 * Copyright 2022 Intel Corporation.
 * This software and the related documents are Intel copyrighted materials, and
 * your use of them is governed by the express license under which they were
 * provided to you (License). Unless the License provides otherwise, you may not
 * use, modify, copy, publish, distribute, disclose or transmit this software or
 * the related documents without Intel's prior written permission. This software
 * and the related documents are provided as is, with no express or implied
 * warranties, other than those that are expressly stated in the License.
 */
import * as constants from '../constants'

const initialState = {
    codeSentSuccess: false,
    requestCodeIsLoading: false,
    requestCodeError: false,
    submitCodeIsLoading: false,
    submitCodeError: false,
    errorMessage: null,
    error: false,
    verified: false,
    isLoading: false,
    isLoadingModal: false,
    selectedMethod:null
}

export default function verify(state = initialState, { type, payload }) {
  switch (type) {
    case constants.VERIFY_REQUEST_CODE:
        return { 
            ...state, 
            isLoading: true,
            isLoadingModal:true,
            errorMessage: null,
            error: false,
        }
    case constants.VERIFY_REQUEST_CODE_SUCCESS:
        return { 
            ...state,
            codeSentSuccess: true,
            selectedMethod:payload,
            isLoading: false,
            isLoadingModal: false,
            errorMessage: null,
            error: false
        }
    case constants.VERIFY_REQUEST_CODE_FAILED:
        return {
            ...state, 
            isLoading: false,
            isLoadingModal: false,
            errorMessage: payload,
            error: true,
        }
    case constants.VERIFY_SUBMIT_CODE:
        return { 
            ...state, 
            errorMessage: null,
            error: false,
            isLoading: true,
        }
    case constants.VERIFY_SUBMIT_CODE_SUCCESS:
        return { 
            ...state,
            verified: true,
            error: false,
            errorMessage: null,
            isLoading: false,
        }
    case constants.VERIFY_SUBMIT_CODE_FAILED:
        return {
            ...state, 
            errorMessage: payload,
            error: true,
            isLoading: false,
        }
    case constants.GET_NEXT_SCRIPT_FAILED:
        return {
            ...state, 
            errorMessage: payload,
            error: true,
            isLoading: true,
        }
    case constants.VERIFY_RESET:
        return {
            ...initialState
        }
    default:
      return state
  }
}