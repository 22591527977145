/*
 * INTEL CONFIDENTIAL
 * Copyright 2022 Intel Corporation.
 * This software and the related documents are Intel copyrighted materials, and
 * your use of them is governed by the express license under which they were
 * provided to you (License). Unless the License provides otherwise, you may not
 * use, modify, copy, publish, distribute, disclose or transmit this software or
 * the related documents without Intel's prior written permission. This software
 * and the related documents are provided as is, with no express or implied
 * warranties, other than those that are expressly stated in the License.
 */
import React from 'react'
import LoadingLogo from '@pcwallet/common/assets/logo_loading.png'

export default function Loading() {
  return (
    <div className="wrapper">
      <div className="content text-center">
        <img className="loading-logo" alt="loading logo" src={LoadingLogo}/>
      </div>
    </div>
  )
}
