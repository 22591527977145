/*
 * INTEL CONFIDENTIAL
 * Copyright 2022 Intel Corporation.
 * This software and the related documents are Intel copyrighted materials, and
 * your use of them is governed by the express license under which they were
 * provided to you (License). Unless the License provides otherwise, you may not
 * use, modify, copy, publish, distribute, disclose or transmit this software or
 * the related documents without Intel's prior written permission. This software
 * and the related documents are provided as is, with no express or implied
 * warranties, other than those that are expressly stated in the License.
 */
import * as constants from "@pcwallet/common/constants"

const initialState = {
    contacts: [],
    activeContact: null,
    defaultShippingContactId: null,
    addContact: false,
    updateContact: false,
    isLoading: false,
    error: false,
    errorMessage: null,
    success: false,
    activeContactId:null,
    selectNewBilling:false,
    updateTokenContact:false,
    contactState:null,
    isLoadingModal: false,
}

export default function contactUpdate(state = initialState, { type, payload }) {
    switch (type) {
        case constants.CONTACTS_GET_START:
            return {
                ...state,
                isLoading:true,                
                contacts: [],
                defaultShippingContactId: null,
                activeContact:null,
            }
        case constants.CONTACTS_GET_SUCCESS:
            return {
                ...state,
                isLoading:false,
                contacts: payload.contacts,
                activeContact: payload.contacts.length>0?payload.activeContact?payload.activeContact:payload.defaultShippingContactId?payload.contacts.find(contact =>{return contact.id === payload.defaultShippingContactId}) : payload.contacts[0] :null,
                defaultShippingContactId: payload.defaultShippingContactId
            }
        case constants.CONTACTS_GET_FAILED:
            return {
                ...state,
                isLoading:false,
                error:true,
                errorMessage:payload
            }
        case constants.CONTACT_ADD_START:
            return {
                ...state,
                isLoading:false,
                addContact: payload.addContact,
                selectNewBilling:payload.selectNewBilling,
                updateTokenContact:payload.updateTokenContact,
                activeContact:payload.contact,
                tokenId:payload.tokenId
            }
        case constants.CONTACT_ADD_STARTED:
            return {
                ...state,
                isLoading:true,
                error: false,
                errorMessage:''
            }
        case constants.CONTACT_ADD_CANCEL:
            return {
                ...state,
                isLoading:false,
                addContact: false,
                updateTokenContact:false,
                selectNewBilling:false,
            }
        case constants.CONTACT_ADD_SUCCESS:
            return {
                ...state,
                isLoading:false,
                addContact: false,
                activeContact:payload,
                contactState: constants.CONTACT_ADD_SUCCESS,
            }
        case constants.CONTACT_ADD_FAILED:
            return {
                ...state,
                isLoading: false,
                error:true,
                errorMessage:payload
            }
        case constants.CONTACT_UPDATE_START:
            return {
                ...state,
                isLoading:false,
                addContact: false,
                updateContact: true,
                activeContact: payload.contact,
                tokenId:payload.tokenId
            }
        case constants.CONTACT_UPDATE_CANCEL:
            return {
                ...state,
                isLoading:false,
                updateContact: false,
            }
        case constants.CONTACT_UPDATE_LOADING:
            return {
                ...state,
                isLoading:true,                
                error:false,
                errorMessage:null
            }
        case constants.CONTACT_UPDATE_SUCCESS:
            return {
                ...state,
                isLoading:false,
                addContact:false,
                updateContact: false,
                selectNewBilling: payload.selectNewBilling,
                activeContact: payload.contact,
                contactState: constants.CONTACT_UPDATE_SUCCESS,
            }
        case constants.CONTACT_UPDATE_FAILED:
            return {
                ...state,
                isLoading: false,
                error:true,
                errorMessage:payload
            }
        case constants.CONTACT_ADD_SHIPPING_ADDRESS:
            return {
                ...state,
                isLoading:false,
                addContact:true,
                updateContact: false,
                activeContact: payload
            }
        case constants.CONTACT_SHOW_DETAILS:
            return {
                ...state,
                isLoading:false,
                activeContact: payload.contact
            }
        case constants.CONTACT_DELETE_DENIED:
            return {
                ...state,
                isLoading:false,
                success: false,
                contactState: constants.CONTACT_DELETE_DENIED,
                error:false
            }
        case constants.CONTACT_DELETE_START:
            return {
                ...state,
                isLoading:false,
                success: false,
                contactState: constants.DELETE_CONTACT,
                error:false
            }
        case constants.CONTACT_DELETE_LOADING:
            return {
                ...state,
                isLoading:false,
                isLoadingModal:true,
                success: false,
                contactState: constants.DELETE_CONTACT,
                error:false
            }
        case constants.CONTACT_DELETE_SUCCESS:
            return {
                ...state,
                isLoading:false,
                isLoadingModal:false,
                success: true,
                activeContact:null,
                contactState: constants.CONTACT_DELETE_SUCCESS,
                error:false
            }
        case constants.CONTACT_DELETE_FAILED:
            return {
                ...state,
                isLoading:false,
                isLoadingModal:false,
                success: false,
                contactState: constants.DELETE_CONTACT,
                error:true,
                errorMessage:payload
            }
        case constants.SET_DEFAULT_CONTACT_START:
            return {
                ...state,
                isLoading:false,
                isLoadingModal: false,
                success: false,
                contactState: constants.SET_DEFAULT_CONTACT,
                error:false
            }
        case constants.SET_DEFAULT_CONTACT_SUCCESS:
            return {
                ...state,
                isLoading:false,
                isLoadingModal: false,
                success: true,
                contactState: constants.SET_DEFAULT_CONTACT_SUCCESS,
                error:false
            }
        case constants.SET_ENROLLMENT_STATUS_SUCCESS:
            return {
                ...state,
                isLoading:false,
                success: true,
                error:false
            }
        case constants.SET_ENROLLMENT_STATUS_FAILED:
            return {
                ...state,
                isLoading:false,
                success: false,
                error:true,
                errorMessage:payload
            }
        case constants.CONTACT_STATE_CLEAR:
            return {
                ...state,
                success:false,
                contactState: ''
            }
        case constants.LINK_BILLING_TO_CARD:
            return {
                ...state,
                error:false,
                isLoading:true,
                selectNewBilling: payload.selectNewBilling,
            }
        case constants.LINK_BILLING_TO_CARD_SUCCESS:
            return {
                ...state,
                error:false,
                isLoading:false,
                success:true,
                selectNewBilling: payload.selectNewBilling,
                updateTokenContact:payload.updateTokenContact,
                contactState:constants.LINK_BILLING_TO_CARD_SUCCESS
            }
        case constants.LINK_BILLING_TO_CARD_FAILED:
            return {
                ...state,
                isLoading:false,
                error: true,
                errorMessage:payload,
            }
        case constants.RESET_ERROR_STATES:
            return{
                ...state,
                errorMessage:'',
                error: false,
            }
        default:
            return state
    }
}