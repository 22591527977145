/*
 * INTEL CONFIDENTIAL
 * Copyright 2022 Intel Corporation.
 * This software and the related documents are Intel copyrighted materials, and
 * your use of them is governed by the express license under which they were
 * provided to you (License). Unless the License provides otherwise, you may not
 * use, modify, copy, publish, distribute, disclose or transmit this software or
 * the related documents without Intel's prior written permission. This software
 * and the related documents are provided as is, with no express or implied
 * warranties, other than those that are expressly stated in the License.
 */
import React, { Component } from 'react'
import { connect } from 'react-redux'
import { Image, Modal } from 'react-bootstrap';
import { queryStatusFromDevice, startDeleteDevice, deleteDevice, startLockWallet, lockWallet, startUnlockWallet, unlockWallet, clearDeviceState } from '../../actions/devices'
import { clearSecurityState} from '../../actions/security'
import * as constants from '../../constants'
import MasterCardArt from '@pcwallet/common/assets/MasterCardArt.png'
import VisaCardArt from '@pcwallet/common/assets/VisaCardArt.png'
import DiscoverCardArt from '@pcwallet/common/assets/DiscoverCardArt.png'
import AmexCardArt from '@pcwallet/common/assets/AmexCardArt.png'
import DefaultCardArt from '@pcwallet/common/assets/grey-card.png'

export class RemoteManageWallet extends Component{

    constructor(props) {
        super(props);
        this.state = {
          disableButton:false,
          disableLockButton:false,
          disableDeleteButton:false,
        }
        
        this.getCardArtSrc = this.getCardArtSrc.bind(this)

    }

    getCardArtSrc(card){
        if(card.prototypeMode || (!card.productConfig || !card.productConfig.cardArt || !card.productConfig.cardArt.data)){
            switch(card.cardNetwork){
                case "VISA":
                    return VisaCardArt
                case "DISCOVER":
                    return DiscoverCardArt
                case "MASTERCARD":
                    return MasterCardArt
                case "AMEX" :
                    return AmexCardArt
                default:
                    return DefaultCardArt
            }
        }
        else{
            return "data:image/png;base64,"+ card.productConfig.cardArt.data   
        }
    }

    render() {
        const { history, device } = this.props;
        return (
            <div>
                <div className="px-5 pb-5">
                    {
                        device.connected && ((parseInt(device.pendingCapability) & 2 ) === 0 )?
                        <div className="py-2 font-weight-bold h5"> Your wallet is locked due to too many failed authorization attempts</div>
                        :
                        !device.connected && (device.state === 'LOCKED' || device.pendingState === 'LOCKED' || (parseInt(device.pendingCapability) & 2) === 0 ) ?
                        <div className="py-2 font-weight-bold h5"> Your wallet is locked, to unlock please connect wallet</div>
                        :
                        <></>
                    }
                    <div>
                        {
                            device.state === "REGISTERED" && device.pendingState === "REGISTERED" && (parseInt(device.pendingCapability) & 2) > 0   ?
                                <div className="d-flex align-items-center">
                                    <button
                                        className="my-3 btn-outline-primary btn-lg button-outline"
                                        disabled={this.state.disableLockButton}
                                        onClick={() => {
                                            this.props.startLockWallet({
                                                showModal: true,
                                                device: device,
                                                history
                                            })
                                        }}>
                                        Lock Wallet
                                    </button>
                                    <div className="font-weight-bold" style={{ marginLeft: '0.5rem' }}>Temporarily disable all cards in this wallet, but your physical cards are still available to be used </div>  
                                                
                                </div>
                        : device.connected && (device.state === "LOCKED" || device.pendingState === "LOCKED" || (parseInt(device.pendingCapability) & 2 ) === 0)?
                                <div className="d-flex align-items-center">
                                    <button 
                                        className="my-3 btn-outline-primary btn-lg button-outline"
                                        disabled={!device.connected?true:false}
                                        onClick={() => {
                                            this.props.startUnlockWallet({
                                                showModal: true,
                                                device:device,
                                                history
                                            })
                                        }}>
                                        Unlock Wallet
                                    </button>
                                    <div className="font-weight-bold  ml-2">Enables all cards, addresses, and fingerprints on this wallet</div>  
                                </div>
                            :
                            <></>
                        }
                        <div className="d-flex align-items-center">
                            <button
                                className="my-3 btn-outline-primary btn-lg button-outline"
                                disabled={this.state.disableDeleteButton}
                                onClick={() => {
                                    this.props.startDeleteDevice({
                                        device: device,
                                        accountSettings: true,
                                        showModal: true,
                                        history
                                    })
                                }}>
                                Delete Wallet
                            </button>
                            <div className="font-weight-bold" style={{ marginLeft: '0.5rem' }}>Remove all cards and information from this wallet</div>  
                                   
                        </div>
                    </div>
                    {/* Show Card List for connected locked wallet */}
                    <div>
                        {
                            device.connected && (device.state === "LOCKED" || device.pendingState === "LOCKED") && this.props.cards.cards.length > 0?
                            <div>
                                <p className="divider my-4"/>
                                {/* Card List */}
                                <div>
                                    <h3> Cards in Wallet</h3>
                                {
                                this.props.cards.cards.map((card, index)=>{
                                    return(
                                        <div 
                                            key={index}
                                            className="align-items-center divider py-3" >
                                            <div className = "d-flex align-items-center">
                                                <Image 
                                                className="card-thumbnail" 
                                                src ={this.getCardArtSrc(card)} 
                                                alt="cardArt"/>
                                                <span className="p-4 text-capitalize">**** {card.panLastFewDigits}</span>
                                            </div>
                                        </div>
                                    
                                )})}
                                </div>
                            </div>
                            :
                            <></>
                        }
                    </div>
                </div>
            </div>
        )
        
    }

}

const mapStateToProps = state => ({
    devices: state.devices,
    cards:state.cards,
    devicePortDiscovery: state.devicePortDiscovery
  })
  
  const mapDispatchToProps = (dispatch) => ({
    startDeleteDevice :(data) => dispatch(startDeleteDevice(data)),
    deleteDevice: (data) => dispatch(deleteDevice(data)),
    queryStatus:(data)=>dispatch(queryStatusFromDevice(data)),
    clearSecurityState:(data)=>dispatch(clearSecurityState(data)),
    startLockWallet :(data) => dispatch(startLockWallet(data)),
    lockWallet: (data) => dispatch(lockWallet(data)),
    startUnlockWallet :(data) => dispatch(startUnlockWallet(data)),
    unlockWallet: (data) => dispatch(unlockWallet(data)),
    clearDeviceState: () => dispatch(clearDeviceState())
  })
  
  export default connect(mapStateToProps, mapDispatchToProps)(RemoteManageWallet)