/*
 * INTEL CONFIDENTIAL
 * Copyright 2022 Intel Corporation.
 * This software and the related documents are Intel copyrighted materials, and
 * your use of them is governed by the express license under which they were
 * provided to you (License). Unless the License provides otherwise, you may not
 * use, modify, copy, publish, distribute, disclose or transmit this software or
 * the related documents without Intel's prior written permission. This software
 * and the related documents are provided as is, with no express or implied
 * warranties, other than those that are expressly stated in the License.
 */
import * as constants from "@pcwallet/common/constants"
import * as messages from '@pcwallet/common/endUserMessages'
import {base64ToJson} from '../utils'
import { getCardListFromServer} from './cards'
import { getCsrfCookieValue, getDeviceTokenFromCookie, customNumberParser } from "@pcwallet/common/utils"
import { parse, stringify, LosslessNumber } from 'lossless-json'

// called when entering delete device flow 
export const startDeleteDevice = data => dispatch => {
    dispatch({
        type: constants.START_DEVICE_DELETE,
        payload: data
    })
}

// called when entering report device flow 
export const startReportDevice = data => dispatch => {
    dispatch({
        type: constants.START_DEVICE_REPORT,
        payload: data.device
    })
    data.history.push('/report-device')
}

// called when entering update device flow 
export const startUpdateDevice = data => dispatch => {
    dispatch({
        type: constants.START_DEVICE_UPDATE,
        payload: data.device
    })
    data.history.push('/update-device')
}

// called when entering register device flow 
export const startRegisterDevice = dispatch => {
    dispatch({
        type: constants.START_DEVICE_REGISTER,
    })
}

export const getDevices = (data,dispatch) => {
    dispatch({
        type: constants.GET_DEVICES
    });

    const cookieValue = getCsrfCookieValue()
    fetch(`${constants.INUS_ADDRESS}/us/v1/devices`, {
        method: 'POST',
        credentials : 'include',
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            'X-XSRF-TOKEN' : `${cookieValue ? cookieValue : ''}`
        },
        body:stringify({
        })
    })
        .then((response) => {
            if (response.status === 401) {
                dispatch({
                    type: constants.ACCESS_TOKEN_INVALID,
                })
                throw response.status
                
            }
            else {
                return response.text()
            }
        })
        .then((response) => {
            return parse(response, null, customNumberParser)
        }
        )
        .then((response) => {
            if (response.errorCode !== "OK") {
                dispatch({
                    type: constants.GET_DEVICES_FAILED,
                    payload: response.message
                });
            }
            else{
                if(response.devices != undefined || response.devices != null){
                    dispatch({
                        type: constants.GET_DEVICES_SUCCESS,
                        payload: response.devices
                    });
                    let connected=0
                    for (var i = 0 ; i < response.devices.length; i++){
                        if(response.devices[i].connected){
                            connected=+1
                            var devData = {}
                            devData.connected= response.devices[i].connected
                            devData.registered=true
                            devData.owner=true
                            devData.publicId=response.devices[i].publicId
                            devData.deviceId=response.devices[i].deviceId
                            devData.displayName=response.devices[i].displayName
                            devData.walletSetupProgress=response.devices[i].walletSetupProgress
                            devData.walletSetupStatus=parseInt(response.devices[i].walletSetupStatus)
                            devData.state=response.devices[i].state                     
                            devData.pendingState=response.devices[i].pendingState                     
                            devData.capability=parseInt(response.devices[i].capability)
                            devData.pendingCapability=parseInt(response.devices[i].pendingCapability)
                            dispatch({
                                type: constants.DETECT_DEVICE_SUCCESS,
                                payload: devData
                            });
                            if(response.devices[i].connected){                              
                                data.walletSetupProgress=devData.walletSetupProgress
                                data.deviceId = response.devices[i].deviceId
                                data.publicId = response.devices[i].publicId
                                data.state=response.devices[i].state
                                data.pendingState=response.devices[i].pendingState
                                data.capability=response.devices[i].capability
                                data.pendingCapability=response.devices[i].pendingCapability
                                if(data.detectDevice || data.walletSetupProgress === constants.CARD_DIGITIZE || data.walletSetupProgress === constants.BILLING_ADDRESS_ENROLL){
                                    dispatch(getCardListFromServer(data))
                                }
                                else{
                                    dispatch({
                                        type: constants.DETECT_DEVICE_COMPLETE,
                                    }); 
                                    if(data.callback){
                                        data.callback();
                                    }
                                }
                            }                        
                        }
                        else{
                            if( connected === 0 && i === response.devices.length-1){
                                dispatch({
                                    type: constants.DETECT_DEVICE_COMPLETE,
                                });   
                            }
                        }
                    }
                    if( connected === 0){
                        dispatch({
                            type: constants.DETECT_DEVICE_COMPLETE,
                        });                    
                    }
                }
                else{
                    dispatch({
                        type: constants.DETECT_DEVICE_COMPLETE,
                    });     
                }
            }
        })
        .catch((error) => {
            if(error !==  401) {
                console.error('Error:', error);
                dispatch({
                    type: constants.GET_DEVICES_FAILED,
                    payload: messages.DEVICE_GET_LIST_FAILED
                });
            }
        });
}

//QueryStatus - Browser to MTO
export const queryStatusFromDevice = (data) => (dispatch) => {
    dispatch({
        type: constants.DETECT_DEVICE
    })
    const deviceToken = getDeviceTokenFromCookie();
    let requestBody = stringify({
        token: deviceToken,
        cmd:constants.QUERY_STATUS
    })
    fetch(`${constants.MTO_ADDRESS_HOSTNAME}:${String(data.devicePort)}/v1/command`, {
        method: 'POST',
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json'
        },
        body: requestBody
    })
    .then((response) => response.json())
    .then((response) => {
        if (response.status) {
            if(response.regStatus === "NOT_REGISTERED"){
                response.registered = false
                response.owner = false           
            }
            else if(response.regStatus === "NOT_AN_OWNER"){
                response.registered = true
                response.owner = false
            }
            dispatch({
                type: constants.DETECT_DEVICE_SUCCESS,
                payload: response
            });    
        }
        if(data.history) {
            data.history.push('/dashboard')
        }
        //getting device list
        data.detectDevice=false
        getDevices(data,dispatch)
    })
    .catch((error) => {
        console.error('Error:', error);
        //getting device list even if device query fails
        data.detectDevice=false
        getDevices(data,dispatch)
    });
}

export const prepareDeviceForRegistration = (data) => (dispatch)=>{
    dispatch({
        type: constants.PREPARE_REGISTRATION
    })
    const deviceToken = getDeviceTokenFromCookie()
    let requestBody = stringify({
        token: deviceToken,
        cmd:constants.PREPARE_REGISTRATION
    })
    fetch(`${constants.MTO_ADDRESS_HOSTNAME}:${String(data.devicePort)}/v1/command`, {
        method: 'POST',
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json'
        },
        body: requestBody
    })
    .then((response) => {
        if (response.status >= 200 && response.status < 300) {
            return response.json();
        }
        if (response.status === 401) {
            dispatch({
                type: constants.ACCESS_TOKEN_INVALID
            })
            throw response.status
        }
        if (response.status === 403) {
            dispatch({
                type: constants.USER_ACTION_FORBIDDEN
            })
        } 
        else {
            dispatch({
                type: constants.PREPARE_REGISTRATION_FAILED,
                payload:"Failed to prepare wallet for registration. Please retry or come back later."
            });
        }
    })
    .then((response) => {
        if (response.status) {
            if(response.regStatus === "NOT_REGISTERED"){
                response.registered = false
                response.owner = false           
            }
            dispatch({
                type: constants.DETECT_DEVICE_SUCCESS,
                payload: response
            });
            //getting device list
            data.detectDevice=false
            getDevices(data,dispatch)    
            data.callback();
        } else {
            dispatch({
                type: constants.PREPARE_REGISTRATION_FAILED,
                payload:response.message
            });            
        }
    })
    .catch((error) => {
        if(error !==  401) {
            console.error('Error:', error);
            dispatch({
                type: constants.ADD_DEVICE_FAILED,
                payload: messages.DEVICE_ADD_FAILED
            });
        }
    });
}


export const preRegisterDevice = data => (dispatch) => {
    dispatch({
        type: constants.ADD_DEVICE,
        payload: messages.DEVICE_WALLET_CREATE_LOADING_MSG
    })
    const cookieValue = getCsrfCookieValue()
    const requestURL = `${constants.INUS_ADDRESS}/us/v1/preregisterDevice`
    fetch(requestURL, {
        method: 'POST',
        credentials : 'include',
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            'X-XSRF-TOKEN' : `${cookieValue ? cookieValue : ''}`
        },
        body: stringify({
            displayName: data.displayName,
        })
    })
    .then((response) => {
        if (response.status === 401) {
            dispatch({
                type: constants.ACCESS_TOKEN_INVALID,
            })
            throw response.status
        }
        else {
            return response.text()
        }
    })
    .then((response) => {
        return parse(response, null, customNumberParser)
    })
    .then((response) => {
        if (response.errorCode !== "OK") {
            dispatch({
                type: constants.ADD_DEVICE_FAILED,
                payload: response.message
            });
            data.callback();           
        } else {
            // trigger device registration
            registerDevice(data,dispatch);
        }
    })
    .catch((error) => {
        if(error !==  401) {
            console.error('Error:', error);
            dispatch({
                type: constants.ADD_DEVICE_FAILED,
                payload: messages.DEVICE_ADD_FAILED

            });
            data.callback();
        }
    });
}

const registerDevice = (data,dispatch) => {
    const deviceToken = getDeviceTokenFromCookie();;
    let requestBody = stringify({
        token: deviceToken,
        cmd:constants.REGISTER_DEVICE
    })
    fetch(`${constants.MTO_ADDRESS_HOSTNAME}:${String(data.devicePort)}/v1/command`, {
        method: 'POST',
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json'
        },
        body: requestBody
    })
    .then((response) => {
        return response.json()
    })
    .then((response) => {
        if (!response.status) {
            dispatch({
                type: constants.ADD_DEVICE_FAILED,
                payload:response.message
            });
            data.callback();
        } else {
            data.registerDevice=true;
            getNextJob(data,dispatch)
        }
    })
    .catch((error) => {
        console.error('Error:', error);
        dispatch({
            type: constants.ADD_DEVICE_FAILED,
            payload: messages.DEVICE_ADD_FAILED
        });
        data.callback();
    });
}

export const updateDevice = data => (dispatch) => {
    dispatch({
        type: constants.UPDATE_DEVICE
    })
    const cookieValue = getCsrfCookieValue()
    const requestURL = `${constants.INUS_ADDRESS}/us/v1/device/${data.deviceId}`
    fetch(requestURL, {
        method: 'PUT',
        credentials : 'include',
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            'X-XSRF-TOKEN' : `${cookieValue ? cookieValue : ''}`
        },
        body: stringify(data.updateInfo)
    })
    .then((response) => {
        if (response.status === 401) {
            dispatch({
                type: constants.ACCESS_TOKEN_INVALID,
            })
            throw response.status
        }
        else {
            return response.text()
        }
    })
    .then((response) => {
        return parse(response, null, customNumberParser)
    }
    )
    .then((response) => {
        if (response.errorCode === "OK") {
            dispatch({
                type: constants.UPDATE_DEVICE_SUCCESS,
                payload: data.updateInfo
            });
        } else {
            dispatch({
                type: constants.UPDATE_DEVICE_FAILED,
                payload: response.message
            });
        }
        data.callback();
    }).catch((error) => {
        if(error !==  401) {
            dispatch({
                type: constants.UPDATE_DEVICE_FAILED,
                payload: messages.DEVICE_UPDATE_FAILED
            });
        }
    })
}

export const deleteDevice = (data) =>(dispatch) => {
    dispatch({
        type: constants.DELETE_DEVICE
    })
    const cookieValue = getCsrfCookieValue()
    const requestURL = `${constants.INUS_ADDRESS}/us/v1/device/${data.device.deviceId}`
    fetch(requestURL, {
        method: 'DELETE',
        credentials : 'include',
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            'X-XSRF-TOKEN' : `${cookieValue ? cookieValue : ''}`
        }
    })
    .then((response) => {
        if (response.status === 401) {
            dispatch({
                type: constants.ACCESS_TOKEN_INVALID,
            })
            throw response.status
        }
        else {
            return response.text()
        }
    })
    .then((response) => {
        return parse(response, null, customNumberParser)
    })
    .then((response) => {
        if (response.errorCode !== "OK") {
            dispatch({
                type: constants.DELETE_DEVICE_FAILED,
                payload: response.message
            });
        } else {
            if(data.device.connected){
                getNextJob(data,dispatch)
            }
            else{
                dispatch({
                    type: constants.DELETE_DEVICE_PENDING
                });
                dispatch({
                    type:constants.CLEAR_SESSION_DELETE_DEVICE
                })
                data.detectDevice=false
                getDevices(data,dispatch)
                data.callback()
            }
        }
    }).catch((error) => {
        if(error !==  401) {
            dispatch({
                type: constants.DELETE_DEVICE_FAILED,
                payload: messages.DEVICE_DELETE_FAILED
            });
        }
    })
}

export const clearDeleteDeviceState = data => (dispatch) => {
    dispatch({
        type: constants.DELETE_DEVICE_CANCEL,
    });
    if(data){
        let pathname = data.history.location.pathname
        data.history.push(pathname)
    }
}


export const clearDeviceState = (data) => dispatch => {
    dispatch({
        type: constants.DEVICE_STATE_CLEAR,
    })
}

export const startLockWallet = data => dispatch => {
    dispatch({
        type: constants.LOCK_WALLET_START,
        payload: data
    })
}

export const cancelLockWallet = data => (dispatch) => {
    dispatch({
        type: constants.LOCK_WALLET_CANCEL,
    });
    if(data){
        let pathname = data.history.location.pathname
        data.history.push(pathname)
    }
}

export const lockWallet = data => (dispatch) => {
    dispatch({
        type: constants.LOCK_WALLET_LOADING
    })
    const cookieValue = getCsrfCookieValue()
    const requestURL = `${constants.INUS_ADDRESS}/us/v1/device/${data.device.publicId}/lock`
    fetch(requestURL, {
        method: 'PUT',
        credentials : 'include',
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            'X-XSRF-TOKEN' : `${cookieValue ? cookieValue : ''}`
        }
    })
    .then((response) => {
        if (response.status === 401) {
            dispatch({
                type: constants.ACCESS_TOKEN_INVALID,
            })
            throw response.status
        }
        else {
            return response.text()
        }
    })
    .then((response) => {
        return parse(response, null, customNumberParser)
    })
    .then((response) => {
        if (response.errorCode !== "OK") {
            dispatch({
                type: constants.LOCK_WALLET_FAILED,
                payload: response.message
            });
        } else {
            if(data.device.connected){
                data.lockWallet=true;
                getNextJob(data,dispatch)
            }
            else{
                //getting updated device status after lock
                dispatch({
                    type: constants.LOCK_WALLET_PENDING,
                    payload: response
                });
                data.detectDevice=false
                getDevices(data,dispatch)
                data.callback()
            }
        }
    }).catch((error) => {
        if(error !==  401) {
            dispatch({
                type: constants.LOCK_WALLET_FAILED,
                payload: messages.DEVICE_LOCK_FAILED
            });
        }
    })
}

export const startUnlockWallet = data => dispatch => {
    dispatch({
        type: constants.UNLOCK_WALLET_START,
        payload: data.device
    })
}

export const cancelUnlockWallet = data => (dispatch) => {
    dispatch({
        type: constants.UNLOCK_WALLET_CANCEL,
    });
    if(data){
        let pathname = data.history.location.pathname
        data.history.push(pathname)
    }
    
}

export const unlockWallet = data => (dispatch) => {
    dispatch({
        type: constants.UNLOCK_WALLET_LOADING
    })
    let requestURL = `${constants.INUS_ADDRESS}/us/v1/device/${data.device.publicId}/unlock`
    if(data.updateCapablity){
        requestURL = `${constants.INUS_ADDRESS}/us/v1/device/${data.device.publicId}/unlockCvmId`
    }
    const cookieValue = getCsrfCookieValue()
    fetch(requestURL, {
        method: 'PUT',
        credentials : 'include',
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            'X-XSRF-TOKEN' : `${cookieValue ? cookieValue : ''}`
        }
    })
    .then((response) => {
        if (response.status === 401) {
            dispatch({
                type: constants.ACCESS_TOKEN_INVALID,
            })
            throw response.status
        }
        else {
            return response.text()
        }
    })
    .then((response) => {
        return parse(response, null, customNumberParser)
    })
    .then((response) => {
        if (response.errorCode !== "OK") {
            dispatch({
                type: constants.UNLOCK_WALLET_FAILED,
                payload: response.message
            });
        } else {
            data.unlockWallet=true
            getNextJob(data,dispatch)            
        }
    }).catch((error) => {
        if(error !==  401) {
            dispatch({
                type: constants.UNLOCK_WALLET_FAILED,
                payload: messages.DEVICE_UNLOCK_FAILED

            });
        }
    })
}

export const getNextJob = (data,dispatch) => {
    const deviceToken = getDeviceTokenFromCookie();
    let requestBody = stringify({
        token: deviceToken,
        cmd: constants.GET_NEXT_JOB,
    })
    if(data.registerDevice){
        dispatch({
            type: constants.SETUP_WALLET_MSG,
            payload: messages.DEVICE_WALLET_NAME_LOADING_MSG
        })
    }
    const requestURL = `${constants.MTO_ADDRESS_HOSTNAME}:${String(data.devicePort)}/v1/command`
    fetch(requestURL, {
        method: 'POST',
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
        },
        body: requestBody
    })
    .then((response) => response.json())
    .then((response) => {
        if (!response.status) {
            dispatch({
                type: constants.GET_NEXT_JOB_FAILED,
                payload:response
            })
            data.callback();
        } else {
            dispatch({
                type: constants.GET_NEXT_JOB_SUCCESS,
            });
            if(data.registerDevice){
                var history = data.history
                //just getDevice list
                dispatch(queryStatusFromDevice({
                    detectDevice:false,
                    devicePort:data.devicePort,
                    callback:()=>{
                        data.callback();
                    }
                }))                
            }
            else if(data.lockWallet){
                dispatch({
                    type: constants.LOCK_WALLET_SUCCESS,
                    payload: response
                });
                data.callback()
            }
            else if(data.unlockWallet){
                dispatch({
                    type: constants.UNLOCK_WALLET_SUCCESS,
                    payload: response
                });
                data.callback()
            }
            else{
                dispatch({
                    type: constants.DELETE_DEVICE_SUCCESS,
                    payload: response
                });
                dispatch({
                    type:constants.CLEAR_SESSION_DELETE_DEVICE
                })
                data.callback()
            }
        }
    })
    .catch((error) => {
        console.log(error)
        dispatch({
            type: constants.GET_NEXT_JOB_FAILED,
            payload: messages.GET_NEXT_JOB_FAILED
        });
        data.callback();
    })
}

export const reportWalletSetupProgress = data => (dispatch) => {
    console.log("reportWalletSetupProgress")
    let requestURL = `${constants.INUS_ADDRESS}/us/v1/walletSetupProgress`
    let requestBody = stringify({
        deviceId: new LosslessNumber(data.deviceId).valueOf(),
        walletSetupProgress: data.walletSetupProgress,
    })
    const cookieValue = getCsrfCookieValue()
    fetch(requestURL, {
        method: 'PUT',
        credentials : 'include',
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            'X-XSRF-TOKEN' : `${cookieValue ? cookieValue : ''}`
        },
        body:requestBody
    })
    .then((response) => {
        if (response.status === 401) {
            dispatch({
                type: constants.ACCESS_TOKEN_INVALID,
            })
            throw response.status
        }
        else {
            return response.text()
        }
    })
    .then((response) => {
        return parse(response, null, customNumberParser)
    })
    .then((response) => {
        if (response.errorCode !== "OK") {
            dispatch({
                type: constants.WALLET_SETUP_PROGRESS_FAILED,
            });
        } else {
            dispatch({
                type: constants.WALLET_SETUP_PROGRESS_SUCCESS,
                payload : {
                    walletSetupProgress:response.walletSetupProgress,
                    walletSetupStatus:parseInt(response.walletSetupStatus)
                }
            });
            if(data.walletSetupProgress === constants.DONE){
                //setup complete
                dispatch({
                    type:constants.WALLET_SETUP_COMPLETE
                })
                dispatch({
                    type: constants.DETECT_DEVICE_COMPLETE,
                });
            }           
        }
        data.callback()
    }).catch((error) => {
        if(error !==  401) {
            dispatch({
                type: constants.WALLET_SETUP_PROGRESS_FAILED,
            });
        }
        data.callback()
    })
}

export const cancelSetup = (data) => (dispatch) => {
    dispatch({
        type:constants.WALLET_SETUP_CANCELLED,
        payload:data
    })
}

export const resetErrorMessages = () => (dispatch)=>{
    dispatch({
        type: constants.RESET_ERROR_STATES,
      })
}
