/*
 * INTEL CONFIDENTIAL
 * Copyright 2022 Intel Corporation.
 * This software and the related documents are Intel copyrighted materials, and
 * your use of them is governed by the express license under which they were
 * provided to you (License). Unless the License provides otherwise, you may not
 * use, modify, copy, publish, distribute, disclose or transmit this software or
 * the related documents without Intel's prior written permission. This software
 * and the related documents are provided as is, with no express or implied
 * warranties, other than those that are expressly stated in the License.
 */
import React, { Component } from 'react'
import { connect } from 'react-redux'
import { Form } from 'react-bootstrap'

import * as constants from '../../constants'
import {resetErrorMessages, showAccount } from '../../actions/user'
import { queryStatusFromDevice, clearDeviceState } from '../../actions/devices'
import { resetAccountUpdateSuccess } from '../../actions/user'

import ChangePassword from './ChangePassword'
import ErrorMessage from '../shared/ErrorMessage'
import ManageWallets from './ManageWallets'
import Notification from '../shared/Notification';
import UserDetails from './UserDetails'


export class AccountSettings extends Component {

    constructor(props) {
        super(props)
        this.state = {
            showContacts:true,
            showPasswords:false,
            showNotifications:false,
            showManageWallets:false,
            showToast:false
          }
        this.hideNotification = this.hideNotification.bind(this)
    }

    hideNotification () {     
        this.setState({showToast:false})
        this.props.resetSuccess({showSuccess:false})
        //reset for remote device management
        if(this.state.showManageWallets){
            if(this.props.devices.deviceState === constants.DELETE_DEVICE_PENDING ){
                this.props.clearDeviceState();
            }
            else{
                this.props.clearDeviceState();
            }
        }
    }

    componentDidMount() {
        this.props.showAccount({showAccount:true})
        const { history } = this.props;
        if(!this.props.devices.connected || !this.props.devicePortDiscovery.devicePort ){
            setTimeout(()=>{
                this.props.queryStatus({
                    detectDevice:true,
                    devicePort: this.props.devicePortDiscovery.devicePort,
                    history
                })
            },100)
        }
        if(this.props.user.showSuccess){
            this.setState({
                showToast:true
            })
        }
    }

    componentWillUnmount(){
        this.props.showAccount({showAccount:false})
    }

    componentDidUpdate(prevProps){
        if(this.props.user.showSuccess!== prevProps.user.showSuccess && this.props.user.showSuccess){
            this.setState({
                showToast:true
            })           
        }
        else if(this.props.devices.deviceState !== prevProps.devices.deviceState && this.props.devices.success){
            this.setState({
                showToast:true
            })
        }
    }

    render() {
        const { history } = this.props;
    return (       
        <div>
            <div className="wrapper">
                <div className="content">
                    {
                        this.props.user.error && this.props.user.errorMessage?
                        <ErrorMessage
                            show = {this.props.user.error}
                            msg = {this.props.user.errorMessage}
                            onClose ={()=>{
                                this.props.resetErrorMessages()
                            }}
                        />
                        :<></>
                    }
                    <div className="main-wrapper">
                            <div className="active-card-header pt-1">
                                <h1><b>Account Settings</b></h1>
                                <div className = "card-link-bar">
                                <div 
                                    className ={this.state.showContacts?"mt-3 active":"mt-3"}
                                    onClick={()=>{
                                        this.setState({
                                            showContacts:true,
                                            showPasswords:false,
                                            showNotifications:false,
                                            showManageWallets:false
                                        })
                                    }}
                                    >User Details</div>
                                <div 
                                    className ={this.state.showPasswords?"mt-3 ml-5 active" :"mt-3 ml-5"}
                                    onClick={()=>{
                                        this.setState({
                                            showContacts:false,
                                            showPasswords:true,
                                            showNotifications:false,
                                            showManageWallets:false
                                        })
                                    }}>Change Password
                                </div>
                                <div 
                                    className ={this.state.showNotifications?"mt-3 ml-5 active" :"mt-3 ml-5"}
                                    onClick={()=>{
                                        this.setState({
                                            showContacts:false,
                                            showPasswords:false,
                                            showNotifications :true,
                                            showManageWallets:false
                                        })
                                    }}
                                    >Notifications
                                </div>
                                {
                                    this.props.devices.connected && this.props.devices.saved.length > 1?
                                    <div 
                                        className ={this.state.showManageWallets?"mt-3 ml-5 active" :"mt-3 ml-5"}
                                        onClick={()=>{
                                            this.setState({
                                                showContacts:false,
                                                showPasswords:false,
                                                showNotifications:false,
                                                showManageWallets:true
                                            })
                                        }}>Manage Wallets
                                    </div>
                                    :
                                    <></>
                                }
                            </div>
                            </div>                           
                            <p className="divider"></p>
                            {
                                    this.state.showToast && this.props.user.showSuccess && this.state.showPasswords && this.props.user.accountState == constants.USER_CHANGE_PASSWORD_SUCCESS?
                                        <Notification 
                                            show={this.state.showToast}
                                            hide={this.hideNotification}
                                            msg={"Account password successfully changed."}/>                           
                                    :                      
                                    this.state.showToast && this.props.user.showSuccess && this.state.showContacts && this.props.user.accountState == constants.UPDATE_ACCOUNT_SUCCESS?
                                        <Notification 
                                            show={this.state.showToast}
                                            hide={this.hideNotification}
                                            msg={"User Details updated successfully."}/>                           
                                    :
                                    this.state.showToast && this.props.devices.success && this.props.devices.deviceState === constants.LOCK_WALLET_SUCCESS?
                                        <Notification 
                                            show={this.state.showToast}
                                            hide={this.hideNotification}
                                            msg={"Wallet was succesfully locked."}/>                           
                                    :
                                    this.state.showToast && this.props.devices.success &&  this.props.devices.deviceState === constants.DEVICE_STATE_LOCK_PENDING?
                                        <Notification 
                                            show={this.state.showToast}
                                            hide={this.hideNotification}
                                            msg={"Remote Wallet lock set successfully."}/>                           
                                    :
                                    this.state.showToast && this.props.devices.success &&  this.props.devices.deviceState === constants.DELETE_DEVICE_PENDING?
                                        <Notification 
                                            show={this.state.showToast}
                                            hide={this.hideNotification}
                                            msg={"Remote Wallet delete set successfully."}/>                           
                                    :                      
                                    <></>
                                }
                            <div className ="active-card-content pb-3">
                                {
                                    this.state.showContacts?
                                    <UserDetails/>
                                    :
                                    this.state.showPasswords?
                                    <div className="px-1 pt-4">
                                        <ChangePassword/>
                                    </div>
                                    :
                                    this.state.showManageWallets && (this.props.devices.connected && this.props.devices.saved.length > 1)?
                                    <div className="px-1 pt-4">
                                        <ManageWallets history={history}/>
                                    </div>
                                    :
                                    <div>
                                        <div >
                                            <div className="my-4 d-flex align-items-center">                                
                                                    <input 
                                                    type="checkbox" 
                                                    checked
                                                    disabled={true}
                                                    readOnly/>
                                                    <div className="pl-2 text-muted ">Send transaction notifications.</div>
                                                
                                            </div>
                                            <div className="my-4" >
                                                <div className="d-flex align-items-center">
                                                <label className="px-5 text-muted">All notifications will be sent to: {this.props.user.data.email}</label>
                                                </div>
                                            </div>
                                                        
                                    </div>
                                    </div>
                                }
                                                
                            </div>
                    </div>
                </div>
            </div>
        </div>
    )
}
}

const mapStateToProps = state => ({
    user:state.user,
    devices:state.devices,
    devicePortDiscovery: state.devicePortDiscovery
})

const mapDispatchToProps = (dispatch) => ({
    showAccount:(data)=>dispatch(showAccount(data)),
    resetSuccess:(data)=>dispatch(resetAccountUpdateSuccess(data)),
    queryStatus:(data)=>dispatch(queryStatusFromDevice(data)),
    clearDeviceState: () => dispatch(clearDeviceState()),
    resetErrorMessages: () => dispatch(resetErrorMessages()),
    
})

export default connect(mapStateToProps, mapDispatchToProps)(AccountSettings)
