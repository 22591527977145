/*
 * INTEL CONFIDENTIAL
 * Copyright 2022 Intel Corporation.
 * This software and the related documents are Intel copyrighted materials, and
 * your use of them is governed by the express license under which they were
 * provided to you (License). Unless the License provides otherwise, you may not
 * use, modify, copy, publish, distribute, disclose or transmit this software or
 * the related documents without Intel's prior written permission. This software
 * and the related documents are provided as is, with no express or implied
 * warranties, other than those that are expressly stated in the License.
 */
import * as constants from '@pcwallet/common/constants'

const initialState = {
    isLoading: false,
    error: false,
    success: false,
    errorMessage: '',
    showCancel:false,
    showNameFinger:false,
    fingerList:[],
    fingerAction:'',
    enrollmentState:'start',
    templateId:0,
    // changePincode:false,
    // resetPincode:false,
    finished:false,
    showPIN:false,
    showSettings:false,
    securityState:constants.SHOW_FINGERPRINT_SETTINGS,
    percentage:0,
    isLoadingModal:false,
    rename:false
}

export default function securityUpdate(state = initialState, { type, payload }) {
    switch (type) {
        case constants.START_FP_ADD:
            return {
                ...state,
                success: false,
                rename:false,
                authState: constants.FP_STATE_ADD
            }
        case constants.START_FP_UPDATE:
            return {
                ...state,
                success: false,
                authState: constants.FP_STATE_UPDATE
            }
        case constants.FP_ENROLLMENT_START:
            return {
                ...state,
                finished: false,
                isLoading: true,
                enrollmentState:'start'
            }
        case constants.FP_ENROLLMENT_START_SUCCESS:
            return {
                ...state,
                isLoading: false,
                success: false, 
                error: false,
            }
        case constants.FP_ENROLLMENT_START_FAILED:
            return {
                ...state,
                isLoading: false,
                error: true,
                errorMessage: payload,
                showCancel:false,
            }
        case constants.FP_ENROLLMENT_CALLBACK:
            return {
                ...state,
                showCancel:true,
                instruction: 'Tap sensor to start enrollment... ',
                enrollmentState:'start',
                error: false,
                authState: constants.FP_STATE_ADD
            }
        case constants.FP_ENROLLMENT_CALLBACK_SUCCESS:
            return {
                ...state,
                isLoading: false,
                success: false,
                finished: false,
                instruction: payload.instruction,
                enrollmentState:'scanning',
                percentage:payload.percentage,
                error: false,
                showCancel:true,
            }
        case constants.FP_ENROLLMENT_CALLBACK_FAILED:
            return {
                ...state,
                isLoading: false,
                error: true,
                instruction: payload,
                errorMessage: payload,
            }
        case constants.FP_ENROLLMENT_COMPLETE_FAILED:
            return {
                ...state,
                isLoading: false,
                error: true,
                errorMessage: payload,
                showCancel:false,
                instruction:payload,
                enrollmentState:'stop'
            }
        case constants.FP_ENROLLMENT_CANCEL_START:
            return {
                ...state,
                isLoading: true,
                showCancel:true,
                finished: false,
                instruction:"Cancelling Enrollment",
                enrollmentState:'cancelling'
            }
        case constants.FP_ENROLLMENT_CANCELED:
            return {
                ...state,
                error:false,
                isLoading: false,
                showCancel:false,
                finished: true,
                instruction:"Enrollment Canceled",
                enrollmentState:'stop'
            }
        case constants.FP_ENROLLMENT_FINGERPRINT_NAME_START:
            return {
                ...state,
                isLoading: false,
                success: false,
                finished: false,
                instruction: "Fingerprint saved! Please name the fingerprint (for example, right index, left thumb).",
                error: false,
                showCancel:false,
                showNameFinger:true, 
                templateId:payload,
                enrollmentState:'complete'              
            }
        case constants.NAME_FINGERPRINT_LOADING:
            return {
                ...state,
                isLoading: false,
                isLoadingModal:true,
                error: false, 
            }
        case constants.NAME_FINGERPRINT_SUCCESS:
            return {
                ...state,
                isLoading: false,
                isLoadingModal:false,
                success: true,
                finished: true,
                instruction: '',
                error: false,
                showCancel:false,
                showNameFinger:false,
                enrollmentState:'start',
                authState: constants.NAME_FINGERPRINT_SUCCESS           
            }
        case constants.NAME_FINGERPRINT_FAILED:
            return {
                ...state,
                isLoading: false,
                isLoadingModal:false,
                success: false,
                finished: false,
                showCancel:false,
                showNameFinger:true,  
                error: true,
                errorMessage:payload ,
                enrollmentState:'start'            
            }
        case constants.SHOW_FINGERPRINT_SETTINGS:
            return {
                ...state,
                isLoading: false,
                error:false,
                securityState:constants.SHOW_FINGERPRINT_SETTINGS
            }
        case constants.FINGERPRINT_LIST_GET_START:
            return {
                ...state,
                isLoading: false,
                error: false,
                success:false,
                authState: constants.FINGER_ACTION_FINGERPRINT_LIST_SHOW                
            }
        case constants.FINGERPRINTS_GET_SUCCESS:
            return {
                ...state,
                isLoading: false,
                error: false,
                fingerList:payload.fingers 
            }
        case constants.FINGERPRINTS_GET_FAILED:
            return {
                ...state,
                isLoading: false,
                error: true,
                errorMessage:payload            
            }
        case constants.FINGERPRINT_RENAME_START:
            return {
                ...state,
                isLoading: false,
                success: false,
                error: false,
                rename:true,
                activeFingerName:payload.fingerName,
                activeTemplateId:payload.templateId,       
                authState: constants.FINGER_ACTION_FINGERPRINT_RENAME      
            }
            case constants.FINGERPRINT_DELETE_START: 
            return {
                ...state,
                success: false,
                errorMessage: '',
                isLoadingModal:false,
                activeFingerName:payload.fingerName,
                activeTemplateId:payload.templateId,
                authState: constants.FINGER_ACTION_FINGERPRINT_DELETE
            }
        case constants.FINGERPRINT_DELETE:
            return {
                ...state,
                error:false,
                errorMessage: '',
                success: false,
                isLoading: false,
                isLoadingModal:true,
                authState: constants.FINGER_ACTION_FINGERPRINT_DELETE
            }
        case constants.FINGERPRINT_DELETE_SUCCESS:
            return {
                ...state,
                isLoading: false,
                errorMessage: '',
                success: true,
                error: false,
                isLoadingModal:false,
                activeDevice: payload.instruction,
                templateId:payload.templateId,
                authState: constants.FINGERPRINT_DELETE_SUCCESS
            }
        case constants.FINGERPRINT_DELETE_FAILED:
            return {
                ...state,
                isLoading: false,
                isLoadingModal:false,
                success: false,
                error: true,
                errorMessage: payload,
                authState: constants.FINGER_ACTION_FINGERPRINT_DELETE
            }
        case constants.FINGERPRINT_NAME_START:
            return {
                ...state,
                isLoading: false,
                error:false,
                authState: constants.FINGER_ACTION_FINGERPRINT_RENAME
            }
        case constants.SHOW_PIN_SETTINGS:
            return {
                ...state,
                isLoading: false,
                error:false,
                securityState:constants.SHOW_PIN_SETTINGS
            }
        case constants.PIN_SETUP_START:
            return {
                ...state,
                success: false,
                // changePincode:false,
                authState: constants.PIN_STATE_ADD
            }
        case constants.PIN_SETUP_LOADING:
            return {
                ...state,
                isLoading: true,
                error:false,
                errorMessage:null,
            }
        case constants.PIN_STATE_CHANGE_START:
            return {
                ...state,
                error: false,
                errorMessage:null,
                success: false,
                // changePincode:true,
                authState: constants.PIN_STATE_CHANGE
            }
        case constants.PIN_STATE_RESET_START:
            return {
                ...state,
                error: false,
                errorMessage:null,
                success: false,
                // changePincode:false,
                // resetPincode:true,
                authState: constants.PIN_STATE_RESET
            }
        case constants.PIN_SETUP_SUCCESS:
            return {
                ...state,
                isLoading: false,
                success: true, 
                finished:true,
                error: false,
                authState: constants.PIN_SETUP_SUCCESS                
            }
        case constants.PIN_SETUP_FAILED:
            return {
                ...state,
                isLoading: false,
                error: true,
                errorMessage: payload,
                success: false, 
                finished:false,
            }
        case constants.SHOW_WALLET_SETTINGS:
            return {
                ...state,
                isLoading: false,
                error:false,
                securityState:constants.SHOW_WALLET_SETTINGS
            }
        case constants.SECURITY_STATE_CLEAR:
            return {
                ...state,
                authState: '',
                success:false,
                finished:false,
                rename:false
            }
        case constants.RESET_ERROR_STATES:
            return{
                ...state,
                isLoading:false,
                errorMessage:'',
                error: false,
            }
        case constants.SECURITY_AUTH_STATE_CLEAR:
            return {
                ...state,
                authState: '',
            }
        default:
            return state
    }
}