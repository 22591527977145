/*
 * INTEL CONFIDENTIAL
 * Copyright 2022 Intel Corporation.
 * This software and the related documents are Intel copyrighted materials, and
 * your use of them is governed by the express license under which they were
 * provided to you (License). Unless the License provides otherwise, you may not
 * use, modify, copy, publish, distribute, disclose or transmit this software or
 * the related documents without Intel's prior written permission. This software
 * and the related documents are provided as is, with no express or implied
 * warranties, other than those that are expressly stated in the License.
 */
import React, { Component } from "react";
import { connect } from 'react-redux'
import { Redirect } from 'react-router';
import { TiDeleteOutline } from 'react-icons/ti';
import { Link } from 'react-router-dom'

import * as constants from '../../constants'
import { queryStatusFromDevice, clearDeviceState, resetErrorMessages } from '../../actions/devices'
import { clearSecurityState} from '../../actions/security'
import ErrorMessage from '../shared/ErrorMessage';
import Loading from '../shared/Loading';
import ManageWallets from "./ManageWallets";
import Notification from '../shared/Notification';
class DeviceDashboard extends Component{

    constructor(props) {
        super(props);
        this.state = {
          disableButton:false,
          showToast:false,
          disableLockButton:false,
          disableDeleteButton:false,
          showRemoteManageWallet: false,
        }
        this.handleClose = this.handleClose.bind(this)
        this.hideNotification = this.hideNotification.bind(this)
        this.handleSkip = this.handleSkip.bind(this)
    }

    hideNotification () {
        this.setState({showToast:false})
        if(this.props.devices.deviceState === constants.DELETE_DEVICE_PENDING ){
                this.props.clearDeviceState();
                this.props.history.push('/dashboard')
            }
            else{
                this.props.clearDeviceState();
            }
    }
  
    
    handleClose(){
    this.setState({
        disableButton:false,
        showToast:true
    })
    const { history } = this.props;
    if(this.props.devices.deviceState === constants.UNLOCK_WALLET_SUCCESS){
        this.props.queryStatus({
            detectDevice:true,
            devicePort: this.props.devicePortDiscovery.devicePort,
            history
        })
        this.props.history.push('/cards')
    }
    if(this.props.devices.deviceState === constants.DEVICE_STATE_LOCK_PENDING ){
        this.setState({
            disableLockButton:true,
            showToast:true})
    }
    if(this.props.devices.deviceState === constants.DELETE_DEVICE_PENDING ){
        this.setState({
            disableLockButton:true,
            disableDeleteButton:true,
            showToast:true})
    }
    else if(this.props.devices.deviceState === constants.DELETE_DEVICE_SUCCESS){
        this.props.clearSecurityState();
        this.props.history.push('/dashboard')
    }
    }
    handleSkip() {
        // go to remote management page
        this.setState({ showRemoteManageWallet : true})
    }
    componentDidMount() {
        const { history } = this.props;
        if(this.props.devices.deviceState  === constants.LOCK_WALLET_SUCCESS){
            this.setState({showToast:true})
        }
        if(this.props.devices.saved.length === 0 && !this.props.devices.setupCanceled){
            this.props.queryStatus({
                history,
                detectDevice:true,
                devicePort: this.props.devicePortDiscovery.devicePort,
                })
            }
    }

    componentDidUpdate(prevProps){
        if(this.props.devices.deviceState !== prevProps.devices.deviceState && this.props.devices.success){
            this.setState({
                showToast:true
            })
        }
    }

    render(){
        const { history } = this.props;

        return(
            <div className="wrapper">
                {
                    !this.state.showRemoteManageWallet && (this.props.devices.isLoading || this.props.devicePortDiscovery.isLoading || (this.props.devicePortDiscovery.discovering && !this.props.devices.isLoading) || this.props.devices.saved.length == 0) ?
                        <div className="content">
                        {
                            this.props.devices.error && this.props.devices.errorMessage?
                            <ErrorMessage
                                show = {this.props.devices.error}
                                msg = {this.props.devices.errorMessage}
                                onClose = {()=>{
                                    this.props.resetErrorMessages()
                                }}
                            />
                            :<></>
                        }   
                        <div className="main-wrapper">
                            <h1 className="d-flex px-5 justify-content-between">
                                Looking for wallet...
                                {
                                    this.props.devices.saved.length > 0 ? 
                                    <div
                                        className="button-tertiary-outline px-3 py-1"
                                        type="button"
                                        onClick={this.handleSkip}
                                    ><b>Skip</b>
                                    </div> 
                                    : 
                                    <></>
                                }
                            </h1>
                            <p className="divider my-4" />
                            <div className='d-flex flex-column align-items-center justify-content-center' style={{minHeight:'350px'}}>
                                <div className='loading-logo-in-card'><Loading ></Loading></div>
                                <p className='mt-1 p-2'>
                                    <strong>Looking for your wallet</strong>
                                </p>
                            </div>
                        </div>
                        </div>
                   
                    :
                    <div className="content">
                        {
                            this.props.devices.error && this.props.devices.errorMessage?
                            <ErrorMessage
                                show = {this.props.devices.error}
                                msg = {this.props.devices.errorMessage}
                                onClose = {()=>{
                                    this.props.resetErrorMessages()
                                }}
                            />
                            :<></>
                        }
                        {
                        !this.props.devices.connected || (this.props.devices.connected && this.props.devices.saved.length > 0 && this.props.devicePortDiscovery.devicePort === null)?
                        <div className="main-wrapper">
                            {
                                this.state.showRemoteManageWallet ? 
                                <h1 className="d-flex px-5 align-items-center justify-content-between">Manage Wallets</h1> : 
                                <h1 className="d-flex px-5 align-items-center justify-content-between">
                                    <div> <TiDeleteOutline size="1.5em" color="#dc3545"></TiDeleteOutline>
                                        Could not find your wallet</div>
                                    {
                                        this.props.devices.saved.length > 0 ? 
                                        <div
                                            className="button-tertiary-outline px-3 py-1"
                                            type="button"
                                            onClick={this.handleSkip}
                                        ><b>Skip</b>
                                        </div> 
                                        : 
                                        <></>
                                    }
                                </h1>
                            }
                            <p className="divider my-4"/>
                            <div className="px-5 pb-5">
                                {this.state.showRemoteManageWallet ? 
                                <>
                                    <div className="pt-5">
                                        <ManageWallets history={history} />
                                    </div>
                                </>:
                                <>
                                    <p className="pb-1 text-left font-weight-bold">
                                        Device could not detected, please plug in and plug out device and click retry button
                                    </p>
                                        <br></br>
                                    <b style={{fontSize:'0.9rem'}}>
                                        If issues persist, please contact <Link>Intel Pay customer support </Link>.
                                    </b>
                                    <div className="text-right ml-5">
                                        <button
                                            className="my-4 btn btn-primary btn-lg button"
                                            onClick={() => {
                                                window.location.reload();
                                            }}
                                        >Retry</button>
                                    </div>
                                </>
                                }
                            </div>
                        </div>
                        :
                        
                        (this.props.devices.connected && this.props.devicePortDiscovery.devicePort != null && (this.props.devices.connected.connected &&  (this.props.devices.connected.state === "LOCKED" || this.props.devices.connected.pendingState === "LOCKED" || (this.props.devices.connected.pendingCapability & 2) === 0))) ||
                        (this.props.devices.connected && this.props.devices.setupCanceled && 
                            ((this.props.devices.connected.enrolledFlag && !this.props.devices.connected.owner) ||
                            (!this.props.devices.connected.enrolledFlag && !this.props.devices.connected.owner)))?                       
                        <div className="content">
                            {
                            this.props.devices.error && this.props.devices.errorMessage?
                            <ErrorMessage
                                show = {this.props.devices.error}
                                msg = {this.props.devices.errorMessage}
                                onClose = {()=>{
                                    this.props.resetErrorMessages()
                                }}
                            />
                            :<></>
                        }
                        <div className="main-wrapper">
                            <h1 className="px-5">
                                Manage Wallets
                            </h1>
                            <p className="divider my-4"/>
                            {
                                this.state.showToast && this.props.devices.success && this.props.devices.deviceState === constants.LOCK_WALLET_SUCCESS?
                                    <Notification 
                                        show={this.state.showToast}
                                        hide={this.hideNotification}
                                        msg={"Wallet was succesfully locked."}/>                           
                                :
                                this.state.showToast && this.props.devices.success &&  this.props.devices.deviceState === constants.DEVICE_STATE_LOCK_PENDING?
                                    <Notification 
                                        show={this.state.showToast}
                                        hide={this.hideNotification}
                                        msg={"Remote wallet lock set successfully."}/>                           
                                :
                                this.state.showToast && this.props.devices.success &&  this.props.devices.deviceState === constants.DELETE_DEVICE_PENDING?
                                    <Notification 
                                        show={this.state.showToast}
                                        hide={this.hideNotification}
                                        msg={"Remote wallet delete set successfully."}/>                           
                                :
                                <></>
                            }
                                <div className="px-5 pb-5">
                                <ManageWallets history={history}/>
                            </div>
                        </div>
                        </div>
                        :
                        <Redirect to ='/dashboard'/>
                        }
                    </div>
                }
            </div>

        );
    }
}
const mapStateToProps = state => ({
    devices: state.devices,
    devicePortDiscovery: state.devicePortDiscovery
  })
  
  const mapDispatchToProps = (dispatch) => ({
    queryStatus:(data)=>dispatch(queryStatusFromDevice(data)),
    clearSecurityState:(data)=>dispatch(clearSecurityState(data)),
    clearDeviceState: () => dispatch(clearDeviceState()),
    resetErrorMessages: () => dispatch(resetErrorMessages())
  })
  
  export default connect(mapStateToProps, mapDispatchToProps) (DeviceDashboard)
  