/*
 * INTEL CONFIDENTIAL
 * Copyright 2022 Intel Corporation.
 * This software and the related documents are Intel copyrighted materials, and
 * your use of them is governed by the express license under which they were
 * provided to you (License). Unless the License provides otherwise, you may not
 * use, modify, copy, publish, distribute, disclose or transmit this software or
 * the related documents without Intel's prior written permission. This software
 * and the related documents are provided as is, with no express or implied
 * warranties, other than those that are expressly stated in the License.
 */
import React from 'react'
import { useField,Field } from 'formik'
import {Row, Col} from 'react-bootstrap'

const SelectInput = ({ label, ...props }) => {
    const [field, meta] = useField(props);
    return (
      <Row className='align-items-center'>
        {
          label?
          <Col>
            <label className={props.showstar ? "required form-label-font pt-3 text-capitalize" : 'pt-3 text-capitalize form-label-font'} htmlFor={props.id || props.name}>{label}</label>
          </Col>
          :''
        }
        <Col sm={label?9:12} className="select">
          <Row>
            {
              meta.touched && meta.error ?
                <div className="error">{meta.error}</div>
                : props.errorMessage ?
                  <div className="error">{props.errorMessage}</div>
                  :
                  <div className="valid"></div>
            }
          </Row>
          <Field className={["text-input text-capitalize",(meta.error && meta.touched) ? "text-input-error-border" : ""].join(' ')} as="select" {...field} {...props}>
          <option value="" disabled  className='text-capitalize text-muted'>Select {props.name}</option>
          { props.itemlist?
            props.itemlist.map((item, index)=>(<option key={index} value={item.value} className='text-capitalize'>{item.label}</option>))
            :
            props.default?
            <option readonly value={props.default} className='text-capitalize'>{props.default}</option>
            :
            <></>

          }
          </Field>
          
        </Col> 
      </Row>
    );
  };

  export default SelectInput