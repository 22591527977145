/*
 * INTEL CONFIDENTIAL
 * Copyright 2022 Intel Corporation.
 * This software and the related documents are Intel copyrighted materials, and
 * your use of them is governed by the express license under which they were
 * provided to you (License). Unless the License provides otherwise, you may not
 * use, modify, copy, publish, distribute, disclose or transmit this software or
 * the related documents without Intel's prior written permission. This software
 * and the related documents are provided as is, with no express or implied
 * warranties, other than those that are expressly stated in the License.
 */
import React, { Component } from 'react'
import { connect } from 'react-redux'
import Button from '../inputs/Button';
import { startAddCard} from '../../actions/cards' 
import { analyticsPageView } from '../../utils'
import Promo from '@pcwallet/common/assets/CCPromo.png'
import fpo from '@pcwallet/common/assets/FPO.png'

export class PCWalletCCPromo extends Component {    

    componentDidMount(){     
        //initializing analytics
        analyticsPageView('/pcwallet-promo');

    }

    render() {
        const { history } = this.props;
        return (
        <div className="wrapper">
            <div className="content">
            <div className="main-wrapper">
            <div className='text-center'>
                <div className='mt-5'>
                    <img className = "promo-watermark" src= {fpo}/>
                    <img className = "pcwallet-promo border" src= {Promo}/>
                </div>
                <div className='promo-button'>
                        <Button onClick={()=>{this.props.startAddCard({history})}}> Continue </Button>
                    </div>
                </div>
            </div>
            </div>
        </div>
    )}
}


const mapStateToProps = state => ({
    authData: state.user.data,
    cards: state.cards,
})

const mapDispatchToProps = (dispatch) => ({
    startAddCard: (data) => dispatch(startAddCard(data))
})

export default connect(mapStateToProps, mapDispatchToProps)(PCWalletCCPromo)
