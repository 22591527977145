/*
 * INTEL CONFIDENTIAL
 * Copyright 2022 Intel Corporation.
 * This software and the related documents are Intel copyrighted materials, and
 * your use of them is governed by the express license under which they were
 * provided to you (License). Unless the License provides otherwise, you may not
 * use, modify, copy, publish, distribute, disclose or transmit this software or
 * the related documents without Intel's prior written permission. This software
 * and the related documents are provided as is, with no express or implied
 * warranties, other than those that are expressly stated in the License.
 */
import * as constants from '@pcwallet/common/constants'
export const WalletSetupSteps = [
    {
        "label": "Wallet Set Up",
        "setupStatus":"NONE",
        "setupBit":1,
        "componentUrl": "register-device"
    },
    {
        "label": "Wallet Set Up",
        "setupStatus":"WALLET_REGISTRATION",
        "setupBit":2,
        "componentUrl": "/register-device"
    },
    {
        "label": "Wallet Set Up",
        "setupStatus":"WALLET_REGISTRATION",
        "setupBit":3,
        "componentUrl": "/register-device"
    },
    {
        "label": "Add Fingerprint",
        "setupStatus":"FINGERPRINT_ENROLL",
        "setupBit":4,
        "componentUrl": "/add-fp"
    
    },
    {
        "label": "Add Card",
        "setupStatus":"CARD_DIGITIZE",
        "setupBit":5,
        "componentUrl": "/add-card"
    
    },
    {
        "label": "Add Billing Address",
        "setupStatus":"BILLING_ADDRESS_ENROLL",
        "setupBit":6,
        "componentUrl": "/add-billing"
    },
    {
        "label": "Add Address",
        "setupStatus":"SHIPPING_ADDRESS_ENROLL",
        "setupBit":7,
        "componentUrl": "/add-shipping"
    },
    {
        "label": "Dashboard",
        "setupStatus":"DONE",
        "setupBit":8,
        "componentUrl": "/cards"
    }
]

function toBinary(value) {
    if (!Number.isSafeInteger(value)) {
        console.log('value must be a safe integer');
        return null
    }
    return ((value >>> 0).toString(2)).split('');
}

function getCurrentBit(walletSetupProgress) {
    let currentStep =  WalletSetupSteps.find((data)=>{ return data.setupStatus === walletSetupProgress})
    return currentStep.setupBit
}

function getCurrentIndex(walletSetupProgress) {
    let currentStepIndex = WalletSetupSteps.findIndex((data)=>{ return data.setupStatus === walletSetupProgress})
    return currentStepIndex
}

export const getNextComponent = (walletSetupStatus, walletSetupProgress) => {
    let currentIndex = getCurrentIndex(walletSetupProgress)
    let walletBinaryStatus = toBinary(walletSetupStatus);
    let currentBit = getCurrentBit(walletSetupProgress)
    let firstDoneStepBit = currentBit;
    let firstDoneStepBitIndex = walletBinaryStatus.findIndex((bit)=>{return bit === "1"})
    if(firstDoneStepBitIndex >= 0){
        if(firstDoneStepBitIndex === 0){
            firstDoneStepBit = walletBinaryStatus.length 
        }
        else {
            firstDoneStepBit = (walletBinaryStatus.length - firstDoneStepBitIndex)
        }
    }
    let DoneStepIndex = WalletSetupSteps.findIndex((data)=>{return data.setupBit === firstDoneStepBit})
    if(DoneStepIndex <= currentIndex){
        DoneStepIndex = currentIndex
    }
    return  WalletSetupSteps[DoneStepIndex+1];
}

export const getPreviousComponent = (walletSetupStatus, walletSetupProgress) => {
    let currentIndex = getCurrentIndex(walletSetupProgress)
    let walletBinaryStatus = toBinary(walletSetupStatus);
    let currentBit = getCurrentBit(walletSetupProgress)
    let firstNotDoneStepBit= currentBit-1;
    let firstNotDoneStepBitIndex = walletBinaryStatus.findIndex((bit)=>{return bit === "0"})
    if(firstNotDoneStepBitIndex >= 0){
        firstNotDoneStepBit = (walletBinaryStatus.length - firstNotDoneStepBitIndex)
    }
    else{
        if(walletBinaryStatus.length < currentIndex){
            firstNotDoneStepBit= currentBit-1
        }
        else{
            return null
        }
    }
    let NotDoneStepIndex = WalletSetupSteps.findIndex((data)=>{return data.setupBit === firstNotDoneStepBit})
    if(NotDoneStepIndex >= currentIndex){
        return null
    }
    if(WalletSetupSteps[NotDoneStepIndex].setupStatus === constants.BILLING_ADDRESS_ENROLL){
        return WalletSetupSteps[NotDoneStepIndex-1]
    }
    return  WalletSetupSteps[NotDoneStepIndex];
}

export function isStepCompleted(setupStep, walletSetupStatus) {
    let walletBinaryStatus = toBinary(walletSetupStatus);
    if(!walletBinaryStatus){
       return false
    }
    let walletSetupStepBit=(WalletSetupSteps.find((step)=>{return step.setupStatus === setupStep })).setupBit
    if(walletBinaryStatus.length >= walletSetupStepBit){
        let reservedBinaryStatus = walletBinaryStatus.reverse()
        if(reservedBinaryStatus[walletSetupStepBit-1] === "1"){
            return true
        }
         
    }
    return false
}