/*
 * INTEL CONFIDENTIAL
 * Copyright 2022 Intel Corporation.
 * This software and the related documents are Intel copyrighted materials, and
 * your use of them is governed by the express license under which they were
 * provided to you (License). Unless the License provides otherwise, you may not
 * use, modify, copy, publish, distribute, disclose or transmit this software or
 * the related documents without Intel's prior written permission. This software
 * and the related documents are provided as is, with no express or implied
 * warranties, other than those that are expressly stated in the License.
 */

import { Formik, Form } from 'formik';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Row, Col, Modal, Alert } from 'react-bootstrap';
import {RiDeleteBinLine} from 'react-icons/ri';
import {TiEdit} from 'react-icons/ti';
import { Link } from 'react-router-dom';
import * as Yup from 'yup';

import * as constants from '@pcwallet/common/constants';
import fingerScanStart from '@pcwallet/common/assets/fingerprint.png';
import { queryStatusFromDevice, startDeleteDevice, deleteDevice, clearDeleteDeviceState, startLockWallet, lockWallet, cancelLockWallet, startUnlockWallet, unlockWallet } from '../../actions/devices';
import {getFingerList, startRenameFingerprint, startDeleteFingerprint, deleteFingerprint, startAddFP, nameFingerprint,  showPINSettings, startChangePincode, startResetUserVerification, showWalletSettings, showFingerprintSettings, clearSecurityState, startResetPincode} from '../../actions/security';
import { analyticsPageView } from '../../utils';
import { inputRegEx } from '../../validationEngine';
import { isStepCompleted } from '../enrollment/WalletSetupProgressUtil';

import LoadingErrorModule from '../shared/LoadingErrorModule';
import Loading from '../shared/Loading';
import Notification from '../shared/Notification';
import RemoteManageWallet from '../shared/RemoteManageWallet';
import TextInput from '../inputs/TextInput';


export class SecurityContainer extends Component {

    constructor(props) {
        super(props);
        this.state = {
          showModal:false,
          currentPincode:false,
          pincode:false,
          confirmPincode:false,
          disableButton:false,
          showToast:false,
          strengthResult:0,
        }
        this.handleClose = this.handleClose.bind(this)
        this.handleMasking = this.handleMasking.bind(this)
        this.hideNotification = this.hideNotification.bind(this)
      }
    
      handleClose(){
        this.setState({
          showModal:false,
          disableButton:false,
          showToast:true
        })
        const { history } = this.props;
        if(this.props.devices.deviceState === constants.LOCK_WALLET_SUCCESS || this.props.devices.deviceState === constants.DEVICE_STATE_LOCK_PENDING){
            this.props.queryStatus({
                detectDevice:false,
                devicePort: this.props.devicePortDiscovery.devicePort,
                history
            })
            this.props.history.push('/device-dashboard')
        }
        else if(this.props.devices.deviceState === constants.DELETE_DEVICE_SUCCESS){
            this.props.history.push('/dashboard')
        }
        else{
            if((this.props.security.authState === constants.FINGERPRINT_DELETE_SUCCESS && 
                this.props.devices.connected && this.props.devices.connected.publicId) ||
                this.props.security.authState === constants.NAME_FINGERPRINT_SUCCESS
                ){
                this.props.getFingerList({
                    publicId:this.props.devices.connected.publicId,
                    devicePort: this.props.devicePortDiscovery.devicePort
            })
        }
        }
      }

    handleMasking(event,value,handleChange){
        const stateName = event.target.name
        if(value){
            this.setState({[stateName]:true})
        }
        else{
            this.setState({[stateName]:false})
        }
        handleChange(event)
    }

    hideNotification () {
        this.setState({showToast:false})
        this.props.clearSecurityState();
    }

    componentDidMount() {
        const { history } = this.props;
        if(!this.props.devices.connected){
            this.props.queryStatus({
                detectDevice:true,
                devicePort: this.props.devicePortDiscovery.devicePort,
                history
            })
        }
        else{
            if((this.props.devices.connected && this.props.security.fingerList.length === 0) ||
             this.props.security.authState === constants.NAME_FINGERPRINT_SUCCESS){
            this.props.getFingerList({
                publicId:this.props.devices.connected.publicId
            })
            }
        }

        if(this.props.security.authState === constants.PIN_SETUP_SUCCESS ||
            this.props.security.authState === constants.NAME_FINGERPRINT_SUCCESS ){
            this.setState({
                showToast:true
            })
        }
        //initializing analytics
        analyticsPageView('/fingerList')
    }

    componentDidUpdate(prevProps){
        if(this.props.devices.showModal!== prevProps.devices.showModal && this.props.devices.showModal){
            this.setState({
                showModal:true
            })           
        }
        else if((this.props.devices.deviceState !== prevProps.devices.deviceState && this.props.devices.success) || 
                this.props.security.authState !== prevProps.security.authState){
            this.setState({
                showToast:true
            })
        }
    }

    render() {
        const { history } = this.props;
        return (
            <div >
                {
                    this.props.devices.isLoading || this.props.cards.isLoading || this.props.contacts.isLoading || this.props.security.isLoading ?
                    Loading()
                    :
                    <Row className="dashboard-wrapper">
                        {
                        this.state.showToast && this.props.security.success && this.props.security.authState === constants.PIN_SETUP_SUCCESS?
                           <Notification 
                                show={this.state.showToast}
                                hide={this.hideNotification}
                                msg={"PIN was successfully changed."}/>                            
                        :this.state.showToast && this.props.security.success && this.props.security.authState === constants.FINGERPRINT_DELETE_SUCCESS?
                           <Notification 
                                show={this.state.showToast}
                                hide={this.hideNotification}
                                msg={"Fingerprint was successfully deleted."}/>
                        :this.state.showToast && this.props.security.success && this.props.security.rename && this.props.security.authState === constants.NAME_FINGERPRINT_SUCCESS?
                                <Notification 
                                     show={this.state.showToast}
                                     hide={this.hideNotification}
                                     msg={"Fingerprint was successfully renamed."}/>
                        :this.state.showToast && this.props.security.success && this.props.security.authState === constants.NAME_FINGERPRINT_SUCCESS?
                           <Notification 
                                show={this.state.showToast}
                                hide={this.hideNotification}
                                msg={"Fingerprint was successfully added."}/>
                        :
                        <></>
                        }
                        <Col sm={3} className="dashboard-list-wrapper">
                        </Col>
                        <Col sm={9} className="dashboard-card-wrapper ">
                            <div className="card-wrapper">
                                <div className="card">
                                        <div className="active-card-header">
                                            <h1>Security</h1>
                                            <div className = "card-link-bar">
                                                <div 
                                                    className ={this.props.security.securityState === constants.SHOW_FINGERPRINT_SETTINGS?"mt-3 active":"mt-3"}
                                                    onClick={()=>{
                                                        this.props.showFingerprintSettings({
                                                            history
                                                        })
                                                    }}
                                                    >Fingerprints</div>
                                                <div 
                                                    className ={this.props.security.securityState === constants.SHOW_PIN_SETTINGS?"mt-3 ml-5 active" :"mt-3 ml-5"}
                                                    onClick={()=>{
                                                        this.props.showPINSettings({
                                                            history
                                                        })
                                                    }}
                                                    >PIN</div>
                                                <div 
                                                    className ={this.props.security.securityState === constants.SHOW_WALLET_SETTINGS?"mt-3 ml-5 active" :"mt-3 ml-5"}
                                                    onClick={()=>{
                                                        this.props.showWalletSettings({
                                                            history
                                                        })
                                                    }}
                                                    >Settings</div>
                                            </div>
                                        </div>
                                        <p className="divider"></p>
                                        <div className ="active-card-content">
                                            <div>
                                                {
                                                    this.props.security.securityState === constants.SHOW_PIN_SETTINGS?
                                                    <div className="px-5 pt-5">
                                                        {/* ToDo : PIN last updated feature */}
                                                        {/* <p className="font-weight-bold" >Your PIN was last updated on: August 18th 2023, 2:15pm</p> */}
                                                        <div className="d-flex align-items-center">                                                                
                                                                <button
                                                                    className="my-3 btn-outline-primary btn-lg button-outline"
                                                                    onClick={() => {
                                                                        this.props.startChangePincode({
                                                                            history
                                                                        })
                                                                    }}>
                                                                    Update PIN
                                                                </button>
                                                        </div>
                                                        <div className="d-flex align-items-center">
                                                                <button
                                                                    className="my-3 btn-outline-primary btn-lg button-outline"
                                                                    // disabled={true}
                                                                    onClick={() => {
                                                                        // this.props.startResetUserVerification({history})
                                                                        this.props.startResetPincode({ history })
                                                                    }}>
                                                                    Forgot PIN
                                                                </button>                                                            
                                                        </div>
                                                    </div>
                                                    :
                                                    this.props.security.securityState === constants.SHOW_WALLET_SETTINGS?
                                                    <div className="pt-5">
                                                        {
                                                            this.props.devices.connected?
                                                                <RemoteManageWallet device={this.props.devices.connected}/>
                                                            :
                                                            <></>
                                                        }
                                                    </div>
                                                    :
                                                    <div>
                                                    {
                                                        this.props.security.fingerList.length<=0 && (this.props.devices.connected && 
                                                            this.props.devices.connected.walletSetupProgress === constants.DONE && !isStepCompleted(constants.FINGERPRINT_ENROLL, this.props.devices.connected.walletSetupStatus))?
                                                            <Alert className='mt-5 mb-3 w-75'  dismissible={false} >
                                                                <div className='px-2 font-weight-bold'><Link className ="link" to="/add-fp"> Add your Fingerprint</Link> for faster, more secure checkout.</div>                        
                                                            </Alert>                 
                                                        :
                                                        this.props.security.fingerList.map((finger, index)=>{
                                                        return(
                                                            <div 
                                                                key={index}
                                                                className="active-card-details align-items-center divider" >
                                                                <div className = "d-flex align-items-center">
                                                                    <img 
                                                                    className="finger-scan-thumbnail pl-1" 
                                                                    src={fingerScanStart} 
                                                                    alt="finger"/>
                                                                    <div className="p-4 text-capitalize">{finger.fingerName}</div>
                                                                </div>
                                                                <div className="d-flex justify-content-between align-items-center">
                                                                            <div
                                                                                className="edit-icon px-4"
                                                                                onClick={()=>{
                                                                                    this.setState({showModal: true})
                                                                                    this.props.startRenameFingerprint({
                                                                                        fingerName: finger.fingerName,
                                                                                        templateId: finger.templateId,
                                                                                        history
                                                                                    })
                                                                                }}
                                                                            
                                                                            >
                                                                            <TiEdit size="1.5em"/>
                                                                            </div>
                                                                            <div
                                                                                className="delete-icon"
                                                                                onClick={()=>{
                                                                                    this.setState({showModal: true})
                                                                                    this.props.startDeleteFingerprint({
                                                                                        fingerName: finger.fingerName,
                                                                                        templateId: finger.templateId,
                                                                                        history
                                                                                    })
                                                                                }}
                                                                            
                                                                            >
                                                                            <RiDeleteBinLine size="1.5em"/>
                                                                            </div>
                                                                </div>
                                                            </div>
                                                        
                                                            )})
                                                    }
                                                        <div className="text-right">
                                                        <button 
                                                        className="my-5 btn btn-primary btn-lg button"
                                                        onClick={() => {
                                                            this.props.startAddFP({
                                                                    history
                                                            })}}>
                                                        Add fingerprint
                                                    </button>
                                                    </div>
                                                    </div>
                                                }                                        
                                            </div>
                                        </div>
                                </div>
                            </div>
                        </Col>
                        <Modal 
                            show={this.state.showModal} 
                            onHide={this.handleClose}
                            backdrop="static"
                            keyboard={false}
                            centered
                            >
                            {
                            this.props.devices.deviceState === constants.DEVICE_STATE_LOCK ? 
                                <div>
                                    <Modal.Header className="justify-content-center"><Modal.Title >Lock Wallet</Modal.Title></Modal.Header>
                                    <Modal.Body>
                                            <div className="p-4 text-left">
                                                <div>
                                                    <p>
                                                        <b> Are you sure you want to lock this wallet? 
                                                            <br/> This will temporarily disable all cards, addresses, and fingerprints in your Intel Pay wallet.
                                                            <br/> <br/>
                                                            Your physical cards are still available for use, to freeze the physical cards contact your bank.  
                                                        </b>
                                                    </p>
                                                </div>
                                                <div className="button-wrapper">
                                                    <div className="d-flex justify-content-between">
                                                        <button
                                                            className="btn btn-outline-primary button-outline"
                                                            type="button"
                                                            onClick={()=>{
                                                                this.props.cancelLockWallet({
                                                                    history
                                                                })
                                                                this.handleClose()
                                                            }}>Cancel
                                                        </button>
                                                        <button
                                                            className="btn btn-primary button"
                                                            type="button"
                                                            disabled={this.state.disableButton && this.props.devices.isLoadingModal && !this.props.devices.error}
                                                            onClick={() => {
                                                                this.setState({
                                                                    disableButton:true})
                                                                this.props.lockWallet({
                                                                    device: this.props.devices.activeDevice,
                                                                    devicePort: this.props.devicePortDiscovery.devicePort,
                                                                    callback:this.handleClose
                                                                })}}>
                                                                    {this.props.devices.isLoadingModal && <span className="spinner-border spinner-border-sm mr-1" role="status" aria-hidden="true" />}
                                                                    Lock Wallet
                                                        </button>
                                                    </div>
                                                </div>
                                                <LoadingErrorModule 
                                                    error={this.props.devices.error}
                                                    errorMessage={this.props.devices.errorMessage}/>
                                            </div>
                                        </Modal.Body>
                                </div>
                            :  
                            this.props.devices.deviceState === constants.DEVICE_STATE_DELETE ? 
                            <div>
                                <Modal.Header className="justify-content-center"><Modal.Title >Delete Wallet</Modal.Title></Modal.Header>
                                <Modal.Body>
                                        <div className="p-4 text-left">
                                            <div>
                                                <p>
                                                    <b> Are you sure you want to delete this wallet?
                                                    <br/> This operation will permanently delete all information in your wallet including cards, addresses, and fingerprints. 
                                                    </b>
                                                </p>
                                            </div>
                                            <div className="button-wrapper">
                                                <div className="d-flex justify-content-between">
                                                    <button
                                                        className="btn btn-outline-primary button-outline"
                                                        type="button"
                                                        onClick={()=>{
                                                            this.props.clearDeleteDeviceState({
                                                                history
                                                            })
                                                            this.handleClose()
                                                        }}>Cancel
                                                    </button>
                                                    <button
                                                        className="btn btn-primary button"
                                                        type="button"
                                                        disabled={this.state.disableButton && this.props.devices.isLoadingModal && !this.props.devices.error}
                                                        onClick={() => {
                                                            this.setState({
                                                                disableButton:true})
                                                            this.props.deleteDevice({
                                                                device: this.props.devices.activeDevice,
                                                                devicePort: this.props.devicePortDiscovery.devicePort,
                                                                callback:this.handleClose
                                                            })}}>
                                                                {this.props.devices.isLoadingModal && <span className="spinner-border spinner-border-sm mr-1" role="status" aria-hidden="true" />}
                                                                Delete Wallet
                                                    </button>
                                                </div>
                                            </div>
                                            <LoadingErrorModule 
                                                error={this.props.devices.error}
                                                errorMessage={this.props.devices.errorMessage}/>
                                        </div>
                                    </Modal.Body>
                            </div>
                            :
                            this.props.security.authState === constants.FINGER_ACTION_FINGERPRINT_DELETE ? 
                            <div>
                                <Modal.Header className="justify-content-center"><Modal.Title >Delete Fingerprint</Modal.Title></Modal.Header>
                                <Modal.Body>
                                        <div className="p-4 text-left">
                                            <div><p><b> Please confirm you want to delete fingerprint : <br/> {this.props.security.activeFingerName} </b></p></div>
                                            <div className="button-wrapper">
                                                {
                                                    <div className="d-flex justify-content-between">
                                                        <button
                                                            className="btn btn-outline-primary button-outline"
                                                            type="button"
                                                            onClick={this.handleClose}>Cancel
                                                        </button>
                                                        <button
                                                            className="btn btn-primary button"
                                                            type="button"
                                                            disabled={this.props.security.isLoadingModal}
                                                            onClick={() => {
                                                                // this.setState({disableButton:true})
                                                                this.props.deleteFingerprint({
                                                                    publicId:this.props.devices.connected.publicId,
                                                                    templateId: parseInt(this.props.security.activeTemplateId),
                                                                    devicePort: this.props.devicePortDiscovery.devicePort,
                                                                    callback:this.handleClose
                                                                })}}>
                                                                    {this.props.security.isLoadingModal && <span className="spinner-border spinner-border-sm mr-1" role="status" aria-hidden="true" />}
                                                                    Delete Fingerprint
                                                        </button>
                                                    </div>
                                                }
                                            </div>
                                            <LoadingErrorModule 
                                                error={this.props.security.error}
                                                errorMessage={this.props.security.errorMessage}
                                            />
                                        </div>
                                    </Modal.Body>
                                </div>
                                :
                                this.props.security.authState === constants.FINGER_ACTION_FINGERPRINT_RENAME ? 
                                <div>
                                    <Modal.Header className="justify-content-center"><Modal.Title >Rename Fingerprint</Modal.Title></Modal.Header>
                                        <Modal.Body>
                                            <div className="px-4 text-left">
                                                <div className="py-4">
                                                <Formik
                                                    initialValues={{
                                                        fingerName: this.props.security.activeFingerName,
                                                    }}
                                                    validationSchema={Yup.object({
                                                        fingerName: Yup.string()
                                                            .max(64)
                                                            .matches(inputRegEx,'field contains invalid character')
                                                            .required('Required')
                                                    })}
                                                    onSubmit={(values, { setSubmitting }) => {
                                                        this.props.nameFingerprint(
                                                            {
                                                                publicId:  this.props.devices.connected.publicId,
                                                                fingerName:values.fingerName,
                                                                templateId:parseInt(this.props.security.activeTemplateId),
                                                                callback:this.handleClose
                                                            }
                                                        )
                                                        setTimeout(()=>{
                                                            setSubmitting(false) 
                                                        },1000)
                                                    }}>
                                                        {({ values, dirty, isValid, isSubmitting }) => (
                                                    <div>
                                                        <Form>
                                                            <TextInput
                                                                sm={7}
                                                                label="Fingerprint name"
                                                                value={values.fingerName}
                                                                name="fingerName"
                                                                type="text"
                                                            />
                                                            <div className="d-flex justify-content-between pt-4 align-items-stretch">
                                                                <button
                                                                    className="btn btn-outline-primary button-outline"
                                                                    type="button"
                                                                    onClick={this.handleClose}>Cancel
                                                                </button>
                                                                <button
                                                                    className="btn btn-primary button"
                                                                    disabled={isSubmitting || !dirty || !isValid || this.props.security.isLoadingModal}
                                                                    type="submit"
                                                                >
                                                                    {this.props.security.isLoadingModal && <span className="spinner-border spinner-border-sm mr-1" role="status" aria-hidden="true" />}
                                                                    Save
                                                                </button>
                                                            </div>
                                                        <LoadingErrorModule 
                                                            error={this.props.security.error}
                                                            errorMessage={this.props.security.errorMessage}
                                                        />
                                                </Form>
                                            </div>
                                            )}
                                            </Formik>
                                            </div>
                                            </div>
                                    </Modal.Body> 
                                </div> 
                                :
                                <></> 
                                }                                                                   
                        </Modal>
                    </Row>
                }       
             </div>
        )
    }
}

const mapStateToProps = state => ({
    security: state.security,
    devices:state.devices,
    cards:state.cards,
    contacts:state.contacts,
    user:state.user,
    devicePortDiscovery: state.devicePortDiscovery

})

const mapDispatchToProps = dispatch => ({
    startAddFP: (data) => dispatch(startAddFP(data)),
    getFingerList: (data) => dispatch(getFingerList(data)),
    startRenameFingerprint: (data) => dispatch(startRenameFingerprint(data)),
    nameFingerprint: (data) => dispatch(nameFingerprint(data)),
    startDeleteFingerprint: (data) => dispatch(startDeleteFingerprint(data)),
    deleteFingerprint: (data) => dispatch(deleteFingerprint(data)),
    showPINSettings: (data) => dispatch(showPINSettings(data)),
    startChangePincode: (data) => dispatch(startChangePincode(data)),
    startResetPincode: (data) => dispatch(startResetPincode(data)),
    showWalletSettings: (data) => dispatch(showWalletSettings(data)),
    showFingerprintSettings :(data) => dispatch(showFingerprintSettings(data)),    
    startDeleteDevice :(data) => dispatch(startDeleteDevice(data)),
    deleteDevice: (data) => dispatch(deleteDevice(data)),
    clearDeleteDeviceState: (data) => dispatch(clearDeleteDeviceState(data)),
    queryStatus:(data)=>dispatch(queryStatusFromDevice(data)),
    clearSecurityState:(data)=>dispatch(clearSecurityState(data)),
    startLockWallet :(data) => dispatch(startLockWallet(data)),
    lockWallet: (data) => dispatch(lockWallet(data)),
    cancelLockWallet: (data) => dispatch(cancelLockWallet(data))
})

export default connect(mapStateToProps, mapDispatchToProps)(SecurityContainer)