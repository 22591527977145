/*
 * INTEL CONFIDENTIAL
 * Copyright 2022 Intel Corporation.
 * This software and the related documents are Intel copyrighted materials, and
 * your use of them is governed by the express license under which they were
 * provided to you (License). Unless the License provides otherwise, you may not
 * use, modify, copy, publish, distribute, disclose or transmit this software or
 * the related documents without Intel's prior written permission. This software
 * and the related documents are provided as is, with no express or implied
 * warranties, other than those that are expressly stated in the License.
 */
import React, { Component } from 'react'
import { connect } from 'react-redux'
import { Formik, Form } from 'formik';
import * as Yup from 'yup';
import { linkBillingtoCard } from '../../actions/contacts'
import SelectInput from '../inputs/SelectInput';


const contactList = (contacts, cards) => {
    let defaultBillingAddress = null
    let defaultCard = cards.find((card)=>{return card.defaultPaymentMethod})
    if(defaultCard && defaultCard.billingContactId){
        defaultBillingAddress = contacts.find(contact =>{return contact.id === defaultCard.billingContactId})
        
    }
    let list = contacts.sort((a, b) => {
        if (defaultBillingAddress) {
            if (a.id === defaultBillingAddress.id) {
                return -1;
            }
            if (b.id === defaultBillingAddress.id) {
                return 1;
            }
        }
        return a.id - b.id;
    })
    .map(contact => {
        return {
            value: contact.id,
            label: contact2String(contact)
        }
    })
    list.push({
        value:"0",
        label: "Add New Address"
    })
    return list
}

const contact2String = (contact) => {
    let result = contact.addressLine1 + '\n';
    if (contact.addressLine2) {
        result = result + ', ' + contact.addressLine2 + ' \n';
    }
    result = result + ', '+ contact.city + ', ' + contact.state + ' ' + contact.postalCode + ' ' + contact.country;
    return result;
}

export class SelectContact extends Component {

    constructor(props) {
        super(props);
        this.handleOnSelected = this.handleOnSelected.bind(this);
    }

    handleOnSelected(data){
        if (data.event.target.value !== "0") {
            if(!this.props.addCardSuccess){
                this.props.linkBillingtoCard({
                    billingEnrolled:this.props.billingEnrolled,
                    contactId: data.event.target.value,
                    deviceId:this.props.devices.connected.deviceId,
                    devicePort:this.props.devicePortDiscovery.devicePort,
                    isShippingEnrolled:this.props.isShippingEnrolled,
                    publicId:this.props.devices.connected.publicId,
                    selectNewBilling:true,
                    tokenId: this.props.tokenId,
                    walletSetupStatus:this.props.walletSetupStatus,

                })
            }
            else{
                this.props.onNewBillingSelected({
                    contactId:data.event.target.value,
                    tokenId: this.props.tokenId,
                    selectNewBilling:true
                   })
            }               
        }
        else{
            this.props.onAddNewBilling()
        }
        data.setFieldValue('address', data.event.target.value)
    }
    render() {
        return (
            <div>
                <Formik
                    initialValues={{
                        address:this.props.activeBillingContact? this.props.activeBillingContact.id:contactList(this.props.contacts.contacts, this.props.cards.cards)?contactList(this.props.contacts.contacts, this.props.cards.cards)[0].id:"",
                    }}
                    validationSchema={Yup.object().shape({
                        address: Yup.string(),
                    })}
                   >
                    {({ setFieldValue }) => (
                        <Form>
                            <SelectInput
                                name="address"
                                onChange={(event) => {
                                        this.handleOnSelected({event,setFieldValue})
                                    }}
                                activeBillingContact={this.props.activeBillingContact}
                                itemlist={contactList(this.props.contacts.contacts, this.props.cards.cards)}/>
                        </Form>
                    )
                    }
                </Formik>
            </div>
        )
    }

}

const mapStateToProps = state => ({
    contacts: state.contacts,
    cards: state.cards,
    devices: state.devices,
    devicePortDiscovery: state.devicePortDiscovery
})

const mapDispatchToProps = dispatch => ({
    linkBillingtoCard: (data) => dispatch(linkBillingtoCard(data)),
})

export default connect(mapStateToProps, mapDispatchToProps)(SelectContact)
