/*
 * INTEL CONFIDENTIAL
 * Copyright 2022 Intel Corporation.
 * This software and the related documents are Intel copyrighted materials, and
 * your use of them is governed by the express license under which they were
 * provided to you (License). Unless the License provides otherwise, you may not
 * use, modify, copy, publish, distribute, disclose or transmit this software or
 * the related documents without Intel's prior written permission. This software
 * and the related documents are provided as is, with no express or implied
 * warranties, other than those that are expressly stated in the License.
 */
import * as constants from '@pcwallet/common/constants'
import * as messages from '@pcwallet/common/endUserMessages'
import {requestVerificationCode} from '../actions/verify'
import {queryStatusFromDevice} from './devices'
import { getCsrfCookieValue, getPreDeviceTokenFromCookie, getDeviceTokenFromCookie ,customNumberParser } from "@pcwallet/common/utils"
import { stringify, parse, LosslessNumber } from 'lossless-json'

export const login = data => (dispatch) => {
  dispatch({
    type: constants.USER_LOGIN
  })
  fetch(`${constants.INUS_ADDRESS}/us/v1/login`, {
    method: 'POST',
    credentials : 'include',
    headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
    },
    body: stringify({
      username: data.username,
      password: data.password
    })
  })
  .then((response) => {
    if (response.status === 401) {
        dispatch({
            type: constants.ACCESS_TOKEN_INVALID,
        })
        throw response.status
    }
    else {
        return response.text()
    }
    })
    .then((response) => {
        return parse(response, null, customNumberParser)
    })
    .then((response) => {        
        if (response.errorCode !== "OK") {
            dispatch({ 
                type: constants.USER_LOGIN_FAILED, 
                payload: response.message
            });
        }
        else{
            dispatch({ 
                type: constants.USER_LOGIN_SUCCESS,
                payload:{
                    username:data.username
                }
            });
            verifyDeviceOwner(data,dispatch)
            dispatch({ 
                type: constants.VERIFY_DEVICE_OWNER_START,
            });

        }
    }).catch(error => {
        console.log(error)
        dispatch({ 
            type: constants.USER_LOGIN_FAILED, 
            payload: messages.USER_LOGIN_FAILED
        });
    })
}

const verifyDeviceOwner = (data,dispatch) => {
    const deviceToken = getPreDeviceTokenFromCookie();
    let requestBody = stringify({
        token: deviceToken,
        cmd:constants.VERIFY_DEVICE_OWNER,
    })
    fetch(`${constants.MTO_ADDRESS_HOSTNAME}:${String(data.devicePort)}/v1/command`, {
        method: 'POST',
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json'
        },
        body: requestBody
    })
    .then((response) => response.json())
    .then((response) => {
        if (!response.status) {
            dispatch({
                type: constants.VERIFY_DEVICE_OWNER_FAILED,
            });
            dispatch(requestVerificationCode({
                otpPurpose : constants.MFA
            }))
        } else {
            verifyDeviceOwnerServer(data,dispatch)
        }
      }).catch((error) => {
          console.log(error)
          dispatch({
            type: constants.VERIFY_DEVICE_OWNER_FAILED,
        });
        dispatch(requestVerificationCode({
            otpPurpose : constants.MFA
        }))
      })
  }

   const verifyDeviceOwnerServer = (data,dispatch) => {
    const cookieValue = getCsrfCookieValue()
    fetch(`${constants.INUS_ADDRESS}/us/v1/verifyDeviceOwner`, {
      method: 'POST',
      credentials : 'include',
      headers: {
          'Accept': 'application/json',
          'Content-Type': 'application/json',
          'X-XSRF-TOKEN' : `${cookieValue ? cookieValue : ''}`
      }
    })
    .then((response) =>{
        if (response.status === 401) {
            dispatch({
                type: constants.ACCESS_TOKEN_INVALID,
            })
            throw response.status
        }
        else {
            return response.text();
        }
    })
    .then((response) => {
        return parse(response, null, customNumberParser)
    })
    .then((response) => {        
          if (response.errorCode !== "OK") {
            dispatch({
                type: constants.VERIFY_DEVICE_OWNER_FAILED,
            });
            dispatch(requestVerificationCode({
                otpPurpose : constants.MFA
            }))
          }
          else{
            dispatch({ 
                type: constants.VERIFY_DEVICE_OWNER_SUCCESS,
            });
            dispatch(getUserDetails());

            // start to retrieve new access_token and refresh_token
            // in 9 minutes which is 1 minute before access_token expires.
            setTimeout(()=>{
                refreshToken(dispatch);
            }, 60000 * 9);

          }
    }).catch((error) => {
        if (error != 401){
            console.error('Error:', error);
            dispatch({
                type: constants.VERIFY_DEVICE_OWNER_FAILED,
            });
        }
        dispatch(requestVerificationCode({
            otpPurpose : constants.MFA
        }))
    });
  }

export const getUserDetails = data => (dispatch) => {
  dispatch({
    type: constants.USER_DETAILS
  })
  const cookieValue = getCsrfCookieValue()
  fetch(`${constants.INUS_ADDRESS}/us/v1/getUserInfo`, {
    method: 'POST',
    credentials : 'include',
    headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'X-XSRF-TOKEN' : `${cookieValue ? cookieValue : ''}`
    }
  })
    .then((response) =>  {
        if (response.status === 401) {
            dispatch({
                type: constants.ACCESS_TOKEN_INVALID,
            })
            throw response.status
        }
        else {
            return response.text()
        }
    })
    .then((response) => {
        return parse(response, null, customNumberParser)
    })
    .then((response) => {        
        if (response.errorCode !== "OK") {
            dispatch({ 
                type: constants.USER_DETAILS_FAILED, 
                payload: response.message
            });
        }
        else{
            dispatch({ 
                type: constants.USER_DETAILS_SUCCESS, 
                payload: response.user
            });
        }
        if (data) {
                data.onComplete()
        }
    }).catch((error) => {
        console.log(error)
    })
}

export const userIdle = (dispatch) => {
    return dispatch({
      type: constants.USER_IDLE
    })
  }

export const timedout = (dispatch) => {
    dispatch({
        type: constants.USER_TIMED_OUT
    });
    const cookieValue = getCsrfCookieValue()
    fetch(`${constants.INUS_ADDRESS}/us/v1/logout`, {
        method: 'POST',
        credentials :'include',
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            'X-XSRF-TOKEN' : `${cookieValue ? cookieValue : ''}`
        },
        body: stringify({
        })
    })
    .then((response) => response.text())
    .then((response) => {
        return parse(response, null, customNumberParser)
    })
    .then((response) => {
        dispatch({
            type: constants.USER_LOGOUT_SUCCESS
        });
        sessionStorage.clear()
    }).catch((error) => {
        console.log(error)
        sessionStorage.clear()
    })

}

export const logout = (dispatch) => {
    dispatch({
        type: constants.USER_LOGOUT
    }); 
    const cookieValue = getCsrfCookieValue()
    fetch(`${constants.INUS_ADDRESS}/us/v1/logout`, {
        method: 'POST',
        credentials :'include',
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            'X-XSRF-TOKEN' : `${cookieValue ? cookieValue : ''}`
        },
        body: stringify({
        })
    })
    .then((response) => response.text())
    .then((response) => {
        return parse(response, null, customNumberParser)
    })
    .then((response) => {
        dispatch({
            type: constants.USER_LOGOUT_SUCCESS
        });
        dispatch({
            type:constants.CLEAR_SESSION_LOGOUT
        })
        sessionStorage.clear()
    }).catch((error) => {
        console.log(error) 
        sessionStorage.clear()
        dispatch({
            type:constants.CLEAR_SESSION_LOGOUT
        })
    })
}

export const updateUserDetails = data => (dispatch) => {
  dispatch({
      type: constants.UPDATE_ACCOUNT
  })
  const requestURL = `${constants.INUS_ADDRESS}/us/v1/user/${data.userId}`
  const cookieValue = getCsrfCookieValue()
  fetch(requestURL, {
      method: 'PUT',
      credentials : 'include',
      headers: {
          'Accept': 'application/json',
          'Content-Type': 'application/json',
          'X-XSRF-TOKEN' : `${cookieValue ? cookieValue : ''}`
      },
      body: stringify({
        givenName: data.givenName,
        familyName: data.familyName,
        phoneNumber: data.phoneNumber,
      })
  })
  .then((response) => {
    if (response.status === 401) {
        dispatch({
            type: constants.ACCESS_TOKEN_INVALID,
        })
        throw response.status
    }
    else {
        return response.text()
    }
})
.then((response) => {
    return parse(response, null, customNumberParser)
})
.then((response) => {
    if(response.errorCode !== "OK"){
        dispatch({
            type: constants.UPDATE_ACCOUNT_FAILED,
            payload: response.message? response.message : "Failed to update user account. Please retry or come back later."
        });
    }
    else{
        if(response.user){
            dispatch({
                type: constants.UPDATE_ACCOUNT_SUCCESS,
                payload: response.user
            });
        }
        else {
            dispatch({
                type: constants.UPDATE_ACCOUNT_FAILED,
                payload: response.message? response.message : messages.USER_UPDATE_ACCOUNT_FAILED
            });
        }
    }   
    }).catch((error) => {
        if(error !==  401){
            dispatch({
                type: constants.UPDATE_ACCOUNT_FAILED,
                payload: messages.USER_UPDATE_ACCOUNT_FAILED
            });
        }
    })
}

export const forgotPassword = data => (dispatch) => {
    dispatch({
      type: constants.USER_FORGOT_PASSWORD
    })
    fetch(`${constants.INUS_ADDRESS}/us/v1/requestPasswordReset`, {
      method: 'POST',
      credentials : 'include',
      headers: {
          'Accept': 'application/json',
          'Content-Type': 'application/json'
      },
      body: stringify({
          username: data.username,
          resendCode:data.resendCode?data.resendCode:false
      })
    })
    .then((response) => {
        if (response.status === 401) {
            dispatch({
                type: constants.ACCESS_TOKEN_INVALID,
            })
            throw response.status
        }
        else {
            return response.text()
        }
    })
    .then((response) => {
        return parse(response, null, customNumberParser)
    })
    .then((response) => { 
        response.username=data.username;
        response.resendCode=data.resendCode
        if (response.errorCode !== "OK") {
            dispatch({ 
                type: constants.USER_FORGOT_PASSWORD_FAILED, 
                payload: response
              });
        } else {
            dispatch({ 
                type: constants.USER_FORGOT_PASSWORD_SUCCESS,
                payload:response
            });
            if(data.resendCode){
                data.callback()
            }
      }
    }).catch(error => {
        if(error != 401){
            dispatch({ 
                type: constants.USER_FORGOT_PASSWORD_FAILED, 
                payload: messages.USER_FORGOT_PASSWORD_FAILED
              });
        }
    })
  }

export const resetPassword = data => (dispatch) => {
    dispatch({
        type: constants.USER_RESET_PASSWORD
    })
    const cookieValue = getCsrfCookieValue()
    fetch(`${constants.INUS_ADDRESS}/us/v1/resetPassword`, {
        method: 'PUT',
        credentials : 'include',
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            'X-XSRF-TOKEN' : `${cookieValue ? cookieValue : ''}`
        },
        body: stringify({
            username: data.username,
            password:data.password,
            otp: data.otp,
        })
    })
    .then((response) => {
        if (response.status === 401) {
            dispatch({
                type: constants.ACCESS_TOKEN_INVALID,
            })
            throw response.status
        }
        else {
            return response.text()
        }
    })
    .then((response) => {
        return parse(response, null, customNumberParser)
    })
    .then((response) => {       
        if (response.errorCode !== "OK") {
            dispatch({
                type: constants.USER_RESET_PASSWORD_FAILED,
                payload: response.message
            });
        } else {
            dispatch({
                type: constants.USER_RESET_PASSWORD_SUCCESS,
                payload: response 
            });
            dispatch({ 
                type: constants.USER_DETAILS_SUCCESS, 
                payload: response.user
            });

            // start to retrieve new access_token and refresh_token
            // in 9 minutes which is 1 minute before access_token expires.
            setTimeout(()=>{
                refreshToken(dispatch);
            }, 60000 * 9);
            
            data.history.push('/dashboard');
        }
    }).catch((error) => {
        if(error != 401){
            dispatch({
                type: constants.USER_RESET_PASSWORD_FAILED,
                payload: messages.USER_RESET_PASSWORD_FAILED
            });
        }
    })
}

export const resetPasswordCanceled = (dispatch)=> {
    dispatch({
        type: constants.USER_RESET_PASSWORD_CANCEL
    })
}

export const extendSession = (dispatch) => {
    dispatch({
        type: constants.ACCESS_TOKEN_INVALID
    })
}

export const refreshToken = (dispatch) => {
    const cookieValue = getCsrfCookieValue()
    return fetch(`${constants.INUS_ADDRESS}/us/v1/refreshToken`, {
            method: 'POST',
            credentials : 'include',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'X-XSRF-TOKEN' : `${cookieValue ? cookieValue : ''}`
            },
        })
        .then((response) => {
            if (response.status === 401) {
                dispatch({
                    type: constants.ACCESS_TOKEN_INVALID,
                })
                throw response.status
            }
            else {
                return response.text()
            }
        })
        .then((response) => {
            return parse(response, null, customNumberParser)
        })
        .then((response) => {
            if (response.errorCode !== "OK") {
                dispatch( {
                    type: constants.REFRESH_TOKEN_GET_FAILED,
                })
            } else {
                // start to retrieve new access_token and refresh_token
                // in 9 minutes which is 1 minute for access_token expires.
                setTimeout(()=>{
                    refreshToken(dispatch);
                }, 60000 * 9);
            }
        }).catch((error) => {
            if(error != 401){
                dispatch( { 
                    type: constants.REFRESH_TOKEN_GET_FAILED, 
                    payload: messages.USER_REFRESH_TOKEN_FAILED
                })
            }
        })
}

export const showAccount = (data) => (dispatch) => {
    dispatch({
        type: constants.USER_ACCOUNT_SHOW,
        payload:data.showAccount
    });
}

export const register = data => (dispatch) => {
    dispatch({
      type: constants.USER_EMAIL_REGISTER
    })
    if(!data.resendCode){
        localStorage.setItem('name',stringify({
            firstName:data.firstName,
            lastName:data.lastName    
        }))
    }
    fetch(`${constants.INUS_ADDRESS}/us/v1/requestRegistrationCode`, {
      method: 'POST',
      credentials : 'include',
      headers: {
          'Accept': 'application/json',
          'Content-Type': 'application/json'
      },
      body: stringify({
        username: data.username,
        resendCode:data.resendCode?data.resendCode:false
      })
    })
    .then(response => {
        if (response.status === 401) {
            dispatch({
                type: constants.ACCESS_TOKEN_INVALID,
            })
            throw response.status
        }
        else {
            return response.text()
        }
    })
    .then((response) => {
        return parse(response, null, customNumberParser)
    })
    .then((response) => {        
        if (response.errorCode === "OK") {
            response.username=data.username;
            response.resendCode=data.resendCode
            dispatch({ 
                type: constants.USER_EMAIL_REGISTER_SUCCESS, 
                payload: response 
            });
            if(data.resendCode){
                data.callback()
            }
            else{
                data.history.push('/registerUser') 
            }
        } else {
            dispatch({ 
                type: constants.USER_EMAIL_REGISTER_FAILED, 
                payload: response.message
            });            
        }
    }).catch(error => {
        if(error != 401){
            dispatch({ 
                type: constants.USER_REGISTER_FAILED, 
                payload: messages.USER_REGISTER_FAILED
            });
        }
    })
}

export const clearResendState = (data) => dispatch => {
    dispatch({
        type: constants.USER_CODE_RESEND_STATE_CLEAR,
    })
}

export const registerUser = data => (dispatch) => {
    let userinfo= JSON.parse(localStorage.getItem('name'))
    dispatch({
      type: constants.USER_REGISTER
    })
    const cookieValue = getCsrfCookieValue()
    fetch(`${constants.INUS_ADDRESS}/us/v1/register`, {
      method: 'POST',
      credentials : 'include',
      headers: {
          'Accept': 'application/json',
          'Content-Type': 'application/json',
          'X-XSRF-TOKEN' : `${cookieValue ? cookieValue : ''}`
      },
      body: stringify({
        username: data.username,
        password: data.password,
        otp:data.otp,
        givenName: userinfo.firstName.trim(),
        familyName: userinfo.lastName.trim(),
      })
    })
    .then(response => {
        if (response.status === 401) {
            dispatch({
                type: constants.ACCESS_TOKEN_INVALID,
            })
            throw response.status
        }
        else {
            return response.text()
        }
    })
    .then((response) => {
        return parse(response, null, customNumberParser)
    })
    .then((response) => {
        if (response.errorCode !== "OK") {
            dispatch({ 
                type: constants.USER_REGISTER_FAILED, 
                payload: response.message
            });
        } else {
            dispatch({ 
                type: constants.USER_REGISTER_SUCCESS, 
                payload: response 
            });
            dispatch({ 
                type: constants.USER_DETAILS_SUCCESS, 
                payload: response.user
            });
            //save usr information
            if(response.user.id && userinfo){
                dispatch(updateUserDetails({
                    userId:response.user.id,
                    givenName: userinfo.firstName.trim(),
                    familyName: userinfo.lastName.trim()
                }))
            }

            // start to retrieve new access_token and refresh_token
            // in 9 minutes which is 1 minute before access_token expires.
            setTimeout(()=>{
                refreshToken(dispatch);
            }, 60000 * 9);

            //redirect to dashboard for wallet initial enrollment flow
            data.history.push('/dashboard')      
        }
    }).catch(error => {
        console.log(error)
        dispatch({ 
            type: constants.USER_REGISTER_FAILED, 
            payload: messages.USER_REGISTER_FAILED
        });
    })
}
 
export const saveRegisterCode = data => (dispatch)=>{
    dispatch({
        type: constants.USER_REGISTER_CODE_SAVE,
        payload:data.otp
      })
    data.history.push('/registerUser')      
}

export const resetErrorMessages = () => (dispatch)=>{
    dispatch({
        type: constants.RESET_ERROR_STATES,
      })
}

export const changePassword = (data) => (dispatch) => {
    dispatch({
        type: constants.USER_CHANGE_PASSWORD
    })
    const requestURL = `${constants.INUS_ADDRESS}/us/v1/user/${data.userId}/password`
    const cookieValue = getCsrfCookieValue()
    fetch(requestURL, {
        method: 'PUT',
        credentials : 'include',
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            'X-XSRF-TOKEN' : `${cookieValue ? cookieValue : ''}`
        },
        body: stringify({
            password:data.password,
            newPassword:data.newPassword
        })
    })
    .then((response) => {
      if (response.status === 401) {
          dispatch({
              type: constants.ACCESS_TOKEN_INVALID,
          })
          throw response.status
      }
      else {
          return response.text()
      }
  })
  .then((response) => {
      return parse(response, null, customNumberParser)
  })
  .then((response) => {
      if(response.errorCode !== "OK"){
          dispatch({
              type: constants.USER_CHANGE_PASSWORD_FAILED,
              payload: response.message? response.message : messages.USER_CHANGE_PASSWORD_FAILED
          });
      }
      else{
        dispatch({
            type: constants.USER_CHANGE_PASSWORD_SUCCESS
        });
      }   
      }).catch((error) => {
        if (error != 401){
            console.log(error)
            dispatch({
                type: constants.USER_CHANGE_PASSWORD_FAILED,
                payload: messages.USER_CHANGE_PASSWORD_FAILED
            });
        }
      })
  }

  export const resetAccountUpdateSuccess = (data) => (dispatch) => {
    dispatch({
        type: constants.USER_ACCOUNT_RESET_SHOW_SUCCESS,
        payload:data.showSuccess
    });
}