/*
 * INTEL CONFIDENTIAL
 * Copyright 2022 Intel Corporation.
 * This software and the related documents are Intel copyrighted materials, and
 * your use of them is governed by the express license under which they were
 * provided to you (License). Unless the License provides otherwise, you may not
 * use, modify, copy, publish, distribute, disclose or transmit this software or
 * the related documents without Intel's prior written permission. This software
 * and the related documents are provided as is, with no express or implied
 * warranties, other than those that are expressly stated in the License.
 */
import * as constants from '@pcwallet/common/constants'

const initialState = {
    isLoading: false,
    cardState:constants.SHOW_TRANSACTIONS,
    error: false,
    activeCard: null,
    success: false,
    errorMessage: '',
    cards: [],
    sessionId:null,
    cardId:null,
    resources:[],
    idvMethodList:[],
    terms:false,
    defaultCardId:null,
    transactions:[],
    skipVerify:false,
    isLoadingModal:false,
    addCardLoading:false,
    // activeCardState: constants.SHOW_TRANSACTIONS,

}

export default function cardsUpdate(state = initialState, { type, payload }) {
    switch (type) {
        case constants.START_CARD_ADD:
            return {
                ...state,
                error: false,
                success: false,
                cardState: constants.CARD_STATE_ADD,
            }
        case constants.GET_PAYMENT_CARDS:
            return {
                ...state,
                isLoading: true,
                error: false,
            }
        case constants.GET_PAYMENT_CARDS_SUCCESS:
            return {
                ...state,
                isLoading: false,
                // success: true,
                cards:payload.cards,
                defaultCardId:payload.dcdid,
                activeCard:payload.activeCardId?payload.cards.find(card =>{return card.id === payload.activeCardId}):payload.cards.find(card =>{return card.id === payload.dcdid}),
                error: false,
            }
        case constants.GET_PAYMENT_CARDS_SUCCESS_LIST_EMPTY:
            return {
                ...state,
                isLoading: false,
                error: false,
                cards:[],
                defaultCardId:null,
                activeCard:'',
            }
        case constants.GET_PAYMENT_CARDS_FAILED:
            return {
                ...state,
                isLoading: false,
                error: true,
                errorMessage: payload.errorMessage
            }
        case constants.START_CARD_DELETE:
            return {
                ...state,
                success: false,
                isLoading:false,
                error: false,
                cardState: constants.CARD_STATE_DELETE,
                activeCard: payload.card,
            }
        case constants.DELETE_CARD:
            return {
                ...state,
                success: false,
                isLoading: false,
                isLoadingModal: true,
                error: false,
                cardState: constants.CARD_STATE_DELETE
            }
        case constants.DELETE_CARD_SUCCESS:
            return {
                ...state,
                isLoading: false,
                isLoadingModal: false,
                success: true,
                error: false,
                cardDeleted:payload,
                cardState: constants.DELETE_CARD_SUCCESS,
            }
        case constants.DELETE_CARD_FAILED:
            return {
                ...state,
                isLoading: false,
                isLoadingModal: false,
                success: false,
                error: true,
                errorMessage: payload.errorMessage
            }
        case constants.START_SET_DEFAULT_CARD:
            return {
                ...state,
                success: false,
                isLoading:false,
                error: false,
                cardState: constants.CARD_STATE_SET_DEFAULT_CARD,
                activeCard: payload.card,
            }
        case constants.SET_DEFAULT_CARD:
            return {
                ...state,
                success: false,
                isLoading: true,
                error: false,
            }
        case constants.SET_DEFAULT_CARD_SUCCESS:
            return {
                ...state,
                isLoading: false,
                success: true,
                error: false,
                activeCard: payload.card,
                cardState: constants.SET_DEFAULT_CARD_SUCCESS
            }
        case constants.SET_DEFAULT_CARD_FAILED:
            return {
                ...state,
                isLoading: false,
                success: false,
                error: true,
                errorMessage: payload.errorMessage
            }
        case constants.PROVISION_CARD_PENDING:
            return { 
                ...state,
                error: false,
                errorMessage: null,
                isLoading: false,
                addCardLoading:false,
                error: false,
                terms:false,
                tokenId:payload,
                cardState:constants.CARD_STATE_DIGITIZE_PENDING
                }
        case constants.PROVISION_CARD_VERIFY:
            return {
                ...state,
                isLoading: false,
                addCardLoading:false,
                success: false,
                error: false,
                terms:true,
                skipVerify:payload.skipVerify,
                tokenId:payload.tokenId,
                idvMethodList:payload.verificationMethods,
                cardState:constants.CARD_STATE_VERIFY
            }
        case constants.PROVISION_CARD_VERIFY_LATER:
            return {
                ...state,
                isLoading: false,
                addCardLoading:false,
                success: false,
                error: false,
                terms:false,
                cardState:constants.CARD_STATE_VERIFY_LATER
            }
        case constants.PROVISION_CARD_TIMED_OUT:
            return {
                ...state,
                isLoading: false,
                addCardLoading:false,
                success: false,
                terms:false,
                errorMessage: payload,
                cardState:constants.PROVISION_CARD_TIMED_OUT
            }
        case constants.PROVISION_CARD_FAILED:
            return {
                ...state,
                isLoading: false,
                addCardLoading:false,
                success: false,
                error: true,
                terms:false,
                errorMessage: payload
            }
        case constants.ACTIVATE_CARD:
            return { 
                ...state,
                error: false,
                errorMessage: null,
                isLoading: false,
                addCardLoading:true,
                }
        case constants.ACTIVATE_CARD_SUCCESS:
            return { 
                ...state,
                verified: true,
                error: false,
                errorMessage: null,
                success:true,
                terms:false,
                isLoading: false,
                addCardLoading:false,
                }
        case constants.ACTIVATE_CARD_FAILED:
            return { 
                ...state,
                error: true,
                errorMessage: payload,
                isLoading: false,
                addCardLoading:false,
                }
        case constants.ADD_CARD_SUCCESS:
            return {
                ...state,
                isLoading: false,
                addCardLoading:false,
                success: true,
                error: false,
                skipVerify:payload.skipVerify,
                tokenId:payload.tokenId,
                cardState: constants.CARD_STATE_ADDED_SUCCESSFULLY,
            }
        case constants.GET_NEXT_SCRIPT:
            return { 
                ...state,
                error: false,
                errorMessage: null,
                isLoading: false,
                addCardLoading:true,
                }
        case constants.GET_NEXT_SCRIPT_SUCCESS:
            return { 
                ...state,
                error: false,
                errorMessage: null,
                isLoading: false,
                addCardLoading:false,
                }
        case constants.GET_NEXT_SCRIPT_FAILED:
            return { 
                ...state,
                error: true,
                errorMessage: payload,
                isLoading: false,
                addCardLoading:false,
                }
        case constants.SHOW_CARD_DETAILS:
            return {
                ...state,
                error: false,
                activeCard: payload.card,
                cardState: constants.SHOW_CARD_DETAILS
            }
        case constants.SHOW_TRANSACTIONS:
            return {
                ...state,
                error: false,
                activeCard: payload.card,
                cardState: constants.SHOW_TRANSACTIONS,
                transactions: []
            }
        case constants.TRANSACTIONS_LOADED:
            return {
                ...state,
                transactions: payload
            }
        case constants.CARD_STATE_CLEAR:
            return {
                ...state,
                cardState: ''
            }
        case constants.GET_NEXT_JOB_FAILED:
            return {
                ...state,
                isLoading:false,
                error:true,
                errorMessage:payload
            }
        case constants.GET_NEXT_JOB_SUCCESS:
            return {
                ...state,
                isLoading:false,
                error:false,
            }
        case constants.RESET_ERROR_STATES:
            return{
                ...state,
                errorMessage:'',
                error: false,
            }
        case constants.SHOW_ERROR_MESSAGES:
            return{
                ...state,
                errorMessage:payload,
                error: true,
            }
        case constants.CARD_NICKNAME_UPDATE:
            return{
                ...state,
                activeCard: payload.card
            }
        case constants.CARD_NICKNAME_UPDATE_SUCCESS:
            return{
                ...state,
                error: false,
                errorMessage: null,
                activeCard: {
                    ...state.activeCard,
                    ...payload.updateInfo
                },
                cards:payload.cards
            }
        case constants.CARD_NICKNAME_UPDATE_FAILED:
            return{
                ...state,
                error:true,
                errorMessage:payload
            }
        default:
            return state
    }
}